import { Group, NumberInput, Switch, TextInput, TransferList } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useEffect, useState } from "react";
import { EmissionReductionResponse } from "../../../services/WebApiService";
import { useTranslation } from "react-i18next";
import { EmissionReduction } from "../../../models/emission-reduction/EmissionReduction";

type Props = {
  form: UseFormReturnType<EmissionReduction>;
  isEditMode: boolean;
  emissionReductionId: number;
  emissionReduction?: EmissionReductionResponse;
};

const EmissionFormInputs = (props: Props) => {
  const [firstLoad, setFirstLoad] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [props, firstLoad]);

  return (
    <>
      <Group grow spacing="lg" pb="lg" align="end">
        <NumberInput label={t("cuemissionreduction_year")} description={t("cuemissionreduction_yeardesc")} {...props.form.getInputProps("Year")} />
        <NumberInput
          label={t("cuemissionreduction_percentage")}
          description={t("cuemissionreduction_percentagedesc")}
          {...props.form.getInputProps("Percentage")}
        />
      </Group>
    </>
  );
};

export default EmissionFormInputs;
