import { Group, TextInput, TransferList, TransferListData, TransferListItem } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { Company } from "../../../models/company/Company";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import AuthService from "../../../services/AuthService";
import { AccountsClient, CompanyClient, CompanyResponse, FleetClient } from "../../../services/WebApiService";
import { axiosInstance } from "../../../services/AxiosService";
import { useTranslation } from "react-i18next";

type Props = {
  form: UseFormReturnType<Company>;
  isEditMode: boolean;
  user: TransferListData;
  setUser: Dispatch<SetStateAction<TransferListData>>;
  fleet: TransferListData;
  setFleet: Dispatch<SetStateAction<TransferListData>>;
  companyId: number;
  company?: CompanyResponse;
};

const CompanyFormInputs = (props: Props) => {
  const [firstLoad, setFirstLoad] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    const loadUser = async () => {
      const accountsClient = new AccountsClient(undefined, axiosInstance);
      const companyClient = new CompanyClient(undefined, axiosInstance);
      if (props.isEditMode) {
        if (props.company) {
          const availableUserData = await companyClient.getAvailableCompanyUser(props.companyId);

          let assignedUser: TransferListItem[] = [];
          let availableUser: TransferListItem[] = [];

          if (availableUserData) {
            availableUser = availableUserData.map((x) => ({
              value: x.id,
              label: x.userName ? x.userName : "",
            }));
          }

          if (props.company.appUserCompany) {
            assignedUser = props.company.appUserCompany.map((x) => ({
              value: x.appUserId,
              label: x.applicationUser.userName ? x.applicationUser.userName : "",
            }));
          }

          props.setUser([[...availableUser], [...assignedUser]]);
        }
      } else {
        const data = await accountsClient.managedUsers();
        const availableUser = data.map((x) => ({
          value: x.id,
          label: x.username,
        }));
        props.setUser([[...availableUser], []]);
      }
    };

    const loadFleet = async () => {
      const fleetClient = new FleetClient(undefined, axiosInstance);
      const companyClient = new CompanyClient(undefined, axiosInstance);
      if (props.isEditMode) {
        const availableFleetData = await companyClient.getAvailableCompanyFleet(props.companyId);

        let assignedFleet: TransferListItem[] = [];
        let availableFleet: TransferListItem[] = [];

        if (availableFleetData) {
          availableFleet = availableFleetData.map((x) => ({
            value: String(x.id),
            label: x.name ? x.name : "",
          }));
        }

        if (props.company?.companyFleet) {
          assignedFleet = props.company.companyFleet.map((x) => ({
            value: String(x.fleetId),
            label: x.fleet.name ? x.fleet.name : "",
          }));
        }

        props.setFleet([[...availableFleet], [...assignedFleet]]);
      } else {
        const data = await fleetClient.getAll();

        const availableFleet = data.map((x) => ({
          value: String(x.id),
          label: x.name,
        }));
        props.setFleet([[...availableFleet], []]);
      }
    };

    if (firstLoad) {
      loadUser();
      loadFleet();
      setFirstLoad(false);
    }
  }, [props, firstLoad]);

  return (
    <>
      <Group grow spacing="lg" pb="lg" align="end">
        <TextInput label={t("cucompany_companyname")} description={t("cucompany_companynamedesc")} {...props.form.getInputProps("Name")} />
      </Group>

      {AuthService.isInRole("Admin") && (
        <Group spacing="lg" pb="lg" align="end" grow>
          <TransferList
            value={props.user}
            onChange={props.setUser}
            searchPlaceholder={`${t("cucompany_search")}...`}
            nothingFound={`${t("cucompany_nothinghere")}`}
            titles={[t("cucompany_availableuser"), t("cucompany_assignedusers")]}
            breakpoint="sm"
          />
        </Group>
      )}
      {AuthService.isInRole("Admin") && (
        <Group spacing="lg" pb="lg" align="end" grow>
          <TransferList
            value={props.fleet}
            onChange={props.setFleet}
            searchPlaceholder={`${t("cucompany_search")}...`}
            nothingFound={`${t("cucompany_nothinghere")}`}
            titles={[t("cucompany_availablefleets"), t("cucompany_assignedfleets")]}
            breakpoint="sm"
          />
        </Group>
      )}
    </>
  );
};

export default CompanyFormInputs;
