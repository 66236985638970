import { Group, Switch, TextInput, TransferList, TransferListData, TransferListItem } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import AuthService from "../../../services/AuthService";
import { AccountsClient, AppUser, FleetClient, FleetResponse, Vessel, VesselsClient } from "../../../services/WebApiService";
import { Fleet } from "../../../models/fleet/Fleet";
import { axiosInstance } from "../../../services/AxiosService";
import { useTranslation } from "react-i18next";

type Props = {
  form: UseFormReturnType<Fleet>;
  isEditMode: boolean;
  user: TransferListData;
  setUser: Dispatch<SetStateAction<TransferListData>>;
  vessel: TransferListData;
  setVessel: Dispatch<SetStateAction<TransferListData>>;
  fleetId: number;
  fleet?: FleetResponse;
};

const FleetFormInputs = (props: Props) => {
  const [firstLoad, setFirstLoad] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    const loadUser = async () => {
      const accountsClient = new AccountsClient(undefined, axiosInstance);
      const fleetClient = new FleetClient(undefined, axiosInstance);
      const user = AuthService.getCurrentUser();
      if (props.isEditMode) {
        if (props.fleet) {
          let availableUserData: AppUser[] = [];
          if (AuthService.isInRole("Admin")) {
            availableUserData = await fleetClient.getAllAvailableAppUserFleet(props.fleetId);
          } else {
            if (user) {
              availableUserData = await fleetClient.getAvailableAppUserFleet(props.fleetId);
            }
          }

          let assignedUser: TransferListItem[] = [];
          let availableUser: TransferListItem[] = [];

          if (availableUserData) {
            availableUser = availableUserData.map((x) => ({
              value: x.id,
              label: x.userName ? x.userName : "",
            }));
          }

          if (props.fleet.appUserFleet) {
            assignedUser = props.fleet.appUserFleet.map((x) => ({
              value: x.appUserId,
              label: x.applicationUser.userName ? x.applicationUser.userName : "",
            }));
          }

          props.setUser([[...availableUser], [...assignedUser]]);
        }
      } else {
        const data = await accountsClient.managedUsers();
        const availableUser = data.map((x) => ({
          value: x.id,
          label: x.username,
        }));
        props.setUser([[...availableUser], []]);
      }
    };

    const loadVessel = async () => {
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      const fleetClient = new FleetClient(undefined, axiosInstance);
      const user = AuthService.getCurrentUser();
      if (props.isEditMode) {
        let availableVesselData: Vessel[] = [];
        if (AuthService.isInRole("Admin")) {
          availableVesselData = await fleetClient.getAllAvailableFleetVessel(props.fleetId);
        } else {
          if (user) {
            availableVesselData = await fleetClient.getAvailableFleetVessel(props.fleetId, parseInt(user?.userid));
          }
        }

        let assignedVessel: TransferListItem[] = [];
        let availableVessel: TransferListItem[] = [];

        if (availableVesselData) {
          availableVessel = availableVesselData.map((x) => ({
            value: String(x.id),
            label: x.name ? x.name : "",
          }));
        }

        if (props.fleet?.fleetVessel) {
          assignedVessel = props.fleet?.fleetVessel.map((x) => ({
            value: String(x.vessel.id),
            label: x.vessel.name ? x.vessel.name : "",
          }));
        }

        props.setVessel([[...availableVessel], [...assignedVessel]]);
      } else {
        const data = await vesselClient.getAll();

        const availableVessel = data.map((x) => ({
          value: String(x.id),
          label: x.name,
        }));
        props.setVessel([[...availableVessel], []]);
      }
    };
    if (firstLoad) {
      loadUser();
      loadVessel();
      setFirstLoad(false);
    }
  }, [props, firstLoad]);

  return (
    <>
      <Group grow spacing="lg" pb="lg" align="end">
        <TextInput label={t("cufleet_fleetname")} description={t("cufleet_fleetnamedesc")} {...props.form.getInputProps("Name")} />
        <Switch label={t("cufleet_active")} {...props.form.getInputProps("IsActive", { type: "checkbox" })} />
      </Group>

      {AuthService.isInRole("Admin") && (
        <Group spacing="lg" pb="lg" align="end" grow>
          <TransferList
            value={props.user}
            onChange={props.setUser}
            searchPlaceholder={`${t("cufleet_search")}...`}
            nothingFound={`${t("cufleet_nothinghere")}`}
            titles={[t("cufleet_availableuser"), t("cufleet_assignedusers")]}
            breakpoint="sm"
          />
        </Group>
      )}
      {AuthService.isInRole("Admin") && (
        <Group spacing="lg" pb="lg" align="end" grow>
          <TransferList
            value={props.vessel}
            onChange={props.setVessel}
            searchPlaceholder={`${t("cufleet_search")}...`}
            nothingFound={`${t("cufleet_nothinghere")}`}
            titles={[t("cufleet_availablevessels"), t("cufleet_assignedvessels")]}
            breakpoint="sm"
          />
        </Group>
      )}
    </>
  );
};

export default FleetFormInputs;
