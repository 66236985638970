import { Navbar } from "@mantine/core";
import { useLocation } from "react-router-dom";
import AuthService from "../services/AuthService";
import ManualReportSideNav from "./ManualReportSideNav";
import VesselSideNav from "./VesselSideNav";
import DocumentationSideNav from "./DocumentationSideNav";

type Props = {};

const SideNav = (props: Props) => {
  const location = useLocation();
  const loadSideNav = () => {
    const path = location.pathname;
    const user = AuthService.getCurrentUser();
    if (user) {
      if (path.startsWith("/vessel/") && path !== "/vessel/") {
        return (
          <Navbar withBorder p="sm" width={{ base: 280 }} style={{ zIndex: 9 }}>
            <VesselSideNav />
          </Navbar>
        );
      } else if (path.startsWith("/report") && path.indexOf("manual-report-library") !== -1) {
        return (
          <Navbar withBorder p="sm" width={{ base: 280 }} style={{ zIndex: 9 }}>
            <ManualReportSideNav />
          </Navbar>
        );
      } else if (path.startsWith("/documentation/")) {
        return (
          <Navbar withBorder p="sm" width={{ base: 280 }} style={{ zIndex: 9 }}>
            <DocumentationSideNav />
          </Navbar>
        );
      }
    }
    return <></>;
  };

  return <>{loadSideNav()}</>;
};

export default SideNav;
