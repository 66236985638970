import { SimpleGrid, Select, NumberInput, Group, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import Responsive from "react-grid-layout";
import { SingleSignalMappingForm } from "../../../common/Models";
import GridHelper from "../../../helpers/GridHelper";
import { AttitudeIndicatorOptions } from "../../../models/dashboard/DashboardModels";
import {
  Classification,
  MappingDefResponse,
  CurveResponse,
  DashboardWidgetResponse,
  FuelType,
  Property,
  WidgetType,
} from "../../../services/WebApiService";
import CommonWidgetConfig from "../Common/CommonWidgetConfig";
import SingleSignalMapping from "../Common/SingleSignalMapping";
import { useTranslation } from "react-i18next";

type Props = {
  isAddWidget: boolean;
  layouts: Responsive.Layout[];
  handleCloseWidgetConfig: () => void;
  updateWidgets(widget: DashboardWidgetResponse): void;
  addWidget(widget: DashboardWidgetResponse): void;
  activeDashboardWidget?: DashboardWidgetResponse;
  classifications: MappingDefResponse[];
  mappings: MappingDefResponse[];
};

const AttitudeIndicatorConfig = (props: Props) => {
  const [header, setHeader] = useState(props.activeDashboardWidget?.header ?? "");
  const [color, setColor] = useState(props.activeDashboardWidget?.color ?? "#FFFFFF");
  const [firstLoad, setFirstLoad] = useState(true);
  const { t } = useTranslation();

  const form = useForm<AttitudeIndicatorOptions>({
    initialValues: {
      mappingPitch: { classification: Classification.NotApplicable, property: Property.NotApplicable, fuelType: FuelType.NotApplicable, index: "1" },
      mappingRoll: { classification: Classification.NotApplicable, property: Property.NotApplicable, fuelType: FuelType.NotApplicable, index: "1" },
    },
  });

  const mappingFormPitch = useForm<SingleSignalMappingForm>({
    initialValues: {
      mapping: { classification: Classification.NotApplicable, property: Property.NotApplicable, fuelType: FuelType.NotApplicable, index: "1" },
    },
  });

  const mappingFormRoll = useForm<SingleSignalMappingForm>({
    initialValues: {
      mapping: { classification: Classification.NotApplicable, property: Property.NotApplicable, fuelType: FuelType.NotApplicable, index: "1" },
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const mapFormPitch = mappingFormPitch.values;
    const mapFormRoll = mappingFormRoll.values;

    const options: AttitudeIndicatorOptions = {
      mappingPitch: mapFormPitch.mapping,
      mappingRoll: mapFormRoll.mapping,
    };
    const optionsJson = JSON.stringify(options);
    if (props.isAddWidget) {
      const newId = GridHelper.getNewUniqueId(props.layouts);
      const newObj = {
        header: header,
        color: color,
        optionJson: optionsJson,
        id: newId,
        height: 8,
        width: 10,
        x: 0,
        y: 0,
        widgetType: WidgetType.AttitudeIndicator,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.addWidget(entity);
    } else {
      const obj = props.activeDashboardWidget;
      const newObj = {
        ...obj,
        header: header,
        color: color,
        optionJson: optionsJson,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.updateWidgets(entity);
    }
    props.handleCloseWidgetConfig();
  };

  useEffect(() => {
    const loadFormValues = () => {
      if (firstLoad && props.activeDashboardWidget) {
        const obj = props.activeDashboardWidget;
        const options: AttitudeIndicatorOptions = JSON.parse(obj.optionJson);
        mappingFormPitch.setValues({
          mapping: options.mappingPitch,
        });

        mappingFormRoll.setValues({
          mapping: options.mappingRoll,
        });

        form.setValues({
          mappingPitch: options.mappingRoll,
          mappingRoll: options.mappingRoll,
        });

        setFirstLoad(false);
      }
    };
    loadFormValues();
    return () => {};
  }, [firstLoad, form, props.activeDashboardWidget, mappingFormPitch, mappingFormRoll]);

  return (
    <form className="custom" onSubmit={(e) => handleSubmit(e)}>
      <CommonWidgetConfig headerValue={header} setHeader={setHeader} color={color} setColor={setColor} />

      <SingleSignalMapping
        form={mappingFormPitch}
        label={t("dashboardwidget_attitudeindicator_mappingspitch")}
        classifications={props.classifications}
        classifProperty={props.mappings}
      />

      <SingleSignalMapping
        form={mappingFormRoll}
        label={t("dashboardwidget_attitudeindicator_mappingsroll")}
        classifications={props.classifications}
        classifProperty={props.mappings}
      />

      <Group style={{ paddingTop: "20px" }}>
        <Button type="submit">{t("dashboardwidget_attitudeindicator_submit")}</Button>
        <Button onClick={props.handleCloseWidgetConfig} variant="subtle">
          {t("dashboardwidget_attitudeindicator_cancel")}
        </Button>
      </Group>
    </form>
  );
};

export default AttitudeIndicatorConfig;
