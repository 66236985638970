import { Divider, Title, Transition, Text, Button, Group, Stack, Accordion, Table, ActionIcon, SelectItem } from "@mantine/core";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { CheckCircle, Edit } from "@mui/icons-material";
import { IconCalendar } from "@tabler/icons";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { axiosInstance } from "../../services/AxiosService";
import { TripResponse, VesselsClient, VoyageClient, VoyageResponse } from "../../services/WebApiService";
import EditVoyageModal from "./components/EditVoyageModal";
import EditTripModal from "./components/EditTripModal";
import CreateVoyageModal from "./components/CreateVoyageModal";
import { useTranslation } from "react-i18next";
import _ from "lodash";

type Props = {};
const ManageVoyage = (props: Props) => {
  const [isVoyageModalOpen, setIsVoyageModalOpen] = useState(false);
  const [isTripModelOpen, setIsTripModelOpen] = useState(false);
  const { vesselId } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [voyages, setVoyages] = useState<VoyageResponse[]>([]);
  const [editVoyage, setEditVoyage] = useState<VoyageResponse | null>(null);
  const [existingVoyages, setExistingVoyages] = useState<SelectItem[]>([]);
  const [editTrip, setEditTrip] = useState<TripResponse | null>(null);
  const [lastOpenedAccordion, setLastOpenedAccordion] = useState<string | null>();
  const [isCreateVoyageModalOpen, setIsCreateVoyageModalOpen] = useState(false);
  const [reloadExistingVoyages, setReloadExistingVoyages] = useState(false);
  const [reloadSearch, setReloadSearch] = useState(false);

  const { t } = useTranslation();

  const [dateRangeValue, setDateRangeValue] = useState<DateRangePickerValue>([
    dayjs().startOf("date").subtract(30, "day").toDate(),
    dayjs().startOf("date").toDate(),
  ]);

  const searchVoyages = useCallback(async () => {
    const start = dateRangeValue[0];
    const end = dateRangeValue[1];
    if (vesselId && start && end) {
      setLoading(true);
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      setVoyages(await vesselClient.voyages(Number(vesselId), start, end));
      setLoading(false);
    }
  }, [dateRangeValue, vesselId]);

  const loadExistingVoyages = async () => {
    const voyageClient = new VoyageClient(undefined, axiosInstance);
    const entites = await voyageClient.allExistingVoyages();
    setExistingVoyages(
      entites.map(
        (x) =>
          ({
            value: x.id,
            label: x.name,
          } as SelectItem)
      )
    );
  };

  useEffect(() => {
    if (reloadExistingVoyages) {
      setReloadExistingVoyages(false);
      loadExistingVoyages();
    }

    return () => {};
  }, [reloadExistingVoyages]);

  useEffect(() => {
    if (reloadSearch) {
      setReloadSearch(false);
      searchVoyages();
    }
    return () => {};
  }, [reloadSearch, searchVoyages]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      loadExistingVoyages();
    }
    return () => {};
  }, [firstLoad, searchVoyages, vesselId]);

  const showEditVoyageModal = (voyage: VoyageResponse) => {
    setEditVoyage(voyage);
    setIsVoyageModalOpen(true);
  };

  const showEditTripModal = (trip: TripResponse) => {
    setEditTrip(trip);
    setIsTripModelOpen(true);
  };

  const setTrip = (trip: TripResponse) => {
    setVoyages((current) =>
      current.map((x) => {
        const obj = x.trips.find((y) => y.id === trip.id);
        if (obj) {
          obj.cargo = trip.cargo;
          obj.description = trip.description;
          obj.name = trip.name;
          obj.isEuPort = trip.isEuPort;
          obj.portName = trip.portName;
          obj.voyageId = trip.voyageId;
        }
        return x;
      })
    );
  };

  const setVoyage = (voyage: VoyageResponse) => {
    setVoyages((current) =>
      current.map((x) => {
        if (x.id === voyage.id) {
          x.name = voyage.name;
          x.description = voyage.description;
          return x;
        } else {
          return x;
        }
      })
    );
  };

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <>
          <Group>
            <Title order={2}>{t("managevoyage")}</Title>
            <Button onClick={() => setIsCreateVoyageModalOpen(true)}>{t("managevoyage_createnewvoyage")}</Button>
          </Group>
          <Divider my="sm" size="md"></Divider>
          <EditVoyageModal isOpen={isVoyageModalOpen} setIsOpen={setIsVoyageModalOpen} voyage={editVoyage ?? null} setVoyage={setVoyage} />
          <EditTripModal
            isOpen={isTripModelOpen}
            setIsOpen={setIsTripModelOpen}
            existingVoyages={existingVoyages}
            trip={editTrip}
            setTrip={setTrip}
            setReloadSearch={setReloadSearch}
          />
          <CreateVoyageModal
            isOpen={isCreateVoyageModalOpen}
            setIsOpen={setIsCreateVoyageModalOpen}
            setReloadExistingVoyages={setReloadExistingVoyages}
            vesselId={Number(vesselId)}
          />
          <Group align="end">
            <DateRangePicker
              withinPortal
              required
              label="Date Range"
              value={dateRangeValue}
              onChange={setDateRangeValue}
              icon={<IconCalendar size={16} />}
              miw={350}
            />
            <Button disabled={loading} loading={loading} onClick={searchVoyages}>
              {t("managevoyage_search")}
            </Button>
          </Group>
          <Stack spacing="md" mt="xl">
            <Accordion variant="separated" value={lastOpenedAccordion} onChange={setLastOpenedAccordion}>
              {voyages.map((x) => {
                return (
                  <Accordion.Item value={x.id}>
                    <Accordion.Control>
                      <Group>
                        <Text>{x.startDateTime.toLocaleString()}</Text>
                        <Text>-</Text>
                        <Text>{x.endDateTime.toLocaleString()}</Text>
                        <Text>|</Text>
                        <Text>{x.name}</Text>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Table mb={"md"} mt="sm" withBorder>
                        <caption>{t("managevoyage_voyage")}</caption>
                        <thead>
                          <tr>
                            <th>{t("managevoyage_voyagename")}</th>
                            <th>{t("managevoyage_voyagedescription")}</th>
                            <th>{t("managevoyage_voyagestartdatetime")}</th>
                            <th>{t("managevoyage_voyageenddatetime")}</th>
                            <th>{t("managevoyage_voyagedistance")}</th>
                            <th>{t("managevoyage_voyageeeoi")}</th>
                            <th>{t("managevoyage_voyageco2emission")}</th>
                            <th>{t("managevoyage_voyageconsumption")}</th>
                            <th>{t("managevoyage_voyagecii")}</th>
                            <th>{t("managevoyage_voyageedit")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{x.name}</td>
                            <td>{x.description}</td>
                            <td>
                              <Text>{x.startDateTime.toLocaleString()}</Text>
                            </td>
                            <td>
                              <Text>{x.endDateTime.toLocaleString()}</Text>
                            </td>
                            <td>
                              <Text>{x.distance}</Text>
                            </td>
                            <td>
                              <Text>{x.eeoi}</Text>
                            </td>
                            <td>
                              <Text>{x.totalCo2Emission}</Text>
                            </td>
                            <td>
                              <Text>{x.totalConsumption}</Text>
                            </td>
                            <td>
                              <Text>{_.round(x?.ciiData[0]?.g1 == null ? 0 : x?.ciiData[0]?.g1, 2)}</Text>
                            </td>
                            <td>
                              <ActionIcon color="blue" onClick={() => showEditVoyageModal(x)}>
                                <Edit />
                              </ActionIcon>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table verticalSpacing="lg" captionSide="top" striped withBorder>
                        <caption>{t("managevoyage_tripsorted")}</caption>
                        <thead>
                          <tr>
                            <th>{t("managevoyage_tripname")}</th>
                            <th>{t("managevoyage_tripdesc")}</th>
                            <th>{t("managevoyage_tripstartdatetime")}</th>
                            <th>{t("managevoyage_tripenddatetime")}</th>
                            <th>{t("managevoyage_tripdistance")}</th>
                            <th>{t("managevoyage_tripeeoi")}</th>
                            <th>{t("managevoyage_tripcargo")}</th>
                            <th>{t("managevoyage_tripco2emission")}</th>
                            <th>{t("managevoyage_tripconsumption")}</th>
                            <th>{t("managevoyage_tripportname")}</th>
                            <th>{t("managevoyage_tripiseuport")}</th>
                            <th>{t("managevoyage_edit")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {x.trips.map((y) => {
                            return (
                              <tr>
                                <td>
                                  <Text>{y.name}</Text>
                                </td>
                                <td>
                                  <Text>{y.description}</Text>
                                </td>
                                <td>
                                  <Text>{y.startDateTime.toLocaleString()}</Text>
                                </td>
                                <td>
                                  <Text>{y.endDateTime.toLocaleString()}</Text>
                                </td>
                                <td>
                                  <Text>{y.distance}</Text>
                                </td>
                                <td>
                                  <Text>{y.eeoi}</Text>
                                </td>
                                <td>
                                  <Text>{y.cargo}</Text>
                                </td>
                                <td>
                                  <Text>{y.totalCo2Emission}</Text>
                                </td>
                                <td>
                                  <Text>{y.totalConsumption}</Text>
                                </td>
                                <td>
                                  <Text>{y.portName}</Text>
                                </td>
                                <td>
                                  {y.isEuPort ? (
                                    <Text color="green">
                                      <CheckCircle />
                                    </Text>
                                  ) : (
                                    <Text color="red">
                                      <CancelIcon color="error" />
                                    </Text>
                                  )}
                                </td>
                                <td>
                                  <ActionIcon color="blue" onClick={() => showEditTripModal(y)}>
                                    <Edit />
                                  </ActionIcon>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Accordion.Panel>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </Stack>
        </>
      )}
    </Transition>
  );
};
export default ManageVoyage;
