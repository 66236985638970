import { TextInput, Button, Group, Box, PasswordInput, Title, Container, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { openModal } from "@mantine/modals";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import { AuthClient, LoginFormModel } from "../services/WebApiService";

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const openNormalModel = (title: string, errorMsg: string) => {
    openModal({
      title: title,
      children: <Text>{errorMsg}</Text>,
    });
  };
  const login = async (values: typeof form.values) => {
    try {
      const authClient = new AuthClient(undefined, axiosInstance);
      const token = await authClient.login(values as LoginFormModel);
      if (token.errorMesg) {
        openNormalModel("Error", token.errorMesg);
      } else {
        AuthService.setCurrentUser(token.accessToken);
        const redirectPath = searchParams.get("redirect");
        if (redirectPath) {
          navigate(redirectPath);
        } else {
          navigate("/dashboard/home");
        }
      }
    } catch {
      openNormalModel("Login Fail", "Wrong credentials or Server cannot be connected!");
    }
  };

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },
  });

  return (
    <Container>
      <Box sx={{ maxWidth: 300 }} mx="auto" mt="4em">
        <Group mb="md">
          <Title order={2}>Login</Title>
        </Group>
        <form onSubmit={form.onSubmit((values) => login(values))}>
          <Stack spacing={"lg"}>
            <TextInput required label="Username" {...form.getInputProps("username")} />
            <PasswordInput required label="Password" {...form.getInputProps("password")} />
            <Group position="right" mt="md">
              <Button type="submit">Submit</Button>
            </Group>
          </Stack>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
