import { SimpleGrid, TextInput } from "@mantine/core";
import WidgetColorPicker from "./WidgetColorPicker";

import { useTranslation } from "react-i18next";

type Props = {
  setHeader(header: string): void;
  setColor(color: string): void;
  headerValue: string;
  color: string;
};

const CommonWidgetConfig = (props: Props) => {
  const { t } = useTranslation();

  return (
    <SimpleGrid className="custom-input" cols={2}>
      <WidgetColorPicker color={props.color} setColor={props.setColor} />
      <TextInput
        required
        label={t("dashboardwidget_common_header")}
        placeholder={t("dashboardwidget_common_headername")}
        onChange={(e) => props.setHeader(e.currentTarget.value)}
        defaultValue={props.headerValue}
      />
    </SimpleGrid>
  );
};

export default CommonWidgetConfig;
