import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import "./../github-markdown-light.css";

const DocumentationOnBoard = () => {
  const [markdown, setMarkdown] = useState("");

  const { category, childname } = useParams();
  const location = useLocation();

  const generateMarkDownDoc = useCallback(async () => {
    const doc = await import(`./onboard-doc/${category}/${childname}.md`);
    fetch(doc.default)
      .then((response) => response.text())
      .then((md) => {
        setMarkdown(md);
      });
  }, [category]);

  useEffect(() => {
    generateMarkDownDoc();
  }, [location, generateMarkDownDoc]);

  return (
    <React.Fragment>
      <div className="markdown-body">
        <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {markdown}
        </Markdown>
      </div>
    </React.Fragment>
  );
};

export default DocumentationOnBoard;
