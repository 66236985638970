import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React, { useCallback, useContext, useEffect, useState } from "react";
import SignalHelper from "../../../../helpers/SignalHelper";
import { MapOptions } from "../../../../models/dashboard/DashboardModels";
import {
  DashboardSignalResponse,
  DashboardVesselResponse,
  DashboardWidgetResponse,
  HubSignalResponse,
  MapWidgetSignalClient,
} from "../../../../services/WebApiService";
import GoogleMapMarker from "./GoogleMapMarker";
import GoogleMapPolyline from "./GoogleMapPolyLine";
import { GlobalContext } from "../../../../providers/GlobalContextProvider";
import { axiosInstance } from "../../../../services/AxiosService";

type GpsData = {
  latitudeValue: number;
  longitudeValue: number;
  cogValue: number;
  sogValue: number;
};

type GpsDef = {
  latitudeId: number;
  longitudeId: number;
  sogId: number;
  cogId: number;
};

type InfoWindowData = {
  title: string;
  description: string;
};

type MapState = {
  vessel: DashboardVesselResponse;
  gpsData: GpsData;
  coordinates: google.maps.LatLng[];
  infoWindowData: InfoWindowData[];
};

type MapStateDef = {
  vesselId: number;
  gpsDefs: GpsDef;
  infoSignalIds: number[];
};

type Props = {
  id: string;
  optionJson: string;
  dashboardVessels: DashboardVesselResponse[];
  dashboardWidget: DashboardWidgetResponse;
  hubSignals: HubSignalResponse[];
  dashboardType: "Fleet" | "Vessel";
};

const GoogleMapHelper = (props: Props) => {
  const [div, setDiv] = useState<HTMLDivElement>();
  const [map, setMap] = React.useState<google.maps.Map>();
  const [mapStates, setMapStates] = useState<MapState[]>([]);
  const [mapStateDefs, setMapStateDefs] = useState<MapStateDef[]>([]);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const render = (status: Status) => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return <></>;
  };
  const [markerState, setMarkerState] = useState("normal");
  const { systemGlobal } = useContext(GlobalContext);

  const updateMapState = useCallback((vesselId: number, gps: GpsData, coordinate: google.maps.LatLng, infoWindow: InfoWindowData[]) => {
    setMapStates((current) =>
      current.map((x) => {
        if (x.vessel.vesselId === vesselId) {
          return { ...x, gpsData: gps, coordinates: [...x.coordinates, coordinate], infoWindowData: infoWindow };
        } else {
          return x;
        }
      })
    );
  }, []);

  const createInfoSignals = async (signals: DashboardSignalResponse[], options: MapOptions) => {
    let infoSignals: DashboardSignalResponse[] = [];
    if (options.manualMapping) {
      infoSignals = SignalHelper.getSignalsByMappings(signals, options.mappings);
    } else {
      const mapWidgetClient = new MapWidgetSignalClient(undefined, axiosInstance);
      let signals = await mapWidgetClient.getByVesselId(systemGlobal.dashboardVesselId);
      for (const s of signals) {
        infoSignals.push({
          id: s.signal.id,
          displayName: s.signal.displayName,
          lastValue: s.signal.lastValue,
        } as DashboardSignalResponse);
      }
    }
    return infoSignals;
  };

  const createInfoWindowData = (infoSignals: DashboardSignalResponse[], latitude: DashboardSignalResponse) => {
    const info: InfoWindowData[] = [];
    info.push({
      title: "Last Updated",
      description: new Date(latitude.lastUpdatedAt).toISOString(),
    });
    for (let j = 0; j < infoSignals.length; j++) {
      info.push({
        title: infoSignals[j].displayName,
        description: infoSignals[j].lastValue.toString(),
      });
    }
    return info;
  };

  const createInfoWindowDataFromHub = (hubSignals: HubSignalResponse[], latitude: HubSignalResponse) => {
    const info: InfoWindowData[] = [];
    info.push({
      title: "Last Updated",
      description: new Date(latitude.timeStamp).toISOString(),
    });
    for (let j = 0; j < hubSignals.length; j++) {
      info.push({
        title: hubSignals[j].name,
        description: hubSignals[j].value.toString(),
      });
    }
    return info;
  };

  const measuredRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setDiv(node);
    }
  }, []);

  useEffect(() => {
    if (div) {
      const localMap = new google.maps.Map(div, {
        center: { lat: 0, lng: 0 },
        zoom: 5,
        styles:
          systemGlobal.colorTheme === "dark"
            ? [
                {
                  elementType: "geometry",
                  stylers: [
                    {
                      color: "#242f3e",
                    },
                  ],
                },
                {
                  elementType: "labels.text.fill",
                  stylers: [
                    {
                      color: "#746855",
                    },
                  ],
                },
                {
                  elementType: "labels.text.stroke",
                  stylers: [
                    {
                      color: "#242f3e",
                    },
                  ],
                },
                {
                  featureType: "administrative.locality",
                  elementType: "labels.text.fill",
                  stylers: [
                    {
                      color: "#d59563",
                    },
                  ],
                },
                {
                  featureType: "poi",
                  elementType: "labels.text.fill",
                  stylers: [
                    {
                      color: "#d59563",
                    },
                  ],
                },
                {
                  featureType: "poi.park",
                  elementType: "geometry",
                  stylers: [
                    {
                      color: "#263c3f",
                    },
                  ],
                },
                {
                  featureType: "poi.park",
                  elementType: "labels.text.fill",
                  stylers: [
                    {
                      color: "#6b9a76",
                    },
                  ],
                },
                {
                  featureType: "road",
                  elementType: "geometry",
                  stylers: [
                    {
                      color: "#38414e",
                    },
                  ],
                },
                {
                  featureType: "road",
                  elementType: "geometry.stroke",
                  stylers: [
                    {
                      color: "#212a37",
                    },
                  ],
                },
                {
                  featureType: "road",
                  elementType: "labels.text.fill",
                  stylers: [
                    {
                      color: "#9ca5b3",
                    },
                  ],
                },
                {
                  featureType: "road.highway",
                  elementType: "geometry",
                  stylers: [
                    {
                      color: "#746855",
                    },
                  ],
                },
                {
                  featureType: "road.highway",
                  elementType: "geometry.stroke",
                  stylers: [
                    {
                      color: "#1f2835",
                    },
                  ],
                },
                {
                  featureType: "road.highway",
                  elementType: "labels.text.fill",
                  stylers: [
                    {
                      color: "#f3d19c",
                    },
                  ],
                },
                {
                  featureType: "transit",
                  elementType: "geometry",
                  stylers: [
                    {
                      color: "#2f3948",
                    },
                  ],
                },
                {
                  featureType: "transit.station",
                  elementType: "labels.text.fill",
                  stylers: [
                    {
                      color: "#d59563",
                    },
                  ],
                },
                {
                  featureType: "water",
                  elementType: "geometry",
                  stylers: [
                    {
                      color: "#17263c",
                    },
                  ],
                },
                {
                  featureType: "water",
                  elementType: "labels.text.fill",
                  stylers: [
                    {
                      color: "#515c6d",
                    },
                  ],
                },
                {
                  featureType: "water",
                  elementType: "labels.text.stroke",
                  stylers: [
                    {
                      color: "#17263c",
                    },
                  ],
                },
              ]
            : [],
      });
      setMap(localMap);
    }
    return () => {};
  }, [div, systemGlobal.colorTheme]);

  useEffect(() => {
    const loadMapState = async () => {
      const resultDef: MapStateDef[] = [];
      const result: MapState[] = [];
      const resultBounds = new google.maps.LatLngBounds();
      const options = JSON.parse(props.optionJson) as MapOptions;
      for (let i = 0; i < props.dashboardVessels.length; i++) {
        const vessel = props.dashboardVessels[i];
        const latitude = SignalHelper.getLatitude(vessel.signals);
        const longitude = SignalHelper.getLongitude(vessel.signals);
        const sog = SignalHelper.getSog(vessel.signals);
        const cog = SignalHelper.getCog(vessel.signals);
        if (latitude && longitude && sog && cog) {
          const infoSignals = await createInfoSignals(vessel.signals, options);
          const info = createInfoWindowData(infoSignals, latitude);
          const coordinate = new google.maps.LatLng({
            lat: latitude.lastValue,
            lng: longitude.lastValue,
          });
          resultBounds.extend(coordinate);
          const entity = {
            vessel: vessel,
            gpsData: {
              latitudeValue: latitude.lastValue,
              longitudeValue: longitude.lastValue,
              sogValue: sog.lastValue,
              cogValue: cog.lastValue,
            },
            coordinates: [coordinate],
            infoWindowData: info,
          } as MapState;
          result.push(entity);
          const entityDef = {
            vesselId: vessel.vesselId,
            gpsDefs: {
              latitudeId: latitude.id,
              longitudeId: longitude.id,
              sogId: sog.id,
              cogId: cog.id,
            },
            infoSignalIds: infoSignals.map((x) => x.id),
          } as MapStateDef;
          resultDef.push(entityDef);
        }
      }
      setBounds(resultBounds);
      setMapStates(result);
      setMapStateDefs(resultDef);
    };

    if (map) {
      loadMapState();
    }
    return () => {};
  }, [map, props.dashboardVessels, props.optionJson]);

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds);
    }
    return () => {};
  }, [bounds, map, mapStates]);

  useEffect(() => {
    const updateMapStateFromHub = () => {
      const stateDefs = [...mapStateDefs];
      const hub = props.hubSignals;

      for (let i = 0; i < stateDefs.length; i++) {
        const stateDef = stateDefs[i];
        const latitude = hub.find((x) => x.id === stateDef.gpsDefs.latitudeId);
        const longitude = hub.find((x) => x.id === stateDef.gpsDefs.longitudeId);
        const cog = hub.find((x) => x.id === stateDef.gpsDefs.cogId);
        const sog = hub.find((x) => x.id === stateDef.gpsDefs.sogId);
        if (latitude && longitude && sog && cog) {
          const hubSignalForInfo: HubSignalResponse[] = [];
          let anyWarnAlert = "normal";
          for (let j = 0; j < stateDef.infoSignalIds.length; j++) {
            const signal = props.hubSignals.find((x) => x.id === stateDef.infoSignalIds[j]);

            if (signal?.isAlarm && (anyWarnAlert === "warn" || anyWarnAlert === "normal")) {
              anyWarnAlert = "alarm";
            } else if (signal?.isWarn && anyWarnAlert === "normal") {
              anyWarnAlert = "warn";
            }

            if (signal) {
              hubSignalForInfo.push(signal);
            }
          }

          setMarkerState(anyWarnAlert);

          const infoWindowData = createInfoWindowDataFromHub(hubSignalForInfo, latitude);
          const coordinate = new google.maps.LatLng({
            lat: latitude.value,
            lng: longitude.value,
          });
          setBounds((current) => current?.extend(coordinate));
          const gps = {
            cogValue: cog.value,
            sogValue: sog.value,
            latitudeValue: latitude.value,
            longitudeValue: longitude.value,
          } as GpsData;
          updateMapState(stateDef.vesselId, gps, coordinate, infoWindowData);
        }
      }
    };
    updateMapStateFromHub();
    return () => {};
  }, [mapStateDefs, props.hubSignals, updateMapState]);

  return (
    <>
      <Wrapper apiKey="AIzaSyBXNU1tbVuH6MZHhcZ5miKuAF51mWsGiss" render={render}>
        <div ref={measuredRef} style={{ width: "100%", height: "100%" }}>
          {mapStates.map((x) => {
            return (
              <React.Fragment key={`MarkerPolyline-${x.vessel.vesselId}`}>
                <GoogleMapMarker
                  vessel={x.vessel}
                  coordinate={x.coordinates[x.coordinates.length - 1]}
                  map={map}
                  key={`Marker-${x.vessel.vesselId}`}
                  cog={x.gpsData.cogValue}
                  infoWindowData={x.infoWindowData}
                  markerState={markerState}
                />
                <GoogleMapPolyline coordinates={x.coordinates} map={map} key={`Polyline-${x.vessel.vesselId}`} />
              </React.Fragment>
            );
          })}
        </div>
      </Wrapper>
    </>
  );
};

export default GoogleMapHelper;
