import { AccountsClient } from "../services/WebApiService";
import { useContext, useEffect, useState } from "react";
import AuthService from "../services/AuthService";
import { Link } from "react-router-dom";
import { ActionIcon, Transition, useMantineTheme } from "@mantine/core";
import NotificationHelper from "../helpers/NotiHelper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { axiosInstance } from "../services/AxiosService";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../providers/GlobalContextProvider";

type Props = {};

type VesselTableData = {
  id: number;
  name: string;
  imoNumber: number;
  callSign: string;
  shipType: string;
  timeZone: string;
  version: string;
};

const IndexVessels = (props: Props) => {
  const { systemGlobal } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });
  const mantineTheme = useMantineTheme();
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [vessels, setVessels] = useState<VesselTableData[]>([]);
  const [mounted, setMounted] = useState(false);
  const [firstUrl, setFirstUrl] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  useEffect(() => {
    const loadUserVessels = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        const accountClient = new AccountsClient(undefined, axiosInstance);
        const vessels = await accountClient.managedVessels(user.userid);
        const result: VesselTableData[] = [];
        for (let i = 0; i < vessels.length; i++) {
          const vessel = vessels[i];
          result.push({
            id: vessel.id,
            name: vessel.name,
            imoNumber: vessel.imoNumber,
            callSign: vessel.callSign,
            shipType: vessel.shipType,
            timeZone: vessel.timeZone,
            version: vessel.onboardVersion,
          });
        }
        setVessels(result);
      } else {
        NotificationHelper.showError("Authentication Error", "Please login to access vessel management");
      }
      setLoading(false);
    };

    const populateFirstUrl = () => {
      if (AuthService.isInRoleArray(["Admin", "Supervisor", "CompanyAdmin"])) {
        setFirstUrl("signal-mapping");
      } else if (AuthService.isInRole("Engineer")) {
        setFirstUrl("manual-input");
      } else {
        setFirstUrl("automatic-report");
      }
    };
    loadUserVessels();
    populateFirstUrl();
    return () => {};
  }, []);

  const loadDataGrid = () => {
    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          loading={loading}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          autoHeight
          pagination
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20]}
          rows={vessels}
          columns={[
            {
              field: "id",
              headerName: t("managevessel_id"),
              flex: 1,
            },
            {
              field: "name",
              headerName: t("managevessel_name"),
              flex: 1,
            },
            {
              field: "imoNumber",
              headerName: t("managevessel_imono"),
              flex: 1,
            },
            {
              field: "callSign",
              headerName: t("managevessel_callsign"),
              flex: 1,
            },
            {
              field: "shipType",
              headerName: t("managevessel_shiptype"),
              flex: 1,
            },
            {
              field: "timeZone",
              headerName: t("managevessel_timezone"),
              flex: 1,
            },
            {
              field: "version",
              headerName: t("managevessel_version"),
              flex: 1,
            },
            {
              field: "edit",
              headerName: t("managevessel_edit"),
              renderCell: (cell) => {
                return (
                  <ActionIcon variant="subtle" color={mantineTheme.primaryColor} component={Link} to={`/vessel/${cell.row.id}/${firstUrl}`}>
                    <Edit />
                  </ActionIcon>
                );
              },
            },
          ]}
        />
      </ThemeProvider>
    );
  };

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => <div style={styles}>{loadDataGrid()}</div>}
    </Transition>
  );
};

export default IndexVessels;
