import { ActionIcon, Badge, Button, Divider, Group, Select, Title, Transition, useMantineTheme } from "@mantine/core";
import { useParams } from "react-router-dom";

import { ChangeEventHandler, useCallback, useContext, useEffect, useState } from "react";
import AuthService from "../services/AuthService";
import NotificationHelper from "../helpers/NotiHelper";
import { Classification, FuelType, Property, SignalMappingRequest, SignalResponse, VesselsClient } from "../services/WebApiService";
import { useForm } from "@mantine/form";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
  GridRowModel,
  GridToolbar,
} from "@mui/x-data-grid";
import { Box, createTheme, TextField, ThemeProvider } from "@mui/material";
import React from "react";
import { axiosInstance } from "../services/AxiosService";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useTranslation } from "react-i18next";
import CreateCustomSignal from "./components/CreateCustomSignal";
import { Edit } from "@mui/icons-material";
import EditCustomSignal from "./components/EditCustomSignal";

type SignalMappingForm = {
  count: number;
  id: number;
  nameByPlc: string;
  displayName: string;
  classification: Classification;
  property: Property;
  fuelType: FuelType;
  index: string;
  isTotalizer: boolean;
  isPlcLiveData: boolean;
  groupByPlc: string;
};

type ModalProps = {
  isOpen: boolean;
  id: number;
  signal?: SignalResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {};
function TextInputValue(props: GridFilterInputValueProps) {
  const { item, applyValue, focusElementRef } = props;

  const ratingRef: React.Ref<any> = React.useRef(null);

  React.useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      ratingRef.current.querySelector(`input[value="${item.value || ""}"]`).focus();
    },
  }));

  const handleFilterChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    applyValue({ ...item, value: event.currentTarget.value });
    return event;
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        height: 48,
        pl: "20px",
      }}
    >
      <TextField
        label="Value"
        id="standard-basic"
        placeholder="Filter Value"
        variant="standard"
        value={item.value}
        onChange={(e) => handleFilterChange(e)}
        ref={ratingRef}
      />
    </Box>
  );
}

const SignalMapping = (props: Props) => {
  const mantineTheme = useMantineTheme();

  const { systemGlobal } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });

  const [modalProps, setmodalProps] = useState<ModalType>({
    createCustomSignal: {
      isOpen: false,
      id: 0,
    },
    editCustomSignal: {
      isOpen: false,
      id: 0,
    },
  });

  const [loading, setLoading] = useState(true);
  const [patchLoading, setPatchLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const indexArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const fuelTypes = Object.keys(FuelType);
  const { vesselId } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [signals, setSignals] = useState<SignalMappingForm[]>([]);

  const [disabled, setDisabled] = useState(false);

  const { isSystemModeVessel } = useContext(GlobalContext);

  const { t } = useTranslation();

  const applyFilterFnClassification: GridFilterOperator[] = [
    {
      label: "Contains",
      value: "contains",
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }

        return (params): boolean => {
          return filterClassification(params.row.count, String(filterItem.value));
        };
      },
      InputComponent: TextInputValue,
      // InputComponentProps: { type: "text" },
    },
  ];

  const applyQuickFilterFnClassification = (value: string) => {
    if (!value) {
      return null;
    }
    return (params: GridCellParams): boolean => {
      return filterClassification(params.row.count, value);
    };
  };

  const applyFilterFnProperty: GridFilterOperator[] = [
    {
      label: "Contains",
      value: "contains",
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }

        return (params): boolean => {
          return filterProperty(params.row.count, String(filterItem.value));
        };
      },
      InputComponent: TextInputValue,
    },
  ];

  const applyQuickFilterFnProperty = (value: string) => {
    if (!value) {
      return null;
    }
    return (params: GridCellParams): boolean => {
      return filterProperty(params.row.count, value);
    };
  };

  const applyFilterFnIsTotalizer: GridFilterOperator[] = [
    {
      label: "Contains",
      value: "contains",
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }

        return (params): boolean => {
          return filterIsTotalizer(params.row.count, String(filterItem.value));
        };
      },
      InputComponent: TextInputValue,
      // InputComponentProps: { type: "text" },
    },
  ];

  const applyQuickFilterFnIsTotalizer = (value: string) => {
    if (!value) {
      return null;
    }
    return (params: GridCellParams): boolean => {
      return filterIsTotalizer(params.row.count, value);
    };
  };

  const filterClassification = (id: number, filterValue: string) => {
    const obj = systemGlobal.mappingDefs.find((x) => x.classification === form.values[id].classification);
    if (obj) {
      const lowerCaseValue = filterValue.toLowerCase();
      return obj.classificationDisplayName.toLowerCase().includes(lowerCaseValue);
    }
    return false;
  };

  const filterProperty = (id: number, filterValue: string) => {
    const obj = systemGlobal.mappingDefs.find((x) => x.property === form.values[id].property);
    if (obj) {
      const lowerCaseValue = filterValue.toLowerCase();
      return obj.propertyDisplayName.toLowerCase().includes(lowerCaseValue);
    }
    return false;
  };

  const filterIsTotalizer = (id: number, filterValue: string) => {
    let valueToCheck = "yes";
    if (!form.values[id].isTotalizer) {
      valueToCheck = "no";
    }
    const lowerCaseValue = filterValue.toLowerCase();

    return valueToCheck === lowerCaseValue;
  };

  const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
    setSignals((x) =>
      x.map((obj, index) => {
        if (obj.id === newRow.id) {
          return { ...obj, displayName: newRow.displayName } as SignalMappingForm;
        } else {
          return obj;
        }
      })
    );
    return newRow;
  }, []);

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    console.log(error);
  }, []);

  const baseColumnDef: GridColDef[] = [
    { field: "nameByPlc", headerName: t("vesselsignalmap_namebyplc"), minWidth: 200, flex: 1 },
    {
      field: "displayName",
      headerName: t("vesselsignalmap_displayname"),
      minWidth: 200,
      flex: 1,
      editable: disabled ? false : true,
    },
    {
      field: "classification",
      headerName: t("vesselsignalmap_classification"),
      filterOperators: applyFilterFnClassification,
      getApplyQuickFilterFn: applyQuickFilterFnClassification,
      minWidth: 200,
      flex: 1,
      renderCell: (cell) => (
        <Select
          withinPortal
          style={{ width: "100%" }}
          label=""
          data={systemGlobal.classifications.map((x) => ({ value: x.classification, label: x.classificationDisplayName }))}
          disabled={disabled}
          {...form.getInputProps(`${cell.row.count}.classification`)}
        />
      ),
    },
    {
      field: "property",
      headerName: t("vesselsignalmap_property"),
      filterOperators: applyFilterFnProperty,
      getApplyQuickFilterFn: applyQuickFilterFnProperty,
      minWidth: 200,
      flex: 1,
      renderCell: (cell) => (
        <Select
          withinPortal
          label=""
          style={{ width: "100%" }}
          key={`Property${cell.row.count}`}
          data={getProperties(cell.row.count)}
          onChange={reloadProperty(cell.row.count)}
          disabled={disabled}
          {...form.getInputProps(`${cell.row.count}.property`)}
        />
      ),
    },
    {
      field: "fuelType",
      headerName: t("vesselsignalmap_fueltype"),
      minWidth: 200,
      renderCell: (cell) => (
        <Select
          withinPortal
          label=""
          key={`FuelType${cell.id}`}
          data={fuelTypes.map((x) => ({ value: x.toString(), label: x.toString() }))}
          disabled={disabled}
          {...form.getInputProps(`${cell.row.count}.fuelType`)}
        />
      ),
    },
    {
      field: "index",
      headerName: t("vesselsignalmap_index"),
      minWidth: 80,
      renderCell: (cell) => (
        <Select
          withinPortal
          label=""
          key={`Index${cell.id}`}
          data={indexArray.map((x) => ({ value: x.toString(), label: x.toString() }))}
          disabled={disabled}
          {...form.getInputProps(`${cell.row.count}.index`)}
        />
      ),
    },
    {
      field: "isTotalizer",
      headerName: t("vesselsignalmap_totalizer"),
      filterOperators: applyFilterFnIsTotalizer,
      getApplyQuickFilterFn: applyQuickFilterFnIsTotalizer,
      minWidth: 80,
      align: "center",
      renderCell: (cell) => {
        return form.values[Number(cell.row.count)].isTotalizer ? <Badge color="green">Yes</Badge> : <Badge color="red">No</Badge>;
      },
    },
    {
      field: "edit",
      headerName: t("vesselsignalmap_edit"),
      filterOperators: applyFilterFnIsTotalizer,
      getApplyQuickFilterFn: applyQuickFilterFnIsTotalizer,
      minWidth: 80,
      align: "center",
      renderCell: (cell) => {
        if (cell.row.groupByPlc !== "Custom") {
          return (
            <ActionIcon variant="subtle" color={mantineTheme.primaryColor} disabled={true}>
              <Edit />
            </ActionIcon>
          );
        } else {
          return (
            <ActionIcon variant="subtle" color={mantineTheme.primaryColor} onClick={() => showModal(cell.row.id, "editCustomSignal", cell.row)}>
              <Edit />
            </ActionIcon>
          );
        }
      },
    },
    // {
    //   field: "isPlcLiveData",
    //   headerName: "Plc Live Data",
    //   width: 80,
    //   align: "center",
    //   renderCell: (cell) => { return form.values[Number(cell.id)].isPlcLiveData ? <Badge color="green">Yes</Badge> : <Badge color="red">No</Badge> }
    // },
  ];

  const form = useForm<SignalMappingForm[]>({
    initialValues: [],
  });

  const reloadProperty = (index: number) => {
    const obj = form.values[index];
    const filtered = systemGlobal.mappingDefs.filter((x) => x.classification === obj.classification);
    const entity = filtered.find((x) => x.property === obj.property);
    if (!entity) {
      form.setFieldValue(`${index}.property`, filtered[0].property);
    }
  };

  const getProperties = (index: number) => {
    return systemGlobal.mappingDefs
      .filter((x) => x.classification === form.values[index].classification)
      .map((x) => ({ value: x.property, label: x.propertyDisplayName }));
  };

  const handleSubmit = async () => {
    setPatchLoading(true);
    const values = form.values;
    const length = Object.keys(form.values).length;
    const result: SignalMappingRequest[] = [];
    for (let i = 0; i < length; i++) {
      const entity = values[i];
      result.push({
        id: entity.id,
        classification: entity.classification,
        property: entity.property,
        fuelType: entity.fuelType,
        displayName: signals[i].displayName,
        index: Number(entity.index),
      } as SignalMappingRequest);
    }
    if (result.length > 0) {
      try {
        const vesselClient = new VesselsClient(undefined, axiosInstance);
        const user = AuthService.getCurrentUser();
        const response = await vesselClient.signalMappingsPATCH(Number(vesselId), result, user?.userid);
        // await new Promise(resolve => setTimeout(resolve, 5000));
        if (response.length > 0) {
          NotificationHelper.showSuccess("Success", "Mappings updated!");
        }
      } catch {
        NotificationHelper.showError("Error", "An error occurred in updating mappings");
      }
    }

    setPatchLoading(false);
  };

  useEffect(() => {
    setMounted(true);
    if (!isSystemModeVessel() && AuthService.isInRole("Admin") !== true) {
      setDisabled(true);
    }

    return () => {
      setMounted(false);
    };
  }, []);

  const hideModal = (type: string) => {
    setmodalProps((prevModalProps) => ({
      ...prevModalProps,
      [type]: {
        ...prevModalProps[type],
        isOpen: false,
      },
    }));
  };

  const showModal = (id: number, type: string, signal?: SignalResponse) => {
    setmodalProps((prevModalProps) => ({
      ...prevModalProps,
      [type]: {
        ...prevModalProps[type],
        isOpen: true,
        id: id,
        signal,
      },
    }));
  };

  const loadSignalsMapping = async () => {
    const user = AuthService.getCurrentUser();
    if (vesselId && user) {
      setFirstLoad(false);
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      const user = AuthService.getCurrentUser();
      const vesselSignalPromise = vesselClient.signalMappingsGET(parseInt(vesselId), null, user?.userid);
      const result: SignalMappingForm[] = [];
      const entities = await vesselSignalPromise;
      for (let i = 0; i < entities.length; i++) {
        const obj = entities[i];
        result.push({
          id: obj.id,
          classification: obj.classification,
          property: obj.property,
          displayName: obj.displayName,
          fuelType: obj.fuelType,
          index: obj.index.toString(),
          isPlcLiveData: obj.isPlcLiveData,
          isTotalizer: obj.isTotalizer,
          nameByPlc: obj.nameByPlc,
          groupByPlc: obj.groupByPlc,
          count: i,
        });
      }
      form.setValues(result);
      setSignals(result);
      setLoading(false);
    } else {
      NotificationHelper.showError("Authentication error", "Please login to access signal mapping");
    }
  };

  useEffect(() => {
    const loadSignals = async () => {
      const user = AuthService.getCurrentUser();
      if (vesselId && user) {
        if (firstLoad) {
          setFirstLoad(false);
          const vesselClient = new VesselsClient(undefined, axiosInstance);
          const user = AuthService.getCurrentUser();
          const vesselSignalPromise = vesselClient.signalMappingsGET(parseInt(vesselId), null, user?.userid);
          const result: SignalMappingForm[] = [];
          const entities = await vesselSignalPromise;
          for (let i = 0; i < entities.length; i++) {
            const obj = entities[i];
            result.push({
              id: obj.id,
              classification: obj.classification,
              property: obj.property,
              displayName: obj.displayName,
              fuelType: obj.fuelType,
              index: obj.index.toString(),
              isPlcLiveData: obj.isPlcLiveData,
              isTotalizer: obj.isTotalizer,
              nameByPlc: obj.nameByPlc,
              groupByPlc: obj.groupByPlc,
              count: i,
            });
          }
          form.setValues(result);
          setSignals(result);
          setLoading(false);
        }
      } else {
        NotificationHelper.showError("Authentication error", "Please login to access signal mapping");
      }
    };

    loadSignals();
    return () => {};
  }, [firstLoad, form, vesselId]);

  const loadDataGrid = () => {
    /* Theme provider is needed to provide to data grid since mantine also uses one*/
    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          experimentalFeatures={{ newEditingApi: true }}
          loading={loading}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          components={{ Toolbar: GridToolbar }}
          autoHeight
          pagination
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          rowsPerPageOptions={[10]}
          rows={signals}
          columns={baseColumnDef}
        />
      </ThemeProvider>
    );
  };

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Title order={2}>{t("vesselsignalmap_signalmapping")}</Title>
            <Button mt={5} onClick={() => showModal(0, "createCustomSignal")}>
              {t("vesselsignalmap_createcustomsignal")}
            </Button>
            <Divider my="sm" size="md"></Divider>
            {loadDataGrid()}
            <Group>
              <Button onClick={handleSubmit} mt={10} mb={10} loading={patchLoading} disabled={disabled}>
                {t("vesselsignalmap_submit")}
              </Button>
            </Group>

            <CreateCustomSignal modalProps={modalProps} loadSignals={loadSignalsMapping} hideModal={() => hideModal("createCustomSignal")} />

            <EditCustomSignal
              modalProps={modalProps}
              loadSignals={loadSignalsMapping}
              hideModal={() => hideModal("editCustomSignal")}
              signalId={modalProps["editCustomSignal"].id}
              signal={modalProps["editCustomSignal"].signal ? modalProps["editCustomSignal"].signal : ({} as SignalResponse)}
            />
          </div>
        )}
      </Transition>
    </>
  );
};
export default SignalMapping;
