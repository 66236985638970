import { ActionIcon, Container, Divider, Group, Stack, Title, Transition } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ArrowBack } from "@mui/icons-material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdditionKpiInput from "./components/AdditionKpiInput";
import CommonCreateKpiInput from "./components/Common/CommonCreateKpiInput";
import EeoiKpiInput from "./components/EeoiKpiInput";
import SubtractionKpiInput from "./components/SubtractionKpiInput";
import NotificationHelper from "../../helpers/NotiHelper";
import { CommonCreateKpiSetting } from "../../models/kpi/CommonCreateKpiSetting";
import { KpiClient, KpiType, SignalMappingResponse, VesselsClient, KpiPostRequest } from "../../services/WebApiService";
import { axiosInstance } from "../../services/AxiosService";
import { GlobalContext } from "../../providers/GlobalContextProvider";
import MultiplicationKpiInput from "./components/MultiplicationKpiInput";
import DivisionKpiInput from "./components/DivisionKpiInput";
import { useTranslation } from "react-i18next";
import CIIKpiInput from "./components/CIIKpiInput";

type Props = {};

const CreateKpi = (props: Props) => {
  const [mounted, setMounted] = useState(false);
  const { systemGlobal } = useContext(GlobalContext);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { vesselId } = useParams();
  const [signalMappings, setSignalMappings] = useState<SignalMappingResponse[]>([]);
  const commonForm = useForm<CommonCreateKpiSetting>({
    initialValues: {
      signalId: 0,
      // classification: Classification.NotApplicable,
      // property: Property.NotApplicable,
      // fuelType: FuelType.NotApplicable,
      // index: "1",
      // alarmNegative: 0,
      // alarmPositive: 0,
      // warningPositive: 0,
      // warningNegative: 0,
      kpiType: KpiType.Addition,
      isNetValue: false,
      order: 1,
    },
  });

  const handleBack = () => {
    navigate(`/vessel/${vesselId}/kpi`);
  };

  const handleSubmit = useCallback(
    async (optionJson: string) => {
      try {
        const commonValues = commonForm.values;
        const kpiClient = new KpiClient(undefined, axiosInstance);
        const entity = {
          // classification: commonValues.classification,
          // property: commonValues.property,
          // fuelType: commonValues.fuelType,
          // index: commonValues.index,
          // warnMin: commonValues.warningNegative,
          // warnMax: commonValues.warningPositive,
          // alarmMin: commonValues.alarmNegative,
          // alarmMax: commonValues.alarmPositive,
          kpiType: commonValues.kpiType,
          isNetValue: commonValues.isNetValue,
          order: commonValues.order,
          signalId: commonValues.signalId,
          optionJson: optionJson,
        };
        const kpiToSave = new KpiPostRequest();
        kpiToSave.init(entity);
        await kpiClient.post(kpiToSave);
        NotificationHelper.showSuccess("Success", "Kpi is created successfully");
      } catch {
        NotificationHelper.showError("Error in saving", "An error occurred in creating the kpi");
      }
    },
    [commonForm.values]
  );

  useEffect(() => {
    const loadKpi = async () => {
      if (vesselId) {
        const vesselClient = new VesselsClient(undefined, axiosInstance);
        const signals = await vesselClient.signalMappingsGET(Number(vesselId), false);
        setSignalMappings(signals);
      }
    };
    loadKpi();
    return () => {};
  }, [vesselId]);

  const loadKpiSpecificInput = useCallback(() => {
    if (commonForm.values.kpiType === KpiType.Addition) {
      return <AdditionKpiInput signalMapping={signalMappings} handleSubmit={handleSubmit} />;
    } else if (commonForm.values.kpiType === KpiType.Eeoi) {
      return <EeoiKpiInput handleSubmit={handleSubmit} />;
    } else if (commonForm.values.kpiType === KpiType.Subtraction) {
      return <SubtractionKpiInput signalMapping={signalMappings} handleSubmit={handleSubmit} />;
    } else if (commonForm.values.kpiType === KpiType.Multiplication) {
      return <MultiplicationKpiInput signalMapping={signalMappings} handleSubmit={handleSubmit} />;
    } else if (commonForm.values.kpiType === KpiType.Division) {
      return <DivisionKpiInput signalMapping={signalMappings} handleSubmit={handleSubmit} />;
    } else if (commonForm.values.kpiType === KpiType.CII) {
      return <CIIKpiInput handleSubmit={handleSubmit} signalMapping={signalMappings} />;
    }
    return <></>;
  }, [commonForm, handleSubmit, signalMappings]);

  useEffect(() => {
    setMounted(true);

    return () => {};
  }, []);

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Group>
              <ActionIcon variant="subtle" color="blue" onClick={handleBack}>
                <ArrowBack />
              </ActionIcon>
              <Title order={2}>{t("createkpi")}</Title>
            </Group>
            <Divider my="sm" size="md"></Divider>
            <Container size="xl">
              <Stack>
                <CommonCreateKpiInput
                  signalMappings={signalMappings}
                  classifications={systemGlobal.classifications}
                  mappingDefs={systemGlobal.mappingDefs}
                  form={commonForm}
                />
                {loadKpiSpecificInput()}
              </Stack>
            </Container>
          </div>
        )}
      </Transition>
    </>
  );
};

export default CreateKpi;
