import { Divider, Title, Transition, Text, Button, Group } from "@mantine/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridColDef, GridRowModel, GridToolbar } from "@mui/x-data-grid";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NotificationHelper from "../../helpers/NotiHelper";
import { GlobalContext } from "../../providers/GlobalContextProvider";
import { axiosInstance } from "../../services/AxiosService";
import { ManualInputSignalRequest, ManualInputSignalResponse, VesselsClient } from "../../services/WebApiService";
import AuthService from "../../services/AuthService";
import { useTranslation } from "react-i18next";

type Props = {};
const IndexManualInput = (props: Props) => {
  const { systemGlobal } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });

  const { vesselId } = useParams();

  const { t } = useTranslation();

  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [manualInputs, setManualInputs] = useState<ManualInputSignalResponse[]>([]);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  useEffect(() => {
    const loadManualInputs = async () => {
      if (vesselId && firstLoad) {
        setLoading(true);
        const user = AuthService.getCurrentUser();
        const vesselClient = new VesselsClient(undefined, axiosInstance);
        const data = await vesselClient.manualInputsGET(Number(vesselId), user?.userid);
        setManualInputs(data);
        setFirstLoad(false);
        setLoading(false);
      }
    };
    loadManualInputs();
    return () => {};
  }, [firstLoad, vesselId]);

  const handleSubmit = async () => {
    try {
      const entities = manualInputs.map((x) => {
        return { id: x.id, value: x.lastValue } as ManualInputSignalRequest;
      });
      const user = AuthService.getCurrentUser();
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      await vesselClient.manualInputsPATCH(Number(vesselId), entities, user?.userid);
      NotificationHelper.showSuccess("Success", "Values updated successfully for manual inputs");
    } catch {
      NotificationHelper.showError("Error", "An error occurred in updating values for manual inputs");
    }
  };

  const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
    setManualInputs((x) =>
      x.map((obj) => {
        if (obj.id === newRow.id) {
          return { ...obj, lastValue: newRow.lastValue } as ManualInputSignalResponse;
        } else {
          return obj;
        }
      })
    );
    return newRow;
  }, []);

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    console.log(error);
  }, []);

  const columnDef: GridColDef[] = [
    {
      field: "nameByPlc",
      headerName: t("vesselmanualinput_namebyplc"),
      minWidth: 300,
      flex: 1,
    },
    {
      field: "displayName",
      headerName: t("vesselmanualinput_displayname"),
      minWidth: 300,
      flex: 1,
    },
    // {
    //   field: "classification",
    //   headerName: "Classification",
    //   minWidth: 250,
    //   flex: 1,
    // },
    {
      field: "property",
      headerName: t("vesselmanualinput_property"),
      minWidth: 250,
      flex: 1,
      renderCell: (cell) => {
        if (manualInputs.length > 0) {
          const manualInput = manualInputs.find((x) => x.id === Number(cell.id));
          if (manualInput) {
            return <Text>{systemGlobal.mappingDefs.find((x) => x.property === manualInput.property)?.propertyDisplayName ?? ""}</Text>;
          }
        }
      },
    },
    {
      field: "fuelType",
      headerName: t("vesselmanualinput_fueltype"),
      minWidth: 80,
      flex: 1,
    },
    {
      field: "lastValue",
      headerName: t("vesselmanualinput_value"),
      minWidth: 250,
      flex: 1,
      editable: true,
    },
  ];

  const loadDataGrid = () => {
    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          experimentalFeatures={{ newEditingApi: true }}
          loading={loading}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          components={{ Toolbar: GridToolbar }}
          autoHeight
          pagination
          onPageSizeChange={setPageSize}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={pageSize}
          rows={manualInputs}
          columns={columnDef}
        />
      </ThemeProvider>
    );
  };

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <div style={styles}>
          <Title order={2}>{t("vesselmanualinput")}</Title>
          <Divider my="sm" size="md"></Divider>
          {loadDataGrid()}
          <Group pt="lg">
            <Button onClick={handleSubmit}>{t("vesselmanualinput_save")}</Button>
          </Group>
        </div>
      )}
    </Transition>
  );
};
export default IndexManualInput;
