import { useMantineTheme, ColorInput } from "@mantine/core";
import { useTranslation } from "react-i18next";

type Props = {
  color: string;
  setColor(color: string): void;
};

const WidgetColorPicker = (props: Props) => {
  const theme = useMantineTheme();

  const { t } = useTranslation();

  /**
   * Load CheckIcon with color swatch is clicked. Default white icon. Black icon for white color swatch
   * @param loopColor
   * @returns JSX.Element
   */
  // const loadCheckIcon = (loopColor: string): JSX.Element => {
  //   if (props.color === loopColor) {
  //     if (props.color !== "onlywhite") {
  //       return (
  //         <CheckIcon width={12} height={12} />
  //       )
  //     } else {
  //       return (
  //         <CheckIcon color="black" width={12} height={12} />
  //       )
  //     }
  //   }
  //   return (
  //     <></>
  //   )
  // }
  // const colors = Object.keys(theme.colors).map((loopColor) => (
  //   <ColorSwatch
  //     color={theme.colorScheme === 'dark' ? theme.colors[loopColor][7] : theme.colors[loopColor][5]}
  //     // component="button"
  //     key={loopColor}
  //     onClick={() => props.setColor(loopColor)}
  //     radius="sm"
  //     sx={{
  //       cursor: 'pointer',
  //       display: 'flex',
  //       alignItems: 'center',
  //       justifyContent: 'center',
  //       color: theme.colorScheme === 'dark' ? theme.colors[loopColor][2] : theme.white,
  //       // flex: '1 0 calc(15% - 4px)',
  //     }}
  //   >
  //     {loadCheckIcon(loopColor)}
  //   </ColorSwatch>
  // ));

  const colorArray = Object.keys(theme.colors).map((loopColor) =>
    theme.colorScheme === "dark" ? theme.colors[loopColor][7] : theme.colors[loopColor][5]
  );

  return (
    <ColorInput
      dropdownZIndex={999}
      swatchesPerRow={8}
      placeholder={t("dashboardwidget_widgetcolor_pickcolor")}
      label={t("dashboardwidget_widgetcolor_widgetcolor")}
      required
      disallowInput
      withPicker={false}
      onChange={props.setColor}
      defaultValue={props.color}
      swatches={[...colorArray]}
    />
  );
};

export default WidgetColorPicker;
