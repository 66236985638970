import { JSONContent } from "@tiptap/react";
import { Mapping } from "../common/Mapping";

export type DashboardOptions = {
  name: string;
  vesselId: string;
  fleetId: string;
  userId: string;
  isFleetDashboard: boolean;
  isSystemDashboard: boolean;
  category: string;
  backgroundImage: string;
  isDefault: boolean;
};

export type MappingGroup = {
  name: string;
  mappings: Mapping[];
};

export type TimeChartOptions = {
  interval: string;
  showVesselName: string;
  chartType: ChartType;
  maxPoints: number;
  showAlarm: string;
  mappings: Mapping[];
};

export type Options = {
  interval: string;
  showVesselName: string;
  chartType: ChartType;
  maxPoints: number;
  showAlarm: string;
  mappings: Mapping[];
};

export type ScatterChartOptions = {
  interval: string;
  maxPoints: number;
  yAxisMapping: Mapping;
  xAxisMapping: Mapping;
  curveId: string | null;
};

export type CIIChartOptions = {
  interval: string;
  maxPoints: number;
  mapping: Mapping;
};

export type AttitudeIndicatorOptions = {
  mappingPitch: Mapping;
  mappingRoll: Mapping;
};

export type CardValueOptions = {
  mapping: Mapping;
};

export type InstantValueOptions = {
  mappingGroups: MappingGroup[];
};

export type RadialGaugeOptions = {
  width: number;
  height: number;
  valueFontSize: number;
  titleFontSize: number;
  showVesselName: string;
  steps: number;
  layout: string;
  mappings: Mapping[];
  manualOverride: boolean;
  warnStart: number;
  alarmStart: number;
  max: number;
};

export type TextEditorOptions = {
  data: JSONContent;
};

export type MapOptions = {
  mapType: string;
  manualMapping: boolean;
  mappings: Mapping[];
};

export enum ChartType {
  Line = "Line",
  Bar = "Bar",
}
