import { ActionIcon, Button, Card, Container, Divider, Group, PasswordInput, Select, Stack, TextInput, Title, Transition } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ArrowBack } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotificationHelper from "../helpers/NotiHelper";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import {
  AccountsClient,
  AppUserChangePasswordRequest,
  AppUserPatchRequest,
  AppUserResetPasswordRequest,
  AppUserResponse,
  SelectItemResponse,
  UserRolesClient,
} from "../services/WebApiService";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useTranslation } from "react-i18next";

type Props = {};
const EditUser = (props: Props) => {
  const [mounted, setMounted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [userRoles, setUserRoles] = useState<SelectItemResponse[]>([]);
  const { isSystemModeVessel } = useContext(GlobalContext);
  const { userId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const form = useForm<AppUserResponse>({
    initialValues: {
      email: "",
      id: "",
      username: "",
      role: "User",
      init: () => {},
      toJSON: () => {},
    },
  });

  const passwordForm = useForm<AppUserChangePasswordRequest>({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      init: () => {},
      toJSON: () => {},
    },
  });

  const resetPasswordForm = useForm<AppUserResetPasswordRequest>({
    initialValues: {
      newPassword: "",
      init: () => {},
      toJSON: () => {},
    },
  });

  const loadPasswordCard = () => {
    const user = AuthService.getCurrentUser();
    if (user) {
      if (user.userid === userId) {
        return (
          <Card withBorder p="md" shadow="md">
            <Card.Section withBorder p="md">
              <Title order={3}>{t("usersetting_password")}</Title>
            </Card.Section>
            <Card.Section p="md" withBorder>
              <Stack>
                <Group grow>
                  <PasswordInput label={t("usersetting_currentpassword")} {...passwordForm.getInputProps("currentPassword")} />
                  <PasswordInput label={t("usersetting_newpassword")} {...passwordForm.getInputProps("newPassword")} />
                </Group>
              </Stack>
            </Card.Section>
            <Card.Section p="md" withBorder>
              <Button onClick={handleChangePassword} loading={passwordLoading}>
                {t("usersetting_changepassword")}
              </Button>
            </Card.Section>
          </Card>
        );
      } else {
        return (
          <Card withBorder p="md" shadow="md">
            <Card.Section withBorder p="md">
              <Title order={3}>{t("usersetting_resetpassword")}</Title>
            </Card.Section>
            <Card.Section p="md" withBorder>
              <Stack>
                <Group grow>
                  <PasswordInput label={t("usersetting_newpassword")} {...resetPasswordForm.getInputProps("newPassword")} />
                </Group>
              </Stack>
            </Card.Section>
            <Card.Section p="md" withBorder>
              <Button onClick={handleResetPassword} loading={passwordLoading}>
                {t("usersetting_resetpassword")}
              </Button>
            </Card.Section>
          </Card>
        );
      }
    }
  };

  const handleSave = async () => {
    if (!form.validate().hasErrors && userId) {
      setLoading(true);
      try {
        const values = form.values;
        const accountClient = new AccountsClient(undefined, axiosInstance);
        const entity = {
          email: values.email,
          username: values.username,
          role: values.role,
        } as AppUserPatchRequest;
        await accountClient.patch(userId, entity);
        NotificationHelper.showSuccess("Updated success", "User updated successfully");
      } catch {
        NotificationHelper.showError(
          "Error",
          "An error occurred in updating user. Please check your password make sure it meets the requirements and username/email is not duplicate!"
        );
      }
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (userId) {
      setPasswordLoading(true);
      const values = passwordForm.values;
      const accountClient = new AccountsClient(undefined, axiosInstance);
      const entity = {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      } as AppUserChangePasswordRequest;
      try {
        const response = await accountClient.changePassword(userId, entity);
        if (response.succeeded) {
          NotificationHelper.showSuccess("Success", "Password changed successfully");
        } else {
          NotificationHelper.showError("Error", response.errors![0].description!);
        }
      } catch {
        NotificationHelper.showError("Error", "An error occurred in changing password");
      }
      setPasswordLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (userId) {
      setPasswordLoading(true);
      const values = resetPasswordForm.values;
      const accountClient = new AccountsClient(undefined, axiosInstance);
      const entity = {
        newPassword: values.newPassword,
      } as AppUserResetPasswordRequest;
      try {
        const response = await accountClient.resetPassword(userId, entity);
        if (response.succeeded) {
          NotificationHelper.showSuccess("Success", "Password changed successfully");
        } else {
          NotificationHelper.showError("Error", response.errors![0].description!);
        }
      } catch {
        NotificationHelper.showError("Error", "An error occurred in resetting password");
      }
      setPasswordLoading(false);
    }
  };

  const handleBack = () => {
    navigate(`/users`);
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }

    return () => {};
  }, [mounted]);

  useEffect(() => {
    const loadUserRoles = async () => {
      const user = AuthService.getCurrentUser();
      if (user && userRoles.length === 0) {
        const userRolesClient = new UserRolesClient(undefined, axiosInstance);
        let data = [];
        if (isSystemModeVessel()) data = await userRolesClient.get();
        else data = await userRolesClient.get(true);

        if (AuthService.isInRole("User")) {
          data = data.filter((x) => x.label !== "Admin");
          data = data.filter((x) => x.label !== "Supervisor");
          data = data.filter((x) => x.label !== "Engineer");
        } else if (AuthService.isInRole("Engineer")) {
          data = data.filter((x) => x.label !== "Admin");
          data = data.filter((x) => x.label !== "Supervisor");
        } else if (AuthService.isInRole("Supervisor")) {
          data = data.filter((x) => x.label !== "Admin");
        }

        setUserRoles(data);
      }
    };

    const loadUser = async () => {
      if (userId) {
        const accountClient = new AccountsClient(undefined, axiosInstance);
        const data = await accountClient.get(userId);
        form.setFieldValue("id", data.id);
        form.setFieldValue("email", data.email);
        form.setFieldValue("username", data.username);
        form.setFieldValue("role", data.role);
      }
    };
    if (firstLoad) {
      setFirstLoad(false);
      loadUserRoles();
      loadUser();
    }

    return () => {};
  }, [firstLoad, form, userId, userRoles, isSystemModeVessel]);

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <Container size="lg" style={styles}>
          <Group>
            <ActionIcon variant="subtle" color="blue" onClick={handleBack}>
              <ArrowBack />
            </ActionIcon>
            <Title order={2}>{t("usersetting")}</Title>
          </Group>
          <Divider my="sm" size="md"></Divider>
          <Stack>
            <Card withBorder p="md" shadow="md">
              <Card.Section withBorder p="md">
                <Title order={3}>{t("usersetting_edituser")}</Title>
              </Card.Section>
              <Card.Section p="md" withBorder>
                <Stack>
                  <Group grow>
                    <TextInput label={t("usersetting_username")} required {...form.getInputProps("username")} />
                    <TextInput label={t("usersetting_email")} {...form.getInputProps("email")} />
                    <Select withinPortal required label={t("usersetting_userrole")} data={userRoles} {...form.getInputProps("role")} />
                  </Group>
                </Stack>
              </Card.Section>
              <Card.Section p="md">
                <Button onClick={handleSave} loading={loading}>
                  {t("usersetting_save")}
                </Button>
              </Card.Section>
            </Card>
            {loadPasswordCard()}
          </Stack>
        </Container>
      )}
    </Transition>
  );
};
export default EditUser;
