import * as echarts from "echarts/core";
import waldenTheme from "../chart-theme/custom-walden.json";
import {
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  MarkAreaComponent,
  MarkAreaComponentOption,
  DataZoomComponent,
  DataZoomComponentOption,
} from "echarts/components";
import { BarChart, BarSeriesOption, LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useForm } from "@mantine/form";
import { Button, Card, Divider, Drawer, Flex, Select, SelectItem, Stack, Title } from "@mantine/core";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import DateRangeIcon from "@mui/icons-material/DateRange";
import dayjs from "dayjs";
import { Token } from "../common/Models";
import {
  AccountsClient,
  CIIDataResponse,
  ChartQueryData,
  ChartQueryVoyageData,
  Kpi,
  KpiClient,
  KpiType,
  Signal,
  SignalCIIDataResponse,
  SignalDataClient,
  SignalDataResponse,
  SignalDataVoyageResponse,
  VesselsClient,
  VoyageClient,
} from "../services/WebApiService";
import { axiosInstance } from "../services/AxiosService";
import AuthService from "../services/AuthService";
import NotificationHelper from "../helpers/NotiHelper";
import { DateService } from "../services/DateService";
import { MarkAreaOption, YAXisOption } from "echarts/types/dist/shared";
import { Table } from "@mantine/core";
import { Grid } from "@mantine/core";

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  MarkAreaComponent,
  DataZoomComponent,
]);

type EChartsOption = echarts.ComposeOption<
  | ToolboxComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | BarSeriesOption
  | LineSeriesOption
  | MarkAreaComponentOption
  | DataZoomComponentOption
>;

type CartesianAxisPosition = "top" | "bottom" | "left" | "right";

type CIIChartForm = {
  vesselId: string;
  signalId: string;
  dateRange?: DateRangePickerValue;
  interval: string;
  voyageId: string;
  vesselSignals: vesselSignals[];
};

type vesselSignals = {
  vesselId: string;
  signalIds: string[];
};

type UnitAxisItem = {
  name: string;
  min?: number | undefined;
  max?: number | undefined;
};

type DataItem = {
  name: string;
  isWarn: boolean;
  isAlarm: boolean;
  unit: string;
  value: [string, number];
};

const CIIChart = () => {
  const location = useLocation();
  const [chart, setChart] = useState<echarts.ECharts>();
  const [firstLoad, setFirstLoad] = useState(true);
  const [signals, setSignals] = useState<Kpi[]>([]);
  const [ciiData, setCiiData] = useState<CIIDataResponse>();
  const [vessels, setVessels] = useState<SelectItem[]>([]);
  const [signalSelectItems, setSignalSelectItems] = useState<SelectItem[]>([]);
  const [historyType, setHistoryType] = useState("Vessel");
  const [drawerData, setDrawerData] = useState(false);

  const { isSystemModeVessel, systemGlobal } = useContext(GlobalContext);

  const { t } = useTranslation();
  const colorThemeRef = useRef(systemGlobal.colorTheme);

  const form = useForm<CIIChartForm>({
    initialValues: {
      vesselId: "",
      signalId: "",
      dateRange: [dayjs().startOf("date").subtract(1, "day").toDate(), dayjs().startOf("date").toDate()],
      interval: "Daily",
      voyageId: "",
      vesselSignals: [{ vesselId: "", signalIds: [] }],
    },
    validate: {
      dateRange: (value?: DateRangePickerValue) => (!value ? "Please set a date range" : null),
    },
  });

  const handleGenerate = async () => {
    try {
      const values = form.values;

      const interval = values.interval;
      if ((interval === "Daily" || interval === "Weekly" || interval === "Monthly") && values.dateRange !== undefined) {
        const start = values.dateRange[0];
        const end = values.dateRange[1];
        const signal_obj = JSON.parse(values.signalId);
        const signalId = [Number(signal_obj.signalId)];

        if (start && end) {
          const startDate = DateService.toStartOfDay(start);
          const endDate = DateService.toStartOfDay(end);
          const signalDataClient = new SignalDataClient(undefined, axiosInstance);

          let signalCIIData: SignalCIIDataResponse = await signalDataClient.datetimeCII(signalId, 1440, startDate, endDate, historyType);
          setCiiData(signalCIIData.ciiData);
          createChart(signalCIIData.chartQueryData, signalCIIData.ciiData);
        }
      } else if (interval === "Voyage" && values.dateRange !== undefined) {
        const start = values.dateRange[0];
        const end = values.dateRange[1];
        const signal_obj = JSON.parse(values.signalId);
        const signalId = [Number(signal_obj.signalId)];

        if (start && end) {
          const startDate = DateService.toStartOfDay(start);
          const endDate = DateService.toStartOfDay(end);
          const signalDataClient = new SignalDataClient(undefined, axiosInstance);

          let signalCIIData: SignalCIIDataResponse = await signalDataClient.datetimeCIIVoyage(signalId, 1440, startDate, endDate, historyType);
          setCiiData(signalCIIData.ciiData);
          createChart(signalCIIData.chartQueryData, signalCIIData.ciiData);
        }
      } else {
        NotificationHelper.showError("Please check your fields", "Fill in all the required fields that cannot be blank!");
        return;
      }
    } catch (error) {
      NotificationHelper.showError("Error Retrieving Data", "No data found!");
    }
  };

  const roundUp = (numToRound: number, multiple: number) => {
    const remainder = numToRound % multiple;
    if (remainder === 0) {
      return numToRound;
    }
    return numToRound + multiple - remainder;
  };

  const calculateIntervalSteps = useCallback((max?: number) => {
    if (max) {
      const roundUpMax = roundUp(max, 1000);
      return roundUpMax / 5;
    }
    return undefined;
  }, []);

  const getUniqueUnitArray = (signals: ChartQueryData[]): UnitAxisItem[] => {
    const units: UnitAxisItem[] = [];
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      const existingUnitIndex = units.findIndex((unit) => unit.name === signal.unitDescription);

      if (existingUnitIndex === -1) {
        units.push({
          name: signal.unitDescription,
          min: signal.viewScaleMin ?? undefined,
          max: signal.viewScaleMax ?? undefined,
        });
      } else {
        const existingUnit = units[existingUnitIndex];
        if (signal.viewScaleMin !== undefined && signal.viewScaleMin !== null) {
          if (existingUnit.min === undefined || signal.viewScaleMin < existingUnit.min) {
            existingUnit.min = signal.viewScaleMin;
          }
        }
        if (signal.viewScaleMax !== undefined && signal.viewScaleMax !== null) {
          if (existingUnit.max === undefined || signal.viewScaleMax > existingUnit.max) {
            existingUnit.max = signal.viewScaleMax;
          }
        }
      }
    }
    return units;
  };

  const createLineSeriesArray = (signals: ChartQueryData[] | ChartQueryVoyageData[], units: UnitAxisItem[], ciiDataRes: CIIDataResponse) => {
    const ratings = ["A", "B", "C", "D", "E"];
    const ratingsObj: { [key: string]: any } = {
      A: {
        label: {
          fontSize: 25,
          position: "right",
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "RGB(149, 218, 160)",
            },
            {
              offset: 1,
              color: "RGB(66, 157, 74)",
            },
          ]),
        },
      },
      B: {
        label: {
          fontSize: 25,
          position: "right",
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "RGB(221, 245, 218)",
            },
            {
              offset: 1,
              color: "RGB(159, 231, 163)",
            },
          ]),
        },
      },
      C: {
        label: {
          fontSize: 25,
          position: "right",
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "RGB(251, 249, 200)",
            },
            {
              offset: 1,
              color: "RGB(250, 247, 179)",
            },
          ]),
        },
      },
      D: {
        label: {
          fontSize: 25,
          position: "right",
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "RGB(253, 234, 205)",
            },
            {
              offset: 1,
              color: "RGB(249, 213, 155)",
            },
          ]),
        },
      },
      E: {
        label: {
          fontSize: 25,
          position: "right",
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "RGB(247, 192, 194)",
            },
            {
              offset: 1,
              color: "RGB(224, 149, 131)",
            },
          ]),
        },
      },
    };

    let indexOfRating = ratings.indexOf(ciiDataRes?.ratings ? ciiDataRes?.ratings : "");
    let min: string = "",
      max: string = "",
      mid: string = "";
    const indexMin: number = indexOfRating - 1;
    const index: number = indexOfRating;
    const indexMax: number = indexOfRating + 1;

    if (indexOfRating !== -1) {
      if (indexMin === -1) {
        max = ratings[indexMax];
        mid = ratings[index];
      } else if (indexMax === 6) {
        min = ratings[indexMin];
        mid = ratings[index];
      } else {
        min = ratings[indexMin];
        max = ratings[indexMax];
        mid = ratings[index];
      }
    }

    const ratingsFunction = (indexMinMax: number) => {
      let yAxisFrist = 0;
      let yAxisSec = 0;

      if (ratings[indexMinMax] === "A") {
        yAxisFrist = 0;
        yAxisSec = ciiDataRes.a;
      } else if (ratings[indexMinMax] === "B") {
        yAxisFrist = ciiDataRes.a;
        yAxisSec = ciiDataRes.b;
      } else if (ratings[indexMinMax] === "C") {
        yAxisFrist = ciiDataRes.b;
        yAxisSec = ciiDataRes.c;
      } else if (ratings[indexMinMax] === "D") {
        yAxisFrist = ciiDataRes.c;
        yAxisSec = ciiDataRes.d;
      } else if (ratings[indexMinMax] === "E") {
        yAxisFrist = ciiDataRes.d;
        yAxisSec = ciiDataRes.e;
      }

      return [yAxisFrist, yAxisSec];
    };

    const markAreaData: MarkAreaOption["data"] = [];
    if (min !== "") {
      let minVal = ratingsFunction(indexMin);

      markAreaData.push([
        {
          name: min,
          yAxis: minVal[0],
          ...ratingsObj[min],
        },
        {
          yAxis: minVal[1],
        },
      ]);
    }

    if (mid !== "") {
      let midVal = ratingsFunction(indexMax);

      markAreaData.push([
        {
          name: mid,
          yAxis: midVal[0],
          ...ratingsObj[mid],
        },
        {
          yAxis: midVal[1],
        },
      ]);
    }

    if (max !== "") {
      let maxVal = ratingsFunction(index);

      markAreaData.push([
        {
          name: max,
          yAxis: maxVal[0],
          ...ratingsObj[max],
        },
        {
          yAxis: maxVal[1],
        },
      ]);
    }

    function isTypeSignalDataVoyageResponse(obj: any): obj is SignalDataResponse {
      return "name" in obj;
    }

    const result = Array<LineSeriesOption>();
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      for (let j = 0; j < units.length; j++) {
        const unit = units[j];
        if (unit.name === signal.unitDescription) {
          const data = signal.signalData.map(
            (x) =>
              ({
                value: [x.timeStamp.toISOString(), x.value],
                name: isTypeSignalDataVoyageResponse(x) ? (x as SignalDataVoyageResponse).name : null,
                isAlarm: x.isAlarm,
                isWarn: x.isWarn,
                unit: signal.unitDescription,
              } as DataItem)
          );

          const values = form.values;
          const interval = values.interval;

          if (interval === "Daily" || interval === "Weekly" || interval === "Monthly") {
            result.push({
              type: "line",
              name: `${signal.displayName} [${signal.unitDescription}]`,
              yAxisIndex: j,
              data: data,
              markArea: {
                itemStyle: {
                  color: "rgba(255, 173, 177, 0.4)",
                },
                data: markAreaData,
              },
            });
          } else {
            result.push({
              type: "line",
              name: `${signal.displayName} [${signal.unitDescription}]`,
              yAxisIndex: j,
              data: data,
              markArea: {
                itemStyle: {
                  color: "rgba(255, 173, 177, 0.4)",
                },
                data: markAreaData,
              },
              label: {
                show: true,
                position: "top",
                formatter: "{b}",
                color: "black",
                fontSize: 20,
                padding: 10,
                borderRadius: 10,
                borderColor: "black",
                borderWidth: 2,
                backgroundColor: "white",
                distance: 15,
              },
            });
          }
        }
      }
    }
    return result;
  };

  const createYAxisArray = useCallback(
    (units: UnitAxisItem[]) => {
      const result = Array<YAXisOption>();
      let position: CartesianAxisPosition = "left";
      let offset = 0;
      let oddOffset = 0;
      let evenOffset = 0;
      const axisBarOffset = 58;

      for (let i = 0; i < units.length; i++) {
        const unit = units[i];
        if (i % 2 === 0) {
          position = "left";
          if (i !== 0) {
            evenOffset += axisBarOffset;
            offset = evenOffset;
          }
        } else {
          position = "right";
          if (i !== 1) {
            oddOffset += axisBarOffset;
            offset = oddOffset;
          }
        }
        const interval = calculateIntervalSteps(unit.max);
        result.push({
          type: "value",
          nameLocation: "middle",
          nameGap: 42,
          name: unit.name,
          nameTextStyle: { color: colorThemeRef.current === "dark" ? "white" : "black" },
          offset: offset,
          position: position,
          interval: interval,
          alignTicks: true,
          axisLine: {
            show: true,
          },
          axisLabel: {
            formatter: `{value}`,
            color: colorThemeRef.current === "dark" ? "white" : "black",
          },
          max: unit.max,
          min: unit.min,
        });
      }

      return result;
    },
    [calculateIntervalSteps]
  );

  const createChart = (chartData: ChartQueryData[] | ChartQueryVoyageData[], ciiDataRes: CIIDataResponse) => {
    const chartDom = document.getElementById(`chart`);
    if (chart) {
      chart.dispose();
    }

    if (!chartDom) {
      return;
    }
    echarts.registerTheme("walden", waldenTheme);
    const timeChart = echarts.init(chartDom, "walden");

    const units = getUniqueUnitArray(chartData);
    const seriesArray = createLineSeriesArray(chartData, units, ciiDataRes);
    const legends = chartData.map((x) => {
      return `${x.displayName} [${x.unitDescription}]`;
    });

    const yAxisArray = createYAxisArray(units);
    const yAxisIndex = [...Array(yAxisArray.length).keys()];
    console.log(yAxisArray);
    const eChartsOption: EChartsOption = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
        formatter: (params: any, ticket: string) => {
          let formatter = "";
          formatter = `<p style="font-size: 16px; margin-bottom: 8px">${params[0].axisValueLabel}<p>`;
          if (params[0].data.name !== null) formatter += `<p style="font-size: 12px; margin-bottom: 8px">${params[0].data.name}<p>`;
          let labels = `<div style="display:flex; flex-direction:column; padding-right: 12px">`;
          let values = `<div style="display:flex; flex-direction:column; padding-right: 3px">`;
          let units = `<div style="display:flex; flex-direction:column">`;
          for (let i = 0; i < params.length; i++) {
            const obj = params[i];
            labels += `
            <div>
              ${obj.marker} ${obj.seriesName}
            </div>
            `;

            units += `
            <div style="display:flex">
              <p>${obj.data.unit}</p>
            </div>
            `;
          }
          values += `</div>`;
          labels += `</div>`;
          units += `</div>`;
          formatter += `
          <div style="display:flex">
            ${labels}
            ${values}
            ${units}
          </div>
          `;
          return formatter;
        },
      },
      dataZoom: [
        {
          type: "inside",
          throttle: 50,
        },
        {
          show: true,
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
        {
          show: true,
          type: "slider",
          yAxisIndex: yAxisIndex,
          filterMode: "none",
        },
      ],
      toolbox: {
        feature: {
          dataView: { show: true, readOnly: false },
          // restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        textStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
        data: legends,
      },
      xAxis: [
        {
          type: "time",
          nameTextStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
          axisLabel: {
            color: systemGlobal.colorTheme === "dark" ? "white" : "black",
          },
        },
      ],
      yAxis: yAxisArray,
      series: seriesArray,
    };

    if (yAxisArray.length !== 0 || seriesArray.length !== 0) {
      timeChart.setOption(eChartsOption);
    } else {
      timeChart.setOption({});
    }
    setChart(timeChart);
  };

  useEffect(() => {
    const loadVessels = async (user: Token) => {
      const accountClient = new AccountsClient(undefined, axiosInstance);
      const entities = await accountClient.vessels(user.userid);
      setVessels(
        entities.map((x) => ({
          value: x.id.toString(),
          label: x.name,
        }))
      );

      if (!isSystemModeVessel() && systemGlobal.dashboardVesselId) {
        form.setFieldValue("vesselId", String(systemGlobal.dashboardVesselId));
      } else {
        form.setFieldValue("vesselId", entities[0].id.toString());
      }
    };

    if (location.pathname.includes("fleet") && historyType !== "Fleet") {
      setFirstLoad(true);
      setHistoryType("Fleet");
    }

    if (!location.pathname.includes("fleet") && historyType !== "Vessel") {
      setFirstLoad(true);
      setHistoryType("Vessel");
    }

    const user = AuthService.getCurrentUser();
    if (user && firstLoad) {
      setFirstLoad(false);
      loadVessels(user);
    }
    return () => {};
  }, [firstLoad, form, isSystemModeVessel, systemGlobal.dashboardVesselId]);

  useEffect(() => {
    const loadSignals = async () => {
      const kpiClient = new KpiClient(undefined, axiosInstance);

      if (isSystemModeVessel()) {
        const signals = await kpiClient.getByTypeVesselID(KpiType.CII, Number(form.values.vesselId));
        setSignalSelectItems(
          signals.map((x) => {
            var optionJSONobj = JSON.parse(x.optionJson);
            return { value: JSON.stringify(x), label: `${x.signal.displayName} (${optionJSONobj?.DataInterval})` };
          })
        );
        setSignals(signals);
      } else {
        // const user = AuthService.getCurrentUser();
        // const signals = await kpiClient.signalMappingsGET(Number(form.values.vesselId), null, user?.userid);
        // setSignalSelectItems(
        //   signals.map((x) => ({
        //     value: x.id.toString(),
        //     label: x.displayName,
        //   }))
        // );
      }
    };

    if (form.values.vesselId) {
      form.setFieldValue("signalIds", []);
      loadSignals();
    }
    return () => {};
  }, [form.values.vesselId, isSystemModeVessel]);

  return (
    <>
      <Stack>
        <Title order={4}>{t("ciichartht")}</Title>
        <Divider size="sm" mb="sm"></Divider>
      </Stack>
      <Flex align="end" gap="md" mb="lg">
        <DateRangePicker label={t("ciichartht_daterange")} icon={<DateRangeIcon fontSize="small" />} miw={350} {...form.getInputProps("dateRange")} />

        <Select
          label={t("ciichartht_vessel")}
          data={vessels}
          searchable
          nothingFound={t("ciichartht_nothingfound")}
          placeholder={t("ciichartht_selectvessel")}
          {...form.getInputProps("vesselId")}
        />
        <Select
          w={300}
          label={t("ciichartht_signal")}
          data={signalSelectItems}
          searchable
          nothingFound={t("ciichartht_nothingfound")}
          placeholder={t("ciichartht_selectsignal")}
          {...form.getInputProps("signalId")}
          onChange={(x) => {
            if (x) {
              var x_obj = JSON.parse(x);
              form.setFieldValue(`signalId`, x);

              var optionJSONobj = JSON.parse(x_obj.optionJson);
              form.setFieldValue(`interval`, optionJSONobj?.DataInterval);
            }
          }}
        />

        <Button onClick={handleGenerate}>{t("ciichartht_generate")}</Button>

        <Button
          onClick={() => {
            if (drawerData) {
              setDrawerData(false);
            } else {
              setDrawerData(true);
            }
          }}
        >
          Show Data
        </Button>
      </Flex>

      <Drawer
        position="right"
        opened={drawerData}
        onClose={() => {
          setDrawerData(false);
        }}
        title="CII Data"
        padding="xl"
        size="lg"
        zIndex={40}
      >
        <h2>CII Data</h2>
        <br />
        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>G1</td>
                <td>{ciiData?.g1}</td>
              </tr>
              <tr>
                <td>G2</td>
                <td>{ciiData?.g2}</td>
              </tr>
              <tr>
                <td>G3</td>
                <td>{ciiData?.g3}</td>
              </tr>
              <tr>
                <td>Rating</td>
                <td>{ciiData?.ratings}</td>
              </tr>
            </tbody>
          </Table>
          <br />
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A</td>
                <td>{ciiData?.a}</td>
              </tr>
              <tr>
                <td>B</td>
                <td>{ciiData?.b}</td>
              </tr>
              <tr>
                <td>C</td>
                <td>{ciiData?.c}</td>
              </tr>
              <tr>
                <td>D</td>
                <td>{ciiData?.d}</td>
              </tr>
              <tr>
                <td>E</td>
                <td>{ciiData?.e}</td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Drawer>

      <Grid>
        <Grid.Col>
          <div id="chart" style={{ width: "100%", height: "700px" }}></div>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default CIIChart;
