import { Badge, createStyles, Divider, Group, Stack, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { NormalGroup, NormalLink } from "../common/Models";

const useStyles = createStyles((theme) => ({
  subNavMenuItem: {
    textDecoration: "none",
    borderRadius: theme.radius.md,
    color: "black",
    "&:hover": {
      cursor: "pointer",
      color: theme.colorScheme === "dark" ? "white" : "black",
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : "#f4f5f7",
    },
    "& svg": {
      color: "white",
      backgroundColor: theme.colors[theme.primaryColor][7],
      padding: "2px",
      borderRadius: theme.radius.xs,
      fontSize: "30px",
    },
  },
  titleColor: { color: theme.colorScheme === "light" ? "black" : "white" },
  badgeBackground: { backgroundColor: theme.colorScheme === "light" ? "white" : "#25262b" },
}));

type Props = {
  isOpen: boolean;
  normalGroups: NormalGroup[];
  footerLinks: NormalLink[];
};

const TopNavNormalSubMenu = (props: Props) => {
  const { classes } = useStyles();
  const loadNormalGroups = props.normalGroups.map((x) => {
    return (
      <Stack spacing={5} key={x.category}>
        <Group>
          <Badge color="dark" className={classes.badgeBackground}>
            {x.category}
          </Badge>
        </Group>
        {x.links.map((y) => {
          return (
            <Link to={y.url} className={classes.subNavMenuItem} key={y.title}>
              <Group p="xs">
                {y.icon}
                <Text className={classes.titleColor}>{y.title}</Text>
              </Group>
            </Link>
          );
        })}
      </Stack>
    );
  });

  const loadFooterLinks = props.footerLinks.map((x) => {
    if (x.isActionLink && x.isActionLink === true) {
      return (
        <div className={classes.subNavMenuItem}>
          <Group p="xs" onClick={x.action}>
            <Text fz="md" className={classes.titleColor}>
              {x.title}
            </Text>
          </Group>
        </div>
      );
    } else {
      return (
        <Link to={x.url} className={classes.subNavMenuItem} key={x.title}>
          <Group p="xs">
            <Text className={classes.titleColor}>{x.title}</Text>
          </Group>
        </Link>
      );
    }
  });

  return (
    <Stack spacing="xs">
      {loadNormalGroups}
      <Divider size="sm"></Divider>
      <Stack spacing={0}>{loadFooterLinks}</Stack>
    </Stack>
  );
};

export default TopNavNormalSubMenu;
