import { ActionIcon, Button, Card, Container, Divider, Group, PasswordInput, Select, Stack, TextInput, Title, Transition } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ArrowBack } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationHelper from "../helpers/NotiHelper";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import { AccountsClient, AppUserPostRequest, SelectItemResponse, UserRolesClient } from "../services/WebApiService";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useTranslation } from "react-i18next";

type AppUserForm = {
  email: string;
  username: string;
  role: string;
  password: string;
  confirmPassword: string;
};

const CreateUser = () => {
  const navigate = useNavigate();
  const [mounted, setMounted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [userRoles, setUserRoles] = useState<SelectItemResponse[]>([]);
  const { isSystemModeVessel } = useContext(GlobalContext);

  const { t } = useTranslation();

  const form = useForm<AppUserForm>({
    initialValues: {
      email: "",
      username: "",
      role: "User",
      password: "",
      confirmPassword: "",
    },
    validate: {
      confirmPassword: (value, values) => (value !== values.password ? "Passwords do not match" : null),
      password: (value, values) => (value !== values.confirmPassword ? "Password do not match" : null),
    },
  });

  const handleBack = () => {
    navigate(`/users`);
  };

  const handleCreate = async () => {
    if (!form.validate().hasErrors) {
      const values = form.values;
      const request = {
        email: values.email,
        username: values.username,
        role: values.role,
        password: values.password,
      } as AppUserPostRequest;
      try {
        const accountsClient = new AccountsClient(undefined, axiosInstance);
        await accountsClient.post(request);
        NotificationHelper.showSuccess("Success", "New user created successfully");
        navigate("/users");
      } catch (e) {
        NotificationHelper.showError(
          "Error",
          "An error occurred in creating user. Please check your password make sure it meets the requirements and username/email is not duplicate!"
        );
      }
    }
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  useEffect(() => {
    const loadUserRoles = async () => {
      const user = AuthService.getCurrentUser();
      if (user && userRoles.length === 0) {
        const userRolesClient = new UserRolesClient(undefined, axiosInstance);
        let data: SelectItemResponse[] = [];
        if (isSystemModeVessel()) data = await userRolesClient.get();
        else data = await userRolesClient.get(true);

        setUserRoles(data);
      }
    };

    if (firstLoad) {
      setFirstLoad(false);
      loadUserRoles();
    }

    return () => {};
  }, [firstLoad, form, userRoles, isSystemModeVessel]);

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <Container size="xl" style={styles}>
          <Group>
            <ActionIcon variant="subtle" color="blue" onClick={handleBack}>
              <ArrowBack />
            </ActionIcon>
            <Title order={2}>{t("createuser")}</Title>
          </Group>
          <Divider my="sm" size="md"></Divider>
          <Card p="md" shadow="md" withBorder>
            <Card.Section p="md" withBorder>
              <Title order={3}>{t("createuser_userdetails")}</Title>
            </Card.Section>
            <Card.Section p="md">
              <Stack>
                <Group grow>
                  <TextInput label={t("createuser_username")} required {...form.getInputProps("username")} />
                  <TextInput label={t("createuser_email")} {...form.getInputProps("email")} />
                  <Select withinPortal required label={t("createuser_userrole")} data={userRoles} {...form.getInputProps("role")} />
                </Group>
                <Group grow>
                  <PasswordInput label={t("createuser_password")} required {...form.getInputProps("password")} />
                  <PasswordInput label={t("createuser_confirmpassword")} required {...form.getInputProps("confirmPassword")} />
                </Group>
              </Stack>
            </Card.Section>
            <Card.Section p="md">
              <Button onClick={handleCreate}>{t("createuser_create")}</Button>
            </Card.Section>
          </Card>
        </Container>
      )}
    </Transition>
  );
};
export default CreateUser;
