import { ActionIcon, Container, Divider, Group, Stack, Title, Transition } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ArrowBack } from "@mui/icons-material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotificationHelper from "../helpers/NotiHelper";
import { CommonAutomaticReportSetting } from "../models/automatic-report/CommonAutomaticReportModel";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { axiosInstance } from "../services/AxiosService";
import {
  AutomaticReportsClient,
  AutomaticReportPutRequest,
  AutomaticReportResponse,
  ReportLevel,
  ReportType,
  TriggerType,
} from "../services/WebApiService";
import CommonAutomaticReportInput from "./components/CommonAutomaticReportInput";
import DailyAutomaticReportInput from "./components/DailyAutomaticReportInput";
import EventAutomaticReportInput from "./components/EventAutomaticReportInput";
import MonthlyAutomaticReportInput from "./components/MonthlyAutomaticReportInput";
import WeeklyAutomaticReportInput from "./components/WeeklyAutomaticReportInput";
import { useTranslation } from "react-i18next";

type Props = {};
const EditAutomaticReportSetting = (props: Props) => {
  const [mounted, setMounted] = useState(false);
  const { systemGlobal } = useContext(GlobalContext);

  const { t } = useTranslation();

  const { automaticReportId } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);
  const navigate = useNavigate();
  const [automaticReport, setAutomaticReport] = useState<AutomaticReportResponse>();
  const commonForm = useForm<CommonAutomaticReportSetting>({
    initialValues: {
      name: "",
      dataInterval: "10",
      mappingJson: [],
      reportLevel: ReportLevel.User,
      triggerType: TriggerType.Daily,
      reportType: ReportType.Vessel,
      appUserId: undefined,
      companyId: undefined,
      fleetId: undefined,
      vesselId: undefined,
    },
  });

  const handleBack = () => {
    navigate(`/automatic-report`);
  };

  const handleSubmit = useCallback(
    async (settingJson: string) => {
      if (automaticReport) {
        try {
          const values = commonForm.values;
          const reportClient = new AutomaticReportsClient(undefined, axiosInstance);
          const entity = {
            id: automaticReport.id,
            name: values.name,
            reportLevel: values.reportLevel,
            reportType: values.reportType,
            triggerType: values.triggerType,
            mappingJson: JSON.stringify(values.mappingJson),
            fleetId: values.fleetId,
            companyId: values.companyId,
            appUserId: values.appUserId,
            dataInterval: values.dataInterval,
            vesselId: values.vesselId,
            settingJson: settingJson,
          };
          const reportToSave = new AutomaticReportPutRequest();
          reportToSave.init(entity);
          await reportClient.put(reportToSave);
          NotificationHelper.showSuccess("Success", "Report is updated successfully");
        } catch {
          NotificationHelper.showError("Error", "An error occurred in saving automatic report");
        }
      }
    },
    [automaticReport, commonForm.values]
  );

  const loadTriggerSpecificInput = useCallback(() => {
    const triggerType = commonForm.values.triggerType;
    if (triggerType === TriggerType.Daily) {
      return <DailyAutomaticReportInput automaticReport={automaticReport} handleSubmit={handleSubmit} />;
    } else if (triggerType === TriggerType.Weekly) {
      return <WeeklyAutomaticReportInput automaticReport={automaticReport} handleSubmit={handleSubmit} />;
    } else if (triggerType === TriggerType.Monthly) {
      return <MonthlyAutomaticReportInput automaticReport={automaticReport} handleSubmit={handleSubmit} />;
    } else if (triggerType === TriggerType.Event) {
      return (
        <EventAutomaticReportInput
          classifications={systemGlobal.classifications}
          mappingDefs={systemGlobal.mappingDefs}
          automaticReport={automaticReport}
          handleSubmit={handleSubmit}
        />
      );
    }
  }, [automaticReport, commonForm.values.triggerType, handleSubmit, systemGlobal.classifications, systemGlobal.mappingDefs]);

  useEffect(() => {
    setMounted(true);

    return () => {};
  }, []);

  useEffect(() => {
    const loadAutomaticReport = async () => {
      if (automaticReportId && !automaticReport && firstLoad) {
        const reportClient = new AutomaticReportsClient(undefined, axiosInstance);
        const data = await reportClient.get(Number(automaticReportId));
        setAutomaticReport(data);
        commonForm.setFieldValue("name", data.name);
        commonForm.setFieldValue("appUserId", data.appUserId);
        commonForm.setFieldValue("companyId", data.companyId?.toString());
        commonForm.setFieldValue("fleetId", data.fleetId?.toString());
        commonForm.setFieldValue("vesselId", data.vesselId?.toString());
        commonForm.setFieldValue("dataInterval", data.dataInterval.toString());
        commonForm.setFieldValue("mappingJson", JSON.parse(data.mappingJson));
        commonForm.setFieldValue("reportLevel", data.reportLevel);
        commonForm.setFieldValue("reportType", data.reportType);
        commonForm.setFieldValue("triggerType", data.triggerType);
      }
    };
    if (firstLoad) {
      setFirstLoad(false);
      loadAutomaticReport();
    }
    return () => {};
  }, [automaticReport, automaticReportId, commonForm, firstLoad]);

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <div style={styles}>
          <Group>
            <ActionIcon variant="subtle" color="blue" onClick={handleBack}>
              <ArrowBack />
            </ActionIcon>
            <Title order={2}>{t("editautoreport")}</Title>
          </Group>
          <Divider my="sm" size="md"></Divider>
          <Container size="xl">
            <Stack>
              <CommonAutomaticReportInput classifications={systemGlobal.classifications} mappingDefs={systemGlobal.mappingDefs} form={commonForm} />
              {loadTriggerSpecificInput()}
            </Stack>
          </Container>
        </div>
      )}
    </Transition>
  );
};
export default EditAutomaticReportSetting;
