import { ActionIcon, Button, Card, Group, NumberInput, Select, Stack, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { RemoveCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import NotificationHelper from "../../helpers/NotiHelper";
import { Mapping } from "../../models/common/Mapping";
import { AutomaticReportResponse, Classification, FuelType, MappingDefResponse, Property, TriggerType } from "../../services/WebApiService";
import { useTranslation } from "react-i18next";

type Props = {
  handleSubmit(settingJson: string): void;
  automaticReport?: AutomaticReportResponse;
  classifications: MappingDefResponse[];
  mappingDefs: MappingDefResponse[];
};

type EventSettingMapping = {
  classification: Classification;
  property: Property;
  fuelType: FuelType;
  index: string;
  start: number;
  end: number;
};

type EventSettingJson = {
  avgTimeToCheck: number;
  mappings: EventSettingMapping[];
};

const EventAutomaticReportInput = (props: Props) => {
  const indexArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [firstLoad, setFirstLoad] = useState(true);

  const { t } = useTranslation();

  const form = useForm<EventSettingJson>({
    initialValues: {
      avgTimeToCheck: 10,
      mappings: [
        {
          classification: Classification.NotApplicable,
          property: Property.NotApplicable,
          fuelType: FuelType.NotApplicable,
          index: "1",
          start: 1,
          end: 0,
        },
      ],
    },
  });

  const handleSubmit = () => {
    const settingJson = JSON.stringify(form.values);
    props.handleSubmit(settingJson);
  };

  const reloadProperty = (index: number) => {
    if (props.mappingDefs.length > 0) {
      const obj = form.values.mappings[index];
      const filtered = props.mappingDefs.filter((x) => x.classification === obj.classification);
      const entity = filtered.find((x) => x.property === obj.property);
      if (!entity) {
        form.setFieldValue(`mappings.${index}.property`, filtered[0].property);
      }
    }
  };

  const removeSignalMapping = (index: number) => {
    if (form.values.mappings.length > 1) {
      form.removeListItem("mappings", index);
    } else {
      NotificationHelper.showError("Error in removing", "Setting needs at least one signal mapping");
    }
  };

  const addSignalMapping = () => {
    const entity = {
      classification: Classification.NotApplicable,
      property: Property.NotApplicable,
      fuelType: FuelType.NotApplicable,
      index: "1",
      start: 1,
      end: 0,
    } as Mapping;
    form.insertListItem("mappings", entity);
  };

  useEffect(() => {
    const loadForm = () => {
      if (firstLoad && props.automaticReport) {
        setFirstLoad(false);
        if (props.automaticReport.triggerType === TriggerType.Event) {
          const optionJson = JSON.parse(props.automaticReport.settingJson) as EventSettingJson;
          form.setFieldValue("avgTimeToCheck", optionJson.avgTimeToCheck);
          form.setFieldValue("mappings", optionJson.mappings);
        }
      }
    };
    loadForm();
    return () => {};
  }, [firstLoad, form, props.automaticReport]);

  return (
    <Card shadow="sm" p="lg" radius="sm" withBorder>
      <Card.Section withBorder p="lg">
        <Title order={3}>{t("createautoreport_eventreport")}</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <Group grow>
            <NumberInput
              label={t("createautoreport_timerangemins")}
              description={t("createautoreport_timerangeminsdesc")}
              min={5}
              max={30}
              {...form.getInputProps("avgTimeToCheck")}
            />
          </Group>
          <Title order={5}>{t("createautoreport_mappingcheck")}</Title>
          {form.values.mappings.map((obj, index) => {
            return (
              <Group grow align="end" key={`Mapping${index}`}>
                <Select
                  withinPortal
                  label={t("createautoreport_classification")}
                  searchable
                  nothingFound={t("createautoreport_notfound")}
                  data={props.classifications.map((x) => ({ value: x.classification, label: x.classificationDisplayName }))}
                  {...form.getInputProps(`mappings.${index}.classification`)}
                />
                <Select
                  withinPortal
                  label={t("createautoreport_property")}
                  searchable
                  onChange={reloadProperty(index)}
                  nothingFound={t("createautoreport_notfound")}
                  data={props.mappingDefs
                    .filter((x) => x.classification === obj.classification)
                    .map((x) => ({ value: x.property, label: x.propertyDisplayName }))}
                  {...form.getInputProps(`mappings.${index}.property`)}
                />
                <Select
                  withinPortal
                  label={t("createautoreport_fueltype")}
                  data={Object.keys(FuelType).map((x) => ({ value: x, label: x }))}
                  {...form.getInputProps(`mappings.${index}.fuelType`)}
                />
                <Select
                  withinPortal
                  label={t("createautoreport_index")}
                  data={indexArray.map((x) => ({ value: x.toString(), label: x.toString() }))}
                  {...form.getInputProps(`mappings.${index}.index`)}
                />
                <NumberInput
                  withinPortal
                  label={t("createautoreport_startvalue")}
                  min={0}
                  max={100000}
                  precision={2}
                  {...form.getInputProps(`mappings.${index}.start`)}
                />
                <NumberInput
                  withinPortal
                  label={t("createautoreport_endvalue")}
                  min={0}
                  max={100000}
                  precision={2}
                  {...form.getInputProps(`mappings.${index}.end`)}
                />
                <Group pl="md">
                  <ActionIcon color="red" onClick={() => removeSignalMapping(index)}>
                    <RemoveCircle />
                  </ActionIcon>
                </Group>
              </Group>
            );
          })}
          <Group>
            <Button variant="outline" onClick={addSignalMapping}>
              {t("createautoreport_addmapping")}
            </Button>
          </Group>
        </Stack>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Button onClick={handleSubmit}>{t("createautoreport_save")}</Button>
      </Card.Section>
    </Card>
  );
};
export default EventAutomaticReportInput;
