import { Badge, Button, Card, Container, Divider, Group, NumberInput, Select, Stack, TextInput, Title, Transition } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NotificationHelper from "../helpers/NotiHelper";
import { axiosInstance } from "../services/AxiosService";
import { SelectItemResponse, ShipTypeClient, VesselSettingPatchRequest, VesselSettingsClient } from "../services/WebApiService";
import FleetAssignList from "./components/FleetAssignList";
import { EnvService } from "../services/EnvService";
import { useTranslation } from "react-i18next";
import { ShipType } from "../models/ship-type/ShipType";

type VesselSettingForm = {
  name: string;
  imoNumber: number;
  callSign: string;
  timeZone: string;
  shipTypeId: string;
  plcDataInterval: string;
  yearOfConstruction: number;
  flagState: string;
  designedSpeed: number;
  mmsi: string;
  netTonnage: number;
  deadWeightTonnage: number;
  grossTonnage: number;
  length: number;
  breadth: number;
  draft: number;
  mainEnginePowerOutput: number;
  auxEnginePowerOutput: number;
};

const VesselSetting = () => {
  const { vesselId } = useParams();
  const [plcDataIntervals, setPlcDataIntervals] = useState<SelectItemResponse[]>([]);
  const [timeZones, setTimeZones] = useState<SelectItemResponse[]>([]);
  const [shipTypes, setShipTypes] = useState<SelectItemResponse[]>([]);

  const [shipTypeOnly, setShipTypeOnly] = useState<ShipType>({
    id: 0,
    name: "",
    idByFps: 0,
    minA: 0,
    minC: 0,
    maxA: 0,
    maxC: 0,
    acHit: 0,
    minD1: 0,
    minD2: 0,
    minD3: 0,
    minD4: 0,
    maxD1: 0,
    maxD2: 0,
    maxD3: 0,
    maxD4: 0,
    dHit: 0,
    midA: 0,
    midC: 0,
    acBetween: 0,
  });
  const [mounted, setMounted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const form = useForm<VesselSettingForm>({
    initialValues: {
      name: "",
      imoNumber: 0,
      callSign: "",
      timeZone: "",
      shipTypeId: "",
      plcDataInterval: "",
      yearOfConstruction: 0,
      flagState: "",
      designedSpeed: 0,
      mmsi: "",
      netTonnage: 0,
      deadWeightTonnage: 0,
      grossTonnage: 0,
      length: 0,
      breadth: 0,
      draft: 0,
      mainEnginePowerOutput: 0,
      auxEnginePowerOutput: 0,
    },
  });

  const { t } = useTranslation();

  const numberInputParser = (value: string | undefined) => value?.replace(/\$\s?|(,*)/g, "") ?? "";

  const numberInputFormatter = (value: string | undefined) =>
    !Number.isNaN(parseFloat(value!)) ? ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ``;

  const handleSubmit = async () => {
    if (vesselId) {
      const values = form.values;
      const entity = {
        callSign: values.callSign,
        timeZone: values.timeZone,
        shipTypeId: Number(values.shipTypeId),
        plcDataInterval: Number(values.plcDataInterval),
        yearOfConstruction: values.yearOfConstruction,
        designedSpeed: values.designedSpeed,
        flagState: values.flagState,
        mmsi: values.mmsi,
        netTonnage: values.netTonnage,
        grossTonnage: values.grossTonnage,
        deadWeightTonnage: values.deadWeightTonnage,
        length: values.length,
        breadth: values.breadth,
        draft: values.draft,
        mainEnginePowerOutput: values.mainEnginePowerOutput,
        auxEnginePowerOutput: values.auxEnginePowerOutput,
      } as VesselSettingPatchRequest;
      try {
        const vesselSettingClient = new VesselSettingsClient(undefined, axiosInstance);
        await vesselSettingClient.patch(Number(vesselId), entity);
        NotificationHelper.showSuccess("Update success", "Vessel settings updated successfully");
      } catch {
        NotificationHelper.showError("Update error", "An error occurred in updating vessel settings");
      }
    }
  };

  const getOneShipType = async () => {
    const shipTypeClient = new ShipTypeClient(undefined, axiosInstance);
    setShipTypeOnly(await shipTypeClient.getByShipTypeId(parseInt(form.values.shipTypeId)));
  };

  useEffect(() => {
    const loadPlcDataIntervals = () => {
      const result = [
        {
          label: "Disabled",
          value: "0",
        },
        {
          label: "5 Mins",
          value: "5",
        },
        {
          label: "10 Mins",
          value: "10",
        },
        {
          label: "15 Mins",
          value: "15",
        },
        {
          label: "30 Mins",
          value: "30",
        },
        {
          label: "Hourly",
          value: "60",
        },
      ] as SelectItemResponse[];
      setPlcDataIntervals(result);
    };

    const loadTimeZones = async () => {
      const vesselSettingClient = new VesselSettingsClient(undefined, axiosInstance);
      setTimeZones(await vesselSettingClient.timeZones());
    };

    const loadShipTypes = async () => {
      const vesselSettingClient = new VesselSettingsClient(undefined, axiosInstance);
      setShipTypes(await vesselSettingClient.shipTypes());
    };

    loadPlcDataIntervals();
    loadTimeZones();
    loadShipTypes();
    return () => {};
  }, []);

  useEffect(() => {
    const loadVesselSetting = async () => {
      if (vesselId && firstLoad) {
        setFirstLoad(false);
        const vesselSettingClient = new VesselSettingsClient(undefined, axiosInstance);
        const data = await vesselSettingClient.get(Number(vesselId));
        form.setFieldValue("name", data.name);
        form.setFieldValue("imoNumber", data.imoNumber);
        form.setFieldValue("callSign", data.callSign);
        form.setFieldValue("timeZone", data.timeZone);
        form.setFieldValue("shipTypeId", data.shipTypeId.toString());
        form.setFieldValue("plcDataInterval", data.plcDataInterval.toString());
        form.setFieldValue("yearOfConstruction", data.yearOfConstruction);
        form.setFieldValue("designedSpeed", data.designedSpeed);
        form.setFieldValue("flagState", data.flagState);
        form.setFieldValue("mmsi", data.mmsi);
        form.setFieldValue("netTonnage", data.netTonnage);
        form.setFieldValue("deadWeightTonnage", data.deadWeightTonnage);
        form.setFieldValue("grossTonnage", data.grossTonnage);
        form.setFieldValue("length", data.length);
        form.setFieldValue("breadth", data.breadth);
        form.setFieldValue("draft", data.draft);
        form.setFieldValue("mainEnginePowerOutput", data.mainEnginePowerOutput);
        form.setFieldValue("auxEnginePowerOutput", data.auxenginePowerOutput);
      }
    };
    loadVesselSetting();
    return () => {};
  }, [firstLoad, form, vesselId]);

  useEffect(() => {
    getOneShipType();
    return () => {};
  }, [form.values.shipTypeId, shipTypes]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }

    return () => {};
  }, [mounted]);

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <div style={styles}>
          <Title order={2}>{t("vesselsetting")}</Title>
          <Divider my="sm" size="md"></Divider>
          <Container size={"xl"}>
            <Stack>
              <Card shadow="sm" p="lg" radius="sm" withBorder>
                <Card.Section withBorder p="lg">
                  <Title order={3}>{t("vesselsetting_general")}</Title>
                </Card.Section>
                <Card.Section withBorder p="lg">
                  <Stack>
                    <Group grow>
                      <TextInput
                        required
                        label={t("vesselsetting_name")}
                        readOnly
                        disabled
                        description={t("vesselsetting_namedesc")}
                        {...form.getInputProps("name")}
                      />
                      <TextInput
                        required
                        label={t("vesselsetting_imo")}
                        readOnly
                        disabled
                        description={t("vesselsetting_imodesc")}
                        {...form.getInputProps("imoNumber")}
                      />
                      <TextInput
                        label={t("vesselsetting_callsign")}
                        description={t("vesselsetting_callsigndesc")}
                        {...form.getInputProps("callSign")}
                      />
                    </Group>
                    <Group grow>
                      <Select
                        withinPortal
                        data={timeZones}
                        required
                        label={t("vesselsetting_timezone")}
                        description={t("vesselsetting_timezonedesc")}
                        {...form.getInputProps("timeZone")}
                      />
                      <Select
                        withinPortal
                        data={shipTypes}
                        required
                        label={t("vesselsetting_shiptype")}
                        description={t("vesselsetting_shiptypedesc")}
                        {...form.getInputProps("shipTypeId")}
                      />
                      <Select
                        withinPortal
                        data={plcDataIntervals}
                        required
                        label={t("vesselsetting_plcdatainterval")}
                        description={t("vesselsetting_plcdataintervaldesc")}
                        {...form.getInputProps("plcDataInterval")}
                      />
                    </Group>
                  </Stack>
                </Card.Section>
              </Card>

              {!EnvService.isVesselSystemMode ? <FleetAssignList /> : <></>}

              <Card shadow="sm" p="lg" radius="sm" withBorder>
                <Card.Section withBorder p="lg">
                  <Title order={3}>{t("vesselsetting_buildinfo")}</Title>
                </Card.Section>
                <Card.Section withBorder p="lg">
                  <Stack>
                    <Group grow>
                      <NumberInput
                        label={t("vesselsetting_yearofconstruction")}
                        min={1900}
                        max={3000}
                        {...form.getInputProps("yearOfConstruction")}
                      />
                      <NumberInput
                        label={t("vesselsetting_designedspeed")}
                        min={0}
                        max={3000}
                        parser={numberInputParser}
                        formatter={numberInputFormatter}
                        {...form.getInputProps("designedSpeed")}
                      />
                      <TextInput label={t("vesselsetting_flagstate")} {...form.getInputProps("flagState")} />
                      <TextInput label={t("vesselsetting_maritimemsi")} {...form.getInputProps("mmsi")} />
                    </Group>
                    <Group grow>
                      <NumberInput
                        label={t("vesselsetting_nettonnage")}
                        min={0}
                        parser={numberInputParser}
                        formatter={numberInputFormatter}
                        {...form.getInputProps("netTonnage")}
                      />
                      <NumberInput
                        label={t("vesselsetting_deadweighttonnage")}
                        min={0}
                        parser={numberInputParser}
                        formatter={numberInputFormatter}
                        {...form.getInputProps("deadWeightTonnage")}
                      />
                      <NumberInput
                        label={t("vesselsetting_grosstonange")}
                        min={0}
                        parser={numberInputParser}
                        formatter={numberInputFormatter}
                        {...form.getInputProps("grossTonnage")}
                      />
                    </Group>
                    <Group grow>
                      <NumberInput
                        label={t("vesselsetting_lengthperpen")}
                        min={0}
                        parser={numberInputParser}
                        formatter={numberInputFormatter}
                        {...form.getInputProps("length")}
                      />
                      <NumberInput
                        label={t("vesselsetting_breath")}
                        min={0}
                        parser={numberInputParser}
                        formatter={numberInputFormatter}
                        {...form.getInputProps("breadth")}
                      />
                      <NumberInput
                        label={t("vesselsetting_draft")}
                        min={0}
                        parser={numberInputParser}
                        formatter={numberInputFormatter}
                        {...form.getInputProps("draft")}
                      />
                    </Group>
                    <Group grow>
                      <NumberInput
                        label={t("vesselsetting_mainenginepo")}
                        min={0}
                        parser={numberInputParser}
                        formatter={numberInputFormatter}
                        {...form.getInputProps("mainEnginePowerOutput")}
                      />
                      <NumberInput
                        label={t("vesselsetting_auxenginepo")}
                        min={0}
                        parser={numberInputParser}
                        formatter={numberInputFormatter}
                        {...form.getInputProps("auxEnginePowerOutput")}
                      />
                    </Group>
                  </Stack>
                </Card.Section>
                <Card.Section withBorder p="lg">
                  <Button onClick={handleSubmit}>{t("vesselsetting_save")}</Button>
                </Card.Section>
              </Card>
              <Card shadow="sm" p="lg" radius="sm" withBorder>
                <Card.Section withBorder p="lg">
                  <Title order={3}>{t("vesselsetting_ciikpi")}</Title>
                </Card.Section>
                <Card.Section withBorder p="lg">
                  <Stack>
                    <Group>
                      <Badge>{t("vesselsetting_acvalue")}</Badge>
                    </Group>
                    <Group grow>
                      <div>
                        <b>{t("vesselsetting_minvalA")}:</b> {shipTypeOnly.minA}
                      </div>
                      <div>
                        <b>{t("vesselsetting_minvalC")}:</b> {shipTypeOnly.minC}
                      </div>
                    </Group>
                    <Group grow>
                      <div>
                        <b>{t("vesselsetting_maxvalA")}:</b> {shipTypeOnly.maxA}
                      </div>
                      <div>
                        <b>{t("vesselsetting_maxvalC")}:</b> {shipTypeOnly.maxC}
                      </div>
                    </Group>
                    <Group grow>
                      <div>
                        <b>{t("vesselsetting_midvalA")}:</b> {shipTypeOnly.midA}
                      </div>
                      <div>
                        <b>{t("vesselsetting_midvalC")}:</b> {shipTypeOnly.midC}
                      </div>
                    </Group>
                    <Group grow>
                      <div>
                        <b>{t("vesselsetting_acrangehit")}:</b> {shipTypeOnly.acHit}
                      </div>
                      <div>
                        <b>{t("vesselsetting_acrangehitbetween")}:</b> {shipTypeOnly.acBetween}
                      </div>
                    </Group>
                    <Group>
                      <Badge>{t("vesselsetting_dvalue")}</Badge>
                    </Group>
                    <Group grow>
                      <div>
                        <b>{t("vesselsetting_minvald1")}:</b> {shipTypeOnly.minD1}
                      </div>
                      <div>
                        <b>{t("vesselsetting_minvald2")}:</b> {shipTypeOnly.minD2}
                      </div>
                    </Group>
                    <Group grow>
                      <div>
                        <b>{t("vesselsetting_minvald3")}:</b> {shipTypeOnly.minD3}
                      </div>
                      <div>
                        <b>{t("vesselsetting_minvald4")}:</b> {shipTypeOnly.minD4}
                      </div>
                    </Group>
                    <Group grow>
                      <div>
                        <b>{t("vesselsetting_maxvald1")}:</b> {shipTypeOnly.maxD1}
                      </div>
                      <div>
                        <b>{t("vesselsetting_maxvald2")}:</b> {shipTypeOnly.maxD2}
                      </div>
                    </Group>
                    <Group grow>
                      <div>
                        <b>{t("vesselsetting_maxvald3")}:</b> {shipTypeOnly.maxD3}
                      </div>
                      <div>
                        <b>{t("vesselsetting_maxvald4")}:</b> {shipTypeOnly.maxD4}
                      </div>
                    </Group>
                    <Group grow>
                      <div>
                        <b>{t("vesselsetting_drangehit")}:</b> {shipTypeOnly.dHit}
                      </div>
                    </Group>
                  </Stack>
                </Card.Section>
              </Card>
            </Stack>
          </Container>
        </div>
      )}
    </Transition>
  );
};
export default VesselSetting;
