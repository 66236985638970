import { Grid, Select } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { SingleSignalMappingForm } from "../../../common/Models";
import { MappingDefResponse, FuelType } from "../../../services/WebApiService";

import { useTranslation } from "react-i18next";

type Props = {
  label: string;
  form: UseFormReturnType<SingleSignalMappingForm>;
  classifications: MappingDefResponse[];
  classifProperty: MappingDefResponse[];
};

const SingleSignalMapping = (props: Props) => {
  const indexArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const { t } = useTranslation();

  /**
   * Reload property that coorespondes to the classification.
   * Have to manually refresh because the previous value will not be cleared even though the select list data is updated
   */
  const reloadProperty = () => {
    const obj = props.form.values.mapping;
    const filtered = props.classifProperty.filter((x) => x.classification === obj.classification);
    const entity = filtered.find((x) => x.property === obj.property);
    if (!entity) {
      props.form.setFieldValue(`mapping.property`, filtered[0].property);
    }
  };

  return (
    <div className="custom-input">
      <label style={{ fontWeight: "500" }}>{props.label}</label>
      <Grid columns={13} align="flex-end" className="mapping-grid">
        <Grid.Col span={4}>
          <Select
            data={props.classifications.map((x) => ({ value: x.classification, label: x.classificationDisplayName }))}
            label={t("dashboardwidget_single_classification")}
            required
            searchable
            nothingFound={t("dashboardwidget_single_notfound")}
            {...props.form.getInputProps("mapping.classification")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            data={props.classifProperty
              .filter((x) => x.classification === props.form.values.mapping.classification)
              .map((x) => ({ value: x.property, label: x.propertyDisplayName }))}
            label={t("dashboardwidget_single_property")}
            required
            searchable
            nothingFound={t("dashboardwidget_single_notfound")}
            onChange={reloadProperty()}
            {...props.form.getInputProps(`mapping.property`)}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Select
            data={Object.keys(FuelType).map((x) => ({ value: x, label: x }))}
            label={t("dashboardwidget_single_fueltype")}
            required
            searchable
            nothingFound={t("dashboardwidget_single_notfound")}
            {...props.form.getInputProps(`mapping.fuelType`)}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            data={indexArray.map((x) => ({ value: x.toString(), label: x.toString() }))}
            label={t("dashboardwidget_single_index")}
            required
            searchable
            nothingFound={t("dashboardwidget_single_notfound")}
            {...props.form.getInputProps(`mapping.index`)}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default SingleSignalMapping;
