import { UseFormReturnType } from "@mantine/form";
import { useContext } from "react";
import NotificationHelper from "../helpers/NotiHelper";
import { DashboardConfigForm } from "../models/dashboard/DashboardConfigForm";
import { DashboardConfigContext } from "../providers/DashboardConfigProvider";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import { DashboardPatchRequest, DashboardPermissionRequest, DashboardResponse, DashboardsClient } from "../services/WebApiService";
import DashboardConfig from "./components/Common/DashboardConfig";
import { useTranslation } from "react-i18next";

const EditDashboardConfig = () => {
  const { isOpen, setIsOpen, setReload, dashboardIdToEdit, dashboardType } = useContext(DashboardConfigContext);

  const { t } = useTranslation();

  const handleEditDashboard = async (
    e: React.FormEvent<HTMLFormElement>,
    form: UseFormReturnType<DashboardConfigForm>,
    file: File | null,
    dashboard: DashboardResponse,
    dashboardPermissions: DashboardPermissionRequest[]
  ) => {
    e.preventDefault();
    try {
      const user = AuthService.getCurrentUser();
      if (user) {
        const dashboardClient = new DashboardsClient(undefined, axiosInstance);
        const values = form.values;
        if (dashboard.isEditable) {
          const entity = {
            id: dashboardIdToEdit,
            category: values.category,
            name: values.name,
            isFleetDashboard: values.isFleetDashboard,
            isSystemDashboard: values.isSystemDashboard,
            viewEnabled: values.viewEnabled,
            dashboardPermissions: dashboardPermissions,
          } as DashboardPatchRequest;
          await dashboardClient.patch(entity);
          if (file) {
            const fileParameter = { data: file, fileName: file.name };
            await dashboardClient.backgroundImagePOST(dashboardIdToEdit, fileParameter);
          }
          NotificationHelper.showSuccess("Success", "Screen has been updated successfully");
        } else {
          await dashboardClient.viewEnabled(dashboardIdToEdit, values.viewEnabled);
          NotificationHelper.showSuccess("Success", "View status of the screen has been updated successfully");
        }
        setReload(true);
      }
    } catch {
      NotificationHelper.showError("Error", "An error occurred in updating screen");
    }
  };
  return (
    <DashboardConfig
      dashboardIdToEdit={dashboardIdToEdit}
      title={t("editscreen")}
      buttonLabel={t("editscreen_update")}
      isOpen={isOpen}
      setReload={setReload}
      setIsOpen={setIsOpen}
      handleSubmit={handleEditDashboard}
      dashboardType={dashboardType}
    />
  );
};
export default EditDashboardConfig;
