import { Button, Group, Modal, Stack, TransferListData } from "@mantine/core";
import { FleetClient, FleetPatchRequest, FleetResponse } from "../../services/WebApiService";
import { useEffect, useState } from "react";

import { useForm } from "@mantine/form";
import AuthService from "../../services/AuthService";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import { Fleet } from "../../models/fleet/Fleet";
import FleetFormInputs from "./common/FleetFormInputs";
import { useTranslation } from "react-i18next";

type ModalProps = {
  isOpen: boolean;
  id: number;
  fleet?: FleetResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  hideModal: (type: string) => void;
  loadFleet?: () => void;
  fleetId: number;
  modalProps: ModalType;
  fleet: FleetResponse;
};

const EditFleetForm = (props: Props) => {
  const [user, setUser] = useState<TransferListData>([[], []]);
  const [vessel, setVessel] = useState<TransferListData>([[], []]);

  const commonForm = useForm<Fleet>({
    initialValues: {
      Name: "",
      IsActive: false,
    },
  });

  const { t } = useTranslation();

  const handleSubmit = async () => {
    if (AuthService.isInRole("Admin")) {
      try {
        const values = commonForm.values;
        const fleetClient = new FleetClient(undefined, axiosInstance);
        const entity = {
          name: values.Name,
          isActive: values.IsActive,
          appUserFleet: user[1].map((x) => ({
            appUserId: x.value,
            fleetId: props.fleetId,
          })),
          fleetVessel: vessel[1].map((x) => ({
            vesselId: Number(x.value),
            fleetId: props.fleetId,
          })),
        };

        const fleetToUpdate = new FleetPatchRequest();
        fleetToUpdate.init(entity);
        await fleetClient.patch(fleetToUpdate, props.fleetId);
        NotificationHelper.showSuccess("Success", "Fleet is updated successfully");
        props.hideModal("editFleet");
        if (props.loadFleet) props.loadFleet();
      } catch {
        NotificationHelper.showError("Error", "An error occurred in updating fleet");
      }
    }
  };

  useEffect(() => {
    commonForm.setFieldValue("Name", props.fleet.name);
    commonForm.setFieldValue("IsActive", props.fleet.isActive);
    return () => {};
  }, [props.fleetId, props?.fleet.name, props?.fleet.isActive, props.modalProps["editFleet"].isOpen]);

  return (
    <Modal size="xl" opened={props.modalProps["editFleet"].isOpen} onClose={() => props.hideModal("editFleet")} title={t("editfleet")}>
      <Stack spacing="lg">
        <Group spacing="lg" align="center" grow>
          <form>
            <FleetFormInputs
              fleet={props.fleet}
              fleetId={props.fleetId}
              form={commonForm}
              isEditMode={true}
              user={user}
              setUser={setUser}
              vessel={vessel}
              setVessel={setVessel}
            />
          </form>
        </Group>
        <Group position="right">
          <Button variant="outline" onClick={() => props.hideModal("editFleet")}>
            {t("editfleet_cancel")}
          </Button>
          <Button disabled={!AuthService.isInRole("Admin")} type="submit" onClick={handleSubmit}>
            {t("editfleet_save")}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default EditFleetForm;
