import { SelectItemResponse } from "./WebApiService";

export const Intervals =
  [{
    label: "5 Mins",
    value: "5",
  },
  {
    label: "10 Mins",
    value: "10",
  },
  {
    label: "15 Mins",
    value: "15",
  },
  {
    label: "30 Mins",
    value: "30",
  },
  {
    label: "Hourly",
    value: "60",
  },
  {
    label: "Daily",
    value: "1440",
  }] as SelectItemResponse[];