import { Card, Group, NumberInput, Select, Stack, Switch, TextInput, Title } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useEffect, useState } from 'react'
import { CommonEditKpiSetting } from "../../../../models/kpi/CommonEditKpiSetting"
import { axiosInstance } from "../../../../services/AxiosService"
import { MappingDefResponse, FuelType, KpiType, UnitResponse, UnitsClient } from "../../../../services/WebApiService"

type Props = {
  form: UseFormReturnType<CommonEditKpiSetting>
  mappingDefs: MappingDefResponse[]
  classifications: MappingDefResponse[]
}

const CommonEditKpiInput = (props: Props) => {
  const [units, setUnits] = useState<UnitResponse[]>([]);
  const indexArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  

  const reloadProperty = () => {
    if (props.mappingDefs.length > 0) {
      const obj = props.form.values;
      const filtered = props.mappingDefs.filter(x => x.classification === obj.classification);
      const entity = filtered.find(x => x.property === obj.property);
      if (!entity) {
        props.form.setFieldValue(`property`, filtered[0].property);
      }
    }
  }

  useEffect(() => {
    const loadUnits = async () => {
      if (units.length === 0) {
        const unitClient = new UnitsClient(undefined, axiosInstance);
        setUnits(await unitClient.get());
      }
    }
    loadUnits();
    return () => {

    }
  }, [units])


  return (
    <Card shadow="sm" p="lg" radius="sm" withBorder>
      <Card.Section withBorder p="lg">
        <Title order={3}>Common Settings</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <Group grow spacing="lg">
            <TextInput
              label="Name"
              {...props.form.getInputProps("name")}
            />
            <Select
              withinPortal
              label="Unit"
              data={units.map(x => ({ value: x.id.toString(), label: x.description }))}
              {...props.form.getInputProps("unitId")}
            />
            <Select
              withinPortal
              label="Kpi Type"
              data={Object.keys(KpiType).map(x => ({ value: x, label: x }))}
              {...props.form.getInputProps("kpiType")}
            />
          </Group>
          <Group grow spacing="lg">
            <Select
              withinPortal
              label="Classification"
              searchable
              nothingFound="Nothing found"
              data={props.classifications.map(x => ({ value: x.classification, label: x.classificationDisplayName }))}
              {...props.form.getInputProps("classification")}
            />
            <Select
              withinPortal
              label="Property"
              searchable
              onChange={reloadProperty()}
              nothingFound="Nothing found"
              data={props.mappingDefs
                .filter(x => x.classification === props.form.values.classification)
                .map(x => ({ value: x.property, label: x.propertyDisplayName }))}
              {...props.form.getInputProps("property")}
            />
            <Select
              withinPortal
              label="Fuel Type"
              data={Object.keys(FuelType).map(x => ({ value: x, label: x }))}
              {...props.form.getInputProps("fuelType")}
            />
            <Select
              withinPortal
              label="Index"
              data={indexArray.map(x => ({ value: x.toString(), label: x.toString() }))}
              {...props.form.getInputProps("index")}
            />
          </Group>
          <Group grow spacing="lg">
            <NumberInput
              label="Warning Positive"
              description="Warning when value goes higher than this"
              {...props.form.getInputProps("warningPositive")}
            />
            <NumberInput
              label="Alarm Positive"
              description="Alarm when value goes higher than this"
              {...props.form.getInputProps("alarmPositive")}
            />
            <NumberInput
              label="Warning Negative"
              description="Warning when value goes lower than this"
              {...props.form.getInputProps("warningNegative")}
            />
            <NumberInput
              label="Alarm Negative"
              description="Alarm when value goes lower than this"
              {...props.form.getInputProps("alarmNegative")}
            />
          </Group>
          <Group grow spacing="lg" pb="md" align="end">
            <NumberInput
              label="Order"
              min={0}
              description="Order in which the kpi will be executed in ascending order"
              {...props.form.getInputProps("order")}
            />
            <Switch
              label="Is Net Value"
              {...props.form.getInputProps("isNetValue", { type: "checkbox" })}
            />
          </Group>
        </Stack>
      </Card.Section>
    </Card>
  )
}

export default CommonEditKpiInput	