import { Button, Card, Container, Divider, Group, NumberInput, Select, Stack, TextInput, Title, Transition } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import NotificationHelper from "../helpers/NotiHelper";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import { SelectItemResponse, SystemSettingResponse, SystemSettingsClient } from "../services/WebApiService";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useNavigate } from "react-router-dom";

const SystemSetting = () => {
  const navigate = useNavigate();

  const [mounted, setMounted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [, setIsEngineer] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const { isSystemModeVessel } = useContext(GlobalContext);
  const systemModes = [
    {
      label: "Vessel",
      value: "Vessel",
    },
    {
      label: "Fleet",
      value: "Fleet",
    },
  ] as SelectItemResponse[];

  const bypassSSLValues = [
    {
      label: "Yes",
      value: "True",
    },
    {
      label: "No",
      value: "False",
    },
  ] as SelectItemResponse[];

  const testDevelopmentValues = [
    {
      label: "Yes",
      value: "True",
    },
    {
      label: "No",
      value: "False",
    },
  ] as SelectItemResponse[];

  const reinitValues = [
    {
      label: "Yes",
      value: "True",
    },
    {
      label: "No",
      value: "False",
    },
  ] as SelectItemResponse[];

  const licenseModes = [
    {
      label: "Master",
      value: "Master",
    },
    {
      label: "Slave",
      value: "Slave",
    },
  ] as SelectItemResponse[];
  const dataIntervals = [
    {
      label: "1 Min",
      value: "1",
    },
    {
      label: "5 Mins",
      value: "5",
    },
    {
      label: "10 Mins",
      value: "10",
    },
    {
      label: "15 Mins",
      value: "15",
    },
    {
      label: "30 Mins",
      value: "30",
    },
    {
      label: "Hourly",
      value: "60",
    },
  ] as SelectItemResponse[];
  const dataTransmissionIntervals = [
    {
      label: "1 Min",
      value: "1",
    },
    {
      label: "5 Mins",
      value: "5",
    },
    {
      label: "10 Mins",
      value: "10",
    },
    {
      label: "15 Mins",
      value: "15",
    },
    {
      label: "30 Mins",
      value: "30",
    },
    {
      label: "Hourly",
      value: "60",
    },
  ] as SelectItemResponse[];

  const form = useForm<SystemSettingResponse>({
    initialValues: {
      systemMode: "Vessel",
      license: "Slave",
      dataStorage: 12,
      modbusPort: 502,
      dataInterval: "5",
      dataTransmissionInterval: "15",
      maxDataSize: 1024,
      cloudUrl: "",
      ftpIpAddress: "",
      modbusIpAddress: "",
      systemFolder: "",
      reInit: "",
      bypassSSL: "",
      skipMonthsForOldData: 2,
      testDevelopment: "",
      init: () => {},
      toJSON: () => {},
    },
  });

  const handleSave = async () => {
    setLoading(true);
    try {
      const systemSettingClient = new SystemSettingsClient(undefined, axiosInstance);
      const values = form.values;
      const data = {
        systemMode: values.systemMode,
        license: values.license,
        systemFolder: values.systemFolder,
        dataStorage: values.dataStorage,
        modbusIpAddress: values.modbusIpAddress,
        ftpIpAddress: values.ftpIpAddress,
        modbusPort: values.modbusPort,
        cloudUrl: values.cloudUrl,
        dataInterval: values.dataInterval,
        dataTransmissionInterval: values.dataTransmissionInterval,
        maxDataSize: values.maxDataSize,
        reInit: values.reInit,
        bypassSSL: values.bypassSSL,
        skipMonthsForOldData: values.skipMonthsForOldData,
        testDevelopment: values.testDevelopment,
      } as SystemSettingResponse;
      await systemSettingClient.put(data);
      NotificationHelper.showSuccess("Save Success", "System settings has been updated successfully");
    } catch {
      NotificationHelper.showError("Update error", "An error has occurred in saving system settings");
    }
    setLoading(false);
  };

  useEffect(() => {
    const loadSystemSettings = async () => {
      const user = AuthService.getCurrentUser();
      if (firstLoad && user) {
        setIsAdmin(AuthService.isInRole("Admin"));
        setIsEngineer(AuthService.isInRoleArray(["Admin", "Supervisor", "Engineer"]));
        setIsSupervisor(AuthService.isInRoleArray(["Admin", "Supervisor"]));
        setFirstLoad(false);
        const systemSettingClient = new SystemSettingsClient(undefined, axiosInstance);
        const data = await systemSettingClient.get();
        form.setFieldValue("systemFolder", data.systemFolder);
        form.setFieldValue("license", data.license);
        form.setFieldValue("systemMode", data.systemMode);
        form.setFieldValue("dataStorage", data.dataStorage);
        form.setFieldValue("modbusIpAddress", data.modbusIpAddress);
        form.setFieldValue("ftpIpAddress", data.ftpIpAddress);
        form.setFieldValue("modbusPort", data.modbusPort);
        form.setFieldValue("cloudUrl", data.cloudUrl);
        form.setFieldValue("dataInterval", data.dataInterval);
        form.setFieldValue("dataTransmissionInterval", data.dataTransmissionInterval);
        form.setFieldValue("maxDataSize", data.maxDataSize);
        form.setFieldValue("reInit", data.reInit);
        form.setFieldValue("bypassSSL", data.bypassSSL);
        form.setFieldValue("skipMonthsForOldData", data.skipMonthsForOldData);
        form.setFieldValue("testDevelopment", data.testDevelopment);
      }
    };
    loadSystemSettings();
    return () => {};
  }, [firstLoad, form]);

  useEffect(() => {
    if (!mounted) {
      if (isSystemModeVessel()) {
        setMounted(true);
      } else {
        navigate("/error/only-for-onboard");
      }
    }
    return () => {};
  }, [mounted, isSystemModeVessel, navigate]);

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <Container style={styles} size="xl">
          <Title order={2}>System Setting</Title>
          <Divider my="sm" size="md"></Divider>
          <Stack>
            <Card withBorder shadow="md" p="md">
              <Card.Section withBorder p="md">
                <Title order={3}>System</Title>
              </Card.Section>
              <Card.Section p="md">
                <Stack>
                  <Group grow>
                    <Select
                      disabled={!isAdmin}
                      withinPortal
                      data={systemModes}
                      label="System Mode"
                      description="Defaults to 'Vessel'. Should not be changed"
                      {...form.getInputProps("systemMode")}
                    />
                    <Select
                      disabled={!isAdmin}
                      withinPortal
                      data={licenseModes}
                      label="License Mode"
                      description="Should not be changed unless admin"
                      {...form.getInputProps("license")}
                    />
                    <TextInput
                      disabled={!isAdmin}
                      label="System Folder"
                      description="Root folder of installed directory"
                      {...form.getInputProps("systemFolder")}
                    />
                  </Group>
                  <Group grow>
                    <NumberInput
                      disabled={!isSupervisor}
                      label="Data Storage (Months)"
                      description="Data storage for signals in database"
                      min={6}
                      max={60}
                      {...form.getInputProps("dataStorage")}
                    />
                    <Select
                      color="red"
                      withinPortal
                      label="Re-initalize system"
                      description="Reinitalize system in 30 seconds after saving."
                      data={reinitValues}
                      {...form.getInputProps("reInit")}
                    />
                    <Select
                      disabled={!isAdmin}
                      color="red"
                      withinPortal
                      label="Test Development"
                      description="Creating simulations of data."
                      data={bypassSSLValues}
                      {...form.getInputProps("testDevelopment")}
                    />
                  </Group>
                </Stack>
              </Card.Section>
            </Card>
            <Card withBorder shadow="md" p="md">
              <Card.Section withBorder p="md">
                <Title order={3}>PLC</Title>
              </Card.Section>
              <Card.Section withBorder p="md">
                <Stack>
                  <Group grow>
                    <TextInput label="Modbus IP Address" description="Modbus IP address of PLC" {...form.getInputProps("modbusIpAddress")} />
                    <TextInput
                      label="FTP IP Address"
                      description="FTP IP address of PLC, can be the same as modbus"
                      {...form.getInputProps("ftpIpAddress")}
                    />
                    <NumberInput
                      label="Modbus Port"
                      description="Port for modbus protocol"
                      min={0}
                      max={65535}
                      {...form.getInputProps("modbusPort")}
                    />
                  </Group>
                </Stack>
              </Card.Section>
            </Card>
            <Card withBorder shadow="md">
              <Card.Section withBorder p="md">
                <Title order={3}>Cloud</Title>
              </Card.Section>
              <Card.Section withBorder p="md">
                <Stack>
                  <Group grow>
                    <TextInput
                      disabled={!isSupervisor}
                      label="Cloud URL"
                      description="Leave blank if no cloud is needed"
                      {...form.getInputProps("cloudUrl")}
                    />
                    <Select
                      disabled={!isSupervisor}
                      withinPortal
                      data={dataIntervals}
                      label="Data interval"
                      description="Data interval for one data point"
                      {...form.getInputProps("dataInterval")}
                    />
                    <Select
                      disabled={!isSupervisor}
                      withinPortal
                      data={dataTransmissionIntervals}
                      label="Data transmission interval"
                      description="Interval to connect to cloud to transfer data"
                      {...form.getInputProps("dataTransmissionInterval")}
                    />
                    <NumberInput
                      disabled={!isSupervisor}
                      min={0}
                      label="Max data packet size"
                      description="If bigger, will create another packet"
                      {...form.getInputProps("maxDataSize")}
                    />
                  </Group>
                  <Group grow>
                    <NumberInput
                      disabled={!isAdmin}
                      min={0}
                      label="Skip months for old data"
                      description="Skip months for old data or when your vessel is not running."
                      {...form.getInputProps("skipMonthsForOldData")}
                    />
                    <Select
                      disabled={!isAdmin}
                      color="red"
                      withinPortal
                      label="Bypass SSL"
                      description="Can bypass SSL for testing purposes, if production should be false and with SSL equiped."
                      data={bypassSSLValues}
                      {...form.getInputProps("bypassSSL")}
                    />
                  </Group>
                </Stack>
              </Card.Section>
              <Card.Section withBorder p="md">
                <Button onClick={handleSave} loading={loading}>
                  Save
                </Button>
              </Card.Section>
            </Card>
          </Stack>
        </Container>
      )}
    </Transition>
  );
};
export default SystemSetting;
