import { Divider, Title, Transition, Button, Group, SelectItem, ActionIcon, useMantineTheme, Stack, Text } from "@mantine/core";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { Cancel, CheckCircle, Edit } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { IconCalendar } from "@tabler/icons";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosService";
import { TripPatchRequest, TripResponse, VesselsClient, VoyageClient } from "../../services/WebApiService";
import EditTripModal from "./components/EditTripModal";
import CreateVoyageModal from "./components/CreateVoyageModal";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../providers/GlobalContextProvider";
import _ from "lodash";

type Props = {};
const VoyageTrip = (props: Props) => {
  const mantineTheme = useMantineTheme();
  const { systemGlobal } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });

  const { vesselId } = useParams();

  const { t } = useTranslation();

  const [editTrip, setEditTrip] = useState<TripResponse | null>(null);
  const [isCreateVoyageModalOpen, setIsCreateVoyageModalOpen] = useState(false);
  const [reloadExistingVoyages, setReloadExistingVoyages] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [trips, setTrips] = useState<TripResponse[]>([]);
  const [existingVoyages, setExistingVoyages] = useState<SelectItem[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [dateRangeValue, setDateRangeValue] = useState<DateRangePickerValue>([
    dayjs().startOf("date").subtract(30, "day").toDate(),
    dayjs().startOf("date").toDate(),
  ]);

  const reloadSearchPlaceholder = (value: boolean) => {};

  const searchTrips = useCallback(async () => {
    const start = dateRangeValue[0];
    const end = dateRangeValue[1];
    if (vesselId && start && end) {
      setLoading(true);
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      setTrips(await vesselClient.trips(Number(vesselId), start, end));
      setLoading(false);
    }
  }, [dateRangeValue, vesselId]);

  const loadExistingVoyages = async () => {
    const voyageClient = new VoyageClient(undefined, axiosInstance);
    const entites = await voyageClient.allExistingVoyages();
    setExistingVoyages(
      entites.map(
        (x) =>
          ({
            value: x.id,
            label: x.name,
          } as SelectItem)
      )
    );
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  useEffect(() => {
    if (reloadExistingVoyages) {
      setReloadExistingVoyages(false);
      loadExistingVoyages();
    }

    return () => {};
  }, [reloadExistingVoyages]);

  useEffect(() => {
    const loadManualInputs = async () => {
      if (vesselId && firstLoad) {
        setLoading(true);
        const vesselClient = new VesselsClient(undefined, axiosInstance);
        const data = await vesselClient.trips(Number(vesselId));
        setTrips(data);
      }
    };

    setFirstLoad(false);
    setLoading(false);
    loadManualInputs();
    loadExistingVoyages();
    return () => {};
  }, [firstLoad, vesselId]);

  const columnDef: GridColDef[] = [
    {
      field: "name",
      headerName: t("managetrip_tripname"),
      minWidth: 200,
      flex: 1,
    },
    {
      field: "Voyage",
      headerName: t("managetrip_voyage"),
      minWidth: 150,
      flex: 1,
      renderCell: (cell) => {
        return <Text>{existingVoyages.find((x) => x.value === cell.row.voyageId)?.label ?? ""}</Text>;
      },
    },
    {
      field: "description",
      headerName: t("managetrip_descriptions"),
      minWidth: 300,
      maxWidth: 600,
    },
    {
      field: "startDateTime",
      headerName: t("managetrip_startdatetime"),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "endDateTime",
      headerName: t("managetrip_enddatetime"),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "distance",
      headerName: t("managetrip_distance"),
      minWidth: 100,
      flex: 1,
    },
    {
      field: "cargo",
      headerName: t("managetrip_cargo"),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "totalCo2Emission",
      headerName: t("managetrip_co2emission"),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "totalConsumption",
      headerName: t("managetrip_consumption"),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "eeoi",
      headerName: t("managetrip_eeoi"),
      flex: 1,
    },
    {
      field: "portName",
      headerName: t("managetrip_portname"),
      minWidth: 200,
      flex: 1,
    },
    {
      field: "isEuPort",
      headerName: t("managetrip_euport"),
      minWidth: 100,
      flex: 1,
      renderCell: (cell) => <>{cell.row.isEuPort ? <CheckCircle color="success" /> : <Cancel color="error" />}</>,
    },
    {
      field: "g1",
      headerName: t("managetrip_G1"),
      flex: 1,
      minWidth: 120,
      renderCell: (cell) => {
        return <Text>{_.round(cell.row.g1, 2)}</Text>;
      },
    },
    {
      field: "g2",
      headerName: t("managetrip_G2"),
      flex: 1,
      minWidth: 120,
      renderCell: (cell) => {
        return <Text>{_.round(cell.row.g2, 2)}</Text>;
      },
    },
    {
      field: "g3",
      headerName: t("managetrip_G3"),
      flex: 1,
      minWidth: 120,
      renderCell: (cell) => {
        return <Text>{_.round(cell.row.g3, 2)}</Text>;
      },
    },
    {
      field: "ratings",
      headerName: t("managetrip_rating"),
      flex: 1,
      minWidth: 120,
      renderCell: (cell) => {
        return <Text>{cell.row.ratings}</Text>;
      },
    },
    {
      field: "edit",
      headerName: t("managetrip_edit"),
      minWidth: 80,
      flex: 1,
      renderCell: (cell) => (
        <ActionIcon variant="subtle" color={mantineTheme.primaryColor} onClick={() => showEditTripModal(cell.row)}>
          <Edit />
        </ActionIcon>
      ),
    },
  ];

  const loadDataGrid = () => {
    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          experimentalFeatures={{ newEditingApi: true }}
          loading={loading}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          autoHeight
          pagination
          onPageSizeChange={setPageSize}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={pageSize}
          rows={trips}
          columns={columnDef}
        />
      </ThemeProvider>
    );
  };

  const showEditTripModal = (trip: TripResponse) => {
    setIsModalOpen(true);
    setEditTrip(trip);
  };

  const setTrip = (trip: TripPatchRequest) => {
    setTrips((current) =>
      current.map((x) => {
        if (x.id === trip.id) {
          x.cargo = trip.cargo;
          x.description = trip.description;
          x.name = trip.name;
          x.isEuPort = trip.isEuPort;
          x.portName = trip.portName;
          x.voyageId = trip.voyageId;
          return x;
        } else {
          return x;
        }
      })
    );
  };

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <div style={styles}>
          <Group>
            <Title order={2}>{t("managetrip")}</Title>
            <Button onClick={() => setIsCreateVoyageModalOpen(true)}>{t("managetrip_createnewvoyage")}</Button>
          </Group>
          <Divider my="sm" size="md"></Divider>
          <Stack>
            <Group align="end">
              <DateRangePicker
                withinPortal
                required
                label={t("managetrip_daterange")}
                value={dateRangeValue}
                onChange={setDateRangeValue}
                icon={<IconCalendar size={16} />}
                miw={350}
              />
              <Button disabled={loading} loading={loading} onClick={searchTrips}>
                {t("managetrip_search")}
              </Button>
            </Group>
            {loadDataGrid()}
            <CreateVoyageModal
              vesselId={Number(vesselId)}
              isOpen={isCreateVoyageModalOpen}
              setIsOpen={setIsCreateVoyageModalOpen}
              setReloadExistingVoyages={setReloadExistingVoyages}
            />
            <EditTripModal
              existingVoyages={existingVoyages}
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              trip={editTrip ?? null}
              setTrip={setTrip}
              setReloadSearch={reloadSearchPlaceholder}
            />
          </Stack>
        </div>
      )}
    </Transition>
  );
};
export default VoyageTrip;
