import { UseFormReturnType } from "@mantine/form";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import NotificationHelper from "../helpers/NotiHelper";
import { DashboardConfigForm } from "../models/dashboard/DashboardConfigForm";
import { DashboardConfigContext } from "../providers/DashboardConfigProvider";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import { DashboardPermissionRequest, DashboardPostRequest, DashboardResponse, DashboardsClient } from "../services/WebApiService";
import DashboardConfig from "./components/Common/DashboardConfig";
import { useTranslation } from "react-i18next";

const CreateDashboardConfig = () => {
  const { isOpen, dashboardType, setIsOpen, setReload } = useContext(DashboardConfigContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleCreateNewDashboard = async (
    e: React.FormEvent<HTMLFormElement>,
    form: UseFormReturnType<DashboardConfigForm>,
    file: File | null,
    dashboard: DashboardResponse,
    dashboardPermissions: DashboardPermissionRequest[]
  ) => {
    e.preventDefault();
    try {
      const user = AuthService.getCurrentUser();
      if (user) {
        const dashboardClient = new DashboardsClient(undefined, axiosInstance);
        const values = form.values;
        const entity = {
          category: values.category,
          name: values.name,
          isFleetDashboard: values.isFleetDashboard,
          isSystemDashboard: values.isSystemDashboard,
          viewEnabled: values.viewEnabled,
          dashboardPermissions: dashboardPermissions,
          ownerId: user.userid,
          toClone: values.toClone,
          cloneDashboardId: values.cloneDashboardId,
        } as DashboardPostRequest;
        var newDashboard = await dashboardClient.post(entity);
        if (file) {
          const fileParameter = { data: file, fileName: file.name };
          await dashboardClient.backgroundImagePOST(newDashboard.id, fileParameter);
        }
        form.setFieldValue("name", "");
        form.setFieldValue("category", "");
        form.setFieldValue("isSystemDashboard", false);
        form.setFieldValue("toClone", false);
        NotificationHelper.showSuccess("Success", "A new screen has been created successfully");
        navigate(`/dashboard/edit/${newDashboard.id}`);
      }
    } catch {
      NotificationHelper.showError("Error", "An error occurred in creating new screen");
    }
  };
  return (
    <DashboardConfig
      title={t("addnewscreen")}
      buttonLabel={t("addnewscreen_create")}
      showCloneOption={true}
      isOpen={isOpen}
      dashboardType={dashboardType}
      setReload={setReload}
      setIsOpen={setIsOpen}
      handleSubmit={handleCreateNewDashboard}
    />
  );
};
export default CreateDashboardConfig;
