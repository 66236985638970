import { ActionIcon, Button, Group, Select } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { RemoveCircleOutline } from "@mui/icons-material";
import NotificationHelper from "../../helpers/NotiHelper";
import { ManualReportLibraryForm } from "../../models/manual-report-library/ManualReportLibraryForm";
import { ManualReportMappingDataType, ManualReportSignalIdMapping, SignalMappingResponse } from "../../services/WebApiService";
import { useTranslation } from "react-i18next";

type Props = {
  signals: SignalMappingResponse[];
  form: UseFormReturnType<ManualReportLibraryForm>;
};

const ManualReportSignalIdMappingInput = (props: Props) => {
  const { t } = useTranslation();

  const addMappingItem = () => {
    const item = {
      signalId: props.signals[0].id.toString(),
      dataType: ManualReportMappingDataType.Instant,
    } as ManualReportSignalIdMapping;
    props.form.insertListItem("signalIdMappings", item);
  };

  const removeMappingItem = (index: number) => {
    if (props.form.values.signalIdMappings.length > 1) {
      props.form.removeListItem("signalIdMappings", index);
    } else {
      NotificationHelper.showError("Error", "Cannot remove the last signal id mapping");
    }
  };

  const loadMappings = props.form.values.signalIdMappings.map((item, index) => {
    return (
      <Group align="end" grow key={`SignalMapping${index}`}>
        <Select
          withinPortal
          label={t("manualreport_signal")}
          data={props.signals.map((x) => ({ label: x.displayName, value: x.id.toString() }))}
          required
          searchable
          nothingFound={t("manualreport_nothingfound")}
          {...props.form.getInputProps(`signalIdMappings.${index}.signalId`)}
        />
        <Select
          withinPortal
          data={Object.keys(ManualReportMappingDataType).map((x) => ({ value: x, label: x }))}
          label={t("manualreport_datatype")}
          required
          searchable
          nothingFound={t("manualreport_nothingfound")}
          {...props.form.getInputProps(`signalIdMappings.${index}.dataType`)}
        />
        <Group>
          <ActionIcon color="red" onClick={() => removeMappingItem(index)}>
            <RemoveCircleOutline></RemoveCircleOutline>
          </ActionIcon>
        </Group>
      </Group>
    );
  });
  return (
    <>
      {loadMappings}
      <Group>
        <Button variant="light" onClick={() => addMappingItem()}>
          {t("manualreport_addsignalidmap")}
        </Button>
      </Group>
    </>
  );
};

export default ManualReportSignalIdMappingInput;
