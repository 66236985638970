import * as echarts from "echarts/core";
import { GaugeChart, GaugeSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { DashboardSignalResponse, DashboardVesselResponse, DashboardWidgetResponse, HubSignalResponse } from "../../../services/WebApiService";
import { RadialGaugeOptions } from "../../../models/dashboard/DashboardModels";
import SignalHelper from "../../../helpers/SignalHelper";
import { useContext, useEffect, useMemo, useState } from "react";
import { Group, Stack } from "@mantine/core";
import { GlobalContext } from "../../../providers/GlobalContextProvider";
import React from "react";

echarts.use([GaugeChart, CanvasRenderer]);

type EChartsOption = echarts.ComposeOption<GaugeSeriesOption>;

type GaugeState = {
  signalId: number;
  gauge: echarts.ECharts | undefined;
};

type ColorString = string;
type GaugeColorStop = [number, ColorString];

type Props = {
  id: string;
  optionJson: string;
  dashboardVessels: DashboardVesselResponse[];
  dashboardWidget: DashboardWidgetResponse;
  hubSignals: HubSignalResponse[];
};

const RadialGaugeHelper = (props: Props) => {
  const [divIds, setDivIds] = useState<string[]>([]);
  const [, setGaugeStates] = useState<GaugeState[]>([]);

  const { systemGlobal } = useContext(GlobalContext);

  const getMax = (signal: DashboardSignalResponse, options: RadialGaugeOptions) => {
    if (options.manualOverride) {
      return options.max;
    }
    return signal.maxValue;
  };

  const getColors = (signal: DashboardSignalResponse, options: RadialGaugeOptions, max: number): GaugeColorStop[] => {
    const green = "#00FF00";
    const yellow = "#FFFF00";
    const red = "#FF0000";
    if (options.manualOverride) {
      return [
        [options.warnStart / options.max, green],
        [options.alarmStart / options.max, yellow],
        [1, red],
      ];
    }
    const result = [
      [signal.warnMax / signal.maxValue, green],
      [signal.alarmMax / signal.maxValue, yellow],
      [1, red],
    ] as GaugeColorStop[];
    return result;
  };

  const loadGauges = () => {
    const options = JSON.parse(props.optionJson) as RadialGaugeOptions;
    if (options.layout === "column") {
      return <Stack align="center">{createDivsForGauge}</Stack>;
    } else {
      return <Group position="center">{createDivsForGauge}</Group>;
    }
  };

  const createDivsForGauge = useMemo(() => {
    const options = JSON.parse(props.optionJson) as RadialGaugeOptions;
    const signals = SignalHelper.getSignalsByMappingsByVessels(props.dashboardVessels, options.mappings, options.showVesselName);
    return signals.map((x) => {
      const domId = `RadialGauge-${props.dashboardWidget.id}-${x.id}`;
      const filteredVessel: DashboardVesselResponse = props.dashboardVessels.filter((e) => e.vesselId === x.vesselId)[0];
      // Setting use state here will cause this to re-render again causing infinite loop
      // Hence it is put in usememo
      setDivIds((old) => [...old, domId]);
      return (
        <React.Fragment>
          <Stack className="text-center">
            <h4 style={{ textAlign: "center" }}> {filteredVessel.vesselName}</h4>

            <div
              key={domId}
              id={domId}
              style={{
                width: options.width,
                height: options.height,
                padding: "0px 0px",
              }}
            ></div>
          </Stack>
        </React.Fragment>
      );
    });
  }, [props.dashboardVessels, props.dashboardWidget, props.optionJson]);

  // Create gauge
  useEffect(() => {
    const createAllGauges = () => {
      const gauges = [];
      const options = JSON.parse(props.optionJson) as RadialGaugeOptions;
      const signals = SignalHelper.getSignalsByMappingsByVessels(props.dashboardVessels, options.mappings, options.showVesselName);
      for (let i = 0; i < signals.length; i++) {
        const signal = signals[i];
        const filteredVessel: DashboardVesselResponse = props.dashboardVessels.filter((e) => e.vesselId === signal.vesselId)[0];
        const gauge = createGauge(`RadialGauge-${props.dashboardWidget.id}-${signal.id}`, signal, options, filteredVessel);
        gauges.push(gauge);
        setGaugeStates((oldArray) => [...oldArray, { signalId: signal.id, gauge: gauge } as GaugeState]);
      }
      return gauges;
    };

    const createGauge = (domId: string, signal: DashboardSignalResponse, options: RadialGaugeOptions, vessel?: DashboardVesselResponse) => {
      const gaugeDom = document.getElementById(domId);
      if (gaugeDom) {
        const gauge = echarts.init(gaugeDom);
        const max = getMax(signal, options);
        const colors = getColors(signal, options, max);
        const eChartsOption: EChartsOption = {
          series: [
            {
              type: "gauge",
              min: 0,
              max: max,
              radius: "100%",
              splitNumber: options.steps,
              axisLine: {
                lineStyle: {
                  width: 15,
                  color: colors,
                },
              },
              pointer: {
                icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
                itemStyle: {
                  color: systemGlobal.colorTheme === "dark" ? "white" : "black",
                },
                length: "70%",
              },
              axisTick: {
                distance: -15,
                length: 6,
                lineStyle: {
                  color: systemGlobal.colorTheme === "dark" ? "white" : "black",
                  width: 1,
                },
              },
              splitLine: {
                distance: -15,
                length: 15,
                lineStyle: {
                  color: systemGlobal.colorTheme === "dark" ? "white" : "black",
                  width: 2.5,
                },
              },
              axisLabel: {
                color: systemGlobal.colorTheme === "dark" ? "white" : "black",
                distance: 20,
                fontSize: options.valueFontSize,
              },
              detail: {
                offsetCenter: [0, "90%"],
                valueAnimation: true,
                formatter: `{value} ${signal.unitDescription}`,
                color: "inherit",
                fontSize: options.titleFontSize,
                precision: 1,
              },
              title: {
                offsetCenter: [0, "67%"],
                fontSize: options.titleFontSize,
                color: systemGlobal.colorTheme === "dark" ? "white" : "black",
              },
              data: [
                {
                  name: `${signal.displayName}`,
                  value: 0,
                },
              ],
            },
          ],
        };
        gauge.setOption(eChartsOption);
        return gauge;
      }
    };
    const gauges = createAllGauges();

    return () => {
      for (let i = 0; i < gauges.length; i++) {
        const gauge = gauges[i];
        gauge?.dispose();
      }
    };
  }, [divIds, props.dashboardVessels, props.dashboardWidget, props.optionJson, systemGlobal.colorTheme]);

  // Live
  useEffect(() => {
    const updateGaugeLive = () => {
      for (let i = 0; i < props.hubSignals.length; i++) {
        const signal = props.hubSignals[i];
        setGaugeStates((current) =>
          current.map((x) => {
            if (x.signalId === signal.id) {
              if (x.gauge) {
                x.gauge.setOption({
                  series: [
                    {
                      data: [
                        {
                          name: signal.name,
                          value: signal.value,
                        },
                      ],
                    },
                  ],
                });
              }
              return x;
            } else {
              return x;
            }
          })
        );
      }
    };
    updateGaugeLive();

    return () => {};
  }, [props.hubSignals]);

  return <>{loadGauges()}</>;
};

export default RadialGaugeHelper;
