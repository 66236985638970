import { ThemeProvider, createTheme } from "@mui/material";
import {
  DataTransferApiKeyClient,
  DataTransferApiKeyLogClient,
  DataTransferApiKeyLogResponse,
  DataTransferApiKeyResponse,
} from "../services/WebApiService";
import { Badge, Text, Card, Container, Divider, Group, Title, Transition } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { axiosInstance } from "../services/AxiosService";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "moment";

const UserApiKey = () => {
  const navigate = useNavigate();

  const { isSystemModeVessel, systemGlobal } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });

  const [mounted, setMounted] = useState(false);

  const [dataTransferApiKeyLogs, setDataTransferApiKeyLogs] = useState<DataTransferApiKeyLogResponse[]>([]);
  const [dataTransferApiKey, setDataTransferApiKey] = useState<DataTransferApiKeyResponse>();

  const { userId } = useParams();

  const { t } = useTranslation();

  const loadDataGrid = () => {
    return (
      <>
        <ThemeProvider theme={theme}>
          <DataGrid
            autoHeight
            rows={dataTransferApiKeyLogs}
            columns={[
              {
                field: "email",
                headerName: t("fulllogdatatransferapikey_email"),
                flex: 1,
                renderCell: (cell) => (
                  <div>
                    {cell.row.dataTransferApiKey.applicationUser.email === "" ? "(no email)" : cell.row.dataTransferApiKey.applicationUser.email}
                  </div>
                ),
              },
              {
                field: "username",
                headerName: t("fulllogdatatransferapikey_username"),
                flex: 1,
                renderCell: (cell) => (
                  <div>
                    {cell.row.dataTransferApiKey.applicationUser.userName === ""
                      ? "(no username)"
                      : cell.row.dataTransferApiKey.applicationUser.userName}
                  </div>
                ),
              },
              {
                field: "ipv4",
                headerName: t("fulllogdatatransferapikey_ipv4"),
                flex: 1,
                renderCell: (cell) => <div>{cell.row.ipv4 === "" ? "(no ipv4)" : cell.row.ipv4}</div>,
              },
              {
                field: "ipv6",
                headerName: t("fulllogdatatransferapikey_ipv6"),
                flex: 1,
                renderCell: (cell) => <div>{cell.row.ipv6 === "" ? "(no ipv6)" : cell.row.ipv6}</div>,
              },
              {
                field: "accessAt",
                headerName: t("fulllogdatatransferapikey_accessat"),
                flex: 1,
                renderCell: (cell) => <div>{Moment(cell.row.accessAt).format("YYYY-MM-DD HH:MM:ss")}</div>,
              },
            ]}
          />
        </ThemeProvider>
      </>
    );
  };

  useEffect(() => {
    if (isSystemModeVessel()) {
      navigate("/error/only-for-onshore");
    }

    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  const loadDataTransfer = async () => {
    var timezoneOffsetMinutes = new Date().getTimezoneOffset();

    const dataTransferApiKeyLogClient = new DataTransferApiKeyLogClient(undefined, axiosInstance);

    const data = await dataTransferApiKeyLogClient.getAll(timezoneOffsetMinutes);
    setDataTransferApiKeyLogs(data);
  };

  const loadDataTransferApiKey = async () => {
    const dataTransferApiKeyClient = new DataTransferApiKeyClient(undefined, axiosInstance);

    const data = await dataTransferApiKeyClient.getByUserId(userId);
    setDataTransferApiKey(data);
  };

  useEffect(() => {
    loadDataTransfer();
    loadDataTransferApiKey();

    return () => {};
  }, []);

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <Container size="xl" style={styles}>
            <Card shadow="sm" p="lg" radius="md" withBorder>
              <Group position="apart" mt="md" mb="xs">
                <Text weight={500} size={20}>
                  API Key
                </Text>
                <Badge color={dataTransferApiKey?.apiKey ? "green" : "red"} variant="light">
                  {dataTransferApiKey?.apiKey ? "Yes" : "No"}
                </Badge>
              </Group>

              <Text size="sm" color="dimmed">
                {dataTransferApiKey?.apiKey}
              </Text>
            </Card>
            <br />
            <Group>
              <Title order={2}>{t("fulllogdatatransferapikey_datatransferapikey")}</Title>
            </Group>
            <Divider my="sm" size="md"></Divider>
            {loadDataGrid()}
          </Container>
        )}
      </Transition>
    </>
  );
};

export default UserApiKey;
