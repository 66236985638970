import { Text, Button, Card, Container, Divider, Group, NumberInput, Select, Stack, TextInput, Title, Transition } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import {
  ManualInputSignalRequest,
  SignalMappingResponse,
  TripClient,
  TripMode,
  VesselsClient,
  VoyageSettingResponse,
} from "../../services/WebApiService";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../providers/GlobalContextProvider";

type Props = {};

type VoyageSettingForm = {
  id: number;
  avgSogTimeRangeInMins: number;
  sogToEndMovingTrip: number;
  sogToStartMovingTrip: number;
  tripMode: TripMode;
  isMovingTripExist: boolean;
};

const IndexTripSetting = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [isCargoFound, setIsCargoFound] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [cargo, setCargo] = useState<SignalMappingResponse>();
  const { vesselId } = useParams();

  const { isSystemModeVessel } = useContext(GlobalContext);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const form = useForm<VoyageSettingForm>({
    initialValues: {
      id: 0,
      avgSogTimeRangeInMins: 5,
      sogToEndMovingTrip: 0,
      sogToStartMovingTrip: 1,
      tripMode: TripMode.Manual,
      isMovingTripExist: false,
    },
  });

  const handleSubmit = async () => {
    const validateResult = form.validate();
    if (!validateResult.hasErrors) {
      try {
        const vesselClient = new VesselsClient(undefined, axiosInstance);
        const values = form.values;
        const obj = {
          avgSogTimeRangeInMins: values.avgSogTimeRangeInMins,
          id: values.id,
          sogToEndMovingTrip: values.sogToEndMovingTrip,
          sogToStartMovingTrip: values.sogToStartMovingTrip,
          tripMode: values.tripMode,
        } as VoyageSettingResponse;
        const entity = new VoyageSettingResponse();
        entity.init(obj);
        await vesselClient.voyageSettingsPUT(entity);
        NotificationHelper.showSuccess("Success", "Voyage settings updated successfully");
      } catch {
        NotificationHelper.showError("Error", "Error in updating voyage settings");
      }
    }
  };

  const handleSwitchToMovingTrip = async () => {
    try {
      const tripClient = new TripClient(undefined, axiosInstance);
      const response = await tripClient.switchToMovingTrip();
      if (response) {
        NotificationHelper.showSuccess("Success", "Trip switched to moving successfully");
        form.setFieldValue("isMovingTripExist", true);
      } else {
        NotificationHelper.showError("Error", "An error occurred in switching trip");
      }
    } catch {
      NotificationHelper.showError("Error", "An error occurred in switching trip");
    }
  };

  const handleSwitchToNonMovingTrip = async () => {
    try {
      const tripClient = new TripClient(undefined, axiosInstance);
      const response = await tripClient.switchToNonMovingTrip();
      if (response) {
        NotificationHelper.showSuccess("Success", "Trip switched to non moving successfully");
        form.setFieldValue("isMovingTripExist", false);
      } else {
        NotificationHelper.showError("Error", "An error occurred in switching trip");
      }
    } catch {
      NotificationHelper.showError("Error", "An error occurred in switching trip");
    }
  };

  const handleSaveCargo = async () => {
    setLoading(true);
    if (cargo && vesselId) {
      const entity = [
        {
          id: cargo.id,
          value: cargo.lastValue,
        },
      ] as ManualInputSignalRequest[];
      try {
        const vesselClient = new VesselsClient(undefined, axiosInstance);
        await vesselClient.manualInputsPATCH(Number(vesselId), entity);
        NotificationHelper.showSuccess("Success", "Cargo value is updated successfully");
      } catch {
        NotificationHelper.showError("Error", "An error occurred in updating cargo value");
      }
    }
    setLoading(false);
  };

  const loadVoyageControls = () => {
    const disabled = form.values.tripMode === TripMode.Automatic;
    if (form.values.isMovingTripExist) {
      return (
        <Button disabled={disabled} onClick={handleSwitchToNonMovingTrip} variant="outline">
          Switch to Non-Moving Trip
        </Button>
      );
    } else {
      return (
        <Button disabled={disabled} onClick={handleSwitchToMovingTrip} variant="outline">
          Switch to Moving Trip
        </Button>
      );
    }
  };

  useEffect(() => {
    if (!isSystemModeVessel()) {
      navigate("/error/only-for-onboard");
    }

    if (!mounted) {
      setMounted(true);
    }

    return () => {};
  }, [mounted]);

  useEffect(() => {
    const loadVoyageSetting = async () => {
      if (vesselId) {
        const vesselClient = new VesselsClient(undefined, axiosInstance);
        const data = await vesselClient.voyageSettingsGET(Number(vesselId));
        if (data) {
          form.setFieldValue("avgSogTimeRangeInMins", data.avgSogTimeRangeInMins);
          form.setFieldValue("sogToStartMovingTrip", data.sogToStartMovingTrip);
          form.setFieldValue("sogToEndMovingTrip", data.sogToEndMovingTrip);
          form.setFieldValue("tripMode", data.tripMode);
          form.setFieldValue("isMovingTripExist", data.isMovingTripExist);
          form.setFieldValue("id", data.id);
        }
      }
    };

    const loadCargo = async () => {
      if (vesselId) {
        const vesselClient = new VesselsClient(undefined, axiosInstance);
        const data = await vesselClient.cargo(Number(vesselId));
        if (data) {
          setIsCargoFound(true);
          setCargo(data);
        } else {
          setIsCargoFound(false);
        }
      }
    };

    if (firstLoad) {
      setFirstLoad(false);
      loadVoyageSetting();
      loadCargo();
    }

    return () => {};
  }, [firstLoad, form, vesselId]);

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Group>
              <Title order={2}>{t("tripsetting")}</Title>
            </Group>
            <Divider my="sm" size="md"></Divider>
            <Container size="xl">
              <Stack>
                <Card shadow="sm" p="lg" radius="sm" withBorder>
                  <Card.Section withBorder p="md">
                    <Title order={3}>{t("tripsetting_settings")}</Title>
                  </Card.Section>
                  <Card.Section withBorder p="lg">
                    <Stack>
                      <Group grow>
                        <NumberInput
                          label={t("tripsetting_startingsog")}
                          description={t("tripsetting_startingsogdesc")}
                          min={0}
                          max={20}
                          {...form.getInputProps("sogToStartMovingTrip")}
                        />
                        <NumberInput
                          label={t("tripsetting_endingsog")}
                          description={t("tripsetting_endingsogdesc")}
                          min={0}
                          max={20}
                          {...form.getInputProps("sogToEndMovingTrip")}
                        />
                        <NumberInput
                          label={t("tripsetting_minutes")}
                          description={t("tripsetting_minutesdesc")}
                          min={5}
                          max={30}
                          {...form.getInputProps("avgSogTimeRangeInMins")}
                        />
                        <Select
                          withinPortal
                          data={Object.keys(TripMode).map((x) => ({
                            value: x.toString(),
                            label: x.toString(),
                          }))}
                          label={t("tripsetting_tripmode")}
                          description={t("tripsetting_tripmodedesc")}
                          {...form.getInputProps("tripMode")}
                        />
                      </Group>
                      <Group>
                        <Button onClick={handleSubmit}>{t("tripsetting_save")}</Button>
                      </Group>
                    </Stack>
                  </Card.Section>
                </Card>
                <Card shadow="sm" p="lg" radius="sm" withBorder>
                  <Card.Section withBorder p="md">
                    <Title order={3}>{t("tripsetting_tripcontrol")}</Title>
                  </Card.Section>
                  <Card.Section withBorder p="lg">
                    <Stack>
                      <Group grow align="end">
                        <TextInput
                          label={t("tripsetting_currenttripstatus")}
                          description={t("tripsetting_currenttrupstatusdesc")}
                          readOnly
                          value={form.values.isMovingTripExist ? t("tripsetting_moving") : t("tripsetting_nonmoving")}
                        />
                      </Group>
                      <Group>{loadVoyageControls()}</Group>
                    </Stack>
                  </Card.Section>
                </Card>
                <Card shadow="sm" p="lg" withBorder>
                  <Card.Section p="md" withBorder>
                    <Title order={3}>{t("tripsetting_changecargovalue")}</Title>
                  </Card.Section>
                  <Card.Section p="md">
                    {isCargoFound ? (
                      <NumberInput
                        precision={5}
                        value={cargo?.lastValue}
                        onChange={(e) => setCargo((current) => ({ ...current, lastValue: e! } as SignalMappingResponse))}
                        label={t("tripsetting_cargo")}
                        min={0}
                        max={10000000}
                      />
                    ) : (
                      <Text color="red">{t("tripsetting_cargonotfound")}</Text>
                    )}
                  </Card.Section>
                  <Card.Section p="md">
                    <Button onClick={handleSaveCargo} loading={loading}>
                      {t("tripsetting_updatevalue")}
                    </Button>
                  </Card.Section>
                </Card>
              </Stack>
            </Container>
          </div>
        )}
      </Transition>
    </>
  );
};

export default IndexTripSetting;
