import { Group, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { CommonEditReportSetting } from "../../../../../models/report-setting/CommonEditReportSetting";
import ContrySelectInput from "../../../../../components/CountrySelectInput";
import { useTranslation } from "react-i18next";

type Props = {
  form: UseFormReturnType<CommonEditReportSetting>;
};

const ContactPersonSectionInput = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Group grow spacing="lg" pb="sm" align="end">
        <TextInput
          label={t("vesselreportsetting_contactname")}
          description={t("vesselreportsetting_contactnamedesc")}
          {...props.form.getInputProps("ContactPersonName")}
        />

        <TextInput
          label={t("vesselreportsetting_contactaddress")}
          description={t("vesselreportsetting_contactaddressdesc")}
          {...props.form.getInputProps("ContactPersonAddress")}
        />
      </Group>
      <Group grow spacing="lg" pb="md" align="end">
        <TextInput
          label={t("vesselreportsetting_contactcity")}
          description={t("vesselreportsetting_contactcitydesc")}
          {...props.form.getInputProps("ContactPersonCity")}
        />

        <TextInput
          label={t("vesselreportsetting_contactstate")}
          description={t("vesselreportsetting_contactstatedesc")}
          {...props.form.getInputProps("ContactPersonState")}
        />

        <TextInput
          label={t("vesselreportsetting_contactpostcode")}
          description={t("vesselreportsetting_contactpostcodedesc")}
          {...props.form.getInputProps("ContactPersonPostCode")}
        />
      </Group>
      <Group grow spacing="lg" pb="md" align="end">
        <ContrySelectInput
          inputPropsName="ContactPersonCountry"
          form={props.form}
          label={t("vesselreportsetting_contactcountry")}
          description={t("vesselreportsetting_contactcountrydesc")}
        />
      </Group>

      <Group grow spacing="lg" pb="md" align="end">
        <TextInput
          label={t("vesselreportsetting_contactphone")}
          description={t("vesselreportsetting_contactphonedesc")}
          {...props.form.getInputProps("ContactPersonPhone")}
        />

        <TextInput
          label={t("vesselreportsetting_contactemail")}
          description={t("vesselreportsetting_contactemaildesc")}
          {...props.form.getInputProps("ContactPersonEmail")}
        />
      </Group>
    </>
  );
};

export default ContactPersonSectionInput;
