import {
  Button,
  Card,
  Group,
  Title,
  TransferList,
  Text,
  TransferListData,
  TransferListItemComponent,
  TransferListItemComponentProps,
  Checkbox,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../services/AxiosService";
import { FleetVesselSettingRequest, VesselSettingsClient } from "../../services/WebApiService";
import AuthService from "../../services/AuthService";
import { useParams } from "react-router-dom";
import NotificationHelper from "../../helpers/NotiHelper";

type TransferListDataItem = {
  value: string;
  label: string;
  companyId: number[];
  fleetId: number;
  companyName: string;
};

const FleetAssignList = () => {
  const { vesselId } = useParams();
  const [fleet, setFleet] = useState<TransferListData>([[], []]);

  useEffect(() => {
    const loadFleetVessel = async () => {
      const user = AuthService.getCurrentUser();
      const vesselSettingsClient = new VesselSettingsClient(undefined, axiosInstance);
      const data = await vesselSettingsClient.getFleetVesselSetting(user?.userid);

      const assignedFleetOptions: TransferListDataItem[] = data.assignedFleets.map(
        (fleet) =>
          ({
            value: fleet.name,
            label: fleet.name,
            fleetId: fleet.id,
            companyId: fleet.companyFleet.map((obj) => obj.company.id),
            companyName: fleet.companyFleet.map((obj) => obj.company.name).join(", "),
          } as TransferListDataItem)
      );

      const availableFleetOptions: TransferListDataItem[] = data.availableFleets.map(
        (fleet) =>
          ({
            value: fleet.name,
            label: fleet.name,
            fleetId: fleet.id,
            companyId: fleet.companyFleet.map((obj) => obj.company.id),
            companyName: fleet.companyFleet.map((obj) => obj.company.name).join(", "),
          } as TransferListDataItem)
      );

      setFleet([availableFleetOptions, assignedFleetOptions]);
    };
    loadFleetVessel();
    return () => {};
  }, []);

  const handleSave = () => {
    try {
      const vesselSettingsClient = new VesselSettingsClient(undefined, axiosInstance);

      //re-map
      let result: FleetVesselSettingRequest[] = [];
      for (const data of fleet[1]) {
        result.push({
          fleetId: data.fleetId,
          vesselId: Number(vesselId),
        } as FleetVesselSettingRequest);
      }

      vesselSettingsClient.updateFleetVesselSetting(Number(vesselId), result);

      NotificationHelper.showSuccess("Success", "Fleet-Vessel Settings Saved successfully");
    } catch {
      NotificationHelper.showError("Error in saving", "An error occurred in updating the fleet-vessel setting");
    }
  };

  const transferListItemComponent: TransferListItemComponent = ({ data, selected }: TransferListItemComponentProps) => (
    <Group noWrap>
      <div style={{ flex: 1 }}>
        <Text size="sm" weight={500}>
          {data.label}
        </Text>
        <Text size="xs" color="dimmed" weight={400}>
          {data?.companyName}
        </Text>
      </div>
      <Checkbox checked={selected} onChange={() => {}} tabIndex={-1} sx={{ pointerEvents: "none" }} />
    </Group>
  );

  return (
    <>
      <Card shadow="sm" p="lg" radius="sm" withBorder>
        <Card.Section withBorder p="lg">
          <Title order={3}>Assign Fleet</Title>
        </Card.Section>
        <Card.Section withBorder p="lg">
          <TransferList
            value={fleet}
            onChange={setFleet}
            searchPlaceholder="Search..."
            nothingFound="Nothing here"
            titles={["Available Fleet:", "Assigned Fleet:"]}
            breakpoint="sm"
            itemComponent={transferListItemComponent}
          />
        </Card.Section>
        <Card.Section withBorder p="lg">
          <Button onClick={handleSave}>Save</Button>
        </Card.Section>
      </Card>
    </>
  );
};

export default FleetAssignList;
