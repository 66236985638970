import { Container, Divider, Title, Text } from "@mantine/core"

type Props = {}
const NotFound = (props: Props) => {
  return (
    <Container size="lg">
      <Title order={3}>Vessel not found</Title>
      <Divider my="sm" size="md"></Divider>
      <Text>Database has not been initialized yet. Please wait for a while and refresh the page later.</Text>
    </Container>
  )
}
export default NotFound