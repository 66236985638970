import * as echarts from "echarts/core";
import waldenTheme from "../chart-theme/custom-walden.json";
import { GridComponent, GridComponentOption } from "echarts/components";
import { LineSeriesOption, ScatterChart, ScatterSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useContext, useEffect, useState } from "react";
import { Button, Divider, Flex, MultiSelect, Select, SelectItem, Stack, Title } from "@mantine/core";
import {
  AccountsClient,
  ChartQueryData,
  CurveResponse,
  CurvesClient,
  SignalDataClient,
  SignalMappingResponse,
  VesselsClient,
} from "../services/WebApiService";
import { axiosInstance } from "../services/AxiosService";
import { Token } from "../common/Models";
import AuthService from "../services/AuthService";
import { useForm } from "@mantine/form";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useTranslation } from "react-i18next";
import NotificationHelper from "../helpers/NotiHelper";

echarts.use([GridComponent, ScatterChart, CanvasRenderer, UniversalTransition]);

type EChartsOption = echarts.ComposeOption<GridComponentOption | ScatterSeriesOption | LineSeriesOption>;

type YXChartForm = {
  vesselId: string;
  xAxisSignalId: string;
  yAxisSignalId: string;
  curveIds: string[];
  dateRange?: DateRangePickerValue;
  interval: string;
};

type DataItem = {
  name: string;
  value: [number, number];
};

const HistoryYX = () => {
  const [chart, setChart] = useState<echarts.ECharts>();
  const [firstLoad, setFirstLoad] = useState(true);
  const [intervals, setIntervals] = useState<SelectItem[]>([]);
  const [vessels, setVessels] = useState<SelectItem[]>([]);
  const [signals, setSignals] = useState<SignalMappingResponse[]>([]);
  const [signalSelectItems, setSignalSelectItems] = useState<SelectItem[]>([]);
  const [curves, setCurves] = useState<SelectItem[]>([]);

  const { isSystemModeVessel, systemGlobal } = useContext(GlobalContext);

  const { t } = useTranslation();

  const form = useForm<YXChartForm>({
    initialValues: {
      vesselId: "",
      xAxisSignalId: "",
      yAxisSignalId: "",
      curveIds: [],
      interval: "30",
    },
    validate: {
      dateRange: (value?: DateRangePickerValue) => (!value ? "Please set a date range" : null),
    },
  });

  const handleGenerate = async () => {
    if (chart) {
      chart.dispose();
    }
    const signalDataClient = new SignalDataClient(undefined, axiosInstance);
    const curveClient = new CurvesClient(undefined, axiosInstance);
    const values = form.values;
    const xAxisSignalId = Number(values.xAxisSignalId);
    const yAxisSignalId = Number(values.yAxisSignalId);
    const signalIds = [xAxisSignalId, yAxisSignalId];
    if (!values.dateRange) {
      NotificationHelper.showError("Please select your date range input ", "Fill in all the required fields that cannot be blank!");
      return;
    }

    if (!values.xAxisSignalId) {
      NotificationHelper.showError("Please select your x-axis", "Fill in all the required fields that cannot be blank!");
      return;
    }

    if (!values.yAxisSignalId) {
      NotificationHelper.showError("Please select your y-axis", "Fill in all the required fields that cannot be blank!");
      return;
    }

    if (values.dateRange) {
      let curveData: CurveResponse[] = [];
      if (values.curveIds.length > 0) {
        const curveIdsNumber = values.curveIds.map((x) => {
          return Number(x);
        });
        curveData = await curveClient.curvePoints(curveIdsNumber);
      }
      const queryData = await signalDataClient.datetime(signalIds, Number(values.interval), values.dateRange[0]!, values.dateRange[1]!, "Vessel");
      const xQueryData = queryData.find((x) => x.signalId === xAxisSignalId);
      const yQueryData = queryData.find((x) => x.signalId === yAxisSignalId);
      if (xQueryData && yQueryData) {
        createChart(curveData, xQueryData, yQueryData, xAxisSignalId, yAxisSignalId);
      }
    }
  };

  const createChart = (
    curveData: CurveResponse[],
    xQueryData: ChartQueryData,
    yQueryData: ChartQueryData,
    xAxisSignalId: number,
    yAxisSignalId: number
  ) => {
    const chartDom = document.getElementById(`chart`);
    const xSignal = signals.find((x) => x.id === xAxisSignalId);
    const ySignal = signals.find((x) => x.id === yAxisSignalId);
    if (chartDom && xSignal && ySignal) {
      echarts.registerTheme("walden", waldenTheme);
      const scatterChart = echarts.init(chartDom, "walden");
      const data = prepareScatterData(xQueryData, yQueryData);
      const curveSeries = prepareCurveSeries(curveData);
      const dataSeries = prepareDataSeries(xSignal, ySignal, data);
      const series = curveSeries;
      series.push(dataSeries);
      const eChartsOption: EChartsOption = {
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "cross",
          },
          // formatter: "{a} - {c} "
          // valueFormatter: (value : number) => '$' + value.toFixed(2)
        },
        // Need to empty legend to show legends
        legend: {
          textStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            saveAsImage: { show: true },
          },
        },
        yAxis: {
          type: "value",
          name: ySignal.displayName,
          nameTextStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
          axisLine: {
            show: true,
            onZero: false,
          },
          axisLabel: {
            formatter: `{value}`,
            color: systemGlobal.colorTheme === "dark" ? "white" : "black",
          },
        },
        xAxis: {
          type: "value",
          name: xSignal.displayName,
          nameTextStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
          axisLine: {
            show: true,
          },
          axisLabel: {
            formatter: `{value}`,
            color: systemGlobal.colorTheme === "dark" ? "white" : "black",
          },
        },
        series: series,
      };
      scatterChart.setOption(eChartsOption);
      setChart(scatterChart);
    }
  };

  const prepareCurveSeries = (curveData: CurveResponse[]) => {
    return curveData.map((x) => {
      return {
        name: x.name,
        type: "line",
        symbolSize: 0,
        data: x.points.map((point) => ({
          name: x.name,
          value: [point.x, point.y],
          itemStyle: {
            color: "",
          },
        })),
      } as LineSeriesOption | ScatterSeriesOption;
    });
  };

  const prepareDataSeries = (xSignal: SignalMappingResponse, ySignal: SignalMappingResponse, data: DataItem[]) => {
    return {
      name: `${xSignal.displayName} x ${ySignal.displayName}`,
      symbolSize: 8,
      type: "scatter",
      data: data,
    } as ScatterSeriesOption;
  };

  const prepareScatterData = (xQueryData: ChartQueryData, yQueryData: ChartQueryData) => {
    const data: DataItem[] = [];
    for (let i = 0; i < xQueryData.signalData.length; i++) {
      data.push({
        name: xQueryData.signalData[i].value.toString(),
        value: [xQueryData.signalData[i].value, yQueryData.signalData[i].value],
      });
    }
    return data;
  };

  useEffect(() => {
    const loadVessels = async (user: Token) => {
      const accountClient = new AccountsClient(undefined, axiosInstance);
      const entities = await accountClient.vessels(user.userid);
      setVessels(
        entities.map((x) => ({
          value: x.id.toString(),
          label: x.name,
        }))
      );

      if (!isSystemModeVessel() && systemGlobal.dashboardVesselId) {
        form.setFieldValue("vesselId", String(systemGlobal.dashboardVesselId));
      } else {
        form.setFieldValue("vesselId", entities[0].id.toString());
      }
    };

    const populateIntervals = () => {
      setIntervals([
        {
          label: "5 Mins",
          value: "5",
        },
        {
          label: "10 Mins",
          value: "10",
        },
        {
          label: "15 Mins",
          value: "15",
        },
        {
          label: "30 Mins",
          value: "30",
        },
        {
          label: "Hourly",
          value: "60",
        },
        {
          label: "Daily",
          value: "1440",
        },
      ] as SelectItem[]);
    };

    const user = AuthService.getCurrentUser();
    if (user && firstLoad) {
      setFirstLoad(false);
      loadVessels(user);
      populateIntervals();
    }
    return () => {};
  }, [firstLoad, form, isSystemModeVessel, systemGlobal.dashboardVesselId]);

  useEffect(() => {
    const loadSignals = async () => {
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      if (isSystemModeVessel()) {
        const signals = await vesselClient.signalMappingsGET(Number(form.values.vesselId));
        setSignals(signals);
        setSignalSelectItems(
          signals.map((x) => ({
            value: x.id.toString(),
            label: x.displayName,
          }))
        );
      } else {
        const user = AuthService.getCurrentUser();
        const signals = await vesselClient.signalMappingsGET(Number(form.values.vesselId), null, user?.userid);
        setSignals(signals);
        setSignalSelectItems(
          signals.map((x) => ({
            value: x.id.toString(),
            label: x.displayName,
          }))
        );
      }
    };

    //todo: check curves too
    const loadCurves = async () => {
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      if (isSystemModeVessel()) {
        const curves = await vesselClient.curves(Number(form.values.vesselId));
        setCurves(
          curves.map((x) => ({
            value: x.id.toString(),
            label: x.name,
          }))
        );
      } else {
        const user = AuthService.getCurrentUser();
        const curves = await vesselClient.curves(Number(form.values.vesselId), user?.userid);
        setCurves(
          curves.map((x) => ({
            value: x.id.toString(),
            label: x.name,
          }))
        );
      }
    };

    if (form.values.vesselId) {
      form.setFieldValue("xAxisSignalId", "");
      form.setFieldValue("yAxisSignalId", "");
      form.setFieldValue("curveIds", []);
      loadSignals();
      loadCurves();
    }
    return () => {};
  }, [isSystemModeVessel, systemGlobal.dashboardVesselId, form.values.vesselId]);

  return (
    <>
      <Stack>
        <Title order={4}>{t("yxchartht")}</Title>
        <Divider size="sm" mb="sm"></Divider>
      </Stack>
      <Flex align="end" gap="md" mb="lg">
        <DateRangePicker label={t("yxchartht_daterange")} icon={<DateRangeIcon fontSize="small" />} miw={350} {...form.getInputProps("dateRange")} />
        <Select w={150} label={t("yxchartht_dateinterval")} data={intervals} {...form.getInputProps("interval")} />
        <Select label={t("yxchartht_vessel")} data={vessels} {...form.getInputProps("vesselId")} />
        <Select
          label={t("yxchartht_xaxis")}
          data={signalSelectItems}
          searchable
          nothingFound={t("yxchartht_nothingfound")}
          placeholder={t("yxchartht_selectsignal")}
          onChange={(x) => {
            console.log(x);
          }}
          {...form.getInputProps("xAxisSignalId")}
        />
        <Select
          label={t("yxchartht_yaxis")}
          data={signalSelectItems}
          searchable
          nothingFound={t("yxchartht_nothingfound")}
          placeholder={t("yxchartht_selectsignal")}
          {...form.getInputProps("yAxisSignalId")}
        />
        <MultiSelect label={t("yxchartht_curves")} data={curves} placeholder={t("yxchartht_pickcurves")} {...form.getInputProps("curveIds")} />
        <Button onClick={handleGenerate}>{t("yxchartht_generate")}</Button>
      </Flex>
      <div id="chart" style={{ width: "100%", height: "700px" }}></div>
    </>
  );
};
export default HistoryYX;
