import { ActionIcon, Button, Group, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { CommonEditReportSetting } from "../../../../../models/report-setting/CommonEditReportSetting";
import { RemoveCircle } from "@mui/icons-material";
import NotificationHelper from "../../../../../helpers/NotiHelper";
import { useTranslation } from "react-i18next";

type Props = {
  form: UseFormReturnType<CommonEditReportSetting>;
};

type MonitoringSource = {
  Name: string;
  ApproachUsed: string;
  Value: string;
};

const MonitoringMethodSectionInput = (props: Props) => {
  const { t } = useTranslation();

  const addMonitoringSource = () => {
    const entity = {
      Name: "",
      ApproachUsed: "",
      Value: "",
    } as MonitoringSource;
    props.form.insertListItem("MonitoringMethod", entity);
  };

  const removeMonitoringSource = (index: number) => {
    if (props.form.values.MonitoringMethod.length > 1) {
      props.form.removeListItem("MonitoringMethod", index);
    } else {
      NotificationHelper.showError("Error in removing", "Setting needs at least one monitoring method");
    }
  };

  return (
    <>
      {props.form.values.MonitoringMethod.map((obj, index) => {
        return (
          <Group grow align="end" key={`MonitoringMethod${index}`}>
            <Group grow>
              <TextInput label={t("vesselreportsetting_mmname")} {...props.form.getInputProps(`MonitoringMethod.${index}.Name`)} />
              <TextInput label={t("vesselreportsetting_approachused")} {...props.form.getInputProps(`MonitoringMethod.${index}.ApproachUsed`)} />
              <TextInput label={t("vesselreportsetting_value")} {...props.form.getInputProps(`MonitoringMethod.${index}.Value`)} />
              <ActionIcon color="red" onClick={() => removeMonitoringSource(index)}>
                <RemoveCircle />
              </ActionIcon>
            </Group>
          </Group>
        );
      })}
      <Group>
        <Button variant="outline" onClick={addMonitoringSource}>
          {t("vesselreportsetting_addmonitoring")}
        </Button>
      </Group>
    </>
  );
};

export default MonitoringMethodSectionInput;
