import { Group, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import Responsive from "react-grid-layout";
import { GroupMultiSignalMappingForm } from "../../../common/Models";
import GridHelper from "../../../helpers/GridHelper";
import { InstantValueOptions } from "../../../models/dashboard/DashboardModels";
import { MappingDefResponse, DashboardWidgetResponse, WidgetType } from "../../../services/WebApiService";
import CommonWidgetConfig from "../Common/CommonWidgetConfig";
import GroupSignalMapping from "../Common/GroupSignalMapping";
import { useTranslation } from "react-i18next";

type Props = {
  layouts: Responsive.Layout[];
  isAddWidget: boolean;
  handleCloseWidgetConfig: () => void;
  updateWidgets(widget: DashboardWidgetResponse): void;
  addWidget(widget: DashboardWidgetResponse): void;
  activeDashboardWidget?: DashboardWidgetResponse;
  classifications: MappingDefResponse[];
  mappings: MappingDefResponse[];
};

const InstantValueConfig = (props: Props) => {
  const [header, setHeader] = useState(props.activeDashboardWidget?.header ?? "");
  const [color, setColor] = useState(props.activeDashboardWidget?.color ?? "#FFFFFF");
  const [firstLoad, setFirstLoad] = useState(true);
  const { t } = useTranslation();

  const form = useForm<GroupMultiSignalMappingForm>({
    initialValues: {
      mappingGroups: [],
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const main = form.values;

    const options: InstantValueOptions = {
      mappingGroups: main.mappingGroups,
    };
    const optionsJson = JSON.stringify(options);
    if (props.isAddWidget) {
      const newId = GridHelper.getNewUniqueId(props.layouts);
      const newObj = {
        header: header,
        color: color,
        optionJson: optionsJson,
        id: newId,
        height: 8,
        width: 10,
        x: 0,
        y: 0,
        widgetType: WidgetType.InstantValue,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.addWidget(entity);
    } else {
      const obj = props.activeDashboardWidget;
      const newObj = {
        ...obj,
        header: header,
        color: color,
        optionJson: optionsJson,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.updateWidgets(entity);
    }
    props.handleCloseWidgetConfig();
  };

  useEffect(() => {
    const loadFormValues = () => {
      if (firstLoad && props.activeDashboardWidget) {
        const obj = props.activeDashboardWidget;
        const options: InstantValueOptions = JSON.parse(obj.optionJson);
        form.setValues({
          mappingGroups: options.mappingGroups,
        });
        setFirstLoad(false);
      }
    };
    loadFormValues();
    return () => {};
  }, [firstLoad, form, props.activeDashboardWidget]);

  return (
    <form className="custom" onSubmit={(e) => handleSubmit(e)}>
      <CommonWidgetConfig headerValue={header} setHeader={setHeader} color={color} setColor={setColor} />

      <GroupSignalMapping classifProperty={props.mappings} classifications={props.classifications} form={form} label="Group" />

      <Group style={{ paddingTop: "20px" }}>
        <Button type="submit">{t("dashboardwidget_instantconfig_submit")}</Button>
        <Button onClick={props.handleCloseWidgetConfig} variant="subtle">
          {t("dashboardwidget_instantconfig_cancel")}
        </Button>
      </Group>
    </form>
  );
};

export default InstantValueConfig;
