import { ActionIcon, Button, Grid, Group, Select } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { RemoveCircleOutline } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { SignalMappingResponse } from "../../services/WebApiService";
import { MapWidgetSignalForm } from "../../common/Models";
import { MapWidgetSignal } from "../../models/common/MapWidgetSignal";

type Props = {
  label: string;
  form: UseFormReturnType<MapWidgetSignalForm>;
  signal: SignalMappingResponse[];
  disabled: boolean;
};

const MapWidgetMappings = (props: Props) => {
  const indexArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const { t } = useTranslation();

  const addMappingItem = () => {
    const item: MapWidgetSignal = {
      signalid: 0,
      index: "1",
    };
    props.form.insertListItem("signals", item);
  };

  const removeMappingItem = (index: number) => {
    if (props.form.values.signals.length > 1) {
      props.form.removeListItem("signals", index);
    }
  };

  const loadMappings = props.form.values.signals.map((item, index) => {
    return (
      <Grid key={`Mapping${index}`} columns={14} align="flex-end" className="mapping-grid">
        <Grid.Col span={4}>
          <Select
            key={`Signals${index}`}
            data={props.signal.map((x) => ({ value: x.id, label: x.displayName }))}
            label={t("mapwidgetmappings_signals")}
            required
            searchable
            nothingFound={t("mapwidgetmappings_notfound")}
            disabled={props.disabled}
            {...props.form.getInputProps(`signals.${index}.signalid`)}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            key={`Index${index}`}
            data={indexArray.map((x) => ({ value: x.toString(), label: x.toString() }))}
            label={t("mapwidgetmappings_index")}
            required
            searchable
            nothingFound={t("mapwidgetmappings_notfound")}
            disabled={props.disabled}
            {...props.form.getInputProps(`signals.${index}.index`)}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <ActionIcon color="red" onClick={() => removeMappingItem(index)} disabled={props.disabled}>
            <RemoveCircleOutline></RemoveCircleOutline>
          </ActionIcon>
        </Grid.Col>
      </Grid>
    );
  });

  return (
    <div className="custom-input">
      <label style={{ fontWeight: "500" }}>{props.label}</label>
      {loadMappings}
      <Group className="custom-input" style={{ marginTop: "10px" }}>
        <Button disabled={props.disabled} variant="light" onClick={() => addMappingItem()}>
          {t("dashboardwidget_multisignal_addmapping")}
        </Button>
      </Group>
    </div>
  );
};

export default MapWidgetMappings;
