import { Button, Group, Modal, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import AuthService from "../../services/AuthService";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import { EmissionReductionClient, EmissionReductionRequest, EmissionReductionResponse } from "../../services/WebApiService";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { EmissionReduction } from "../../models/emission-reduction/EmissionReduction";
import EmissionFormInputs from "./components/EmissionFormInputs";

type ModalProps = {
  isOpen: boolean;
  id: number;
  emissionReduction?: EmissionReductionResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  hideModal: (type: string) => void;
  loadEmissionReduction?: () => void;
  modalProps: ModalType;
};

const CreateEmissionReductionForm = (props: Props) => {
  const { t } = useTranslation();

  const commonForm = useForm<EmissionReduction>({
    initialValues: {
      Year: 1900,
      Percentage: 0,
    },
  });

  const handleSubmit = async () => {
    if (AuthService.isInRole("Admin")) {
      try {
        const values = commonForm.values;
        const emissionReductionClient = new EmissionReductionClient(undefined, axiosInstance);
        const entity = {
          year: values.Year,
          percentage: values.Percentage,
        };

        const emissionReductionToCreate = new EmissionReductionRequest();
        emissionReductionToCreate.init(entity);
        await emissionReductionClient.post(emissionReductionToCreate);

        NotificationHelper.showSuccess("Success", "Emission Reduction is created successfully!");

        props.hideModal("createEmissionReduction");
        if (props.loadEmissionReduction) props.loadEmissionReduction();
      } catch {
        NotificationHelper.showError("Error", "An error occurred in creating emission reduction, cannot have duplicate year.");
      }
    }
  };

  useEffect(() => {
    commonForm.setFieldValue("Year", 1900);
    commonForm.setFieldValue("Percentage", 0);
    return () => {};
  }, [props.modalProps["createEmissionReduction"].isOpen]);

  return (
    <>
      <Modal
        size="xl"
        opened={props.modalProps["createEmissionReduction"].isOpen}
        onClose={() => props.hideModal("createEmissionReduction")}
        title={t("createEmissionReduction")}
      >
        <Stack spacing="lg">
          <Group spacing="lg" align="center" grow>
            <form>
              <EmissionFormInputs emissionReductionId={0} form={commonForm} isEditMode={false} />
            </form>
          </Group>
          <Group position="right">
            <Button variant="outline" onClick={() => props.hideModal("createEmissionReduction")}>
              {t("createemissionreduction_cancel")}
            </Button>
            <Button disabled={!AuthService.isInRole("Admin") && !AuthService.isInRole("Supervisor")} type="submit" onClick={handleSubmit}>
              {t("createemissionreduction_create")}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default CreateEmissionReductionForm;
