import { SimpleGrid, Select, NumberInput, Group, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import Responsive from "react-grid-layout";
import { SingleSignalMappingForm } from "../../../common/Models";
import GridHelper from "../../../helpers/GridHelper";
import { ScatterChartOptions } from "../../../models/dashboard/DashboardModels";
import {
  Classification,
  MappingDefResponse,
  CurveResponse,
  DashboardWidgetResponse,
  FuelType,
  Property,
  WidgetType,
} from "../../../services/WebApiService";
import CommonWidgetConfig from "../Common/CommonWidgetConfig";
import SingleSignalMapping from "../Common/SingleSignalMapping";
import { useTranslation } from "react-i18next";

type Props = {
  isAddWidget: boolean;
  layouts: Responsive.Layout[];
  curves: CurveResponse[];
  handleCloseWidgetConfig: () => void;
  updateWidgets(widget: DashboardWidgetResponse): void;
  addWidget(widget: DashboardWidgetResponse): void;
  activeDashboardWidget?: DashboardWidgetResponse;
  classifications: MappingDefResponse[];
  mappings: MappingDefResponse[];
};

const ScatterChartConfig = (props: Props) => {
  const [header, setHeader] = useState(props.activeDashboardWidget?.header ?? "");
  const [color, setColor] = useState(props.activeDashboardWidget?.color ?? "#FFFFFF");
  const [firstLoad, setFirstLoad] = useState(true);
  const { t } = useTranslation();

  const form = useForm<ScatterChartOptions>({
    initialValues: {
      interval: "0",
      maxPoints: 30,
      xAxisMapping: { classification: Classification.NotApplicable, property: Property.NotApplicable, fuelType: FuelType.NotApplicable, index: "1" },
      yAxisMapping: { classification: Classification.NotApplicable, property: Property.NotApplicable, fuelType: FuelType.NotApplicable, index: "1" },
      curveId: "1",
    },
  });

  const xAxisMappingForm = useForm<SingleSignalMappingForm>({
    initialValues: {
      mapping: { classification: Classification.NotApplicable, property: Property.NotApplicable, fuelType: FuelType.NotApplicable, index: "1" },
    },
  });

  const yAxisMappingForm = useForm<SingleSignalMappingForm>({
    initialValues: {
      mapping: { classification: Classification.NotApplicable, property: Property.NotApplicable, fuelType: FuelType.NotApplicable, index: "1" },
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const main = form.values;
    const xAxisForm = xAxisMappingForm.values;
    const yAxisForm = yAxisMappingForm.values;
    if (main.maxPoints > 60) {
      main.maxPoints = 60;
    }
    const options: ScatterChartOptions = {
      curveId: main.curveId,
      interval: main.interval,
      maxPoints: main.maxPoints,
      xAxisMapping: xAxisForm.mapping,
      yAxisMapping: yAxisForm.mapping,
    };
    const optionsJson = JSON.stringify(options);
    if (props.isAddWidget) {
      const newId = GridHelper.getNewUniqueId(props.layouts);
      const newObj = {
        header: header,
        color: color,
        optionJson: optionsJson,
        id: newId,
        height: 8,
        width: 10,
        x: 0,
        y: 0,
        widgetType: WidgetType.ScatterChart,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.addWidget(entity);
    } else {
      const obj = props.activeDashboardWidget;
      const newObj = {
        ...obj,
        header: header,
        color: color,
        optionJson: optionsJson,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.updateWidgets(entity);
    }
    props.handleCloseWidgetConfig();
  };

  useEffect(() => {
    const loadFormValues = () => {
      if (firstLoad && props.activeDashboardWidget) {
        const obj = props.activeDashboardWidget;
        const options: ScatterChartOptions = JSON.parse(obj.optionJson);
        form.setValues({
          interval: options.interval.toString(),
          maxPoints: options.maxPoints,
          xAxisMapping: options.xAxisMapping,
          yAxisMapping: options.yAxisMapping,
          curveId: options.curveId,
        });
        xAxisMappingForm.setValues({
          mapping: options.xAxisMapping,
        });
        yAxisMappingForm.setValues({
          mapping: options.yAxisMapping,
        });
        setFirstLoad(false);
      }
    };
    loadFormValues();
    return () => {};
  }, [firstLoad, form, props.activeDashboardWidget, xAxisMappingForm, yAxisMappingForm]);

  return (
    <form className="custom" onSubmit={(e) => handleSubmit(e)}>
      <CommonWidgetConfig headerValue={header} setHeader={setHeader} color={color} setColor={setColor} />

      <SimpleGrid cols={2} className="custom-input">
        <Select
          data={[
            { value: "0", label: "Live" },
            { value: "5", label: "5 Mins" },
            { value: "15", label: "15 Mins" },
            { value: "30", label: "30 Mins" },
            { value: "60", label: "Hourly" },
            { value: "1440", label: "Daily" },
          ]}
          label={t("dashboardwidget_scatterconfig_interval")}
          required
          {...form.getInputProps("interval")}
        />

        <NumberInput required min={5} max={60} label={t("dashboardwidget_scatterconfig_maxdatapts")} {...form.getInputProps("maxPoints")} />
      </SimpleGrid>

      <Select
        clearable
        placeholder="None"
        description={t("dashboardwidget_scatterconfig_maxdataptsdesc")}
        className="custom-input"
        data={props.curves.map((x) => ({
          value: x.id.toString(),
          label: x.name,
        }))}
        label={t("dashboardwidget_scatterconfig_maxdatapts")}
        {...form.getInputProps("curveId")}
      />

      <SingleSignalMapping
        form={xAxisMappingForm}
        label={t("dashboardwidget_scatterconfig_xaxismap")}
        classifications={props.classifications}
        classifProperty={props.mappings}
      />

      <SingleSignalMapping
        form={yAxisMappingForm}
        label={t("dashboardwidget_scatterconfig_yaxismap")}
        classifications={props.classifications}
        classifProperty={props.mappings}
      />

      <Group style={{ paddingTop: "20px" }}>
        <Button type="submit">{t("dashboardwidget_scatterconfig_submit")}</Button>
        <Button onClick={props.handleCloseWidgetConfig} variant="subtle">
          {t("dashboardwidget_scatterconfig_cancel")}
        </Button>
      </Group>
    </form>
  );
};

export default ScatterChartConfig;
