import { useEffect } from 'react'
import NotificationHelper from "./helpers/NotiHelper";
import RoutesProvider from "./providers/RoutesProvider";
import AppMantineProvider from "./providers/AppMantineProvider";
import { GlobalContextProvider } from "./providers/GlobalContextProvider";
import { DashboardContextProvider } from "./providers/DashboardConfigProvider";

type Props = {}
const App = (props: Props) => {
  // Get system mode
  async function getSystemMode() {
    try {
      // const newGlobal = systemVar;
      // newGlobal.systemMode = await systemSettingsClient.systemMode();
      // setSystemVar(newGlobal);
    } catch {
      NotificationHelper.showError("Connection Error", "Unable to get system mode");
    }
  }

  useEffect(() => {
    getSystemMode();
  }, [])

  return (
    <GlobalContextProvider>
      <DashboardContextProvider>
        <AppMantineProvider>
          <RoutesProvider />
        </AppMantineProvider>
      </DashboardContextProvider>
    </GlobalContextProvider>
  )
}

export default App
