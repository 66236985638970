import { Button, Group, Modal, NumberInput, Select, SelectItem, Stack, Switch, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect } from "react";
import { TripClient, TripPatchRequest, TripResponse } from "../../../services/WebApiService";
import { axiosInstance } from "../../../services/AxiosService";
import NotificationHelper from "../../../helpers/NotiHelper";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  setIsOpen(value: boolean): void;
  trip?: TripResponse | null;
  setTrip(value: TripPatchRequest): void;
  existingVoyages: Array<SelectItem>;
  setReloadSearch(value: boolean): void;
};

type TripForm = {
  id: string;
  name: string;
  description: string;
  cargo: number;
  portName: string;
  isEuPort: boolean;
  voyageId: string;
};

const EditTripModal = (props: Props) => {
  const { t } = useTranslation();

  const form = useForm<TripForm>({
    initialValues: {
      id: "",
      name: "",
      cargo: 0,
      description: "",
      isEuPort: false,
      portName: "",
      voyageId: "",
    },
  });

  useEffect(() => {
    if (props.trip && props.trip.id !== form.values.id) {
      const trip = props.trip;
      form.setFieldValue("id", props.trip.id);
      form.setFieldValue("name", trip.name);
      form.setFieldValue("description", trip.description);
      form.setFieldValue("isEuPort", trip.isEuPort);
      form.setFieldValue("cargo", trip.cargo);
      form.setFieldValue("portName", trip.portName);
      let voyageId: string = "";
      if (trip.voyageId) {
        voyageId = trip.voyageId;
      }
      form.setFieldValue("voyageId", voyageId);
    }

    return () => {};
  }, [form, props.trip]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const tripClient = new TripClient(undefined, axiosInstance);
    const trip = props.trip;
    const values = form.values;
    if (trip) {
      let voyageId: string | null = null;
      if (values.voyageId) {
        voyageId = values.voyageId;
      }
      const request = {
        id: values.id,
        name: values.name,
        description: values.description,
        cargo: values.cargo,
        isEuPort: values.isEuPort,
        portName: values.portName,
        voyageId: voyageId,
      } as TripPatchRequest;

      try {
        const result = await tripClient.patch(request);
        if (result) {
          NotificationHelper.showSuccess("Success", "Trip Saved Successfully");
        } else {
          NotificationHelper.showError("Error", "An error occurred in saving trip");
        }
      } catch {
        NotificationHelper.showError("Error", "An error occurred in saving trip");
      }
      props.setReloadSearch(true);
      props.setIsOpen(false);
      props.setTrip(request);
    }
  };

  return (
    <Modal size="lg" opened={props.isOpen} onClose={() => props.setIsOpen(false)} title={t("edittrip")}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack spacing={"lg"}>
          <TextInput required label={t("edittrip_name")} {...form.getInputProps("name")} />
          <Textarea label={t("edittrip_desc")} minRows={3} maxRows={5} {...form.getInputProps("description")} />
          <Group grow>
            <NumberInput required label={t("edittrip_cargo")} min={1} {...form.getInputProps("cargo")} />
            <Select
              clearable
              label={t("edittrip_voyage")}
              placeholder="Assign a voyage"
              data={props.existingVoyages}
              {...form.getInputProps("voyageId")}
            />
          </Group>
          <TextInput label={t("edittrip_port")} {...form.getInputProps("portName")} />
          <Switch label={t("edittrip_iseuport")} {...form.getInputProps("isEuPort", { type: "checkbox" })} />
          <Group>
            <Button type="submit">{t("edittrip_save")}</Button>
            <Button onClick={() => props.setIsOpen(false)} variant="subtle">
              {t("edittrip_cancel")}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default EditTripModal;
