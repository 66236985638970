import { ThemeProvider, createTheme } from "@mui/material";
import { DataTransferApiKeyClient, DataTransferApiKeyResponse } from "../services/WebApiService";
import { ActionIcon, Button, Container, Divider, Group, Title, Transition } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { axiosInstance } from "../services/AxiosService";
import AuthService from "../services/AuthService";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useNavigate } from "react-router-dom";
import CreateDataTransferForm from "./components/CreateForm";
import DeleteDataTransferForm from "./components/DeleteForm";

type ModalProps = {
  isOpen: boolean;
  id: number;
  dataTransferApiKey?: DataTransferApiKeyResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

const IndexDataTransferManagement = () => {
  const navigate = useNavigate();

  const { isSystemModeVessel, systemGlobal } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });

  const [mounted, setMounted] = useState(false);
  const [modalProps, setmodalProps] = useState<ModalType>({
    createDataTransfer: {
      isOpen: false,
      id: 0,
    },
    deleteDataTransfer: {
      isOpen: false,
      id: 0,
    },
  });
  const [dataTransferApiKeys, setDataTransferApiKeys] = useState<DataTransferApiKeyResponse[]>([]);

  const { t } = useTranslation();

  const hideModal = (type: string) => {
    setmodalProps((prevModalProps) => ({
      ...prevModalProps,
      [type]: {
        ...prevModalProps[type],
        isOpen: false,
      },
    }));
  };

  const showModal = (id: number, type: string, dataTransferApiKey?: DataTransferApiKeyResponse) => {
    setmodalProps((prevModalProps) => ({
      ...prevModalProps,
      [type]: {
        ...prevModalProps[type],
        isOpen: true,
        id: id,
        dataTransferApiKey,
      },
    }));
  };

  const loadDataGrid = () => {
    return (
      <>
        <ThemeProvider theme={theme}>
          <DataGrid
            autoHeight
            rows={dataTransferApiKeys}
            columnVisibilityModel={AuthService.isInRole("Admin") ? {} : { edit: false, delete: false }}
            columns={[
              {
                field: "appUserId",
                headerName: t("managedatatransferapikey_userid"),
                flex: 1,
              },
              {
                field: "applicationUser.email",
                headerName: t("managedatatransferapikey_email"),
                flex: 1,
                renderCell: (cell) => <div>{cell.row.applicationUser.email === "" ? "(no email)" : cell.row.applicationUser.email}</div>,
              },
              {
                field: "apiKey",
                headerName: t("managedatatransferapikey_apikey"),
                flex: 1,
              },
              {
                field: "delete",
                headerName: t("managedatatransferapikey_delete"),
                renderCell: (cell) => (
                  <ActionIcon onClick={() => showModal(cell.row.id, "deleteDataTransfer")} variant="subtle" color="red">
                    <Delete />
                  </ActionIcon>
                ),
              },
            ]}
          />
        </ThemeProvider>
      </>
    );
  };

  useEffect(() => {
    if (isSystemModeVessel()) {
      navigate("/error/only-for-onshore");
    }

    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  const loadDataTransfer = async () => {
    const dataTransferApikeyClient = new DataTransferApiKeyClient(undefined, axiosInstance);

    const data = await dataTransferApikeyClient.getAll();

    if (AuthService.isInRole("Admin")) {
      /**
       * Root/Admin - see everything
       */
      setDataTransferApiKeys(data);
    }
  };

  const navigateToFullLog = () => {
    navigate("/datatransfer/fulllog");
  };

  useEffect(() => {
    loadDataTransfer();
    return () => {};
  }, []);

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <Container size="xl" style={styles}>
            <Group>
              <Title order={2}>{t("managedatatransferapikey_datatransferapikey")}</Title>
              {AuthService.isInRole("Admin") && (
                <div>
                  <Button onClick={() => showModal(0, "createDataTransfer")}>{t("managedatatransferapikey_createapikey")}</Button>
                  <Button ml={5} onClick={() => navigateToFullLog()}>
                    {t("managedatatransferapikey_seefulllog")}
                  </Button>
                </div>
              )}
            </Group>
            <Divider my="sm" size="md"></Divider>
            {loadDataGrid()}
          </Container>
        )}
      </Transition>

      <CreateDataTransferForm
        modalProps={modalProps}
        loadDataTransfer={loadDataTransfer}
        hideModal={() => hideModal("createDataTransfer")}
        dataTransferApiKeys={dataTransferApiKeys}
      />

      <DeleteDataTransferForm
        modalProps={modalProps}
        hideModal={() => hideModal("deleteDataTransfer")}
        dataTransferApiKeys={dataTransferApiKeys}
        setDataTransferApiKeys={setDataTransferApiKeys}
      />
    </>
  );
};

export default IndexDataTransferManagement;
