import { Button, Group, NumberInput, Select, SimpleGrid, Switch } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import Responsive from "react-grid-layout";
import { MultiSignalMappingForm } from "../../../common/Models";
import GridHelper from "../../../helpers/GridHelper";
import { RadialGaugeOptions } from "../../../models/dashboard/DashboardModels";
import { MappingDefResponse, DashboardWidgetResponse, WidgetType } from "../../../services/WebApiService";
import CommonWidgetConfig from "../Common/CommonWidgetConfig";
import MultiSignalMapping from "../Common/MultiSignalMapping";
import { useTranslation } from "react-i18next";

type Props = {
  isAddWidget: boolean;
  layouts: Responsive.Layout[];
  handleCloseWidgetConfig(): void;
  addWidget(widget: DashboardWidgetResponse): void;
  updateWidgets(widget: DashboardWidgetResponse): void;
  activeDashboardWidget?: DashboardWidgetResponse | undefined;
  classifications: MappingDefResponse[];
  mappings: MappingDefResponse[];
};

const RadialGaugeConfig = (props: Props) => {
  const [header, setHeader] = useState(props.activeDashboardWidget?.header ?? "");
  const [color, setColor] = useState(props.activeDashboardWidget?.color ?? "#FFFFFF");
  const [firstLoad, setFirstLoad] = useState(true);
  const { t } = useTranslation();

  const form = useForm<RadialGaugeOptions>({
    initialValues: {
      width: 270,
      height: 200,
      titleFontSize: 14,
      valueFontSize: 11,
      steps: 10,
      layout: "row",
      manualOverride: false,
      warnStart: 600,
      alarmStart: 800,
      max: 1000,
      showVesselName: "false",
      mappings: [],
    },
  });

  const mappingForm = useForm<MultiSignalMappingForm>({
    initialValues: {
      mappings: [],
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const main = form.values;
    const signalMappingForm = mappingForm.values;
    const options: RadialGaugeOptions = {
      width: main.width,
      height: main.height,
      titleFontSize: main.titleFontSize,
      valueFontSize: main.valueFontSize,
      manualOverride: main.manualOverride,
      warnStart: main.warnStart,
      alarmStart: main.alarmStart,
      max: main.max,
      layout: main.layout,
      steps: main.steps,
      showVesselName: main.showVesselName,
      mappings: signalMappingForm.mappings,
    };
    const optionsJson = JSON.stringify(options);
    if (props.isAddWidget) {
      const newId = GridHelper.getNewUniqueId(props.layouts);
      const newObj = {
        header: header,
        color: color,
        optionJson: optionsJson,
        id: newId,
        height: 8,
        width: 10,
        x: 0,
        y: 0,
        widgetType: WidgetType.RadialGauge,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.addWidget(entity);
    } else {
      const obj = props.activeDashboardWidget;
      const newObj = {
        ...obj,
        header: header,
        color: color,
        optionJson: optionsJson,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.updateWidgets(entity);
    }
    props.handleCloseWidgetConfig();
  };

  useEffect(() => {
    const loadFormValues = () => {
      if (firstLoad && !props.isAddWidget && props.activeDashboardWidget) {
        const obj = props.activeDashboardWidget;
        const options: RadialGaugeOptions = JSON.parse(obj.optionJson);
        form.setValues({
          width: options.width,
          height: options.height,
          titleFontSize: options.titleFontSize,
          valueFontSize: options.valueFontSize,
          layout: options.layout,
          manualOverride: options.manualOverride,
          warnStart: options.warnStart,
          alarmStart: options.alarmStart,
          max: options.max,
          steps: options.steps,
          showVesselName: options.showVesselName,
          mappings: options.mappings,
        });
        mappingForm.setValues({
          mappings: options.mappings,
        });
        setFirstLoad(false);
      }
    };
    loadFormValues();
    return () => {};
  }, [firstLoad, form, mappingForm, props.activeDashboardWidget, props.isAddWidget]);

  return (
    <>
      <form className="custom" onSubmit={(e) => handleSubmit(e)}>
        <CommonWidgetConfig headerValue={header} setHeader={setHeader} color={color} setColor={setColor} />

        <SimpleGrid cols={3} className="custom-input">
          <NumberInput required label={t("dashboardwidget_radialconfig_width")} {...form.getInputProps("width")} />

          <NumberInput required label={t("dashboardwidget_radialconfig_height")} {...form.getInputProps("height")} />
          <Select
            data={[
              { value: "row", label: "Row" },
              { value: "column", label: "Column" },
            ]}
            label={t("dashboardwidget_radialconfig_layout")}
            required
            {...form.getInputProps("layout")}
          />
        </SimpleGrid>
        <SimpleGrid cols={3}>
          <NumberInput required label={t("dashboardwidget_radialconfig_titlefont")} {...form.getInputProps("titleFontSize")} />

          <NumberInput required label={t("dashboardwidget_radialconfig_valuefont")} {...form.getInputProps("valueFontSize")} />

          <NumberInput required label={t("dashboardwidget_radialconfig_step")} {...form.getInputProps("steps")} />
        </SimpleGrid>
        <MultiSignalMapping
          label={t("dashboardwidget_radialconfig_mappings")}
          form={mappingForm}
          classifications={props.classifications}
          classifProperty={props.mappings}
          disabled={false}
        />

        <Switch
          className="custom-input"
          label={t("dashboardwidget_radialconfig_manualoverride")}
          {...form.getInputProps("manualOverride", { type: "checkbox" })}
        />

        <SimpleGrid cols={3}>
          <NumberInput
            required
            label={t("dashboardwidget_radialconfig_warnstart")}
            {...form.getInputProps("warnStart")}
            disabled={!form.values.manualOverride}
          />

          <NumberInput
            required
            label={t("dashboardwidget_radialconfig_alarmstart")}
            {...form.getInputProps("alarmStart")}
            disabled={!form.values.manualOverride}
          />

          <NumberInput required label={t("dashboardwidget_radialconfig_max")} {...form.getInputProps("max")} disabled={!form.values.manualOverride} />
        </SimpleGrid>

        <Group style={{ paddingTop: "20px" }}>
          <Button type="submit">{t("dashboardwidget_radialconfig_submit")}</Button>
          <Button onClick={props.handleCloseWidgetConfig} variant="subtle">
            {t("dashboardwidget_radialconfig_cancel")}
          </Button>
        </Group>
      </form>
    </>
  );
};

export default RadialGaugeConfig;
