import { ActionIcon, Container, Divider, Group, Stack, Title, Transition } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ArrowBack } from "@mui/icons-material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationHelper from "../helpers/NotiHelper";
import { CommonAutomaticReportSetting } from "../models/automatic-report/CommonAutomaticReportModel";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { axiosInstance } from "../services/AxiosService";
import {
  AutomaticReportsClient,
  AutomaticReportPostRequest,
  Classification,
  FuelType,
  Property,
  ReportLevel,
  ReportType,
  TriggerType,
} from "../services/WebApiService";
import CommonAutomaticReportInput from "./components/CommonAutomaticReportInput";
import DailyAutomaticReportInput from "./components/DailyAutomaticReportInput";
import EventAutomaticReportInput from "./components/EventAutomaticReportInput";
import MonthlyAutomaticReportInput from "./components/MonthlyAutomaticReportInput";
import WeeklyAutomaticReportInput from "./components/WeeklyAutomaticReportInput";
import { useTranslation } from "react-i18next";

type Props = {};
const CreateAutomaticReportSetting = (props: Props) => {
  const [mounted, setMounted] = useState(false);
  const { systemGlobal } = useContext(GlobalContext);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const commonForm = useForm<CommonAutomaticReportSetting>({
    initialValues: {
      name: "",
      dataInterval: "10",
      mappingJson: [
        {
          classification: Classification.NotApplicable,
          property: Property.NotApplicable,
          fuelType: FuelType.NotApplicable,
          index: "1",
        },
      ],
      reportLevel: ReportLevel.User,
      triggerType: TriggerType.Daily,
      reportType: ReportType.Vessel,
      appUserId: undefined,
      companyId: undefined,
      fleetId: undefined,
      vesselId: undefined,
    },
  });

  const handleBack = () => {
    navigate(`/automatic-report`);
  };

  const handleSubmit = useCallback(
    async (settingJson: string) => {
      try {
        const values = commonForm.values;
        const reportClient = new AutomaticReportsClient(undefined, axiosInstance);
        const entity = {
          name: values.name,
          reportLevel: values.reportLevel,
          reportType: values.reportType,
          triggerType: values.triggerType,
          mappingJson: JSON.stringify(values.mappingJson),
          fleetId: values.fleetId,
          companyId: values.companyId,
          appUserId: values.appUserId,
          dataInterval: values.dataInterval,
          vesselId: values.vesselId,
          settingJson: settingJson,
        };
        const reportToCreate = new AutomaticReportPostRequest();
        reportToCreate.init(entity);
        await reportClient.post(reportToCreate);
        NotificationHelper.showSuccess("Success", "Report is created successfully");
        navigate(`/automatic-report`);
      } catch {
        NotificationHelper.showError("Error", "An error occurred in creating automatic report");
      }
    },
    [commonForm.values, navigate]
  );

  const loadTriggerSpecificInput = useCallback(() => {
    const triggerType = commonForm.values.triggerType;
    if (triggerType === TriggerType.Daily) {
      return <DailyAutomaticReportInput handleSubmit={handleSubmit} />;
    } else if (triggerType === TriggerType.Weekly) {
      return <WeeklyAutomaticReportInput handleSubmit={handleSubmit} />;
    } else if (triggerType === TriggerType.Monthly) {
      return <MonthlyAutomaticReportInput handleSubmit={handleSubmit} />;
    } else if (triggerType === TriggerType.Event) {
      return (
        <EventAutomaticReportInput
          classifications={systemGlobal.classifications}
          mappingDefs={systemGlobal.mappingDefs}
          handleSubmit={handleSubmit}
        />
      );
    }
  }, [commonForm.values.triggerType, handleSubmit, systemGlobal.classifications, systemGlobal.mappingDefs]);

  useEffect(() => {
    setMounted(true);

    return () => {};
  }, []);

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <div style={styles}>
          <Group>
            <ActionIcon variant="subtle" color="blue" onClick={handleBack}>
              <ArrowBack />
            </ActionIcon>
            <Title order={2}>{t("createautoreport")}</Title>
          </Group>
          <Divider my="sm" size="md"></Divider>
          <Container size="xl">
            <Stack>
              <CommonAutomaticReportInput classifications={systemGlobal.classifications} mappingDefs={systemGlobal.mappingDefs} form={commonForm} />
              {loadTriggerSpecificInput()}
            </Stack>
          </Container>
        </div>
      )}
    </Transition>
  );
};
export default CreateAutomaticReportSetting;
