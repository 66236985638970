import { Container, Divider, Text, Title } from "@mantine/core";

const OnlyForOnShore = () => {
  return (
    <Container size="lg">
      <Title order={3}>OnShore Only</Title>
      <Divider my="sm" size="md"></Divider>
      <Text>This features is only be access at OnShore platform</Text>
    </Container>
  );
};
export default OnlyForOnShore;
