import axios from "axios";
import NotificationHelper from "../helpers/NotiHelper";
import AuthService from "../services/AuthService";
import { AuthClient, AuthRefreshRequest, AuthResponse } from "../services/WebApiService";
import { getBaseUrl } from "./BaseUrlService";
import { useEffect } from "react";

// Global promise for this axios instance that checks for refresh token
let refreshTokenPromise: Promise<AuthResponse | null> | null = null;

const baseUrl = getBaseUrl();
export const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  // headers: {
  //   "Content-Type": "application/json",
  // }
});

const refreshTokenCookie = async () => {
  try {
    const customAxios = axios.create({
      withCredentials: true,
    });
    const authClient = new AuthClient(baseUrl, customAxios);
    const token = await authClient.refreshToken({ refreshToken: "" } as AuthRefreshRequest);
    return token;
  } catch {
    NotificationHelper.showError("Login expired", "Please login again");
    if (!window.location.href.includes("/account/login")) {
      window.location.href = "/account/login";
    }
  }
  return null;
};

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/account/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig?._retry) {
        try {
          let token: AuthResponse | null = null;
          if (!refreshTokenPromise) {
            refreshTokenPromise = refreshTokenCookie();
            token = await refreshTokenPromise;
            refreshTokenPromise = null;
          } else {
            token = await refreshTokenPromise;
          }
          AuthService.setCurrentUser(token?.accessToken ?? "");
          return axios({
            ...originalConfig,
            _retry: true,
          });
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (err.response.status === 403) {
        NotificationHelper.showError("Login expired", "Please login again");
        //window.location.href = "/account/access-denied";
        // navigate("/account/login");
        console.log(err);
        if (!window.location.href.includes("/account/login")) {
          //window.location.href = "/account/access-denied";
        }
      }
    }

    return Promise.reject(err);
  }
);
