import * as echarts from "echarts/core";
import waldenTheme from "../../../chart-theme/custom-walden.json";
import {
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  MarkAreaComponent,
  MarkAreaComponentOption,
} from "echarts/components";
import { BarChart, BarSeriesOption, LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  DashboardSignalResponse,
  DashboardVesselResponse,
  DashboardWidgetResponse,
  HubSignalResponse,
  SignalDataClient,
} from "../../../services/WebApiService";
import { ChartType, TimeChartOptions } from "../../../models/dashboard/DashboardModels";
import SignalHelper from "../../../helpers/SignalHelper";
import { YAXisOption } from "echarts/types/dist/shared";
import { axiosInstance } from "../../../services/AxiosService";
import NotificationHelper from "../../../helpers/NotiHelper";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../providers/GlobalContextProvider";

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  MarkAreaComponent,
]);

type EChartsOption = echarts.ComposeOption<
  | ToolboxComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | BarSeriesOption
  | LineSeriesOption
  | MarkAreaComponentOption
>;

type CartesianAxisPosition = "top" | "bottom" | "left" | "right";

type ChartState = {
  signalId: number;
  name: string;
  data: DataItem[];
};

type ChartSeries = {
  data: DataItem[];
  markArea: any;
};

type DataItem = {
  name: string;
  isWarn: boolean;
  isAlarm: boolean;
  unit: string;
  value: [string, number];
};

type UnitAxisItem = {
  name: string;
  min?: number | undefined;
  max?: number | undefined;
};

type Props = {
  id: string;
  optionJson: string;
  dashboardVessels: DashboardVesselResponse[];
  dashboardWidget: DashboardWidgetResponse;
  hubSignals: HubSignalResponse[];
  selectedInterval: string;
  isEdit: boolean;
};

const TimeChartHelper = (props: Props) => {
  const [chart, setChart] = useState<echarts.ECharts>();
  const [chartStates, setChartStates] = useState<ChartState[]>([]);
  const { systemGlobal } = useContext(GlobalContext);

  const colorThemeRef = useRef(systemGlobal.colorTheme);
  useEffect(() => {
    colorThemeRef.current = systemGlobal.colorTheme;
  }, [systemGlobal.colorTheme]);

  const getUniqueUnitArray = (signals: DashboardSignalResponse[]): UnitAxisItem[] => {
    const units: UnitAxisItem[] = [];
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      const existingUnitIndex = units.findIndex((unit) => unit.name === signal.unitDescription);

      if (existingUnitIndex === -1) {
        units.push({
          name: signal.unitDescription,
          min: signal.viewScaleMin ?? undefined,
          max: signal.viewScaleMax ?? undefined,
        });
      } else {
        const existingUnit = units[existingUnitIndex];
        if (signal.viewScaleMin !== undefined && signal.viewScaleMin !== null) {
          if (existingUnit.min === undefined || signal.viewScaleMin < existingUnit.min) {
            existingUnit.min = signal.viewScaleMin;
          }
        }
        if (signal.viewScaleMax !== undefined && signal.viewScaleMax !== null) {
          if (existingUnit.max === undefined || signal.viewScaleMax > existingUnit.max) {
            existingUnit.max = signal.viewScaleMax;
          }
        }
      }
    }
    return units;
  };

  const calculateIntervalSteps = useCallback((max?: number) => {
    if (max) {
      const roundUpMax = roundUp(max, 1000);
      return roundUpMax / 5;
    }
    return undefined;
  }, []);

  const createYAxisArray = useCallback(
    (units: UnitAxisItem[]) => {
      const result = Array<YAXisOption>();
      let position: CartesianAxisPosition = "left";
      let offset = 0;
      let oddOffset = 0;
      let evenOffset = 0;
      const axisBarOffset = 58;
      for (let i = 0; i < units.length; i++) {
        const unit = units[i];
        if (i % 2 === 0) {
          position = "left";
          if (i !== 0) {
            evenOffset += axisBarOffset;
            offset = evenOffset;
          }
        } else {
          position = "right";
          if (i !== 1) {
            oddOffset += axisBarOffset;
            offset = oddOffset;
          }
        }
        const interval = calculateIntervalSteps(unit.max);
        result.push({
          type: "value",
          nameLocation: "middle",
          nameGap: 42,
          name: unit.name,
          nameTextStyle: { color: colorThemeRef.current === "dark" ? "white" : "black" },
          offset: offset,
          position: position,
          interval: interval,
          alignTicks: true,
          axisLine: {
            show: true,
          },
          axisLabel: {
            formatter: `{value}`,
            color: colorThemeRef.current === "dark" ? "white" : "black",
          },
          max: unit.max,
          min: unit.min,
        });
      }
      return result;
    },
    [calculateIntervalSteps]
  );

  const roundUp = (numToRound: number, multiple: number) => {
    const remainder = numToRound % multiple;
    if (remainder === 0) {
      return numToRound;
    }
    return numToRound + multiple - remainder;
  };

  const createSeriesArray = useCallback((signals: DashboardSignalResponse[], units: UnitAxisItem[], chartType: ChartType) => {
    if (chartType === ChartType.Bar) {
      return createBarSeriesArray(signals, units);
    } else {
      return createLineSeriesArray(signals, units);
    }
  }, []);

  const createLineSeriesArray = (signals: DashboardSignalResponse[], units: UnitAxisItem[]) => {
    const result = Array<LineSeriesOption>();
    const resultChartState = Array<ChartState>();
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      for (let j = 0; j < units.length; j++) {
        const unit = units[j];
        if (unit.name === signal.unitDescription) {
          result.push({
            type: "line",
            name: `${signal.displayName} [${signal.unitDescription}]`,
            yAxisIndex: j,
            data: [
              // [ new Date(), 5]
            ],
          });
          resultChartState.push({
            data: [],
            signalId: signal.id,
            name: `${signal.displayName} [${signal.unitDescription}]`,
          } as ChartState);
        }
      }
      setChartStates(resultChartState);
    }
    return result;
  };

  const createBarSeriesArray = (signals: DashboardSignalResponse[], units: UnitAxisItem[]) => {
    const result = Array<BarSeriesOption>();
    const resultChartState = Array<ChartState>();
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      for (let j = 0; j < units.length; j++) {
        const unit = units[j];
        if (unit.name === signal.unitDescription) {
          result.push({
            type: "bar",
            name: `${signal.displayName} [${signal.unitDescription}]`,
            yAxisIndex: j,
            data: [
              // [ new Date(), 5]
            ],
          });
          resultChartState.push({
            data: [],
            signalId: signal.id,
            name: `${signal.displayName} [${signal.unitDescription}]`,
          } as ChartState);
        }
      }
      setChartStates(resultChartState);
    }
    return result;
  };

  const getMarkAreaData = useCallback(
    (data: DataItem[]) => {
      const options = JSON.parse(props.optionJson) as TimeChartOptions;
      const showAlarm = options.showAlarm === "true" || options.showAlarm === undefined; // in case db previous data dont have showAlarm
      let color = "rgba(255, 173, 177, 0.25)";
      const markAreaData = [];
      let startAxisValue: string | null = null;
      if (showAlarm) {
        // proceed with logic only if show alarm
        for (let i = 0; i < data.length; i++) {
          const obj = data[i];
          if (obj.isAlarm || obj.isWarn) {
            startAxisValue ??= obj.value[0];
          } else {
            if (startAxisValue) {
              markAreaData.push([
                {
                  xAxis: startAxisValue,
                },
                {
                  xAxis: obj.value[0],
                },
              ]);
              startAxisValue = null;
            }
          }
          if (i === data.length - 1 && startAxisValue) {
            markAreaData.push([
              {
                xAxis: startAxisValue,
              },
              {
                xAxis: obj.value[0],
              },
            ]);
          }
        }
      }
      const markArea = {
        itemStyle: {
          color: color,
        },
        data: markAreaData,
      };
      return markArea;
    },
    [props.optionJson]
  );

  // Create Chart
  useEffect(() => {
    const options = JSON.parse(props.optionJson) as TimeChartOptions;
    const showAlarm = options.showAlarm === "true" || options.showAlarm === undefined; // in case db previous data dont have showAlarm

    const createChart = () => {
      const chartDom = document.getElementById(`widget-${props.id}`);
      if (chartDom && !chart) {
        echarts.registerTheme("walden", waldenTheme);
        const timeChart = echarts.init(chartDom, "walden");
        const options = JSON.parse(props.optionJson) as TimeChartOptions;
        const signals = SignalHelper.getSignalsByMappingsByVessels(props.dashboardVessels, options.mappings, options.showVesselName);
        const legends = signals.map((x) => {
          return `${x.displayName} [${x.unitDescription}]`;
        });
        const units = getUniqueUnitArray(signals);
        const yAxisArray = createYAxisArray(units);
        const seriesArray = createSeriesArray(signals, units, options.chartType);
        const eChartsOption: EChartsOption = {
          tooltip: {
            trigger: "axis",

            axisPointer: {
              type: "cross",
            },
            formatter: (params: any, ticket: string) => {
              let formatter = "";
              formatter = `<p style="font-size: 16px; margin-bottom: 8px">${params[0].axisValueLabel}<p>`;
              let labels = `<div style="display:flex; flex-direction:column; padding-right: 12px">`;
              let values = `<div style="display:flex; flex-direction:column; padding-right: 3px">`;
              let units = `<div style="display:flex; flex-direction:column">`;
              for (let i = 0; i < params.length; i++) {
                const obj = params[i];
                const isAlarm = obj.data.isAlarm;
                const isWarn = obj.data.isWarn;
                labels += `
                <div>
                  ${obj.marker} ${obj.seriesName}
                </div>
                `;
                if (isAlarm && showAlarm) {
                  values += `
                  <div style="display:flex; justify-content: flex-end">
                    <b><p style="margin-right: 5px; color: red">${obj.value[1]}</p></b> 
                  </div>
                  `;
                } else if (isWarn && showAlarm) {
                  values += `
                  <div style="display:flex; justify-content: flex-end; background-color: gray">
                    <b><p style="margin-right: 5px; color: yellow">${obj.value[1]}</p></b> 
                  </div>
                  `;
                } else {
                  values += `
                  <div style="display:flex; justify-content: flex-end">
                    <b><p style="margin-right: 5px">${obj.value[1]}</p></b> 
                  </div>
                  `;
                }

                units += `
                <div style="display:flex">
                  <p>${obj.data.unit}</p>
                </div>
                `;
              }
              values += `</div>`;
              labels += `</div>`;
              units += `</div>`;
              formatter += `
              <div style="display:flex">
                ${labels}
                ${values}
                ${units}
              </div>
              `;
              return formatter;
            },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              // restore: { show: true },
              saveAsImage: { show: true },
            },
          },
          legend: {
            textStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
            data: legends,
          },
          xAxis: [
            {
              type: "time",
              nameTextStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
              axisLabel: {
                color: systemGlobal.colorTheme === "dark" ? "white" : "black",
              },
            },
          ],
          yAxis: yAxisArray,
          series: seriesArray,
        };

        if (yAxisArray.length !== 0 || seriesArray.length !== 0) {
          timeChart.setOption(eChartsOption);
        } else {
          timeChart.setOption({});
        }
        setChart(timeChart);
      }
    };

    createChart();

    return () => {
      if (chart) {
        chart.dispose();
        setChart(undefined);
      }
    };
  }, [chart, createSeriesArray, createYAxisArray, props.dashboardVessels, props.id, props.optionJson, systemGlobal.colorTheme]);

  // Live - update chart state
  useEffect(() => {
    const updateChartData = () => {
      const options = JSON.parse(props.optionJson) as TimeChartOptions;
      for (let i = 0; i < props.hubSignals.length; i++) {
        const signal = props.hubSignals[i];
        setChartStates((current) =>
          current.map((x) => {
            if (x.signalId === signal.id) {
              const date = new Date(signal.timeStamp.toString());
              if (x.data.length >= options.maxPoints) {
                x.data.shift();
              }
              // Name must be a javascript date object
              const name = date.toString();
              const value = [signal.timeStamp.toString(), signal.value];
              return {
                ...x,
                name: name,
                data: [
                  ...x.data,
                  {
                    name: name,
                    value: value,
                    isAlarm: signal.isAlarm,
                    isWarn: signal.isWarn,
                    unit: signal.unitDescription,
                  } as DataItem,
                ],
              };
            } else {
              return x;
            }
          })
        );
      }
    };
    // Only update on live
    if (props.selectedInterval === "0") {
      updateChartData();
    }
    return () => {};
  }, [props.hubSignals, props.optionJson, props.selectedInterval]);

  // Update chart on chart state Change
  useEffect(() => {
    let resultSeries: ChartSeries[] = [];
    for (let i = 0; i < chartStates.length; i++) {
      const chartSignal = chartStates[i];
      if (chartSignal.data.length > 0) {
        const markArea = getMarkAreaData(chartSignal.data);
        resultSeries.push({ data: chartSignal.data, markArea: markArea });
      }
    }
    if (chart) {
      chart.setOption({
        series: resultSeries,
      });
    }
    return () => {};
  }, [chart, chartStates, getMarkAreaData]);

  // Interval Change
  useEffect(() => {
    const updateChartQuery = async () => {
      const options = JSON.parse(props.optionJson) as TimeChartOptions;
      const signals = SignalHelper.getSignalsByMappingsByVessels(props.dashboardVessels, options.mappings, options.showVesselName);
      const signalDataClient = new SignalDataClient(undefined, axiosInstance);
      try {
        const response = await signalDataClient.get(
          signals.map((x) => x.id),
          parseInt(props.selectedInterval),
          options.maxPoints
        );
        let resultSeries: ChartSeries[] = [];
        for (let i = 0; i < chartStates.length; i++) {
          const chartState = chartStates[i];
          const signal = response.find((x) => x.signalId === chartState.signalId);
          if (signal) {
            // x.TimeStamp is serialized as Javascript Date object by axios automatically
            const data = signal.signalData.map(
              (x) =>
                ({
                  value: [x.timeStamp.toISOString(), x.value],
                  name: x.timeStamp.toString(),
                  isAlarm: x.isAlarm,
                  isWarn: x.isWarn,
                  unit: signal.unitDescription,
                } as DataItem)
            );
            const markArea = getMarkAreaData(data);
            resultSeries.push({ data: data, markArea: markArea });
          }
        }
        if (chart) {
          chart.setOption({
            series: resultSeries,
          });
        }
      } catch {
        NotificationHelper.showError("Error", "Not enough or missing some data points for this query");
      }
    };

    if (chart && props.selectedInterval !== "0" && !props.isEdit) {
      chart.showLoading();
      updateChartQuery();
      chart.hideLoading();
    }

    return () => {};
  }, [chart, chartStates, getMarkAreaData, props.dashboardVessels, props.isEdit, props.optionJson, props.selectedInterval]);

  useEffect(() => {
    if (chart) {
      chart.resize();
    }
    return () => {};
  }, [chart, props.dashboardWidget]);

  return <></>;
};

export default TimeChartHelper;
