import { UseFormReturnType } from "@mantine/form";
import { Button, Card, Group, Stack, Title, createStyles } from "@mantine/core";
import { AdminPanelSettings, ContactPhone, DirectionsBoat, EnergySavingsLeaf, Preview } from "@mui/icons-material";
import ShipSectionInput from "./inputs/ShipSectionInput";
import ContactPersonSectionInput from "./inputs/ContactPersonSectionInput";
import VerifierSectionInput from "./inputs/VerifierSectionInput";
import EmissionSourceSectionInput from "./inputs/EmissionSourceSectionInput";
import MonitoringMethodSectionInput from "./inputs/MonitoringMethodSectionInput";

import { ReportSettingKey, ReportSettingsPatchRequest, VesselsClient } from "../../../../services/WebApiService";
import { axiosInstance } from "../../../../services/AxiosService";
import { CommonEditReportSetting } from "../../../../models/report-setting/CommonEditReportSetting";
import NotificationHelper from "../../../../helpers/NotiHelper";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  form: UseFormReturnType<CommonEditReportSetting>;
};

const CommonEditReportSettingInput = (props: Props) => {
  const { vesselId } = useParams();

  const { t } = useTranslation();

  const useStyles = createStyles((theme) => ({
    iconHeader: {
      color: "white",
      backgroundColor: theme.colors[theme.primaryColor][7],
      padding: "5px",
      fontSize: "38px !important",
      borderRadius: theme.radius.sm,
    },
  }));

  const handleSubmit = async () => {
    if (!props.form.validate().hasErrors) {
      try {
        const commonValues = props.form.values;
        const vesselsClient = new VesselsClient(undefined, axiosInstance);

        const entity = {
          ShipOwnerName: commonValues.ShipOwnerName,
          ShipOwnerAddress: commonValues.ShipOwnerAddress,
          ShipOwnerCity: commonValues.ShipOwnerCity,
          ShipOwnerState: commonValues.ShipOwnerState,
          ShipOwnerPostCode: commonValues.ShipOwnerPostCode,
          ShipOwnerCountry: commonValues.ShipOwnerCountry,
          ContactPersonName: commonValues.ContactPersonName,
          ContactPersonAddress: commonValues.ContactPersonAddress,
          ContactPersonCity: commonValues.ContactPersonCity,
          ContactPersonState: commonValues.ContactPersonState,
          ContactPersonPostCode: commonValues.ContactPersonPostCode,
          ContactPersonCountry: commonValues.ContactPersonCountry,
          ContactPersonPhone: commonValues.ContactPersonPhone,
          ContactPersonEmail: commonValues.ContactPersonEmail,
          VerifierName: commonValues.VerifierName,
          VerifierAddressLineOne: commonValues.VerifierAddressLineOne,
          VerifierAddressLineTwo: commonValues.VerifierAddressLineTwo,
          VerifierCity: commonValues.VerifierCity,
          VerifierState: commonValues.VerifierState,
          VerifierPostCode: commonValues.VerifierPostCode,
          VerifierCountry: commonValues.VerifierCountry,
          VerifierAccreditationNumber: commonValues.VerifierAccreditationNumber,
          VerifierStatement: commonValues.VerifierStatement,
          EmissionSource: commonValues.EmissionSource,
          MonitoringMethod: commonValues.MonitoringMethod,
        };

        let result: ReportSettingsPatchRequest[] = [];
        for (const [key, value] of Object.entries(entity)) {
          if (Array.isArray(value)) {
            result.push({
              name: key as ReportSettingKey,
              value: JSON.stringify(value),
            } as ReportSettingsPatchRequest);
          } else {
            result.push({
              name: key as ReportSettingKey,
              value,
            } as ReportSettingsPatchRequest);
          }
        }

        await vesselsClient.reportSettings(Number(vesselId), result);

        NotificationHelper.showSuccess("Success", "Report Settings Saved successfully");
      } catch {
        NotificationHelper.showError("Error in saving", "An error occurred in updating the report setting");
      }
    }
  };

  const { classes } = useStyles();

  return (
    <Card shadow="sm" p="lg" radius="sm" withBorder>
      <Card.Section withBorder p="lg">
        <Title order={3} className="horizontal-align-icons">
          <DirectionsBoat
            className={classes.iconHeader}
            style={{
              marginRight: "5px",
            }}
          />
          {t("vesselreportsetting_shipownerdetails")}
        </Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <ShipSectionInput form={props.form} />
        </Stack>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Title order={3} className="horizontal-align-icons">
          <ContactPhone
            className={classes.iconHeader}
            style={{
              marginRight: "5px",
            }}
          />
          {t("vesselreportsetting_contactperson")}
        </Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <ContactPersonSectionInput form={props.form} />
        </Stack>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Title order={3} className="horizontal-align-icons">
          <AdminPanelSettings
            className={classes.iconHeader}
            style={{
              marginRight: "5px",
            }}
          />
          {t("vesselreportsetting_verifierdetails")}
        </Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <VerifierSectionInput form={props.form} />
        </Stack>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Title order={3} className="horizontal-align-icons">
          <EnergySavingsLeaf
            className={classes.iconHeader}
            style={{
              marginRight: "5px",
            }}
          />
          {t("vesselreportsetting_emissionsource")}
        </Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <EmissionSourceSectionInput form={props.form} />
        </Stack>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Title order={3} className="horizontal-align-icons">
          <Preview
            className={classes.iconHeader}
            style={{
              marginRight: "5px",
            }}
          />
          {t("vesselreportsetting_monitoringmethod")}
        </Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <MonitoringMethodSectionInput form={props.form} />
        </Stack>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Group>
          <Button onClick={handleSubmit}>{t("vesselreportsetting_save")}</Button>
        </Group>
      </Card.Section>
    </Card>
  );
};

export default CommonEditReportSettingInput;
