import { Link, RichTextEditor } from "@mantine/tiptap"
import TextAlign from "@tiptap/extension-text-align"
import Underline from "@tiptap/extension-underline"
import SubScript from '@tiptap/extension-subscript';
import { useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import Superscript from '@tiptap/extension-superscript';
import { useEffect } from 'react'
import { TextEditorOptions } from "../../../models/dashboard/DashboardModels"
import { DashboardVesselResponse, DashboardWidgetResponse } from "../../../services/WebApiService"
import Highlight from "@tiptap/extension-highlight";

type Props = {
  id: string
  optionJson: string
  dashboardVessels: DashboardVesselResponse[]
  dashboardWidget: DashboardWidgetResponse
}

const TextEditorHelper = (props: Props) => {
  const editor = useEditor({
    editable: false,
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
  })
  useEffect(() => {
    const options = JSON.parse(props.optionJson) as TextEditorOptions;
    if(editor){
      editor.commands.setContent(options.data);
    }
    return () => {
    }
  }, [editor, props.optionJson]);

  return (
    <RichTextEditor editor={editor}>
      <RichTextEditor.Content />
    </RichTextEditor>

  )
}

export default TextEditorHelper