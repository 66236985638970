import { Group, Button, Select, Switch } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import Responsive from "react-grid-layout";
import { MultiSignalMappingForm } from "../../../common/Models";
import GridHelper from "../../../helpers/GridHelper";
import { MapOptions } from "../../../models/dashboard/DashboardModels";
import { MappingDefResponse, DashboardWidgetResponse, WidgetType } from "../../../services/WebApiService";
import CommonWidgetConfig from "../Common/CommonWidgetConfig";
import MultiSignalMapping from "../Common/MultiSignalMapping";
import { useTranslation } from "react-i18next";

type Props = {
  isAddWidget: boolean;
  layouts: Responsive.Layout[];
  addWidget(widget: DashboardWidgetResponse): void;
  handleCloseWidgetConfig: () => void;
  updateWidgets(widget: DashboardWidgetResponse): void;
  activeDashboardWidget?: DashboardWidgetResponse;
  classifications: MappingDefResponse[];
  mappings: MappingDefResponse[];
};

const MapConfig = (props: Props) => {
  const [header, setHeader] = useState(props.activeDashboardWidget?.header ?? "");
  const [color, setColor] = useState(props.activeDashboardWidget?.color ?? "#FFFFFF");
  const [firstLoad, setFirstLoad] = useState(true);
  const { t } = useTranslation();

  const mappingForm = useForm<MultiSignalMappingForm>({
    initialValues: {
      mappings: [],
    },
  });

  const form = useForm<MapOptions>({
    initialValues: {
      mapType: "google",
      manualMapping: false,
      mappings: [],
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const main = form.values;
    const signalMappingForm = mappingForm.values;
    const options: MapOptions = {
      manualMapping: main.manualMapping,
      mapType: main.mapType,
      mappings: signalMappingForm.mappings,
    };
    const obj = props.activeDashboardWidget;
    const optionsJson = JSON.stringify(options);
    if (props.isAddWidget) {
      const newId = GridHelper.getNewUniqueId(props.layouts);
      const newObj = {
        header: header,
        color: color,
        optionJson: optionsJson,
        id: newId,
        height: 8,
        width: 10,
        x: 0,
        y: 0,
        widgetType: WidgetType.Map,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.addWidget(entity);
    } else {
      const newObj = {
        ...obj,
        header: header,
        color: color,
        optionJson: optionsJson,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.updateWidgets(entity);
    }
    props.handleCloseWidgetConfig();
  };

  useEffect(() => {
    const loadFormValues = () => {
      if (firstLoad && props.activeDashboardWidget) {
        const obj = props.activeDashboardWidget;
        const options: MapOptions = JSON.parse(obj.optionJson);
        form.setValues({
          manualMapping: options.manualMapping,
          mapType: options.mapType,
          mappings: [],
        });

        mappingForm.setValues({
          mappings: options.mappings,
        });
        setFirstLoad(false);
      }
    };
    loadFormValues();
    return () => {};
  }, [firstLoad, form, mappingForm, props.activeDashboardWidget]);

  return (
    <form className="custom" onSubmit={(e) => handleSubmit(e)}>
      <CommonWidgetConfig headerValue={header} setHeader={setHeader} color={color} setColor={setColor} />

      <Select
        className="custom-input"
        data={[
          { value: "google", label: "Google Maps" },
          { value: "openmap", label: "Open Maps" },
        ]}
        label={t("dashboardwidget_mapconfig_mapengine")}
        required
        {...form.getInputProps("mapType")}
      />

      <Switch
        className="custom-input"
        label={t("dashboardwidget_mapconfig_manualmappings")}
        {...form.getInputProps("manualMapping", { type: "checkbox" })}
      />

      <MultiSignalMapping
        label={t("dashboardwidget_mapconfig_mappings")}
        form={mappingForm}
        classifications={props.classifications}
        classifProperty={props.mappings}
        disabled={!form.values.manualMapping}
      />
      <Group style={{ paddingTop: "20px" }}>
        <Button type="submit">{t("dashboardwidget_mapconfig_submit")}</Button>
        <Button onClick={props.handleCloseWidgetConfig} variant="subtle">
          {t("dashboardwidget_mapconfig_cancel")}
        </Button>
      </Group>
    </form>
  );
};

export default MapConfig;
