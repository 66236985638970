import { ActionIcon, Button, Group, Select } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { RemoveCircleOutline } from "@mui/icons-material";
import NotificationHelper from "../../helpers/NotiHelper";
import { ManualReportLibraryForm } from "../../models/manual-report-library/ManualReportLibraryForm";
import {
  Classification,
  FuelType,
  ManualReportMappingDataType,
  ManualReportSignalMapping,
  MappingDefResponse,
  Property,
} from "../../services/WebApiService";
import { useTranslation } from "react-i18next";

type Props = {
  classifications: MappingDefResponse[];
  mappingDefs: MappingDefResponse[];
  form: UseFormReturnType<ManualReportLibraryForm>;
};

const ManualReportSignalMappingInput = (props: Props) => {
  const indexArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const reloadProperty = (index: number) => {
    const obj = props.form.values.signalMappings[index];
    const filtered = props.mappingDefs.filter((x) => x.classification === obj.classification);
    const entity = filtered.find((x) => x.property === obj.property);

    if (!entity) {
      props.form.setFieldValue(`signalMappings.${index}.property`, filtered[0].property);
    }
  };

  const { t } = useTranslation();

  const addMappingItem = () => {
    const item = {
      classification: Classification.NotApplicable,
      property: Property.NotApplicable,
      fuelType: FuelType.NotApplicable,
      index: "1",
      dataType: ManualReportMappingDataType.Instant,
    } as ManualReportSignalMapping;
    props.form.insertListItem("signalMappings", item);
  };

  const removeMappingItem = (index: number) => {
    if (props.form.values.signalMappings.length > 1) {
      props.form.removeListItem("signalMappings", index);
    } else {
      NotificationHelper.showError("Error", "Cannot remove the last mapping");
    }
  };

  const loadMappings = props.form.values.signalMappings.map((item, index) => {
    return (
      <Group align="end" grow key={`SignalMapping${index}`}>
        <Select
          withinPortal
          key={`Classification${index}`}
          label={t("manualreport_classification")}
          data={props.classifications.map((x) => ({ value: x.classification, label: x.classificationDisplayName }))}
          required
          searchable
          nothingFound={t("manualreport_nothingfound")}
          {...props.form.getInputProps(`signalMappings.${index}.classification`)}
        />
        <Select
          withinPortal
          label={t("manualreport_property")}
          data={props.mappingDefs
            .filter((x) => x.classification === item.classification)
            .map((x) => ({ value: x.property, label: x.propertyDisplayName }))}
          required
          searchable
          onChange={reloadProperty(index)}
          nothingFound={t("manualreport_nothingfound")}
          {...props.form.getInputProps(`signalMappings.${index}.property`)}
        />
        <Select
          withinPortal
          key={`FuelType${index}`}
          data={Object.keys(FuelType).map((x) => ({ value: x, label: x }))}
          label={t("manualreport_fueltype")}
          required
          searchable
          nothingFound={t("manualreport_nothingfound")}
          {...props.form.getInputProps(`signalMappings.${index}.fuelType`)}
        />
        <Select
          withinPortal
          key={`Index${index}`}
          data={indexArray.map((x) => ({ value: x.toString(), label: x.toString() }))}
          label={t("manualreport_index")}
          required
          searchable
          nothingFound={t("manualreport_nothingfound")}
          {...props.form.getInputProps(`signalMappings.${index}.index`)}
        />
        <Select
          withinPortal
          label={t("manualreport_datatype")}
          data={Object.keys(ManualReportMappingDataType).map((x) => ({ value: x, label: x }))}
          {...props.form.getInputProps(`signalMappings.${index}.dataType`)}
        />
        <Group>
          <ActionIcon color="red" onClick={() => removeMappingItem(index)}>
            <RemoveCircleOutline></RemoveCircleOutline>
          </ActionIcon>
        </Group>
      </Group>
    );
  });
  return (
    <>
      {loadMappings}
      <Group>
        <Button variant="light" onClick={() => addMappingItem()}>
          {t("manualreport_addsignalmap")}
        </Button>
      </Group>
    </>
  );
};

export default ManualReportSignalMappingInput;
