import { Button, Container, Divider, FileInput, Tabs, TextInput, Title } from "@mantine/core"
import { FileDownload, FileUpload, Key } from "@mui/icons-material"
import { useState } from "react"
import { isDev } from "../helpers/DevDetect"
import NotificationHelper from "../helpers/NotiHelper"
import AuthService from "../services/AuthService"
import { axiosInstance } from "../services/AxiosService"
import { FileParameter, LicenseClient } from "../services/WebApiService"

type Props = {}
const IndexLicense = (props: Props) => {
  const [licenseFile, setLicenseFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const handleDownloadLicense = async () => {
    setLoading(true);
    try {
      const licenseClient = new LicenseClient(undefined, axiosInstance);
      const fileName = await licenseClient.getCurrentSettings();
      const link = document.createElement("a");
      let url = `/api/downloads/`;
      if (isDev()) {
        url = `https://localhost:7254/downloads/`;
      }
      link.href = `${url}${fileName}`;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch {
      NotificationHelper.showError("Server Error", "An error occurred in getting current settings");
    }
    setLoading(false);
  }

  const handleNewLicense = async () => {
    setLoading(true);
    const licenseClient = new LicenseClient(undefined, axiosInstance);
    try {
      const fileName = await licenseClient.generateNew(uniqueId);
      const link = document.createElement("a");
      let url = `/api/downloads/`;
      if (isDev()) {
        url = `https://localhost:7254/downloads/`;
      }
      link.href = `${url}${fileName}`;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch {
      NotificationHelper.showError("Server Error", "An error occurred in generating new license.");
    }
    setLoading(false);
  }

  const handleUploadLicense = async () => {
    const licenseClient = new LicenseClient(undefined, axiosInstance);
    setLoading(true);
    if (licenseFile) {
      try {
        const fileParameter: FileParameter = { data: licenseFile, fileName: licenseFile.name };
        const response = await licenseClient.uploadLicense(fileParameter);
        if (response.success) {
          NotificationHelper.showSuccess(response.statusTitle, response.statusMessage);
        } else {
          NotificationHelper.showError(response.statusTitle, response.statusMessage);
        }
      } catch {
        NotificationHelper.showError("Server Error", "An error occurred in uploading license.");
      }
    } else {
      NotificationHelper.showError("Error", "No file is attached");
    }
    setLoading(false);
  }

  return (
    <Container size={"md"}>
      <Title order={3}>System License</Title>
      <Divider my="sm" size="md"></Divider>
      <Tabs defaultValue="uploadLicense">
        <Tabs.List>
          <Tabs.Tab value="uploadLicense" icon={<FileUpload />}>Upload new license</Tabs.Tab>
          <Tabs.Tab value="downloadLicense" icon={<FileDownload />}>Download current settings</Tabs.Tab>
          {AuthService.isInRole("Admin") ? <Tabs.Tab value="newLicense" icon={<Key />}>Generate new license</Tabs.Tab> : <></>}
        </Tabs.List>

        <Tabs.Panel value="uploadLicense" pt="md">
          <FileInput
            label="Click to upload license file"
            value={licenseFile}
            onChange={setLicenseFile}
          />
          <Button
            mt="lg"
            onClick={handleUploadLicense}
            loading={loading}
          >
            Upload
          </Button>
        </Tabs.Panel>

        <Tabs.Panel value="downloadLicense" pt="md">
          <Button
            loading={loading}
            onClick={handleDownloadLicense}
          >
            Download current settings
          </Button>
        </Tabs.Panel>

        <Tabs.Panel value="newLicense" pt="xs">
          <TextInput
            value={uniqueId}
            onChange={(e) => setUniqueId(e.currentTarget.value)}
            label="Plc unique ID"
            description="Unique ID from plc. If Unique ID changes, license has to be regenerated"
          />
          <Button mt="lg" onClick={handleNewLicense} loading={loading}>Generate license</Button>
        </Tabs.Panel>
      </Tabs>
    </Container>
  )
}
export default IndexLicense