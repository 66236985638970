import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import React, { Dispatch, SetStateAction } from "react";
import { DataTransferApiKeyClient, DataTransferApiKeyResponse, FleetClient, FleetResponse } from "../../services/WebApiService";
import { axiosInstance } from "../../services/AxiosService";
import NotificationHelper from "../../helpers/NotiHelper";

type ModalProps = {
  isOpen: boolean;
  id: number;
  dataTransfer?: DataTransferApiKeyResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  modalProps: ModalType;
  hideModal: (modalName: string) => void;
  dataTransferApiKeys: DataTransferApiKeyResponse[];
  setDataTransferApiKeys: Dispatch<SetStateAction<DataTransferApiKeyResponse[]>>;
};

const DeleteDataTransferForm = (props: Props) => {
  const handleDeleteDataTransfer = async () => {
    if (props.modalProps["deleteDataTransfer"].id !== 0) {
      try {
        const dataTransferApiKeyClient = new DataTransferApiKeyClient(undefined, axiosInstance);
        const response = await dataTransferApiKeyClient.delete(props.modalProps["deleteDataTransfer"].id);
        if (response) {
          props.setDataTransferApiKeys((x) => x.filter((y) => y.id !== props.modalProps["deleteDataTransfer"].id));
          props.hideModal("deleteDataTransfer");
          NotificationHelper.showSuccess("Success", "Data Transfer has been successfully deleted");
        } else {
          NotificationHelper.showError("Error", "An error has occurred in deleting data transfer");
        }
      } catch {
        NotificationHelper.showError("Error", "An error has occurred in deleting data transfer");
      }
    }
  };

  return (
    <Modal opened={props.modalProps["deleteDataTransfer"].isOpen} onClose={() => props.hideModal("deleteDataTransfer")} title="Confirmation">
      <Stack>
        <Text>Are you sure you want to delete this api key? This action is irreversible!</Text>
        <Text>
          <b>Everything link to this api key will be deleted!</b>
        </Text>
        <Group spacing="lg" align="center" grow>
          <Button variant="outline" color="red" onClick={handleDeleteDataTransfer}>
            Yes
          </Button>
          <Button onClick={() => props.hideModal("deleteDataTransfer")}>No</Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default DeleteDataTransferForm;
