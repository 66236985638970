import { Card, Group, NumberInput, Select, Stack, Switch, Title } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useEffect, useState } from "react";
import { CommonCreateKpiSetting } from "../../../../models/kpi/CommonCreateKpiSetting";
import { axiosInstance } from "../../../../services/AxiosService";
import { MappingDefResponse, KpiType, UnitResponse, UnitsClient, SignalMappingResponse } from "../../../../services/WebApiService";
import { useTranslation } from "react-i18next";

type Props = {
  form: UseFormReturnType<CommonCreateKpiSetting>;
  mappingDefs: MappingDefResponse[];
  classifications: MappingDefResponse[];
  signalMappings: SignalMappingResponse[];
};

const CommonCreateKpiInput = (props: Props) => {
  const { t } = useTranslation();

  const [units, setUnits] = useState<UnitResponse[]>([]);
  // const indexArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  // const reloadProperty = () => {
  //   if (props.mappingDefs.length > 0) {
  //     const obj = props.form.values;
  //     const filtered = props.mappingDefs.filter(x => x.classification === obj.classification);
  //     const entity = filtered.find(x => x.property === obj.property);
  //     if (!entity) {
  //       props.form.setFieldValue(`property`, filtered[0].property);
  //     }
  //   }
  // }

  useEffect(() => {
    const loadUnits = async () => {
      if (units.length === 0) {
        const unitClient = new UnitsClient(undefined, axiosInstance);
        setUnits(await unitClient.get());
      }
    };
    loadUnits();
    return () => {};
  }, [units]);

  return (
    <Card shadow="sm" p="lg" radius="sm" withBorder>
      <Card.Section withBorder p="lg">
        <Title order={3}>{t("createkpi_commonsettings")}</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <Group grow spacing="lg">
            <Select
              withinPortal
              searchable
              nothingFound={t("createkpi_notfound")}
              label={t("createkpi_signal")}
              description={t("createkpi_signaldesc")}
              data={props.signalMappings.map((x) => ({ label: x.displayName, value: x.id.toString() }))}
              {...props.form.getInputProps("signalId")}
            />
            <Select
              withinPortal
              description={t("createkpi_kpitypedesc")}
              label={t("createkpi_kpitype")}
              data={Object.keys(KpiType).map((x) => ({ value: x, label: x }))}
              {...props.form.getInputProps("kpiType")}
            />
          </Group>
          {/* <Group grow spacing="lg">
            <Select
              withinPortal
              label="Classification"
              searchable
              nothingFound="Nothing found"
              data={props.classifications.map(x => ({ value: x.classification, label: x.classificationDisplayName }))}
              {...props.form.getInputProps("classification")}
            />
            <Select
              withinPortal
              label="Property"
              searchable
              onChange={reloadProperty()}
              nothingFound="Nothing found"
              data={props.mappingDefs
                .filter(x => x.classification === props.form.values.classification)
                .map(x => ({ value: x.property, label: x.propertyDisplayName }))}
              {...props.form.getInputProps("property")}
            />
            <Select
              withinPortal
              label="Fuel Type"
              data={Object.keys(FuelType).map(x => ({ value: x, label: x }))}
              {...props.form.getInputProps("fuelType")}
            />
            <Select
              withinPortal
              label="Index"
              data={indexArray.map(x => ({ value: x.toString(), label: x.toString() }))}
              {...props.form.getInputProps("index")}
            />
          </Group>
          <Group grow spacing="lg">
            <NumberInput
              label="Warning Positive"
              description="Warning when value goes higher than this"
              {...props.form.getInputProps("warningPositive")}
            />
            <NumberInput
              label="Alarm Positive"
              description="Alarm when value goes higher than this"
              {...props.form.getInputProps("alarmPositive")}
            />
            <NumberInput
              label="Warning Negative"
              description="Warning when value goes lower than this"
              {...props.form.getInputProps("warningNegative")}
            />
            <NumberInput
              label="Alarm Negative"
              description="Alarm when value goes lower than this"
              {...props.form.getInputProps("alarmNegative")}
            />
          </Group> */}
          <Group grow spacing="lg" pb="md" align="end">
            <NumberInput label={t("createkpi_order")} min={0} description={t("createkpi_orderdesc")} {...props.form.getInputProps("order")} />
            <Switch label={t("createkpi_isnetvalue")} {...props.form.getInputProps("isNetValue", { type: "checkbox" })} />
          </Group>
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default CommonCreateKpiInput;
