import { Group, Text, Stack, Select, Badge, createStyles, Divider, ScrollArea, Center, LoadingOverlay } from "@mantine/core";
import { Dashboard as DashboardIcon } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NormalLink } from "../common/Models";
import NotificationHelper from "../helpers/NotiHelper";
import { DashboardConfigContext } from "../providers/DashboardConfigProvider";
import { GlobalContext } from "../providers/GlobalContextProvider";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import { EnvService } from "../services/EnvService";
import { AccountsClient, DashboardsClient, DashboardResponse } from "../services/WebApiService";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  subNavMenuItem: {
    textDecoration: "none",
    borderRadius: theme.radius.md,
    color: "black",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : "#f4f5f7",
    },
    "& svg": {
      color: "white",
      backgroundColor: theme.colors[theme.primaryColor][7],
      padding: "2px",
      fontSize: "30px",
    },
  },
  titleColor: { color: theme.colorScheme === "light" ? "black" : "white" },
  badgeBackground: { backgroundColor: theme.colorScheme === "light" ? "white" : "#25262b" },
}));

type SelectModel = {
  id: number;
  name: string;
};

type DashboardGroup = {
  category: string;
  links: DashboardLink[];
};

type DashboardLink = {
  title: string;
  url: string;
};

type Props = {
  isOpen: boolean;
  footerLinks: NormalLink[];
  isForFleet: boolean;
};

const TopNavDashboardSubMenu = (props: Props) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [vesselSelectData, setVesselSelectData] = useState<Array<SelectModel>>([]);
  const [fleetSelectData, setFleetSelectData] = useState<Array<SelectModel>>([]);
  const [systemDashboardGroup, setSystemDashboardGroup] = useState<DashboardGroup[]>([]);
  const [systemFleetDashboardGroup, setSystemFleetDashboardGroup] = useState<DashboardGroup[]>([]);
  const [systemLoadingVisible, setSystemLoadingVisible] = useState(false);
  const { systemGlobal, setDashboardVesselId, setDashboardVesselName, setDashboardFleetId, isSystemModeVessel } = useContext(GlobalContext);
  const { setIsOpen, setConfigType, setDashboardType } = useContext(DashboardConfigContext);
  const { classes } = useStyles();

  const { t } = useTranslation();

  const convertToDashboardGroups = (dashboards: DashboardResponse[]) => {
    const result: DashboardGroup[] = [];
    for (let i = 0; i < dashboards.length; i++) {
      const dashboard = dashboards[i];
      const obj = result.find((x) => x.category === dashboard.category);
      if (obj) {
        obj.links.push({
          title: dashboard.name,
          url: dashboard.id.toString(),
        });
      } else {
        result.push({
          category: dashboard.category,
          links: [
            {
              title: dashboard.name,
              url: dashboard.id.toString(),
            },
          ],
        });
      }
    }
    return result;
  };

  useEffect(() => {
    const loadDisplayVessels = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        setSystemLoadingVisible(true);
        try {
          const accountClient = new AccountsClient(undefined, axiosInstance);
          const vessels = await accountClient.vessels(user.userid, null, null, null);
          if (vessels.length <= 0) {
            // NotificationHelper.showErrorNoAutoClose(
            //   "Initialization error",
            //   "No vessel found in database. Please wait for database initialization and refresh the page manually"
            // );
          } else {
            setDashboardVesselId(vessels[0].id);
            setDashboardVesselName(vessels[0].name);
            setVesselSelectData(vessels);
            //NotificationHelper.showSuccess("Success", "A vessel has been found in the database");
          }
        } catch {
          //NotificationHelper.showError("Connection error", "Cannot get vessels by user");
        }
        setSystemLoadingVisible(false);
      }
    };

    const loadDisplayFleet = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        setSystemLoadingVisible(true);
        try {
          const accountClient = new AccountsClient(undefined, axiosInstance);
          const fleets = await accountClient.fleetVessels(user.userid);
          if (fleets.length <= 0) {
            // NotificationHelper.showErrorNoAutoClose(
            //   "Initialization error",
            //   "No fleet found in database. Please wait for database initialization and refresh the page manually"
            // );
          } else {
            setDashboardFleetId(fleets[0].fleetId);

            let fleetsSelectData: SelectModel[] = fleets.map((item) => {
              return {
                id: item.fleetId,
                name: item.fleetName,
              };
            });
            setFleetSelectData(fleetsSelectData);

            //NotificationHelper.showSuccess("Success", "A fleet has been found in the database");
          }
        } catch {
          //NotificationHelper.showError("Connection error", "Cannot get fleets by user");
        }
        setSystemLoadingVisible(false);
      }
    };

    if (firstLoad) {
      setFirstLoad(false);
      loadDisplayVessels();
      if (!isSystemModeVessel()) {
        loadDisplayFleet();
      }
    }
    return () => {};
  }, [firstLoad, setDashboardVesselId, setDashboardVesselName, setDashboardFleetId, isSystemModeVessel]);

  useEffect(() => {
    const loadDisplayVessels = async () => {
      setSystemLoadingVisible(true);
      const user = AuthService.getCurrentUser();
      if (user) {
        try {
          const accountClient = new AccountsClient(undefined, axiosInstance);
          const vessels = await accountClient.vessels(user.userid, null, null, null);
          if (vessels.length <= 0) {
            // NotificationHelper.showErrorNoAutoClose(
            //   "Initialization error",
            //   "No vessel found in database. Please wait for database initialization and refresh the page manually"
            // );
          } else {
            setVesselSelectData(vessels);
            await loadDashboards();
          }
        } catch {
          //NotificationHelper.showError("Connection error", "Cannot get vessels by user");
        }
      }
      setSystemLoadingVisible(false);
    };

    const loadDisplayFleet = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        setSystemLoadingVisible(true);
        try {
          const accountClient = new AccountsClient(undefined, axiosInstance);
          const fleets = await accountClient.fleetVessels(user.userid);
          if (fleets.length <= 0) {
            // NotificationHelper.showErrorNoAutoClose(
            //   "Initialization error",
            //   "No fleet found in database. Please wait for database initialization and refresh the page manually"
            // );
          } else {
            let fleetsSelectData: SelectModel[] = fleets.map((item) => {
              return {
                id: item.fleetId,
                name: item.fleetName,
              };
            });
            setFleetSelectData(fleetsSelectData);
            await loadDashboardsFleets();
          }
        } catch {
          //NotificationHelper.showError("Connection error", "Cannot get fleets by user");
        }
        setSystemLoadingVisible(false);
      }
    };

    const loadDashboards = async () => {
      const dashboardClient = new DashboardsClient(undefined, axiosInstance);
      let data: DashboardResponse[];

      if (isSystemModeVessel()) data = await dashboardClient.getAll("vessel", false);
      else data = await dashboardClient.getAll("fleet", false, systemGlobal.dashboardVesselId);

      setSystemDashboardGroup(convertToDashboardGroups(data));
    };

    const loadDashboardsFleets = async () => {
      const dashboardClient = new DashboardsClient(undefined, axiosInstance);
      let data: DashboardResponse[];

      data = await dashboardClient.getAll("fleetonly", false, null, systemGlobal.dashboardFleetId);

      setSystemFleetDashboardGroup(convertToDashboardGroups(data));
    };

    if (props.isOpen) {
      loadDisplayVessels();
      if (!isSystemModeVessel()) {
        loadDisplayFleet();
      }
    }
    return () => {};
  }, [isSystemModeVessel, props.isOpen, systemGlobal.dashboardVesselId, systemGlobal.dashboardFleetId, setDashboardFleetId]);

  const displayDashboards = (dashboardGroups: DashboardGroup[]) => {
    return dashboardGroups.map((x) => {
      return (
        <Stack align="stretch" spacing="xs">
          <Group>
            <Badge color="dark" className={classes.badgeBackground}>
              {x.category}
            </Badge>
          </Group>
          {x.links.map((y) => {
            return (
              <Link to={`dashboard/view/${y.url}`} key={y.url} className={classes.subNavMenuItem}>
                <Group p="xs">
                  <DashboardIcon></DashboardIcon>
                  <Text className={classes.titleColor}>{y.title}</Text>
                </Group>
              </Link>
            );
          })}
        </Stack>
      );
    });
  };

  const handleCreateDashboardConfig = (type: "Vessel" | "Fleet" | undefined) => {
    setConfigType("Create");
    setIsOpen(true);

    if (type) {
      setDashboardType(type);
    }
  };

  const loadFooterLinks = props.footerLinks.map((x, index) => {
    if (index === 1) {
      return (
        <Group p="xs" onClick={() => handleCreateDashboardConfig(x.dashboardType)} className={classes.subNavMenuItem} key={x.title}>
          <Text className={classes.titleColor}>{x.title}</Text>
        </Group>
      );
    } else {
      return (
        <Link to={x.url} className={classes.subNavMenuItem} key={x.title}>
          <Group p="xs">
            <Text className={classes.titleColor}>{x.title}</Text>
          </Group>
        </Link>
      );
    }
  });

  const loadVesselSelection = () => {
    if (EnvService.isVesselSystemMode) {
      if (vesselSelectData.length > 0) {
        return (
          <>
            <Center>
              <Text className={classes.titleColor}>{vesselSelectData[0].name}</Text>
            </Center>
            <Divider size="sm"></Divider>
          </>
        );
      } else {
        return (
          <>
            <Center>
              <Text className={classes.titleColor}>No Vessel found</Text>
            </Center>
            <Divider size="sm"></Divider>
          </>
        );
      }
    } else {
      return (
        <Select
          label={t("navdashboard_vessel")}
          value={String(systemGlobal.dashboardVesselId)}
          onChange={(x) => {
            setDashboardVesselId(Number(x));
            var vessel = vesselSelectData.find(function (item) {
              return item.id === Number(x);
            });
            if (vessel && typeof vessel.name === "string") setDashboardVesselName(vessel?.name);
          }}
          defaultValue={String(systemGlobal.dashboardVesselId)}
          data={vesselSelectData.map((x) => ({
            value: x.id.toString(),
            label: x.name,
          }))}
        ></Select>
      );
    }
  };

  const loadFleetSelection = () => {
    if (fleetSelectData.length <= 0) {
      return (
        <>
          <Center>
            <Text>No Fleet found</Text>
          </Center>
          <Divider size="sm"></Divider>
        </>
      );
    } else {
      return (
        <Select
          label="Fleet"
          value={String(systemGlobal.dashboardFleetId)}
          onChange={(x) => {
            setDashboardFleetId(Number(x));
          }}
          defaultValue={String(systemGlobal.dashboardFleetId)}
          data={fleetSelectData.map((x) => ({
            value: x.id.toString(),
            label: x.name,
          }))}
        ></Select>
      );
    }
  };

  return (
    <div>
      {!props.isForFleet && (
        <Stack spacing="xs">
          {loadVesselSelection()}
          <ScrollArea.Autosize maxHeight={window.innerHeight - 350} scrollHideDelay={50}>
            <Stack spacing="xs">
              <LoadingOverlay visible={systemLoadingVisible} overlayBlur={2} />
              {displayDashboards(systemDashboardGroup)}
            </Stack>
          </ScrollArea.Autosize>
          <Divider size="sm"></Divider>
          <Stack spacing={0}>{loadFooterLinks}</Stack>
        </Stack>
      )}

      {props.isForFleet && (
        <Stack spacing="xs">
          {loadFleetSelection()}
          <ScrollArea.Autosize maxHeight={window.innerHeight - 350} scrollHideDelay={50}>
            <Stack spacing="xs">
              <LoadingOverlay visible={systemLoadingVisible} overlayBlur={2} />
              {displayDashboards(systemFleetDashboardGroup)}
            </Stack>
          </ScrollArea.Autosize>
          <Divider size="sm"></Divider>
          <Stack spacing={0}>{loadFooterLinks}</Stack>
        </Stack>
      )}
    </div>
  );
};

export default TopNavDashboardSubMenu;
