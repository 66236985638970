import { Card, Drawer, Group, Stack } from "@mantine/core";
import { Text, Badge, Button } from "@mantine/core";
import { useEffect, useState } from "react";
import { WidgetType } from "../../../services/WebApiService";
import "./WidgetBar.scss";
import { useTranslation } from "react-i18next";

type Props = {
  isWidgetBarHidden: boolean;
  dashboardType: "Vessel" | "Fleet";
  handleOpenWidgetConfig(): void;
  handleCloseWidgetBar(): void;
  setIsAddWidget(value: boolean): void;
  setWidgetTypeForAdd(value: WidgetType): void;
};

type WidgetCard = {
  widgetType: WidgetType;
  title: string;
  hasLive: boolean;
  hasQuery: boolean;
  description: string;
};

const WidgetBar = (props: Props) => {
  const [widgetCards, setWidgetCards] = useState<WidgetCard[]>([]);

  const { t } = useTranslation();

  // const addWidgetCard = (widgetCard: WidgetCard) => {
  //   setWidgetCards(prev => [...prev, widgetCard]);
  // }

  useEffect(() => {
    console.log(props.dashboardType);
  }, [props.dashboardType]);

  useEffect(() => {
    const populateWidgetCards = () => {
      const entities: WidgetCard[] = [];

      if (props.dashboardType === "Vessel") {
        entities.push({
          widgetType: WidgetType.TimeChart,
          title: t("dashboardwidget_widgetbar_ytchart"),
          hasLive: true,
          hasQuery: true,
          description: t("dashboardwidget_widgetbar_ytchartdesc"),
        });
        entities.push({
          widgetType: WidgetType.ScatterChart,
          title: t("dashboardwidget_widgetbar_yxchart"),
          hasLive: true,
          hasQuery: true,
          description: t("dashboardwidget_widgetbar_yxchartdesc"),
        });
      }

      entities.push({
        widgetType: WidgetType.RadialGauge,
        title: t("dashboardwidget_widgetbar_radialguage"),
        hasLive: true,
        hasQuery: false,
        description: t("dashboardwidget_widgetbar_radialguagedesc"),
      });
      entities.push({
        widgetType: WidgetType.InstantValue,
        title: t("dashboardwidget_widgetbar_instantval"),
        hasLive: true,
        hasQuery: false,
        description: t("dashboardwidget_widgetbar_instantvaldesc"),
      });
      entities.push({
        widgetType: WidgetType.TextEditor,
        title: t("dashboardwidget_widgetbar_texteditor"),
        hasLive: false,
        hasQuery: false,
        description: t("dashboardwidget_widgetbar_texteditordesc"),
      });
      entities.push({
        widgetType: WidgetType.Map,
        title: t("dashboardwidget_widgetbar_map"),
        hasLive: true,
        hasQuery: false,
        description: t("dashboardwidget_widgetbar_mapdesc"),
      });

      entities.push({
        widgetType: WidgetType.AttitudeIndicator,
        title: t("dashboardwidget_widgetbar_attitudeindicator"),
        hasLive: true,
        hasQuery: false,
        description: t("dashboardwidget_widgetbar_attitudeindicatordesc"),
      });
      setWidgetCards(entities);
    };
    populateWidgetCards();

    return () => {};
  }, [props.dashboardType, t]);

  const loadWidgetCards = widgetCards.map((x) => {
    return (
      <Card shadow="sm" p="md" radius="md" withBorder key={x.title}>
        {/* <Card.Section>
          <Image
            src={TimeChartImage}
            height={160}
            alt="Time Chart Example"
          />
        </Card.Section> */}
        <Group position="apart" mt="xs" mb="xs">
          <Text weight={500}>{x.title}</Text>
          <Group spacing={"xs"}>
            {x.hasLive ? (
              <Badge color="green" variant="light">
                {t("dashboardwidget_widgetbar_live")}
              </Badge>
            ) : (
              <></>
            )}
            {x.hasQuery ? (
              <Badge color="orange" variant="light">
                {t("dashboardwidget_widgetbar_query")}
              </Badge>
            ) : (
              <></>
            )}
          </Group>
        </Group>
        <Text size="sm" color="dimmed">
          {x.description}
        </Text>
        <Button variant="light" color="blue" fullWidth mt="md" radius="md" onClick={() => showWidgetConfig(x.widgetType)}>
          {t("dashboardwidget_widgetbar_addwidget")}
        </Button>
      </Card>
    );
  });

  const showWidgetConfig = (widgetType: WidgetType) => {
    props.setIsAddWidget(true);
    props.setWidgetTypeForAdd(widgetType);
    props.handleOpenWidgetConfig();
  };

  return (
    <Drawer
      position="right"
      opened={!props.isWidgetBarHidden}
      onClose={props.handleCloseWidgetBar}
      title="Widgets"
      padding="xl"
      size="lg"
      zIndex={40}
    >
      <Stack>{loadWidgetCards}</Stack>
    </Drawer>
  );
};

export default WidgetBar;
