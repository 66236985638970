import React, { useEffect, useState } from "react";
import { Grid, Text } from "@mantine/core";
import SignalHelper from "../../../helpers/SignalHelper";
import { AttitudeIndicatorOptions } from "../../../models/dashboard/DashboardModels";
import { DashboardVesselResponse, DashboardWidgetResponse, HubSignalResponse } from "../../../services/WebApiService";

import { AttitudeIndicator } from "react-typescript-flight-indicators";
import { v4 as uuidv4 } from "uuid";

type Props = {
  id: string;
  optionJson: string;
  dashboardVessels: DashboardVesselResponse[];
  dashboardWidget: DashboardWidgetResponse;
  hubSignals: HubSignalResponse[];
  dashboardType: "Fleet" | "Vessel";
};

type AttitudeIndicatorPitch = {
  id: number;
  value: number;
  displayName: string;
};

type AttitudeIndicatorRoll = {
  id: number;
  value: number;
  displayName: string;
};

type AttitudeIndicatorHub = {
  pitchSignals: AttitudeIndicatorPitch[];
  rollSignals: AttitudeIndicatorRoll[];
};

const AttitudeIndicatorHelper = (props: Props) => {
  const [attitudeIndicatorValue, setAttitudeIndicatorValue] = useState<AttitudeIndicatorHub>({ pitchSignals: [], rollSignals: [] });

  const createAttitudeIndicator = () => {
    const options = JSON.parse(props.optionJson) as AttitudeIndicatorOptions;
    const signalsMappingPitch = SignalHelper.getSignalsByMappingsByVessels(props.dashboardVessels, [options.mappingPitch], "false");
    const signalsMappingRoll = SignalHelper.getSignalsByMappingsByVessels(props.dashboardVessels, [options.mappingPitch], "false");

    var tempPitch: AttitudeIndicatorPitch[] = [];
    signalsMappingPitch.map((signal) => {
      tempPitch.push({
        id: signal.id,
        value: signal.lastValue,
        displayName: signal.displayName,
      });
      return signal;
    });

    var tempRoll: AttitudeIndicatorRoll[] = [];
    signalsMappingRoll.map((signal) => {
      tempRoll.push({
        id: signal.id,
        value: signal.lastValue,
        displayName: signal.displayName,
      });
      return signal;
    });

    // setattitudeIndicatorValue((prev) => {
    //   return { pitchSignals: tempPitch, rollSignals: tempRoll };
    // });

    return (
      <>
        <Grid columns={12} p={2} key={`AttitudeIndicatorGrid`}>
          {props.dashboardType === "Vessel" &&
            signalsMappingPitch?.map((signal, index) => (
              <Grid.Col span={6}>
                <AttitudeIndicator roll={signal.lastValue} pitch={signalsMappingRoll[index].lastValue} showBox={false} />
                <Text>{signal.displayName}</Text>
              </Grid.Col>
            ))}
        </Grid>

        {/* {props.dashboardType === "Fleet" &&
          props.dashboardVessels.map((v) => {
            return (
              <React.Fragment>
                <Grid columns={10} p={2} key={`VesselGroup-${v.vesselId}`}>
                  <Grid.Col span={5}>
                    <Badge>{v.vesselName}</Badge>
                  </Grid.Col>
                </Grid>
                {signals
                  .find((y) => y.key === x.name)
                  ?.signals.filter((s) => s.vesselId === v.vesselId)
                  ?.map((signal) => (
                    <Grid columns={6} p={2} key={`AttitudeIndicatorGrid-${signal.id}`}>
                      <Grid.Col span={6}>
                        <AttitudeIndicator roll={(Math.random() - 0.5) * 120} pitch={(Math.random() - 0.5) * 40} showBox={false} />
                        <Text>{signal.displayName}</Text>
                      </Grid.Col>
                    </Grid>
                  ))}
              </React.Fragment>
            );
          })} */}
      </>
    );
  };

  useEffect(() => {
    const options = JSON.parse(props.optionJson) as AttitudeIndicatorOptions;

    const signalsMappingPitch = SignalHelper.getSignalsByMappingsByVessels(props.dashboardVessels, [options.mappingPitch], "false");
    const signalsMappingRoll = SignalHelper.getSignalsByMappingsByVessels(props.dashboardVessels, [options.mappingPitch], "false");

    var tempPitch: AttitudeIndicatorPitch[] = [];
    signalsMappingPitch.map((signal) => {
      tempPitch.push({
        id: signal.id,
        value: signal.lastValue,
        displayName: signal.displayName,
      });
      return signal;
    });

    var tempRoll: AttitudeIndicatorRoll[] = [];
    signalsMappingRoll.map((signal) => {
      tempRoll.push({
        id: signal.id,
        value: signal.lastValue,
        displayName: signal.displayName,
      });
      return signal;
    });

    setAttitudeIndicatorValue((prev) => {
      return { pitchSignals: tempPitch, rollSignals: tempRoll };
    });

    return () => {};
  }, []);

  // Live
  useEffect(() => {
    const updateAttitudeLive = () => {
      var tempSignal: HubSignalResponse[] = props.hubSignals;

      setAttitudeIndicatorValue((prev) => {
        var tempPitch: AttitudeIndicatorPitch[] = prev.pitchSignals;
        var tempRoll: AttitudeIndicatorRoll[] = prev.rollSignals;

        for (const pitch of tempPitch) {
          var foundSignal = tempSignal.filter((x) => x.id === pitch.id);
          pitch.value = foundSignal[0].value;
        }

        for (const roll of tempRoll) {
          var foundSignal = tempSignal.filter((x) => x.id === roll.id);
          roll.value = foundSignal[0].value;
        }

        return { pitchSignals: tempPitch, rollSignals: tempRoll };
      });
    };
    updateAttitudeLive();

    return () => {};
  }, [props.hubSignals]);

  return (
    <>
      <Grid columns={12} p={2} key={`AttitudeIndicatorGrid`}>
        {props.dashboardType === "Vessel" &&
          attitudeIndicatorValue.pitchSignals?.map((signal, index) => (
            <Grid.Col span={6}>
              <AttitudeIndicator roll={signal.value} pitch={attitudeIndicatorValue.rollSignals[index].value} showBox={false} />
              <Text>{signal.displayName}</Text>
            </Grid.Col>
          ))}
      </Grid>
    </>
  );
};

export default AttitudeIndicatorHelper;
