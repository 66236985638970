import { Container, Divider, Text, Title } from "@mantine/core";

const AccessDenied = () => {
  return (
    <Container size="lg">
      <Title order={3}>Access Denied</Title>
      <Divider my="sm" size="md"></Divider>
      <Text>You do not have the permissions to access this resource</Text>
    </Container>
  );
};
export default AccessDenied;
