import { Button, Card, Container, Divider, Group, Select, Stack, Title, Transition } from "@mantine/core";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { isDev } from "../helpers/DevDetect";
import NotificationHelper from "../helpers/NotiHelper";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import { AccountsClient, Classification, FuelConsReportRequest, ReportClient, SelectItemResponse, VesselResponse } from "../services/WebApiService";
import { useTranslation } from "react-i18next";

type FuelConsForm = {
  reportDateRange?: DateRangePickerValue;
  vesselId: string;
  engineType: string;
  interval: string;
};

type Props = {};
const FuelConsReport = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [intervals, setIntervals] = useState<SelectItemResponse[]>([]);
  const [engineTypes, setEngineTypes] = useState<SelectItemResponse[]>([]);
  const [vessels, setVessels] = useState<SelectItemResponse[]>([]);
  const form = useForm<FuelConsForm>({
    initialValues: {
      engineType: Classification.MainEngine,
      interval: "30",
      vesselId: "0",
    },
    validate: {
      reportDateRange: (value?: DateRangePickerValue) => (!value ? "Please set a date range" : null),
    },
  });
  const { t } = useTranslation();

  const handleGenerate = async (values: FuelConsForm) => {
    setLoading(true);
    const reportClient = new ReportClient(undefined, axiosInstance);
    if (values.reportDateRange) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const entity = {
        vesselId: Number(values.vesselId),
        interval: Number(values.interval),
        engineType: values.engineType,
        startDateTime: values.reportDateRange[0],
        endDateTime: values.reportDateRange[1],
        timeZone: timeZone,
      } as FuelConsReportRequest;

      try {
        const fileName = await reportClient.fuelCons(entity);
        const link = document.createElement("a");
        let url = `/api/`;
        if (isDev()) {
          url = `https://localhost:7254/`;
        }
        link.href = `${url}${fileName}`;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      } catch {
        NotificationHelper.showError("Server error", "An error occurred in generating report");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  useEffect(() => {
    const populateIntervals = () => {
      setIntervals([
        {
          label: "5 Mins",
          value: "5",
        },
        {
          label: "10 Mins",
          value: "10",
        },
        {
          label: "15 Mins",
          value: "15",
        },
        {
          label: "30 Mins",
          value: "30",
        },
        {
          label: "Hourly",
          value: "60",
        },
        {
          label: "Daily",
          value: "1440",
        },
      ] as SelectItemResponse[]);
    };

    const populateEngineTypes = () => {
      setEngineTypes([
        {
          label: "Main Engine",
          value: Classification.MainEngine,
        },
        {
          label: "Aux Engine",
          value: Classification.AuxEngineOrGenerator,
        },
        {
          label: "Boiler",
          value: Classification.Boiler,
        },
        {
          label: "Thruster",
          value: Classification.Thruster,
        },
      ] as SelectItemResponse[]);
    };

    const loadVessels = async () => {
      const accountClient = new AccountsClient(undefined, axiosInstance);
      const user = AuthService.getCurrentUser();
      if (user) {
        let localVessels: VesselResponse[] = [];
        if (AuthService.isInRoleArray(["Admin", "Supervisor", "CompanyAdmin"])) {
          localVessels = await accountClient.managedVessels(user.userid);
        } else {
          localVessels = await accountClient.vessels(user.userid);
        }
        setVessels(
          localVessels.map(
            (x) =>
              ({
                label: x.name,
                value: x.id.toString(),
              } as SelectItemResponse)
          )
        );
        if (localVessels.length > 0) {
          form.setFieldValue("vesselId", localVessels[0].id.toString());
        }
      }
    };

    if (firstLoad) {
      setFirstLoad(false);
      populateEngineTypes();
      populateIntervals();
      loadVessels();
    }

    return () => {};
  }, [firstLoad, form, vessels]);

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <Container size="xs" style={styles}>
          <Group>
            <Title order={3}>{t("fuelconsreport")}</Title>
          </Group>
          <Divider my="sm" size="md"></Divider>
          <Card p="md" shadow="md" withBorder>
            <Card.Section p="md">
              <form onSubmit={form.onSubmit((values) => handleGenerate(values))}>
                <Stack>
                  <DateRangePicker withinPortal required label={t("fuelconsreport_daterange")} {...form.getInputProps("reportDateRange")} />
                  <Select withinPortal label={t("fuelconsreport_vessel")} data={vessels} {...form.getInputProps("vesselId")} />
                  <Select withinPortal label={t("fuelconsreport_enginetypes")} data={engineTypes} {...form.getInputProps("engineType")} />
                  <Select withinPortal label={t("fuelconsreport_dateinterval")} data={intervals} {...form.getInputProps("interval")} />
                  <Group>
                    <Button type="submit" loading={loading}>
                      {t("fuelconsreport_generate")}
                    </Button>
                  </Group>
                </Stack>
              </form>
            </Card.Section>
          </Card>
        </Container>
      )}
    </Transition>
  );
};
export default FuelConsReport;
