import { ActionIcon, Button, Divider, Group, Stack, Title, Transition, useMantineTheme, Text } from "@mantine/core";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { ArrowBack, DateRange, Download } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isDev } from "../helpers/DevDetect";
import NotificationHelper from "../helpers/NotiHelper";
import { axiosInstance } from "../services/AxiosService";
import { AutomaticReportListClient, AutomaticReportListResponse } from "../services/WebApiService";
import { useTranslation } from "react-i18next";

type Props = {};
const AutomaticReportList = (props: Props) => {
  const theme = createTheme();
  const mantineTheme = useMantineTheme();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [firstLoad, setFirstLoad] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState<AutomaticReportListResponse[]>([]);
  const [dateRangeValue, setDateRangeValue] = useState<DateRangePickerValue>([
    dayjs().startOf("date").subtract(7, "day").toDate(),
    dayjs().startOf("date").add(1, "day").toDate(),
  ]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const start = dateRangeValue[0];
      const end = dateRangeValue[1];
      if (start && end) {
        const automaticReportClient = new AutomaticReportListClient(undefined, axiosInstance);
        const data = await automaticReportClient.get(start, end);
        setReports(data);
      }
    } catch {
      NotificationHelper.showError("Error", "An error occurred in getting report lists");
    }
    setLoading(false);
  }, [dateRangeValue]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      handleSubmit();
    }
    return () => {};
  }, [firstLoad, handleSubmit]);

  const handleDownload = (fileName: string) => {
    const link = document.createElement("a");
    let url = `/api/reports/`;
    if (isDev()) {
      url = `https://localhost:7254/reports/`;
    }
    link.href = `${url}${fileName}`;
    link.setAttribute("download", `${fileName}`);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };

  const handleBack = () => {
    navigate("/automatic-report");
  };

  const loadDataGrid = () => {
    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          loading={loading}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          autoHeight
          pagination
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20]}
          rows={reports}
          columns={[
            {
              field: "fileName",
              headerName: t("downloadautoreport_fileName"),
              flex: 1,
            },
            {
              field: "reportName",
              headerName: t("downloadautoreport_report"),
              flex: 1,
            },
            {
              field: "createdAt",
              headerName: t("downloadautoreport_createdat"),
              flex: 1,
              renderCell: (cell) => {
                return <Text>{new Date(cell.value).toString()}</Text>;
              },
            },
            {
              field: "download",
              headerName: t("downloadautoreport_download"),
              renderCell: (cell) => {
                return (
                  <ActionIcon variant="subtle" color={mantineTheme.primaryColor} onClick={() => handleDownload(cell.row.fileName)}>
                    <Download />
                  </ActionIcon>
                );
              },
            },
          ]}
        />
      </ThemeProvider>
    );
  };

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <div style={styles}>
          <Stack>
            <Group>
              <ActionIcon variant="subtle" color="blue" onClick={handleBack}>
                <ArrowBack />
              </ActionIcon>
              <Title order={4}>{t("downloadautoreport")}</Title>
            </Group>
            <Divider size="sm" mb="sm"></Divider>
          </Stack>
          <Group mb="sm" align="end">
            <DateRangePicker
              required
              label={t("downloadautoreport_daterange")}
              icon={<DateRange fontSize="small" />}
              value={dateRangeValue}
              onChange={setDateRangeValue}
              miw={450}
            />
            <Button onClick={handleSubmit}>{t("downloadautoreport_submit")}</Button>
          </Group>
          {loadDataGrid()}
        </div>
      )}
    </Transition>
  );
};
export default AutomaticReportList;
