import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DeleteOutline } from "@mui/icons-material";
import { DashboardWidgetResponse, WidgetType, DashboardVesselResponse, HubSignalResponse, CurveResponse } from "../../../services/WebApiService";
import TimeChartHelper from "../TimeChart/TimeChartHelper";
import { SegmentedControl, Text, Grid } from "@mantine/core";
import { useEffect, useState } from "react";
import { ScatterChartOptions, TimeChartOptions } from "../../../models/dashboard/DashboardModels";
import ScatterChartHelper from "../ScatterChart/ScatterChartHelper";
import InstantValueHelper from "../InstantValue/InstantValueHelper";
import RadialGaugeHelper from "../RadialGauge/RadialGaugeHelper";
import TextEditorHelper from "../TextEditor/TextEditorHelper";
import MapHelper from "../Map/MapHelper";
import { ActionIcon, Group } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import AttitudeIndicatorHelper from "../AttitudeIndicator/AttitudeIndicatorHelper";

type Props = {
  isEdit: boolean;
  dashboardWidget: DashboardWidgetResponse;
  dashboardVessels: DashboardVesselResponse[];
  handleConfigureWidget: (dashboardWidgetId: number) => void;
  handleDeleteWidget: (dashboardWidgetId: number) => void;
  hubSignals: HubSignalResponse[];
  curves: CurveResponse[];
  dashboardType: "Fleet" | "Vessel";
};

const WidgetItem = (props: Props) => {
  const [interval, setInterval] = useState("0");

  const loadEditButtons = () => {
    if (props.isEdit) {
      return (
        <>
          <Group>
            <ActionIcon variant="subtle" onClick={() => props.handleConfigureWidget(props.dashboardWidget.id)}>
              <EditOutlinedIcon fontSize="small"></EditOutlinedIcon>
            </ActionIcon>
            <ActionIcon color="red" variant="subtle" onClick={openDeleteModal}>
              <DeleteOutline fontSize="small"></DeleteOutline>
            </ActionIcon>
          </Group>
        </>
      );
    }
  };

  const openDeleteModal = () => {
    openConfirmModal({
      title: "Please confirm your action",
      children: <Text size="sm">Are you sure you want to delete this widget?</Text>,
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => props.handleDeleteWidget(props.dashboardWidget.id),
      closeOnConfirm: true,
    });
  };

  const loadWidget = () => {
    const widgetType = props.dashboardWidget.widgetType;
    if (widgetType === WidgetType.TimeChart) {
      return (
        <TimeChartHelper
          id={props.dashboardWidget.id.toString()}
          dashboardWidget={props.dashboardWidget}
          optionJson={props.dashboardWidget.optionJson}
          dashboardVessels={props.dashboardVessels}
          hubSignals={props.hubSignals}
          selectedInterval={interval}
          isEdit={props.isEdit}
        />
      );
    } else if (widgetType === WidgetType.ScatterChart) {
      return (
        <ScatterChartHelper
          id={props.dashboardWidget.id.toString()}
          dashboardWidget={props.dashboardWidget}
          optionJson={props.dashboardWidget.optionJson}
          dashboardVessels={props.dashboardVessels}
          hubSignals={props.hubSignals}
          selectedInterval={interval}
          isEdit={props.isEdit}
          curves={props.curves}
        />
      );
    } else if (widgetType === WidgetType.InstantValue) {
      return (
        <InstantValueHelper
          id={props.dashboardWidget.id.toString()}
          dashboardWidget={props.dashboardWidget}
          optionJson={props.dashboardWidget.optionJson}
          dashboardVessels={props.dashboardVessels}
          hubSignals={props.hubSignals}
          dashboardType={props.dashboardType}
        />
      );
    } else if (widgetType === WidgetType.RadialGauge) {
      return (
        <RadialGaugeHelper
          id={props.dashboardWidget.id.toString()}
          dashboardWidget={props.dashboardWidget}
          optionJson={props.dashboardWidget.optionJson}
          dashboardVessels={props.dashboardVessels}
          hubSignals={props.hubSignals}
          key={props.dashboardWidget.id}
        />
      );
    } else if (widgetType === WidgetType.TextEditor) {
      return (
        <TextEditorHelper
          id={props.dashboardWidget.id.toString()}
          dashboardWidget={props.dashboardWidget}
          optionJson={props.dashboardWidget.optionJson}
          dashboardVessels={props.dashboardVessels}
        />
      );
    } else if (widgetType === WidgetType.Map) {
      return (
        <MapHelper
          id={props.dashboardWidget.id.toString()}
          dashboardWidget={props.dashboardWidget}
          optionJson={props.dashboardWidget.optionJson}
          dashboardVessels={props.dashboardVessels}
          hubSignals={props.hubSignals}
          dashboardType={props.dashboardType}
        />
      );
    } else if (widgetType === WidgetType.AttitudeIndicator) {
      return (
        <AttitudeIndicatorHelper
          id={props.dashboardWidget.id.toString()}
          dashboardWidget={props.dashboardWidget}
          optionJson={props.dashboardWidget.optionJson}
          dashboardVessels={props.dashboardVessels}
          hubSignals={props.hubSignals}
          dashboardType={props.dashboardType}
        />
      );
    }
  };

  const loadIntervalButtons = () => {
    const widgetType = props.dashboardWidget.widgetType;
    if (!props.isEdit) {
      if (widgetType === WidgetType.TimeChart || widgetType === WidgetType.ScatterChart) {
        return (
          <SegmentedControl
            value={interval}
            onChange={setInterval}
            className="interval-button-container"
            data={[
              { label: "Live", value: "0" },
              { label: "5m", value: "5" },
              { label: "15m", value: "15" },
              { label: "30m", value: "30" },
              { label: "1H", value: "60" },
              { label: "1D", value: "1440" },
            ]}
          />
        );
      }
    }
  };

  useEffect(() => {
    if (props.dashboardWidget.widgetType === WidgetType.TimeChart) {
      const options = JSON.parse(props.dashboardWidget.optionJson) as TimeChartOptions;
      setInterval(options.interval);
    } else if (props.dashboardWidget.widgetType === WidgetType.ScatterChart) {
      const options = JSON.parse(props.dashboardWidget.optionJson) as ScatterChartOptions;
      setInterval(options.interval);
    }

    return () => {};
  }, [props.dashboardWidget]);

  return (
    <>
      <Group grow pb="lg">
        <Group grow>
          <Grid>
            <Grid.Col span="auto" className="gridItemDragHandler">
              {props.dashboardWidget.header}
            </Grid.Col>
            <Grid.Col span="content">
              {loadIntervalButtons()}
              {loadEditButtons()}
            </Grid.Col>
          </Grid>
        </Group>
      </Group>
      <div id={`widget-${props.dashboardWidget.id}`} className="widget-contents">
        {loadWidget()}
      </div>
    </>
  );
};

export default WidgetItem;
