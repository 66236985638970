import { ActionIcon, Container, Divider, Group, Stack, Title, Transition } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ArrowBack } from "@mui/icons-material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdditionKpiInput from "./components/AdditionKpiInput";
import CommonEditKpiInput from "./components/Common/CommonEditKpiInput";
import EeoiKpiInput from "./components/EeoiKpiInput";
import SubtractionKpiInput from "./components/SubtractionKpiInput";
import NotificationHelper from "../../helpers/NotiHelper";
import { CommonEditKpiSetting } from "../../models/kpi/CommonEditKpiSetting";
import {
  Classification,
  FuelType,
  KpiResponse,
  KpiClient,
  KpiType,
  Property,
  SignalMappingResponse,
  VesselsClient,
} from "../../services/WebApiService";
import { axiosInstance } from "../../services/AxiosService";
import { GlobalContext } from "../../providers/GlobalContextProvider";
import MultiplicationKpiInput from "./components/MultiplicationKpiInput";
import DivisionKpiInput from "./components/DivisionKpiInput";
import CIIKpiInput from "./components/CIIKpiInput";

type Props = {};

const EditKpi = (props: Props) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [mounted, setMounted] = useState(false);
  const { systemGlobal } = useContext(GlobalContext);

  const navigate = useNavigate();
  const { vesselId, kpiId } = useParams();
  const [kpi, setKpi] = useState<KpiResponse>();
  const [signalMappings, setSignalMappings] = useState<SignalMappingResponse[]>([]);
  const commonForm = useForm<CommonEditKpiSetting>({
    initialValues: {
      name: "",
      unitId: "0",
      classification: Classification.NotApplicable,
      property: Property.NotApplicable,
      fuelType: FuelType.NotApplicable,
      index: "1",
      alarmNegative: 0,
      alarmPositive: 0,
      warningPositive: 0,
      warningNegative: 0,
      kpiType: KpiType.Addition,
      isNetValue: false,
      order: 1,
    },
  });

  const handleBack = () => {
    navigate(`/vessel/${vesselId}/kpi`);
  };

  const handleSubmit = useCallback(
    async (optionJson: string) => {
      if (kpi) {
        try {
          const commonValues = commonForm.values;
          const kpiClient = new KpiClient(undefined, axiosInstance);
          const entity = {
            id: kpi.id,
            classification: commonValues.classification,
            property: commonValues.property,
            fuelType: commonValues.fuelType,
            index: commonValues.index,
            warnMin: commonValues.warningNegative,
            warnMax: commonValues.warningPositive,
            alarmMin: commonValues.alarmNegative,
            alarmMax: commonValues.alarmPositive,
            kpiType: commonValues.kpiType,
            isNetValue: commonValues.isNetValue,
            order: commonValues.order,
            name: commonValues.name,
            unitId: commonValues.unitId,
            optionJson: optionJson,
          };
          const kpiToSave = new KpiResponse();
          kpiToSave.init(entity);
          await kpiClient.put(kpiToSave);
          NotificationHelper.showSuccess("Success", "Kpi is updated successfully");
        } catch {
          NotificationHelper.showError("Error in saving", "An error occurred in saving the kpi");
        }
      }
    },
    [commonForm.values, kpi]
  );

  useEffect(() => {
    const loadKpi = async () => {
      if (vesselId && kpiId && !kpi && firstLoad) {
        setFirstLoad(false);
        const kpiClient = new KpiClient(undefined, axiosInstance);
        const vesselClient = new VesselsClient(undefined, axiosInstance);
        const signals = await vesselClient.signalMappingsGET(Number(vesselId), null);
        const data = await kpiClient.get(Number(kpiId));
        setKpi(data);
        setSignalMappings(signals);
        commonForm.setFieldValue("name", data.name);
        commonForm.setFieldValue("unitId", data.unitId.toString());
        commonForm.setFieldValue("kpiType", data.kpiType);
        commonForm.setFieldValue("classification", data.classification);
        commonForm.setFieldValue("property", data.property);
        commonForm.setFieldValue("fuelType", data.fuelType);
        commonForm.setFieldValue("index", data.index.toString());
        commonForm.setFieldValue("warningNegative", data.warnMin);
        commonForm.setFieldValue("warningPositive", data.warnMax);
        commonForm.setFieldValue("alarmNegative", data.alarmMin);
        commonForm.setFieldValue("alarmPositive", data.alarmMax);
        commonForm.setFieldValue("order", data.order);
        commonForm.setFieldValue("isNetValue", data.isNetValue);
      }
    };
    loadKpi();
    return () => {};
  }, [commonForm, firstLoad, kpi, kpiId, vesselId]);

  const loadKpiSpecificInput = useCallback(() => {
    if (commonForm.values.kpiType === KpiType.Addition) {
      return <AdditionKpiInput kpi={kpi} signalMapping={signalMappings} handleSubmit={handleSubmit} />;
    } else if (commonForm.values.kpiType === KpiType.Eeoi) {
      return <EeoiKpiInput handleSubmit={handleSubmit} />;
    } else if (commonForm.values.kpiType === KpiType.Subtraction) {
      return <SubtractionKpiInput kpi={kpi} signalMapping={signalMappings} handleSubmit={handleSubmit} />;
    } else if (commonForm.values.kpiType === KpiType.Multiplication) {
      return <MultiplicationKpiInput kpi={kpi} signalMapping={signalMappings} handleSubmit={handleSubmit} />;
    } else if (commonForm.values.kpiType === KpiType.Division) {
      return <DivisionKpiInput kpi={kpi} signalMapping={signalMappings} handleSubmit={handleSubmit} />;
    } else if (commonForm.values.kpiType === KpiType.CII) {
      return <CIIKpiInput kpi={kpi} handleSubmit={handleSubmit} signalMapping={signalMappings} />;
    }

    return <></>;
  }, [commonForm, handleSubmit, kpi, signalMappings]);

  useEffect(() => {
    setMounted(true);

    return () => {};
  }, []);

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Group>
              <ActionIcon variant="subtle" color="blue" onClick={handleBack}>
                <ArrowBack />
              </ActionIcon>
              <Title order={2}>Edit KPI</Title>
            </Group>
            <Divider my="sm" size="md"></Divider>
            <Container size="xl">
              <Stack>
                <CommonEditKpiInput classifications={systemGlobal.classifications} mappingDefs={systemGlobal.mappingDefs} form={commonForm} />
                {loadKpiSpecificInput()}
              </Stack>
            </Container>
          </div>
        )}
      </Transition>
    </>
  );
};

export default EditKpi;
