import * as echarts from "echarts/core";
import waldenTheme from "../chart-theme/custom-walden.json";
import {
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  MarkAreaComponent,
  MarkAreaComponentOption,
  DataZoomComponent,
  DataZoomComponentOption,
} from "echarts/components";
import { BarChart, BarSeriesOption, LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { Button, Divider, Flex, MultiSelect, Select, SelectItem, Stack, Title } from "@mantine/core";
import DateRange from "@mui/icons-material/DateRange";
import { AccountsClient, ChartQueryData, SignalDataClient, VesselsClient } from "../services/WebApiService";
import AuthService from "../services/AuthService";
import { Token } from "../common/Models";
import { axiosInstance } from "../services/AxiosService";
import dayjs from "dayjs";
import { DateService } from "../services/DateService";
import { YAXisOption } from "echarts/types/dist/shared";
import { ChartType } from "../models/dashboard/DashboardModels";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useLocation } from "react-router-dom";
import YtMultipleVesselSignals from "./YtMultipleVesselSignals";
import { useTranslation } from "react-i18next";
import NotificationHelper from "../helpers/NotiHelper";

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  MarkAreaComponent,
  DataZoomComponent,
]);

type EChartsOption = echarts.ComposeOption<
  | ToolboxComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | BarSeriesOption
  | LineSeriesOption
  | MarkAreaComponentOption
  | DataZoomComponentOption
>;

type CartesianAxisPosition = "top" | "bottom" | "left" | "right";

type YtChartForm = {
  vesselId: string;
  dateRange: DateRangePickerValue;
  interval: string;
  signalIds: string[];
  chartType: string;
  showAlarms: string;
  vesselSignals: vesselSignals[];
};

type vesselSignals = {
  vesselId: string;
  signalIds: string[];
};

type DataItem = {
  name: string;
  isWarn: boolean;
  isAlarm: boolean;
  unit: string;
  value: [string, number];
};

type UnitAxisItem = {
  name: string;
  min?: number | undefined;
  max?: number | undefined;
};

const HistoryYt = () => {
  const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);
  const [chart, setChart] = useState<echarts.ECharts>();
  const [intervals, setIntervals] = useState<SelectItem[]>([]);
  const [vessels, setVessels] = useState<SelectItem[]>([]);
  const [signalSelectItems, setSignalSelectItems] = useState<SelectItem[]>([]);
  const [multipleSignalSelectItems, setMultipleSignalSelectItems] = useState<SelectItem[][]>([[]]);
  const [historyType, setHistoryType] = useState("Vessel");

  const { isSystemModeVessel, systemGlobal } = useContext(GlobalContext);

  const { t } = useTranslation();

  const colorThemeRef = useRef(systemGlobal.colorTheme);
  useEffect(() => {
    colorThemeRef.current = systemGlobal.colorTheme;
  }, [systemGlobal.colorTheme]);

  const form = useForm<YtChartForm>({
    initialValues: {
      vesselId: "",
      interval: "30",
      signalIds: [],
      dateRange: [dayjs().startOf("date").subtract(1, "day").toDate(), dayjs().startOf("date").toDate()],
      chartType: ChartType.Line.toString(),
      showAlarms: "true",
      vesselSignals: [{ vesselId: "", signalIds: [] }],
    },
    validate: {
      dateRange: (value?: DateRangePickerValue) => (!value ? "Please set a date range" : null),
    },
  });

  const handleGenerate = async () => {
    try {
      const values = form.values;
      let signalIds = null;

      const interval = Number(values.interval);
      const start = values.dateRange[0];
      const end = values.dateRange[1];
      const signalsId = values.signalIds;

      if (isSystemModeVessel()) {
        if (signalsId.length === 0) {
          NotificationHelper.showError("Please select your signals IDs", "Fill in all the required fields that cannot be blank!");
          return;
        }
      }

      if (!start || !end) {
        NotificationHelper.showError("Please select your x-axis", "Fill in all the required fields that cannot be blank!");
        return;
      }

      if (start && end) {
        const startDate = DateService.toStartOfDay(start);
        const endDate = DateService.toStartOfDay(end);
        const signalDataClient = new SignalDataClient(undefined, axiosInstance);
        let data = null;

        if (historyType === "Fleet") {
          signalIds = values.vesselSignals.map((subArray) => subArray.signalIds.map((x) => Number(x)));
          signalIds = signalIds.flatMap((x) => x);
        } else {
          signalIds = values.signalIds.map((x) => {
            return Number(x);
          });
        }

        data = await signalDataClient.datetime(signalIds, interval, startDate, endDate, historyType);
        createChart(data);
      }
    } catch (error) {
      NotificationHelper.showError("Empty or Error", "There is no data or server is down.");
      return;
    }
  };

  const createChart = (chartData: ChartQueryData[]) => {
    const chartDom = document.getElementById(`chart`);
    if (chart) {
      chart.dispose();
    }

    if (!chartDom) {
      return;
    }
    echarts.registerTheme("walden", waldenTheme);
    const timeChart = echarts.init(chartDom, "walden");

    const units = getUniqueUnitArray(chartData);
    const seriesArray = createSeriesArray(chartData, units);
    const legends = chartData.map((x) => {
      return `${x.displayName} [${x.unitDescription}]`;
    });

    const yAxisArray = createYAxisArray(units);
    const yAxisIndex = [...Array(yAxisArray.length).keys()];

    const eChartsOption: EChartsOption = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
        formatter: (params: any, ticket: string) => {
          let formatter = "";
          formatter = `<p style="font-size: 16px; margin-bottom: 8px">${params[0].axisValueLabel}<p>`;
          let labels = `<div style="display:flex; flex-direction:column; padding-right: 12px">`;
          let values = `<div style="display:flex; flex-direction:column; padding-right: 3px">`;
          let units = `<div style="display:flex; flex-direction:column">`;
          for (let i = 0; i < params.length; i++) {
            const obj = params[i];
            const isAlarm = obj.data.isAlarm;
            const isWarn = obj.data.isWarn;
            labels += `
            <div>
              ${obj.marker} ${obj.seriesName}
            </div>
            `;
            if (isAlarm && form.values.showAlarms === "true") {
              values += `
              <div style="display:flex; justify-content: flex-end">
                <b><p style="margin-right: 5px; color: red">${obj.value[1]}</p></b> 
              </div>
              `;
            } else if (isWarn && form.values.showAlarms === "true") {
              values += `
              <div style="display:flex; justify-content: flex-end; background-color: gray">
                <b><p style="margin-right: 5px; color: yellow">${obj.value[1]}</p></b> 
              </div>
              `;
            } else {
              values += `
              <div style="display:flex; justify-content: flex-end">
                <b><p style="margin-right: 5px">${obj.value[1]}</p></b> 
              </div>
              `;
            }

            units += `
            <div style="display:flex">
              <p>${obj.data.unit}</p>
            </div>
            `;
          }
          values += `</div>`;
          labels += `</div>`;
          units += `</div>`;
          formatter += `
          <div style="display:flex">
            ${labels}
            ${values}
            ${units}
          </div>
          `;
          return formatter;
        },
      },
      dataZoom: [
        {
          type: "inside",
          throttle: 50,
        },
        {
          show: true,
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
        {
          show: true,
          type: "slider",
          yAxisIndex: yAxisIndex,
          filterMode: "none",
        },
      ],

      toolbox: {
        feature: {
          dataView: { show: true, readOnly: false },
          // restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        textStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
        data: legends,
      },
      xAxis: [
        {
          type: "time",
          nameTextStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
          axisLabel: {
            color: systemGlobal.colorTheme === "dark" ? "white" : "black",
          },
        },
      ],
      yAxis: yAxisArray,
      series: seriesArray,
    };

    if (yAxisArray.length !== 0 || seriesArray.length !== 0) {
      timeChart.setOption(eChartsOption);
    } else {
      timeChart.setOption({});
    }
    setChart(timeChart);
  };

  const getUniqueUnitArray = (signals: ChartQueryData[]): UnitAxisItem[] => {
    const units: UnitAxisItem[] = [];
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      const existingUnitIndex = units.findIndex((unit) => unit.name === signal.unitDescription);

      if (existingUnitIndex === -1) {
        units.push({
          name: signal.unitDescription,
          min: signal.viewScaleMin ?? undefined,
          max: signal.viewScaleMax ?? undefined,
        });
      } else {
        const existingUnit = units[existingUnitIndex];
        if (signal.viewScaleMin !== undefined && signal.viewScaleMin !== null) {
          if (existingUnit.min === undefined || signal.viewScaleMin < existingUnit.min) {
            existingUnit.min = signal.viewScaleMin;
          }
        }
        if (signal.viewScaleMax !== undefined && signal.viewScaleMax !== null) {
          if (existingUnit.max === undefined || signal.viewScaleMax > existingUnit.max) {
            existingUnit.max = signal.viewScaleMax;
          }
        }
      }
    }
    return units;
  };

  const calculateIntervalSteps = useCallback((max?: number) => {
    if (max) {
      const roundUpMax = roundUp(max, 1000);
      return roundUpMax / 5;
    }
    return undefined;
  }, []);

  const createYAxisArray = useCallback(
    (units: UnitAxisItem[]) => {
      const result = Array<YAXisOption>();
      let position: CartesianAxisPosition = "left";
      let offset = 0;
      let oddOffset = 0;
      let evenOffset = 0;
      const axisBarOffset = 58;

      for (let i = 0; i < units.length; i++) {
        const unit = units[i];
        if (i % 2 === 0) {
          position = "left";
          if (i !== 0) {
            evenOffset += axisBarOffset;
            offset = evenOffset;
          }
        } else {
          position = "right";
          if (i !== 1) {
            oddOffset += axisBarOffset;
            offset = oddOffset;
          }
        }
        const interval = calculateIntervalSteps(unit.max);
        result.push({
          type: "value",
          nameLocation: "middle",
          nameGap: 42,
          name: unit.name,
          nameTextStyle: { color: colorThemeRef.current === "dark" ? "white" : "black" },
          offset: offset,
          position: position,
          interval: interval,
          alignTicks: true,
          axisLine: {
            show: true,
          },
          axisLabel: {
            formatter: `{value}`,
            color: colorThemeRef.current === "dark" ? "white" : "black",
          },
          max: unit.max,
          min: unit.min,
        });
      }

      return result;
    },
    [calculateIntervalSteps]
  );

  const roundUp = (numToRound: number, multiple: number) => {
    const remainder = numToRound % multiple;
    if (remainder === 0) {
      return numToRound;
    }
    return numToRound + multiple - remainder;
  };

  const createSeriesArray = (signals: ChartQueryData[], units: UnitAxisItem[]) => {
    if (form.values.chartType === ChartType.Bar.toString()) {
      return createBarSeriesArray(signals, units);
    } else {
      return createLineSeriesArray(signals, units);
    }
  };

  const createLineSeriesArray = (signals: ChartQueryData[], units: UnitAxisItem[]) => {
    const result = Array<LineSeriesOption>();
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      for (let j = 0; j < units.length; j++) {
        const unit = units[j];
        if (unit.name === signal.unitDescription) {
          const data = signal.signalData.map(
            (x) =>
              ({
                value: [x.timeStamp.toISOString(), x.value],
                name: x.timeStamp.toString(),
                isAlarm: x.isAlarm,
                isWarn: x.isWarn,
                unit: signal.unitDescription,
              } as DataItem)
          );
          result.push({
            type: "line",
            name: `${signal.displayName} [${signal.unitDescription}]`,
            yAxisIndex: j,
            data: data,
            markArea: getMarkAreaData(data),
          });
        }
      }
    }
    return result;
  };

  const createBarSeriesArray = (signals: ChartQueryData[], units: UnitAxisItem[]) => {
    const result = Array<BarSeriesOption>();
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      for (let j = 0; j < units.length; j++) {
        const unit = units[j];
        if (unit.name === signal.unitDescription) {
          const data = signal.signalData.map(
            (x) =>
              ({
                value: [x.timeStamp.toISOString(), x.value],
                name: x.timeStamp.toString(),
                isAlarm: x.isAlarm,
                isWarn: x.isWarn,
                unit: signal.unitDescription,
              } as DataItem)
          );
          result.push({
            type: "bar",
            name: `${signal.displayName} [${signal.unitDescription}]`,
            yAxisIndex: j,
            data: data,
            markArea: getMarkAreaData(data),
          });
        }
      }
    }
    return result;
  };

  const getMarkAreaData = (data: DataItem[]) => {
    if (form.values.showAlarms === "false") {
      return;
    }

    let color = "rgba(255, 173, 177, 0.25)";
    const markAreaData = [];
    let startAxisValue: string | null = null;
    for (let i = 0; i < data.length; i++) {
      const obj = data[i];
      if (obj.isAlarm || obj.isWarn) {
        startAxisValue ??= obj.value[0];
      } else {
        if (startAxisValue) {
          markAreaData.push([
            {
              xAxis: startAxisValue,
            },
            {
              xAxis: obj.value[0],
            },
          ]);
          startAxisValue = null;
        }
      }
      if (i === data.length - 1 && startAxisValue) {
        markAreaData.push([
          {
            xAxis: startAxisValue,
          },
          {
            xAxis: obj.value[0],
          },
        ]);
      }
    }
    const markArea = {
      itemStyle: {
        color: color,
      },
      data: markAreaData,
    } as MarkAreaComponentOption;
    return markArea;
  };

  useEffect(() => {
    const loadVessels = async (user: Token) => {
      const accountClient = new AccountsClient(undefined, axiosInstance);
      const entities = await accountClient.vessels(user.userid);
      setVessels(
        entities.map((x) => ({
          value: x.id.toString(),
          label: x.name,
        }))
      );

      if (!isSystemModeVessel() && systemGlobal.dashboardVesselId) {
        form.setFieldValue("vesselId", String(systemGlobal.dashboardVesselId));
      } else {
        form.setFieldValue("vesselId", entities[0].id.toString());
      }
    };

    const populateIntervals = () => {
      setIntervals([
        {
          label: "5 Mins",
          value: "5",
        },
        {
          label: "10 Mins",
          value: "10",
        },
        {
          label: "15 Mins",
          value: "15",
        },
        {
          label: "30 Mins",
          value: "30",
        },
        {
          label: "Hourly",
          value: "60",
        },
        {
          label: "Daily",
          value: "1440",
        },
      ] as SelectItem[]);
    };

    const user = AuthService.getCurrentUser();

    if (location.pathname.includes("fleet") && historyType !== "Fleet") {
      setFirstLoad(true);
      setHistoryType("Fleet");
    }

    if (!location.pathname.includes("fleet") && historyType !== "Vessel") {
      setFirstLoad(true);
      setHistoryType("Vessel");
    }

    if (user && firstLoad) {
      setFirstLoad(false);
      loadVessels(user);
      populateIntervals();
    }
    return () => {};
  }, [firstLoad, form, isSystemModeVessel, systemGlobal.dashboardVesselId, location.pathname, historyType]);

  useEffect(() => {
    const loadSignals = async () => {
      const vesselClient = new VesselsClient(undefined, axiosInstance);

      if (isSystemModeVessel()) {
        const signals = await vesselClient.signals(Number(form.values.vesselId));
        setSignalSelectItems(
          signals.map((x) => ({
            value: x.id.toString(),
            label: x.displayName,
          }))
        );
      } else {
        const user = AuthService.getCurrentUser();
        const signals = await vesselClient.signalMappingsGET(Number(form.values.vesselId), null, user?.userid);
        setSignalSelectItems(
          signals.map((x) => ({
            value: x.id.toString(),
            label: x.displayName,
          }))
        );
      }
    };

    if (form.values.vesselId) {
      form.setFieldValue("signalIds", []);
      loadSignals();
    }
    return () => {};
  }, [form.values.vesselId, isSystemModeVessel]);

  useEffect(() => {
    handleGenerate();
    return () => {};
  }, [systemGlobal.colorTheme]);

  return (
    <>
      <Stack>
        <Title order={4}>{t("ytchartht")}</Title>
        <Divider size="sm" mb="sm"></Divider>
      </Stack>
      <Flex align="end" gap="md" mb="lg" mr="md">
        <DateRangePicker label={t("ytchartht_daterange")} icon={<DateRange fontSize="small" />} miw={330} {...form.getInputProps("dateRange")} />
        <Select miw={110} label={t("ytchartht_dateinterval")} data={intervals} {...form.getInputProps("interval")} />
        <Select
          miw={90}
          label={t("ytchartht_charttype")}
          data={Object.keys(ChartType).map((x) => ({
            value: x.toString(),
            label: x.toString(),
          }))}
          {...form.getInputProps("chartType")}
        />
        <Select
          miw={90}
          label={t("ytchartht_showalarms")}
          data={[
            {
              value: "true",
              label: "Yes",
            },
            {
              value: "false",
              label: "No",
            },
          ]}
          {...form.getInputProps("showAlarms")}
        />

        {historyType === "Vessel" && (
          <>
            <Select miw={220} label={t("ytchartht_vessel")} data={vessels} {...form.getInputProps("vesselId")} />
            <MultiSelect
              miw={280}
              label={t("ytchartht_signals")}
              data={signalSelectItems}
              searchable
              nothingFound={t("ytchartht_nothingfound")}
              placeholder={t("ytchartht_selectsignalmulti")}
              {...form.getInputProps("signalIds")}
            />
            <Button onClick={handleGenerate}>{t("ytchartht_generate")}</Button>
          </>
        )}

        {historyType === "Fleet" && <Button onClick={handleGenerate}>{t("ytchartht_generate")}</Button>}
      </Flex>

      {historyType === "Fleet" && (
        <>
          <YtMultipleVesselSignals
            vessels={vessels}
            form={form}
            multipleSignalSelectItems={multipleSignalSelectItems}
            setMultipleSignalSelectItems={setMultipleSignalSelectItems}
          />
        </>
      )}
      <br />
      <div id="chart" style={{ width: "100%", height: "700px" }}></div>
    </>
  );
};
export default HistoryYt;
