import { Button, Center, createStyles, Divider, Navbar, NavLink, Stack, Title } from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { ManualReportLibraryClient, ManualReportLibraryResponse } from "../services/WebApiService";
import { axiosInstance } from "../services/AxiosService";
import AuthService from "../services/AuthService";
import { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  sideNavHeader: {
    "& svg": {
      color: "white",
      backgroundColor: theme.colors[theme.primaryColor][7],
      padding: "5px",
      fontSize: "38px",
      borderRadius: theme.radius.sm,
    },
  },
  sideNavLink: {
    "& svg": {
      color: "white",
      padding: "4px",
      fontSize: "28px",
      borderRadius: theme.radius.sm,
    },
  },
}));

const ManualReportSideNav = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [reports, setReports] = useState<ManualReportLibraryResponse[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    const loadReportLibrary = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        const manualReportLibClient = new ManualReportLibraryClient(undefined, axiosInstance);
        const result = await manualReportLibClient.getList(user.userid);
        setReports(result);
      }
    };

    loadReportLibrary();
    return () => {};
  }, [location]);

  const navigateToCreate = () => {
    navigate(`report/manual-report-library/create`);
  };

  return (
    <Navbar withBorder p="sm" width={{ base: 280 }} style={{ zIndex: 9 }}>
      <Stack>
        <Center>
          <Title order={4}>{t("manualreport_reportlibrary")}</Title>
        </Center>
        <Divider size={"sm"}></Divider>
        <Button leftIcon={<Add />} onClick={() => navigateToCreate()}>
          {t("manualreport_createnewreport")}
        </Button>
        {reports.map((x) => (
          <NavLink
            component={Link}
            to={`/report/manual-report-library/${x.id}/`}
            active={location.pathname.indexOf(`manual-report-library/${x.id}`) !== -1}
            className={classes.sideNavLink}
            label={x.name}
            icon={<SummarizeIcon style={{ backgroundColor: `${x.color}` }} />}
          />
        ))}
      </Stack>
    </Navbar>
  );
};

export default ManualReportSideNav;
