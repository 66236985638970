import { ActionIcon, Button, Grid, Group, Select, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { RemoveCircleOutline } from "@mui/icons-material";
import React from "react";
import { GroupMultiSignalMappingForm } from "../../../common/Models";
import { Mapping } from "../../../models/common/Mapping";
import { MappingGroup } from "../../../models/dashboard/DashboardModels";
import { Classification, MappingDefResponse, FuelType, Property } from "../../../services/WebApiService";

import { useTranslation } from "react-i18next";

type Props = {
  label: string;
  form: UseFormReturnType<GroupMultiSignalMappingForm>;
  classifications: MappingDefResponse[];
  classifProperty: MappingDefResponse[];
};

const GroupSignalMapping = (props: Props) => {
  const indexArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const { t } = useTranslation();

  /**
   * Reload property that coorespondes to the classification.
   * Have to manually refresh because the previous value will not be cleared even though the select list data is updated
   * @param index Index
   */
  const reloadProperty = (groupIndex: number, index: number) => {
    const obj = props.form.values.mappingGroups[groupIndex].mappings[index];
    const filtered = props.classifProperty.filter((x) => x.classification === obj.classification);
    const entity = filtered.find((x) => x.property === obj.property);
    if (!entity) {
      props.form.setFieldValue(`mappingGroups.${groupIndex}.mappings.${index}.property`, filtered[0].property);
    }
  };

  const addMappingItem = (groupIndex: number) => {
    const item: Mapping = {
      classification: Classification.NotApplicable,
      property: Property.NotApplicable,
      fuelType: FuelType.NotApplicable,
      index: "1",
    };
    props.form.insertListItem(`mappingGroups.${groupIndex}.mappings`, item);
  };

  const removeMappingItem = (groupIndex: number, index: number) => {
    if (props.form.values.mappingGroups[groupIndex].mappings.length > 1) {
      props.form.removeListItem(`mappingGroups.${groupIndex}.mappings`, index);
    }
  };

  const addMappingGroup = () => {
    const itemGroup: MappingGroup = {
      mappings: [
        {
          classification: Classification.NotApplicable,
          property: Property.NotApplicable,
          fuelType: FuelType.NotApplicable,
          index: "1",
        },
      ],
      name: "",
    };
    props.form.insertListItem(`mappingGroups`, itemGroup);
  };

  const removeMappingGroup = (groupIndex: number) => {
    if (props.form.values.mappingGroups.length > 1) {
      props.form.removeListItem(`mappingGroups`, groupIndex);
    }
  };

  const loadMappings = (groupIndex: number) => {
    return props.form.values.mappingGroups[groupIndex].mappings.map((item, index) => {
      return (
        <Grid key={`Mapping${groupIndex}-${index}`} columns={14} align="flex-end" className="mapping-group-grid">
          <Grid.Col span={4}>
            <Select
              key={`Classification${index}`}
              data={props.classifications.map((x) => ({ value: x.classification, label: x.classificationDisplayName }))}
              label={t("dashboardwidget_group_classification")}
              required
              searchable
              nothingFound={t("dashboardwidget_group_notfound")}
              {...props.form.getInputProps(`mappingGroups.${groupIndex}.mappings.${index}.classification`)}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              key={`Property${groupIndex}-${index}`}
              data={props.classifProperty
                .filter((x) => x.classification === item.classification)
                .map((x) => ({ value: x.property, label: x.propertyDisplayName }))}
              label={t("dashboardwidget_group_property")}
              required
              searchable
              onChange={reloadProperty(groupIndex, index)}
              nothingFound={t("dashboardwidget_group_notfound")}
              {...props.form.getInputProps(`mappingGroups.${groupIndex}.mappings.${index}.property`)}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              key={`FuelType${groupIndex}-${index}`}
              data={Object.keys(FuelType).map((x) => ({ value: x, label: x }))}
              label={t("dashboardwidget_group_fueltype")}
              required
              searchable
              nothingFound={t("dashboardwidget_group_notfound")}
              {...props.form.getInputProps(`mappingGroups.${groupIndex}.mappings.${index}.fuelType`)}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Select
              key={`Index${groupIndex}-${index}`}
              data={indexArray.map((x) => ({ value: x.toString(), label: x.toString() }))}
              label={t("dashboardwidget_group_index")}
              required
              searchable
              nothingFound={t("dashboardwidget_group_notfound")}
              {...props.form.getInputProps(`mappingGroups.${groupIndex}.mappings.${index}.index`)}
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon key={`RemoveMapping-${groupIndex}-${index}`} color="red" onClick={() => removeMappingItem(groupIndex, index)}>
              <RemoveCircleOutline></RemoveCircleOutline>
            </ActionIcon>
          </Grid.Col>
        </Grid>
      );
    });
  };

  const loadGroup = props.form.values.mappingGroups.map((item, groupIndex) => {
    return (
      <React.Fragment key={`Group-${groupIndex}`}>
        <Grid columns={10} align="flex-end" className="custom-input">
          <Grid.Col span={8}>
            <TextInput
              required
              key={`GroupName-${groupIndex}`}
              label={t("dashboardwidget_group_name")}
              {...props.form.getInputProps(`mappingGroups.${groupIndex}.name`)}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <ActionIcon key={`GroupRemove-${groupIndex}`} onClick={() => removeMappingGroup(groupIndex)} color="red">
              <RemoveCircleOutline></RemoveCircleOutline>
            </ActionIcon>
          </Grid.Col>
        </Grid>
        {loadMappings(groupIndex)}
        <Group className="custom-input">
          <Button variant="light" onClick={() => addMappingItem(groupIndex)}>
            {t("dashboardwidget_group_addmapping")}
          </Button>
        </Group>
      </React.Fragment>
    );
  });

  return (
    <div className="custom-input">
      <Group>
        <label style={{ fontWeight: "500" }}>{props.label}</label>
        <Button variant="light" onClick={() => addMappingGroup()}>
          {t("dashboardwidget_group_addgroup")}
        </Button>
      </Group>

      {loadGroup}
      {/* {() => loadMappings(1)} */}
    </div>
  );
};

export default GroupSignalMapping;
