import { ActionIcon, Button, Grid, Group, Select } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { RemoveCircleOutline } from "@mui/icons-material";
import { MultiSignalMappingForm } from "../../../common/Models";
import { Mapping } from "../../../models/common/Mapping";
import { Classification, MappingDefResponse, FuelType, Property } from "../../../services/WebApiService";

import { useTranslation } from "react-i18next";

type Props = {
  label: string;
  form: UseFormReturnType<MultiSignalMappingForm>;
  classifications: MappingDefResponse[];
  classifProperty: MappingDefResponse[];
  disabled: boolean;
};

const MultiSignalMapping = (props: Props) => {
  const indexArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const { t } = useTranslation();

  /**
   * Reload property that coorespondes to the classification.
   * Have to manually refresh because the previous value will not be cleared even though the select list data is updated
   * @param index Index
   */
  const reloadProperty = (index: number) => {
    const obj = props.form.values.mappings[index];
    const filtered = props.classifProperty.filter((x) => x.classification === obj.classification);
    const entity = filtered.find((x) => x.property === obj.property);
    if (!entity) {
      props.form.setFieldValue(`mappings.${index}.property`, filtered[0].property);
    }
  };

  const addMappingItem = () => {
    const item: Mapping = {
      classification: Classification.NotApplicable,
      property: Property.NotApplicable,
      fuelType: FuelType.NotApplicable,
      index: "1",
    };
    props.form.insertListItem("mappings", item);
  };

  const removeMappingItem = (index: number) => {
    if (props.form.values.mappings.length > 1) {
      props.form.removeListItem("mappings", index);
    }
  };

  const loadMappings = props.form.values.mappings.map((item, index) => {
    return (
      <Grid key={`Mapping${index}`} columns={14} align="flex-end" className="mapping-grid">
        <Grid.Col span={4}>
          <Select
            key={`Classification${index}`}
            data={props.classifications.map((x) => ({ value: x.classification, label: x.classificationDisplayName }))}
            label={t("dashboardwidget_multisignal_classification")}
            required
            searchable
            nothingFound={t("dashboardwidget_multisignal_notfound")}
            disabled={props.disabled}
            {...props.form.getInputProps(`mappings.${index}.classification`)}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            key={`Property${index}`}
            data={props.classifProperty
              .filter((x) => x.classification === item.classification)
              .map((x) => ({ value: x.property, label: x.propertyDisplayName }))}
            label={t("dashboardwidget_multisignal_property")}
            required
            searchable
            onChange={reloadProperty(index)}
            nothingFound={t("dashboardwidget_multisignal_notfound")}
            disabled={props.disabled}
            {...props.form.getInputProps(`mappings.${index}.property`)}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Select
            key={`FuelType${index}`}
            data={Object.keys(FuelType).map((x) => ({ value: x, label: x }))}
            label={t("dashboardwidget_multisignal_fueltype")}
            required
            searchable
            nothingFound={t("dashboardwidget_multisignal_notfound")}
            disabled={props.disabled}
            {...props.form.getInputProps(`mappings.${index}.fuelType`)}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            key={`Index${index}`}
            data={indexArray.map((x) => ({ value: x.toString(), label: x.toString() }))}
            label={t("dashboardwidget_multisignal_index")}
            required
            searchable
            nothingFound={t("dashboardwidget_multisignal_notfound")}
            disabled={props.disabled}
            {...props.form.getInputProps(`mappings.${index}.index`)}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <ActionIcon color="red" onClick={() => removeMappingItem(index)} disabled={props.disabled}>
            <RemoveCircleOutline></RemoveCircleOutline>
          </ActionIcon>
        </Grid.Col>
      </Grid>
    );
  });

  return (
    <div className="custom-input">
      <label style={{ fontWeight: "500" }}>{props.label}</label>
      {loadMappings}
      <Group className="custom-input" style={{ marginTop: "10px" }}>
        <Button disabled={props.disabled} variant="light" onClick={() => addMappingItem()}>
          {t("dashboardwidget_multisignal_addmapping")}
        </Button>
      </Group>
    </div>
  );
};

export default MultiSignalMapping;
