import { Button, Card, Group, NumberInput, Select, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { CIIOption } from "../../../models/kpi/KpiOptionJson";
import { KpiResponse, KpiType, SignalMappingResponse } from "../../../services/WebApiService";
import { useTranslation } from "react-i18next";

type Props = {
  signalMapping: SignalMappingResponse[];
  kpi?: KpiResponse;
  handleSubmit(optionJson: string): void;
};

const CIIKpiInput = (props: Props) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const { t } = useTranslation();

  const form = useForm<CIIOption>({
    initialValues: {
      F_i: 0,
      F_c: 0,
      F_ise: 0,
      F_m: 0,
      DataInterval: "Daily",
      Co2Total: "",
      DisTotal: "",
    },
  });

  const handleSubmit = async () => {
    const optionJson = JSON.stringify(form.values);
    props.handleSubmit(optionJson);
  };

  useEffect(() => {
    const loadForm = () => {
      if (firstLoad && props.kpi) {
        setFirstLoad(false);
        if (props.kpi.kpiType === KpiType.CII) {
          const optionJson = JSON.parse(props.kpi.optionJson) as CIIOption;

          form.setFieldValue("F_i", optionJson.F_i);
          form.setFieldValue("F_c", optionJson.F_c);
          form.setFieldValue("F_ise", optionJson.F_ise);
          form.setFieldValue("F_m", optionJson.F_m);
          form.setFieldValue("DataInterval", optionJson.DataInterval);
          form.setFieldValue("Co2Total", optionJson.Co2Total);
          form.setFieldValue("DisTotal", optionJson.DisTotal);
        }
      }
    };
    loadForm();
    return () => {};
  }, [firstLoad, form, props.kpi]);

  return (
    <Card shadow="sm" p="lg" radius="sm" withBorder>
      <Card.Section withBorder p="lg">
        <Title order={3}>{t("ciikpisignal")}</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Group grow spacing="lg">
          <NumberInput label={t("ciikpisignal_f_i")} placeholder={t("ciikpisignal_f_i")} {...form.getInputProps("F_i")} />
          <NumberInput label={t("ciikpisignal_f_c")} placeholder={t("ciikpisignal_f_c")} {...form.getInputProps("F_c")} />
        </Group>
        <br />
        <Group grow spacing="lg">
          <NumberInput label={t("ciikpisignal_f_ise")} placeholder={t("ciikpisignal_f_ise")} {...form.getInputProps("F_ise")} />
          <NumberInput label={t("ciikpisignal_f_m")} placeholder={t("ciikpisignal_f_m")} {...form.getInputProps("F_m")} />
        </Group>
        <br />
        <Group grow spacing="lg">
          <Select
            withinPortal
            searchable
            nothingFound={t("ciikpisignal_notfound")}
            label={t("ciikpisignal_data_interval")}
            description={t("ciikpisignal_data_intervaldesc")}
            data={["Daily", "Weekly", "Monthly", "Voyage"]}
            defaultValue={"Daily"}
            {...form.getInputProps("DataInterval")}
          />
        </Group>
        <br />
        <Group grow spacing="lg">
          <Select
            withinPortal
            searchable
            nothingFound={t("ciikpisignal_notfound")}
            label={t("ciikpisignal_co2totalsignal")}
            description={t("ciikpisignal_co2totalsignaldesc")}
            data={props.signalMapping.map((x) => ({ label: x.displayName, value: x.id.toString() }))}
            {...form.getInputProps("Co2Total")}
          />

          <Select
            withinPortal
            searchable
            nothingFound={t("ciikpisignal_notfound")}
            label={t("ciikpisignal_distotalsignal")}
            description={t("ciikpisignal_distotalsignaldesc")}
            data={props.signalMapping.map((x) => ({ label: x.displayName, value: x.id.toString() }))}
            {...form.getInputProps("DisTotal")}
          />
        </Group>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Button mt="lg" onClick={handleSubmit}>
          {t("ciikpisignal_save")}
        </Button>
      </Card.Section>
    </Card>
  );
};

export default CIIKpiInput;
