import { Select } from "@mantine/core";
import CountryList from "../json/country-list.json";
import { UseFormReturnType } from "@mantine/form";

type Props = {
  form: UseFormReturnType<any>;
  label: string;
  description: string;
  inputPropsName: string;
};

const CountrySelectInput = (props: Props) => {
  return (
    <>
      <Select
        label={props.label}
        min={0}
        data={CountryList}
        description={props.description}
        searchable
        {...props.form.getInputProps(props.inputPropsName)}
      />
    </>
  );
};

export default CountrySelectInput;
