import { Button, Card, Container, Divider, Group, MultiSelect, Select, Stack, Title, Transition } from "@mantine/core";
import { DateRangePickerValue } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { isDev } from "../helpers/DevDetect";
import NotificationHelper from "../helpers/NotiHelper";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import { FleetClient, FleetOverviewReportRequest, FleetResponse, ReportClient, SelectItemResponse, SignalsClient } from "../services/WebApiService";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type FleetOverviewReportForm = {
  reportDateRange?: DateRangePickerValue;
  fleetId: string;
  signalsName: string[];
};

const FleetOverviewReport = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [fleets, setFleets] = useState<SelectItemResponse[]>([]);
  const [signals, setSignals] = useState<SelectItemResponse[]>([]);
  const { isSystemModeVessel } = useContext(GlobalContext);
  const form = useForm<FleetOverviewReportForm>({
    initialValues: {
      fleetId: "0",
      signalsName: [],
    },
    validate: {
      //reportDateRange: (value?: DateRangePickerValue) => (!value ? "Please set a date range" : null),
    },
  });

  const { t } = useTranslation();

  const handleGenerate = async (values: FleetOverviewReportForm) => {
    setLoading(true);
    const reportClient = new ReportClient(undefined, axiosInstance);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const entity = {
      fleetId: Number(values.fleetId),
      signalsName: values.signalsName,
      // startDateTime: values.reportDateRange[0],
      // endDateTime: values.reportDateRange[1],
      startDateTime: new Date("2024-01-20"),
      endDateTime: new Date("2024-01-21"),
      timeZone: timeZone,
    } as FleetOverviewReportRequest;

    try {
      const fileName = await reportClient.fleetOverviewReport(entity);

      const link = document.createElement("a");
      let url = `/api/`;
      if (isDev()) {
        url = `https://localhost:7254/`;
      }
      link.href = `${url}${fileName}`;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch {
      NotificationHelper.showError("Server error", "An error occurred in generating report");
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);

      if (isSystemModeVessel()) {
        navigate("/error/only-for-onshore");
      }
    }
    return () => {};
  }, [mounted, isSystemModeVessel, navigate]);

  useEffect(() => {
    const loadFleets = async () => {
      const fleetClient = new FleetClient(undefined, axiosInstance);
      const user = AuthService.getCurrentUser();
      if (user) {
        let localFleets: FleetResponse[] = [];

        localFleets = await fleetClient.getAllWithUserFleetUnderCompany(user.userid);

        setFleets(
          localFleets.map(
            (x) =>
              ({
                label: x.name,
                value: x.id.toString(),
              } as SelectItemResponse)
          )
        );
        if (localFleets.length > 0) {
          form.setFieldValue("vesselId", localFleets[0].id.toString());
        }
      }
    };

    if (firstLoad) {
      setFirstLoad(false);
      loadFleets();
    }

    return () => {};
  }, [firstLoad, form, fleets]);

  useEffect(() => {
    const loadSignals = async () => {
      const signalClient = new SignalsClient(undefined, axiosInstance);
      let localSignals: string[] = [];
      localSignals = await signalClient.getSignalNameByFleetDistinct(Number(form.values.fleetId));

      setSignals(
        localSignals.map(
          (x) =>
            ({
              label: x,
              value: x,
            } as SelectItemResponse)
        )
      );
    };

    loadSignals();

    return () => {};
  }, [firstLoad, fleets, form.values.fleetId]);

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <Container size="xs" style={styles}>
          <Group>
            <Title order={3}>{t("fleetoverviewreport")}</Title>
          </Group>
          <Divider my="sm" size="md"></Divider>
          <Card p="md" shadow="md" withBorder>
            <Card.Section p="md">
              <form onSubmit={form.onSubmit((values) => handleGenerate(values))}>
                <Stack>
                  {/* <DateRangePicker withinPortal required label={t("fleetoverviewreport_daterange")} {...form.getInputProps("reportDateRange")} /> */}
                  <Select withinPortal label={t("fleetoverviewreport_fleet")} data={fleets} {...form.getInputProps("fleetId")} />
                  <MultiSelect
                    withinPortal
                    searchable
                    label={t("fleetoverviewreport_signals")}
                    data={signals}
                    {...form.getInputProps("signalsName")}
                  />
                  <Group>
                    <Button type="submit" loading={loading}>
                      {t("fleetoverviewreport_generate")}
                    </Button>
                  </Group>
                </Stack>
              </form>
            </Card.Section>
          </Card>
        </Container>
      )}
    </Transition>
  );
};
export default FleetOverviewReport;
