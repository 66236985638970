import { useCallback, useContext, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Table } from "@mantine/core";
import { DashboardVesselResponse } from "../../../../services/WebApiService";
import { GlobalContext } from "../../../../providers/GlobalContextProvider";

type InfoWindowData = {
  title: string;
  description: string;
};

type Props = {
  vessel: DashboardVesselResponse;
  cog: number;
  coordinate: google.maps.LatLng;
  map: google.maps.Map | undefined;
  infoWindowData: InfoWindowData[];
  markerState: string;
};

const GoogleMapMarker = (props: Props) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();
  const { systemGlobal } = useContext(GlobalContext);

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  const createInfoWindow = useCallback(() => {
    return (
      <>
        <Table striped captionSide="top">
          <caption>{props.vessel.vesselName}</caption>
          <thead>
            <tr>
              <th>Name</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {props.infoWindowData.map((x) => (
              <tr key={x.title}>
                <td>{x.title}</td>
                <td>{x.description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }, [props.infoWindowData, props.vessel.vesselName]);

  useEffect(() => {
    if (marker && props.map) {
      var colorStatus = systemGlobal.colorTheme === "dark" ? "#52afff" : "#003766";

      //warning state
      if (props.markerState === "warn") {
        colorStatus = "#FFC436";
      }
      //alarm state, will be priority alarm first if both got triggered
      if (props.markerState === "alarm") {
        colorStatus = "#B31312";
      }
      marker.setOptions({
        position: props.coordinate,
        map: props.map,
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 4,
          strokeColor: colorStatus,
          rotation: props.cog,
          strokeWeight: 2,
          fillColor: colorStatus,
          fillOpacity: 0.2,
        },
      });
      const content = ReactDOMServer.renderToString(createInfoWindow());
      if (infoWindow) {
        infoWindow.setContent(content);
      } else {
        const localInfoWindow = new google.maps.InfoWindow();
        marker.addListener("click", () => {
          localInfoWindow.open(props.map, marker);
        });
        setInfoWindow(localInfoWindow);
      }
    }
    return () => {};
  }, [marker, props.coordinate, props.cog, props.map, createInfoWindow, infoWindow, props.markerState]);

  return <></>;
};

export default GoogleMapMarker;
