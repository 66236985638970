import { Button, Card, Container, Divider, Group, Select, Stack, Title, Transition } from "@mantine/core";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { isDev } from "../helpers/DevDetect";
import NotificationHelper from "../helpers/NotiHelper";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import { AccountsClient, Co2ReportRequest, ReportClient, SelectItemResponse, VesselResponse } from "../services/WebApiService";
import { useTranslation } from "react-i18next";

type CO2ReportForm = {
  reportDateRange?: DateRangePickerValue;
  vesselId: string;
  reportType: string;
};

const CO2Report = () => {
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [reportTypes, setReportTypes] = useState<SelectItemResponse[]>([]);
  const [vessels, setVessels] = useState<SelectItemResponse[]>([]);
  const form = useForm<CO2ReportForm>({
    initialValues: {
      reportType: "IMO",
      vesselId: "0",
    },
    validate: {
      reportDateRange: (value?: DateRangePickerValue) => (!value ? "Please set a date range" : null),
    },
  });

  const { t } = useTranslation();

  const handleGenerate = async (values: CO2ReportForm) => {
    setLoading(true);
    const reportClient = new ReportClient(undefined, axiosInstance);
    if (values.reportDateRange) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const entity = {
        vesselId: Number(values.vesselId),
        reportType: values.reportType,
        startDateTime: values.reportDateRange[0],
        endDateTime: values.reportDateRange[1],
        timeZone: timeZone,
      } as Co2ReportRequest;

      try {
        const fileName = await reportClient.co2Report(entity);
        if (fileName === "No Voyage") {
          NotificationHelper.showError("Error", "No voyages are found in the time range");
        } else {
          const link = document.createElement("a");
          let url = `/api/`;
          if (isDev()) {
            url = `https://localhost:7254/`;
          }
          link.href = `${url}${fileName}`;
          link.setAttribute("download", `${fileName}`);
          document.body.appendChild(link);
          link.click();
          if (link.parentNode) {
            link.parentNode.removeChild(link);
          }
        }
      } catch {
        NotificationHelper.showError("Server error", "An error occurred in generating report");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  useEffect(() => {
    const populateReportTypes = () => {
      setReportTypes([
        {
          label: "IMO",
          value: "IMO",
        },
        {
          label: "EU",
          value: "EU",
        },
        {
          label: "China",
          value: "China",
        },
      ] as SelectItemResponse[]);
    };

    const loadVessels = async () => {
      const accountClient = new AccountsClient(undefined, axiosInstance);
      const user = AuthService.getCurrentUser();
      if (user) {
        let localVessels: VesselResponse[] = [];
        if (AuthService.isInRoleArray(["Admin", "Supervisor", "CompanyAdmin"])) {
          localVessels = await accountClient.managedVessels(user.userid);
        } else {
          localVessels = await accountClient.vessels(user.userid);
        }
        setVessels(
          localVessels.map(
            (x) =>
              ({
                label: x.name,
                value: x.id.toString(),
              } as SelectItemResponse)
          )
        );
        if (localVessels.length > 0) {
          form.setFieldValue("vesselId", localVessels[0].id.toString());
        }
      }
    };

    if (firstLoad) {
      setFirstLoad(false);
      populateReportTypes();
      loadVessels();
    }

    return () => {};
  }, [firstLoad, form, vessels]);

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <Container size="xs" style={styles}>
          <Group>
            <Title order={3}>{t("co2emissionreport")}</Title>
          </Group>
          <Divider my="sm" size="md"></Divider>
          <Card p="md" shadow="md" withBorder>
            <Card.Section p="md">
              <form onSubmit={form.onSubmit((values) => handleGenerate(values))}>
                <Stack>
                  <DateRangePicker withinPortal required label={t("co2emissionreport_daterange")} {...form.getInputProps("reportDateRange")} />
                  <Select withinPortal label={t("co2emissionreport_vessel")} data={vessels} {...form.getInputProps("vesselId")} />
                  <Select withinPortal label={t("co2emissionreport_reporttype")} data={reportTypes} {...form.getInputProps("reportType")} />
                  <Group>
                    <Button type="submit" loading={loading}>
                      {t("co2emissionreport_generate")}
                    </Button>
                  </Group>
                </Stack>
              </form>
            </Card.Section>
          </Card>
        </Container>
      )}
    </Transition>
  );
};
export default CO2Report;
