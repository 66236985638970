import { Button, Group, NumberInput, Select, SimpleGrid } from "@mantine/core";
import { useForm } from "@mantine/form";
import Responsive from "react-grid-layout";
import React, { useEffect, useState } from "react";
import { MultiSignalMappingForm } from "../../../common/Models";
import { ChartType, TimeChartOptions } from "../../../models/dashboard/DashboardModels";
import { MappingDefResponse, DashboardWidgetResponse, WidgetType } from "../../../services/WebApiService";
import CommonWidgetConfig from "../Common/CommonWidgetConfig";
import MultiSignalMapping from "../Common/MultiSignalMapping";
import GridHelper from "../../../helpers/GridHelper";
import { useTranslation } from "react-i18next";

type Props = {
  isAddWidget: boolean;
  layouts: Responsive.Layout[];
  handleCloseWidgetConfig: () => void;
  addWidget(widget: DashboardWidgetResponse): void;
  updateWidgets(widget: DashboardWidgetResponse): void;
  activeDashboardWidget?: DashboardWidgetResponse;
  classifications: MappingDefResponse[];
  mappings: MappingDefResponse[];
};

const TimeChartConfig = (props: Props) => {
  const [header, setHeader] = useState(props.activeDashboardWidget?.header ?? "");
  const [color, setColor] = useState(props.activeDashboardWidget?.color ?? "#FFFFFF");
  const [firstLoad, setFirstLoad] = useState(true);
  const { t } = useTranslation();

  const form = useForm<TimeChartOptions>({
    initialValues: {
      interval: "30",
      chartType: ChartType.Bar,
      showVesselName: "false",
      maxPoints: 30,
      showAlarm: "true",
      mappings: [],
    },
  });

  const mappingForm = useForm<MultiSignalMappingForm>({
    initialValues: {
      mappings: [],
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const main = form.values;
    const signalMappingForm = mappingForm.values;
    if (main.maxPoints > 60) {
      main.maxPoints = 60;
    }
    const options: TimeChartOptions = {
      chartType: main.chartType,
      interval: main.interval,
      showVesselName: main.showVesselName,
      maxPoints: main.maxPoints,
      showAlarm: main.showAlarm,
      mappings: signalMappingForm.mappings,
    };
    const optionsJson = JSON.stringify(options);
    if (props.isAddWidget) {
      const newId = GridHelper.getNewUniqueId(props.layouts);
      const newObj = {
        header: header,
        color: color,
        optionJson: optionsJson,
        id: newId,
        height: 8,
        width: 10,
        x: 0,
        y: 0,
        widgetType: WidgetType.TimeChart,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.addWidget(entity);
    } else {
      const obj = props.activeDashboardWidget;
      const newObj = {
        ...obj,
        header: header,
        color: color,
        optionJson: optionsJson,
      };
      const entity = new DashboardWidgetResponse();
      entity.init(newObj);
      props.updateWidgets(entity);
    }

    props.handleCloseWidgetConfig();
  };

  useEffect(() => {
    const loadFormValues = () => {
      if (firstLoad && props.activeDashboardWidget) {
        const obj = props.activeDashboardWidget;
        const options: TimeChartOptions = JSON.parse(obj.optionJson);
        if (firstLoad) {
          form.setValues({
            chartType: options.chartType,
            interval: options.interval.toString(),
            mappings: options.mappings,
            maxPoints: options.maxPoints,
            showAlarm: options.showAlarm || "true", // if the database dont have showAlarm, default to true
            showVesselName: options.showVesselName,
          });
          mappingForm.setValues({
            mappings: options.mappings,
          });
          setFirstLoad(false);
        }
      }
    };
    loadFormValues();
    return () => {};
  }, [firstLoad, form, mappingForm, props.activeDashboardWidget]);

  return (
    <>
      <form className="custom" onSubmit={(e) => handleSubmit(e)}>
        <CommonWidgetConfig headerValue={header} setHeader={setHeader} color={color} setColor={setColor} />

        <SimpleGrid cols={3} className="custom-input">
          <Select
            data={[
              { value: "0", label: "Live" },
              { value: "5", label: "5 Mins" },
              { value: "15", label: "15 Mins" },
              { value: "30", label: "30 Mins" },
              { value: "60", label: "Hourly" },
              { value: "1440", label: "Daily" },
            ]}
            label={t("dashboardwidget_timeconfig_interval")}
            required
            {...form.getInputProps("interval")}
          />

          <Select
            data={Object.keys(ChartType).map((x) => ({ value: x, label: x }))}
            label={t("dashboardwidget_timeconfig_charttype")}
            required
            {...form.getInputProps("chartType")}
          />

          <Select
            data={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            label={t("dashboardwidget_timeconfig_showvessel")}
            required
            {...form.getInputProps("showVesselName")}
          />

          <NumberInput required min={5} max={60} label={t("dashboardwidget_timeconfig_maxdatapts")} {...form.getInputProps("maxPoints")} />

          <Select
            data={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            label={t("dashboardwidget_timeconfig_showalarm")}
            required
            {...form.getInputProps("showAlarm")}
          />
        </SimpleGrid>

        <MultiSignalMapping
          label={t("dashboardwidget_timeconfig_mappings")}
          form={mappingForm}
          classifications={props.classifications}
          classifProperty={props.mappings}
          disabled={false}
        />

        <Group style={{ paddingTop: "20px" }}>
          <Button type="submit">{t("dashboardwidget_timeconfig_submit")}</Button>
          <Button onClick={props.handleCloseWidgetConfig} variant="subtle">
            {t("dashboardwidget_timeconfig_cancel")}
          </Button>
        </Group>
      </form>
    </>
  );
};

export default TimeChartConfig;
