import {
  Group,
  Image,
  Text,
  ActionIcon,
  createStyles,
  Paper,
  useMantineTheme,
  Header,
  Title,
  Center,
  Grid,
  Button,
  Modal,
  Tooltip,
} from "@mantine/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import companyLogo from "./../images/corporate-logo-transparent.png";
import companyWhiteLogo from "./../images/corporate-white-logo.png";
import vesselMoving from "./../images/vessel-moving.gif";
import {
  ModeOfTravel,
  OilBarrel,
  Co2,
  QueryStats,
  Search,
  SettingsOutlined,
  PersonOutlineOutlined,
  StackedLineChart,
  DirectionsBoat,
  DarkMode,
  LightMode,
  MultilineChart,
  Insights,
} from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import GlobalStyles from "../common/GlobalStyles";
import TopNavDashboardSubMenu from "./TopNavDashboardSubMenu";
import TopNavNormalSubMenu from "./TopNavNormalSubMenu";
import { NormalGroup, NormalLink } from "../common/Models";
import { SpotlightAction, openSpotlight } from "@mantine/spotlight";
import TopNavSettingSubMenu from "./TopNavSettingSubMenu";
import TopNavAccountSubMenu from "./TopNavAccountSubMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../providers/GlobalContextProvider";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AuthService from "../services/AuthService";
import CreateCompanyForm from "../company/components/CreateForm";
import CreateFleetForm from "../fleet/components/CreateForm";
import { AccountsClient, DashboardResponse, DashboardsClient, FpsPlc, VesselsClient } from "../services/WebApiService";
import { axiosInstance } from "../services/AxiosService";
import NotificationHelper from "../helpers/NotiHelper";
import { useTranslation } from "react-i18next";
import Moment, { utc } from "moment";

const useStyles = createStyles((theme) => ({
  mainLogo: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  navBar: {
    height: GlobalStyles.topNavHeight,
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : "white",
    padding: theme.spacing.xs,
    "&:after": {
      content: "''",
      position: "absolute",
      left: "0px",
      right: "0px",
      top: "100%",
      height: "4px",
      background: "linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px)",
    },
  },
  navMenu: {
    position: "relative",
  },
  navMenuItem: {
    borderRadius: theme.radius.md,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors[theme.primaryColor][9],
      color: "white",
    },
  },
  subNavMenu: {
    width: "325px",
    position: "absolute",
    top: "50px",
    left: "-2px",
    backgroundColor: "white",
    zIndex: 999,
    color: "black",
  },
  settingNavIcon: {
    padding: "5px",
    fontSize: "35px !important",
  },
}));
type MenuLink = {
  id: number;
  name: string;
  isSubMenuOpen: boolean;
  isDashboardSubMenu: boolean;
  isDashboardFleetSubMenu: boolean;
  normalGroups: NormalGroup[];
  footerLinks: NormalLink[];
};

type ModalProps = {
  isOpen: boolean;
  id: number;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  setSpotLightActions: React.Dispatch<React.SetStateAction<SpotlightAction[]>>;
};

const TopNav = ({ setSpotLightActions }: Props) => {
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const [menuLinks, setMenuLinks] = useState<MenuLink[]>([]);
  const [settingIsOpen, setSettingIsOpen] = useState(false);
  const [accountIsOpen, setAccountIsOpen] = useState(false);
  const { systemGlobal, isSystemModeVessel, setColortheme } = useContext(GlobalContext);
  const { classes } = useStyles();
  const location = useLocation();
  const theme = useMantineTheme();
  const [fpsPLC, setFpsPLC] = useState<FpsPlc>();
  const [online, setOnline] = useState(true);

  const { t } = useTranslation();

  const user = AuthService.getCurrentUser();

  //usestate (for company and fleet modal)
  const [modalProps, setmodalProps] = useState<ModalType>({
    createCompany: {
      isOpen: false,
      id: 0,
    },
    createFleet: {
      isOpen: false,
      id: 0,
    },
  });

  //hideModal (for company and fleet modal)
  const hideModal = (type: string) => {
    setmodalProps((prevModalProps) => ({
      ...prevModalProps,
      [type]: {
        ...prevModalProps[type],
        isOpen: false,
      },
    }));
  };

  //companyModal (for company and fleet modal)
  const showModal = (id: number, type: string) => {
    setmodalProps((prevModalProps) => ({
      ...prevModalProps,
      [type]: {
        ...prevModalProps[type],
        isOpen: true,
        id: id,
      },
    }));
  };

  const handleNavMenu = (id: number, settingIsOpen: boolean, accountIsOpen: boolean) => {
    setMenuLinks((prev) =>
      prev.map((x) => {
        if (x.id === id) {
          return { ...x, isSubMenuOpen: !x.isSubMenuOpen };
        } else {
          return { ...x, isSubMenuOpen: false };
        }
      })
    );
    setSettingIsOpen(settingIsOpen);
    setAccountIsOpen(accountIsOpen);
  };

  const getDisplayValue = (id: number) => {
    const isOpen = menuLinks.find((x) => x.id === id)?.isSubMenuOpen ?? false;
    if (isOpen) {
      return "block";
    } else {
      return "none";
    }
  };

  const loadNavLinks = () => {
    const path = location.pathname;
    const user = AuthService.getCurrentUser();
    if (path.indexOf("login") !== -1) {
      return (
        <Grid columns={16}>
          <Grid.Col span={4}>
            <Image src={systemGlobal.colorTheme === "light" ? companyLogo : companyWhiteLogo} height={45} width={145} fit="contain"></Image>
          </Grid.Col>
          <Grid.Col span={8}>
            <Center>
              <Title order={2}>{t("nav_fps")}</Title>
            </Center>
          </Grid.Col>
          <Grid.Col span={4}>
            <Group position="right">
              <Button onClick={() => setOpened(true)}>{t("nav_aboutfps")}</Button>
            </Group>
          </Grid.Col>
        </Grid>
      );
    } else if (user) {
      return (
        <Group position="apart">
          <Group spacing={"xl"} style={{ position: "relative" }}>
            <Image
              src={systemGlobal.colorTheme === "light" ? companyLogo : companyWhiteLogo}
              height={45}
              width={145}
              fit="contain"
              onClick={() => navigate("/dashboard/home")}
              className={classes.mainLogo}
            ></Image>
            {loadMenuLinks()}
          </Group>
          {loadTopNavRightSideItems()}
        </Group>
      );
    } else {
      return <></>;
    }
  };

  const loadMenuLinks = () => {
    const path = location.pathname;
    if (path.indexOf("login") === -1) {
      return menuLinks.map((x) => {
        return (
          <Group key={`MenuLink${x.id}`} className={classes.navMenu}>
            <Group onClick={() => handleNavMenu(x.id, false, false)} className={classes.navMenuItem} p={7} spacing={3}>
              <Text weight={500}>{x.name}</Text>
              <KeyboardArrowDownIcon fontSize="small"></KeyboardArrowDownIcon>
            </Group>

            <Paper
              className={classes.subNavMenu}
              style={{ display: getDisplayValue(x.id), backgroundColor: systemGlobal.colorTheme === "light" ? "white" : "#25262b" }}
              withBorder
              shadow="md"
              p="md"
            >
              {x.isDashboardSubMenu ? (
                <TopNavDashboardSubMenu isOpen={x.isSubMenuOpen} footerLinks={x.footerLinks} isForFleet={false} />
              ) : x.isDashboardFleetSubMenu ? (
                <TopNavDashboardSubMenu isOpen={x.isSubMenuOpen} footerLinks={x.footerLinks} isForFleet={true} />
              ) : (
                <TopNavNormalSubMenu isOpen={x.isSubMenuOpen} normalGroups={x.normalGroups} footerLinks={x.footerLinks} />
              )}
            </Paper>
          </Group>
        );
      });
    }
    return <></>;
  };

  const loadTopNavRightSideItems = () => {
    const path = location.pathname;

    if (path.indexOf("login") === -1) {
      return (
        <Group style={{ position: "relative" }}>
          <Tooltip
            label={
              <div>
                {fpsPLC?.vessel.name}
                <br /> Last Updated: {fpsPLC?.lastPingDate ? Moment(fpsPLC?.lastPingDate).format("YYYY-MM-DD HH:MM:ss") : ""}
              </div>
            }
            position="bottom"
            offset={60}
            withArrow
          >
            <Image
              src={vesselMoving}
              style={{ width: "135px", height: "50px", marginTop: "-120px", filter: online ? "grayscale(0%)" : "grayscale(100%)" }}
            />
          </Tooltip>

          <Button component="a" href="#" className="top-navbar-search" variant="default" onClick={() => openSpotlight()} leftIcon={<Search />}>
            {t("nav_search")}
          </Button>
          <ActionIcon
            color={theme.colors[theme.primaryColor][9]}
            variant="subtle"
            onClick={() => setColortheme(systemGlobal.colorTheme === "dark" ? "light" : "dark")}
          >
            {systemGlobal.colorTheme === "light" && <DarkMode className={classes.settingNavIcon} />}
            {systemGlobal.colorTheme === "dark" && <LightMode className={classes.settingNavIcon} />}
          </ActionIcon>

          <ActionIcon color={theme.colors[theme.primaryColor][9]} variant="subtle" onClick={() => handleNavMenu(-1, !settingIsOpen, false)}>
            <SettingsOutlined className={classes.settingNavIcon} />
          </ActionIcon>
          <TopNavSettingSubMenu isOpen={settingIsOpen} handleNavMenu={handleNavMenu} />

          <ActionIcon color={theme.colors[theme.primaryColor][9]} variant="subtle" onClick={() => handleNavMenu(-1, false, !accountIsOpen)}>
            <PersonOutlineOutlined className={classes.settingNavIcon} />
          </ActionIcon>
          <TopNavAccountSubMenu isOpen={accountIsOpen} />
        </Group>
      );
    }
    return <></>;
  };

  useEffect(() => {
    let reportNormalGroups: NormalLink[] = [];

    reportNormalGroups.push(
      {
        title: t("nav_report_fuelconsreport"),
        url: "/report/fuel-cons",
        icon: <OilBarrel />,
      },
      {
        title: t("nav_report_co2report"),
        url: "/report/co2",
        icon: <Co2 />,
      },
      {
        title: t("nav_report_manualreport"),
        url: "/report/manual-report-library",
        icon: <LibraryBooksIcon />,
      }
    );

    if (!isSystemModeVessel()) {
      reportNormalGroups.push({
        title: t("nav_report_fleetoverviewreport"),
        url: "/report/fleet-overview",
        icon: <DirectionsBoat />,
      });
    }

    const populateMenuLinks = () => {
      const entities: MenuLink[] = [];
      entities.push({
        id: 1,
        name: t("nav_onlinedata"),
        isSubMenuOpen: false,
        isDashboardSubMenu: true,
        isDashboardFleetSubMenu: false,
        normalGroups: [],
        footerLinks: [
          {
            title: t("nav_onlinedata_viewallscreens"),
            url: "/dashboard",
          },
          {
            title: t("nav_onlinedata_createnewscreen"),
            url: "",
          },
        ],
      });

      if (isSystemModeVessel()) {
        entities.push({
          id: 4,
          name: t("nav_voyage"),
          isSubMenuOpen: false,
          isDashboardSubMenu: false,
          isDashboardFleetSubMenu: false,
          normalGroups: [
            {
              category: t("nav_voyage_control"),
              links: [
                // {
                //   title: "Change Cargo",
                //   url: "",
                //   icon: <Scale />
                // },
                {
                  title: t("nav_voyage_settings"),
                  url: `/vessel/${systemGlobal.dashboardVesselId}/voyage-setting`,
                  icon: <ModeOfTravel />,
                },
              ],
            },
          ],
          footerLinks: [
            {
              title: t("nav_voyage_viewallvoyages"),
              url: `/vessel/${systemGlobal.dashboardVesselId}/voyage/manage`,
            },
          ],
        });

        entities.push({
          id: 6,
          name: t("nav_historicaldata"),
          isSubMenuOpen: false,
          isDashboardSubMenu: false,
          isDashboardFleetSubMenu: false,
          normalGroups: [
            {
              category: t("nav_historicaldata_chart"),
              links: [
                {
                  title: t("nav_historicaldata_yxchart"),
                  url: "/history/y-x",
                  icon: <QueryStats />,
                },
                {
                  title: t("nav_historicaldata_ytchart"),
                  url: `/history/y-t/${isSystemModeVessel() ? "vessel" : "fleet"}`,
                  icon: <StackedLineChart />,
                },
                {
                  title: t("nav_historicaldata_ciichart"),
                  url: `/history/cii-chart/${isSystemModeVessel() ? "vessel" : "fleet"}`,
                  icon: <MultilineChart />,
                },
                {
                  title: t("nav_historicaldata_trimopchart"),
                  url: `/history/trimop-chart/${isSystemModeVessel() ? "vessel" : "fleet"}`,
                  icon: <Insights />,
                },
              ],
            },
          ],
          footerLinks: [],
        });
      } else {
        entities.push({
          id: 2,
          name: t("nav_onlinedatafleet"),
          isSubMenuOpen: false,
          isDashboardSubMenu: false,
          isDashboardFleetSubMenu: true,
          normalGroups: [
            {
              category: "Control",
              links: [],
            },
          ],
          footerLinks: [
            {
              title: t("nav_onlinedatafleet_viewallscreens"),
              url: `/dashboard/fleet`,
            },
            {
              title: t("nav_onlinedatafleet_createnewscreen"),
              url: ``,
            },
          ],
        });

        entities.push({
          id: 7,
          name: t("nav_company"),
          isSubMenuOpen: false,
          isDashboardSubMenu: false,
          isDashboardFleetSubMenu: false,
          normalGroups: [
            {
              category: t("nav_company_control"),
              links: [],
            },
          ],
          footerLinks: [
            {
              title: t("nav_company_viewallcompanies"),
              url: `/company`,
            },
          ],
        });

        entities.push({
          id: 8,
          name: t("nav_fleet"),
          isSubMenuOpen: false,
          isDashboardSubMenu: false,
          isDashboardFleetSubMenu: false,
          normalGroups: [
            {
              category: t("nav_fleet_control"),
              links: [],
            },
          ],
          footerLinks: [
            {
              title: t("nav_fleet_viewallfleets"),
              url: `/fleet`,
            },
          ],
        });

        if (AuthService.isInRole("Admin")) {
          const companyEntity = entities.find((entity) => entity.id === 7);
          companyEntity?.footerLinks.push({
            title: t("nav_company_createnewcompany"),
            url: ``,
            isActionLink: true,
            action: () => showModal(0, "createCompany"),
          });

          const fleetEntity = entities.find((entity) => entity.id === 8);
          fleetEntity?.footerLinks.push({
            title: t("nav_fleet_createnewfleet"),
            url: ``,
            isActionLink: true,
            action: () => showModal(0, "createFleet"),
          });
        }

        entities.push({
          id: 6,
          name: t("nav_historicaldata"),
          isSubMenuOpen: false,
          isDashboardSubMenu: false,
          isDashboardFleetSubMenu: false,
          normalGroups: [
            {
              category: t("nav_historicaldata_chart"),
              links: [
                {
                  title: t("nav_historicaldata_yxchart"),
                  url: "/history/y-x",
                  icon: <QueryStats />,
                },
                {
                  title: t("nav_historicaldata_ytchart"),
                  url: `/history/y-t/${isSystemModeVessel() ? "vessel" : "fleet"}`,
                  icon: <StackedLineChart />,
                },
                // {
                //   title: t("nav_historicaldata_ciichart"),
                //   url: `/history/cii-chart/${isSystemModeVessel() ? "vessel" : "fleet"}`,
                //   icon: <MultilineChart />,
                // },
              ],
            },
          ],
          footerLinks: [],
        });
      }

      entities.push({
        id: 5,
        name: t("nav_report"),
        isSubMenuOpen: false,
        isDashboardSubMenu: false,
        isDashboardFleetSubMenu: false,
        normalGroups: [
          {
            category: t("nav_report_generate"),
            links: reportNormalGroups,
          },
        ],
        footerLinks: [
          {
            title: t("nav_report_viewallautomaticreports"),
            url: "/automatic-report",
          },
          {
            title: t("nav_report_createautomaticrereport"),
            url: "/automatic-report/create",
          },
        ],
      });

      setMenuLinks(entities);
    };
    populateMenuLinks();
    return () => {};
  }, [isSystemModeVessel, systemGlobal, systemGlobal.dashboardVesselId, systemGlobal.systemMode, t]);

  useEffect(() => {
    const fpsPLC_date = Moment(fpsPLC?.lastPingDate);
    const utctime = Moment();

    if (utctime.diff(fpsPLC_date, "minutes") >= 30) {
      setOnline(false);
    } else {
      setOnline(true);
    }
  }, [fpsPLC]);

  const loadFPSPlc = async () => {
    const vesselClient = new VesselsClient(undefined, axiosInstance);

    const data = await vesselClient.getFPSPLCByVessel(systemGlobal.dashboardVesselId);
    setFpsPLC(data);
  };

  useEffect(() => {
    //get fpsplc
    loadFPSPlc();
    //default spotlight actions
    const spotLightActionsDefault: SpotlightAction[] = [
      {
        title: t("spotlight_viewallscreens"),
        description: t("spotlight_viewallscreensdesc"),
        onTrigger: () => navigate("/dashboard"),
      },
      {
        title: t("spotlight_viewallautomaticreports"),
        description: t("spotlight_viewallautomaticreportsdesc"),
        onTrigger: () => navigate("/automatic-report"),
      },
      {
        title: t("spotlight_createnewautoreport"),
        description: t("spotlight_createnewautoreportsdesc"),
        onTrigger: () => navigate("/automatic-report/create"),
      },
      {
        title: t("spotlight_yxchart"),
        description: t("spotlight_yxchartdesc"),
        onTrigger: () => navigate("/history/y-x"),
      },
      {
        title: t("spotlight_ytchart"),
        description: t("spotlight_ytchartdesc"),
        onTrigger: () => navigate("/history/y-t"),
      },
      {
        title: t("spotlight_fuelconsreport"),
        description: t("spotlight_fuelconsreportdesc"),
        onTrigger: () => navigate("/report/fuel-cons"),
      },
      {
        title: t("spotlight_co2report"),
        description: t("spotlight_co2reportdesc"),
        onTrigger: () => navigate("/report/co2"),
      },
      {
        title: t("spotlight_manageaccount"),
        description: t("spotlight_manageaccountdesc"),
        onTrigger: () => navigate(`/users/edit/${user?.userid}`),
      },
      {
        title: t("spotlight_viewallusers"),
        description: t("spotlight_viewallusersdesc"),
        onTrigger: () => navigate("/users"),
      },
      {
        title: t("spotlight_licenseimport"),
        description: t("spotlight_licenseimportdesc"),
        onTrigger: () => navigate("/license"),
      },
    ];

    setSpotLightActions(spotLightActionsDefault);

    let spotLightActionsVessels: SpotlightAction[] = [];
    let spotLightActionsDashboards: SpotlightAction[] = [];

    const loadDisplayVessels = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        try {
          const accountClient = new AccountsClient(undefined, axiosInstance);
          const vessels = await accountClient.vessels(user.userid, null, null, null);
          if (vessels.length <= 0) {
            // NotificationHelper.showErrorNoAutoClose(
            //   "Initialization error",
            //   "No vessel found in database. Please wait for database initialization and refresh the page manually"
            // );
          } else {
            spotLightActionsVessels = vessels.map((x) => ({
              title: `${t("spotlight_manage")} ${x.name} (${t("spotlight_vessel")})`,
              description: t("spotlight_managevesseldesc"),
              onTrigger: () => navigate(`/vessel/${x.id}/setting`),
            }));

            setSpotLightActions((spotlight) => [...spotlight, ...spotLightActionsVessels]);
          }
        } catch {
          //NotificationHelper.showError("Connection error", "Cannot get vessels by user");
        }
      }
    };

    const loadDashboards = async () => {
      const dashboardClient = new DashboardsClient(undefined, axiosInstance);
      let data: DashboardResponse[];

      if (isSystemModeVessel()) data = await dashboardClient.getAll("vessel", false);
      else data = await dashboardClient.getAll("fleet", false, systemGlobal.dashboardVesselId);

      spotLightActionsDashboards = data.map((x) => ({
        title: `${t("spotlight_view")} ${x.name} (${t("spotlight_screens")})`,
        description: t("spotlight_viewscreensdesc"),
        onTrigger: () => navigate(`/dashboard/view/${x.id}`),
      }));
      setSpotLightActions((spotlight) => [...spotlight, ...spotLightActionsDashboards]);
    };

    const spotLightActionsForFleet: SpotlightAction[] = [
      {
        title: t("spotlight_viewallcompanies"),
        description: t("spotlight_viewallcompaniesdesc"),
        onTrigger: () => navigate("/company"),
      },
      {
        title: t("spotlight_viewallfleet"),
        description: t("spotlight_viewallfleetdesc"),
        onTrigger: () => navigate("/fleet"),
      },
    ];

    const spotLightActionsForVessel: SpotlightAction[] = [
      {
        title: t("spotlight_voyagesettings"),
        description: t("spotlight_voyagesettingsdesc"),
        onTrigger: () => navigate(`/vessel/${systemGlobal.dashboardVesselId}/voyage-setting`),
      },
      {
        title: t("spotlight_viewallvoyage"),
        description: t("spotlight_viewallvoyagesdesc"),
        onTrigger: () => navigate(`/vessel/${systemGlobal.dashboardVesselId}/voyage/manage`),
      },
    ];

    const path = location.pathname;
    if (path.indexOf("login") === -1) {
      loadDisplayVessels();
      loadDashboards();

      if (isSystemModeVessel()) {
        setSpotLightActions((spotlight) => [...spotlight, ...spotLightActionsForVessel]);
      } else {
        setSpotLightActions((spotlight) => [...spotlight, ...spotLightActionsForFleet]);
      }
    }
  }, [systemGlobal, isSystemModeVessel, setSpotLightActions, user?.userid, location.pathname, navigate, t]);

  useEffect(() => {
    if (location) {
      handleNavMenu(-1, false, false);
    }

    return () => {};
  }, [location]);

  return (
    <>
      <Header className={classes.navBar} height={65}>
        {loadNavLinks()}
        <Modal size="lg" opened={opened} onClose={() => setOpened(false)} title="About FPS Dashboard Software">
          <Text>The Aquametro Oil &amp; Marine Dashboard is a web-based software, as an add-on product of the Fuel Performance System.</Text>
          <Text>
            The flexible software provides a clear overview of the most important parameters kpi’s of the ship&#39;s fuel system. In this way,
            monitoring can ensure that the ship is always operating optimally and efficiently and, if necessary, can take fast action in the event of
            deviations.
          </Text>
          <Text>Furthermore, the possibility of reporting makes it easy to comply with a wide range of regulations.</Text>
          <br />
          <Button component="a" target="_blank" href="https://www.aquametro-oil-marine.com/en/">
            Visit Our Website!
          </Button>
        </Modal>

        <Modal size="lg" opened={modalProps["createCompany"].isOpen} onClose={() => hideModal("createCompany")} title="Create Company">
          <CreateCompanyForm modalProps={modalProps} hideModal={() => hideModal("createCompany")} />
        </Modal>
        <Modal size="lg" opened={modalProps["createFleet"].isOpen} onClose={() => hideModal("createFleet")} title="Create Fleet">
          <CreateFleetForm modalProps={modalProps} hideModal={() => hideModal("createFleet")} />
        </Modal>
      </Header>
    </>
  );
};

export default TopNav;
