import { createStyles, Divider, NavLink, Stack, Title, useMantineTheme } from "@mantine/core";
import {
  ArrowBack,
  AccountTree,
  Category,
  DirectionsBoat,
  ModeOfTravel,
  SettingsInputComponent,
  History,
  DisplaySettings,
  Map,
  Co2,
  Engineering,
  Architecture,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import { EnvService } from "../services/EnvService";
import { VesselResponse, VesselsClient } from "../services/WebApiService";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  sideNavHeader: {
    "& svg": {
      color: "white",
      backgroundColor: theme.colors[theme.primaryColor][7],
      padding: "5px",
      fontSize: "38px",
      borderRadius: theme.radius.sm,
    },
  },
  sideNavLink: {
    "& svg": {
      color: "white",
      // backgroundColor: theme.colors[theme.primaryColor][7],
      padding: "4px",
      fontSize: "28px",
      borderRadius: theme.radius.sm,
    },
  },
}));

type Props = {};

const VesselSideNav = (props: Props) => {
  const theme = useMantineTheme();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEngineer, setIsEngineer] = useState(false);

  const { t } = useTranslation();

  const { classes } = useStyles();
  const [vessel, setVessel] = useState<VesselResponse>();
  useEffect(() => {
    const loadVessel = async () => {
      // useParams hook will not work since this component is not nested under <Route> from react router
      // Manually extract vessel id from url
      const path = location.pathname;
      const vesselId = path.substring(path.indexOf("/", path.indexOf("/") + 1) + 1, path.lastIndexOf("/"));
      if (vesselId) {
        const vesselClient = new VesselsClient(undefined, axiosInstance);
        const vessel = await vesselClient.get(parseInt(vesselId));
        setVessel(vessel);
      }
    };

    const setRoles = () => {
      if (AuthService.isInRoleArray(["Admin", "Supervisor", "CompanyAdmin"])) {
        setIsAdmin(true);
        setIsEngineer(true);
      } else if (AuthService.isInRole("Engineer")) {
        setIsEngineer(true);
      }
    };

    setRoles();
    loadVessel();
    return () => {};
  }, [location.pathname]);

  return (
    <Stack className="vessels-side-nav">
      <Stack align="center" spacing="xs" className={classes.sideNavHeader}>
        <DirectionsBoat />
        <Title order={4}>{vessel?.name ?? ""}</Title>
        <Title order={5}>{t("vesselnav_vesselmanagement")}</Title>
      </Stack>
      <Divider size="sm" />

      {isAdmin ? (
        <>
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/kpi/trim-setting`}
            active={location.pathname === `/vessel/${vessel?.id}/kpi/trim-setting`}
            className={classes.sideNavLink}
            label={t("vesselnav_trimsetting")}
            description={t("vesselnav_trimsettingdesc")}
            icon={<Architecture style={{ backgroundColor: theme.colors["yellow"][4] }} />}
          />
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/signal-mapping`}
            active={location.pathname === `/vessel/${vessel?.id}/signal-mapping`}
            className={classes.sideNavLink}
            label={t("vesselnav_signalmapping")}
            description={t("vesselnav_signalmappingdesc")}
            icon={<AccountTree style={{ backgroundColor: theme.colors["red"][7] }} />}
          />
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/kpi`}
            active={location.pathname === `/vessel/${vessel?.id}/kpi`}
            className={classes.sideNavLink}
            label={t("vesselnav_kpisetting")}
            description={t("vesselnav_kpisettingdesc")}
            icon={<Category style={{ backgroundColor: theme.colors["green"][7] }} />}
          />
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/emission-reduction`}
            active={location.pathname === `/vessel/${vessel?.id}/emission-reduction`}
            className={classes.sideNavLink}
            label={t("vesselnav_emissionreduction")}
            description={t("vesselnav_emissionreductiondesc")}
            icon={<Co2 style={{ backgroundColor: theme.colors["yellow"][7] }} />}
          />
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/co2-operation-mode`}
            active={location.pathname === `/vessel/${vessel?.id}/co2-operation-mode`}
            className={classes.sideNavLink}
            label={t("vesselnav_co2operationmode")}
            description={t("vesselnav_co2operationmodedesc")}
            icon={<Engineering style={{ backgroundColor: theme.colors["teal"][4] }} />}
          />
        </>
      ) : (
        <></>
      )}
      {isEngineer ? (
        <>
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/trip-setting`}
            active={location.pathname === `/vessel/${vessel?.id}/trip-setting`}
            className={classes.sideNavLink}
            label={t("vesselnav_tripsetting")}
            description={t("vesselnav_tripsettingdesc")}
            icon={<ModeOfTravel style={{ backgroundColor: theme.colors["orange"][7] }} />}
          />
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/voyage/manage`}
            active={location.pathname === `/vessel/${vessel?.id}/voyage/manage`}
            className={classes.sideNavLink}
            label={t("vesselnav_managevoyage")}
            description={t("vesselnav_managevoyagedesc")}
            icon={<ModeOfTravel style={{ backgroundColor: theme.colors["blue"][7] }} />}
          />
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/voyage/trip`}
            active={location.pathname === `/vessel/${vessel?.id}/voyage/trip`}
            className={classes.sideNavLink}
            label={t("vesselnav_managetrip")}
            description={t("vesselnav_managetripdesc")}
            icon={<ModeOfTravel style={{ backgroundColor: theme.colors["teal"][7] }} />}
          />
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/manual-input`}
            active={location.pathname === `/vessel/${vessel?.id}/manual-input`}
            className={classes.sideNavLink}
            label={t("vesselnav_manualinput")}
            description={t("vesselnav_manualinputdesc")}
            icon={<SettingsInputComponent style={{ backgroundColor: theme.colors["violet"][7] }} />}
          />
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/report-setting`}
            active={location.pathname === `/vessel/${vessel?.id}/report-setting`}
            className={classes.sideNavLink}
            label={t("vesselnav_reportseting")}
            description={t("vesselnav_reportsettingdesc")}
            icon={<DisplaySettings style={{ backgroundColor: theme.colors["yellow"][5] }} />}
          />
        </>
      ) : (
        <></>
      )}

      {/* <NavLink
        component={Link}
        to={`/vessel/${vessel?.id}/automatic-report`}
        active={location.pathname === ""}
        className={classes.sideNavLink}
        label="Automatic Report"
        description="Edit automatic reports"
        icon={<Summarize style={{ backgroundColor: theme.colors["yellow"][7] }} />} /> */}

      {isAdmin ? (
        <>
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/setting`}
            active={location.pathname === `/vessel/${vessel?.id}/setting`}
            className={classes.sideNavLink}
            label={t("vesselnav_vesselsettings")}
            description={t("vesselnav_vesselsettingsdesc")}
            icon={<DirectionsBoat style={{ backgroundColor: theme.colors["grape"][7] }} />}
          />
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/event-log`}
            active={location.pathname === `/vessel/${vessel?.id}/event-log`}
            className={classes.sideNavLink}
            label={t("vesselnav_eventlog")}
            description={t("vesselnav_eventlogdesc")}
            icon={<History style={{ backgroundColor: theme.colors["gray"][7] }} />}
          />
          <NavLink
            component={Link}
            to={`/vessel/${vessel?.id}/map-signal`}
            active={location.pathname === `/vessel/${vessel?.id}/`}
            className={classes.sideNavLink}
            label={t("vesselnav_mapsignal")}
            description={t("vesselnav_mapsignaldesc")}
            icon={<Map style={{ backgroundColor: theme.colors["cyan"][5] }} />}
          />
        </>
      ) : (
        <></>
      )}
      {!EnvService.isVesselSystemMode ? (
        <NavLink
          component={Link}
          to={`/vessel/`}
          className={classes.sideNavLink}
          label={t("vesselnav_backtovessel")}
          description=""
          icon={<ArrowBack style={{ backgroundColor: theme.colors["dark"][7] }} />}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default VesselSideNav;
