import { Group, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { CommonEditReportSetting } from "../../../../../models/report-setting/CommonEditReportSetting";
import ContrySelectInput from "../../../../../components/CountrySelectInput";
import { useTranslation } from "react-i18next";

type Props = {
  form: UseFormReturnType<CommonEditReportSetting>;
};

const ShipSectionInput = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Group grow spacing="lg" pb="sm" align="end">
        <TextInput
          label={t("vesselreportsetting_ownername")}
          description={t("vesselreportsetting_ownernamedesc")}
          {...props.form.getInputProps("ShipOwnerName")}
        />

        <TextInput
          label={t("vesselreportsetting_owneraddr")}
          description={t("vesselreportsetting_owneraddrdesc")}
          {...props.form.getInputProps("ShipOwnerAddress")}
        />
      </Group>
      <Group grow spacing="lg" pb="md" align="end">
        <TextInput
          label={t("vesselreportsetting_owneraddrcity")}
          description={t("vesselreportsetting_owneraddrcitydesc")}
          {...props.form.getInputProps("ShipOwnerCity")}
        />

        <TextInput
          label={t("vesselreportsetting_owneraddrstate")}
          description={t("vesselreportsetting_owneraddrstatedesc")}
          {...props.form.getInputProps("ShipOwnerState")}
        />

        <TextInput
          label={t("vesselreportsetting_owneraddrpostcode")}
          description={t("vesselreportsetting_owneraddrpostcodedesc")}
          {...props.form.getInputProps("ShipOwnerPostCode")}
        />
      </Group>
      <Group grow spacing="lg" pb="md" align="end">
        <ContrySelectInput
          inputPropsName="ShipOwnerCountry"
          form={props.form}
          label={t("vesselreportsetting_owneraddrcountry")}
          description={t("vesselreportsetting_owneraddrcountrydesc")}
        />
      </Group>
    </>
  );
};

export default ShipSectionInput;
