import { Card, Center, Container, Divider, Image, SimpleGrid, Text, Title, createStyles, useMantineTheme } from "@mantine/core";
import DashboardIcon from "../images/dashboard.jpg";
import HistoricalIcon from "../images/historical-data.jpg";
import VoyageIcon from "../images/voyage.jpg";
import ReportIcon from "../images/report.jpg";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../providers/GlobalContextProvider";

import { useTranslation } from "react-i18next";
import AttitudeIndicatorHelper from "./components/AttitudeIndicator/AttitudeIndicatorHelper";

const useStyles = createStyles((theme) => ({
  card: {
    "&:hover": {
      cursor: "pointer",
      border: "1.5px solid black",
    },
  },
}));

type Props = {};

const Home = (props: Props) => {
  const imageWidth = "650px";
  const imageHeight = "350px";
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { systemGlobal, isSystemModeVessel } = useContext(GlobalContext);
  const { t } = useTranslation();
  const theme = useMantineTheme();

  return (
    <Container size="xl">
      <Title order={2} style={{ color: theme.colors[theme.primaryColor][9] }}>
        {t("home_welcometofps")} {isSystemModeVessel() ? "OnBoard" : "OnShore"}
      </Title>
      <Divider size="md" my="sm"></Divider>

      <SimpleGrid cols={2}>
        <Card p="md" shadow="md" withBorder className={classes.card} onClick={() => navigate("/dashboard")}>
          <Card.Section p="xs">
            <Center>
              <Text>{t("home_onlinedata")}</Text>
            </Center>
          </Card.Section>
          <Card.Section>
            <Image width={imageWidth} height={imageHeight} src={DashboardIcon} />
          </Card.Section>
          <Card.Section p="md">
            <Text>{t("home_onlinedatadesc")}</Text>
          </Card.Section>
        </Card>
        <Card p="md" shadow="md" withBorder className={classes.card} onClick={() => navigate("/history/y-x")}>
          <Card.Section p="xs">
            <Center>
              <Text>{t("home_historicaldata")}</Text>
            </Center>
          </Card.Section>
          <Card.Section>
            <Image width={imageWidth} height={imageHeight} src={HistoricalIcon} />
          </Card.Section>
          <Card.Section p="md">
            <Text>{t("home_historicaldatadesc")}</Text>
          </Card.Section>
        </Card>
        <Card
          p="md"
          shadow="md"
          withBorder
          className={classes.card}
          onClick={() => navigate(`/vessel/${systemGlobal.dashboardVesselId}/voyage/manage`)}
        >
          <Card.Section p="xs">
            <Center>
              <Text>{t("home_voyage")}</Text>
            </Center>
          </Card.Section>
          <Card.Section>
            <Image width={imageWidth} height={imageHeight} src={VoyageIcon} />
          </Card.Section>
          <Card.Section p="md">
            <Text>{t("home_voyagedesc")}</Text>
          </Card.Section>
        </Card>
        <Card p="md" shadow="md" withBorder className={classes.card} onClick={() => navigate("/report/fuel-cons")}>
          <Card.Section p="xs">
            <Center>
              <Text>{t("home_report")}</Text>
            </Center>
          </Card.Section>
          <Card.Section>
            <Image width={imageWidth} height={imageHeight} src={ReportIcon} />
          </Card.Section>
          <Card.Section p="md">
            <Text>{t("home_reportdesc")}</Text>
          </Card.Section>
        </Card>
      </SimpleGrid>
    </Container>
  );
};

export default Home;
