import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import React, { Dispatch, SetStateAction } from "react";
import { FleetClient, FleetResponse } from "../../services/WebApiService";
import { axiosInstance } from "../../services/AxiosService";
import NotificationHelper from "../../helpers/NotiHelper";

type ModalProps = {
  isOpen: boolean;
  id: number;
  fleet?: FleetResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  modalProps: ModalType;
  hideModal: (modalName: string) => void;
  fleet: FleetResponse[];
  setFleet: Dispatch<SetStateAction<FleetResponse[]>>;
};

const DeleteFleetForm = (props: Props) => {
  const handleDeleteFleet = async () => {
    if (props.modalProps["deleteFleet"].id !== 0) {
      try {
        const fleetClient = new FleetClient(undefined, axiosInstance);
        const response = await fleetClient.delete(props.modalProps["deleteFleet"].id);
        if (response) {
          props.setFleet((x) => x.filter((y) => y.id !== props.modalProps["deleteFleet"].id));
          props.hideModal("deleteFleet");
          NotificationHelper.showSuccess("Success", "Fleet has been successfully deleted");
        } else {
          NotificationHelper.showError("Error", "An error has occurred in deleting fleet");
        }
      } catch {
        NotificationHelper.showError("Error", "An error has occurred in deleting fleet");
      }
    }
  };

  return (
    <Modal opened={props.modalProps["deleteFleet"].isOpen} onClose={() => props.hideModal("deleteFleet")} title="Confirmation">
      <Stack>
        <Text>Are you sure you want to delete this fleet? This action is irreversible!</Text>
        <Text>
          <b>Everything link to this fleet will be deleted!</b>
        </Text>
        <Group spacing="lg" align="center" grow>
          <Button variant="outline" color="red" onClick={handleDeleteFleet}>
            Yes
          </Button>
          <Button onClick={() => props.hideModal("deleteFleet")}>No</Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default DeleteFleetForm;
