import { Button, Group, Modal, Stack, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect } from "react";
import { VoyageClient, VoyagePatchRequest, VoyageResponse } from "../../../services/WebApiService";
import { axiosInstance } from "../../../services/AxiosService";
import NotificationHelper from "../../../helpers/NotiHelper";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  setIsOpen(value: boolean): void;
  setVoyage(voyage: VoyagePatchRequest): void;
  voyage?: VoyageResponse | null;
};

type VoyageForm = {
  id: string;
  name: string;
  description: string;
};

const EditVoyageModal = (props: Props) => {
  const { t } = useTranslation();

  const form = useForm<VoyageForm>({
    initialValues: {
      id: "",
      name: "",
      description: "",
    },
  });

  useEffect(() => {
    if (props.voyage && props.voyage.id !== form.values.id) {
      const trip = props.voyage;
      form.setFieldValue("id", props.voyage.id);
      form.setFieldValue("name", trip.name);
      form.setFieldValue("description", trip.description);
    }

    return () => {};
  }, [form, props.voyage]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const voyage = props.voyage;
    const values = form.values;
    if (voyage) {
      const voyageClient = new VoyageClient(undefined, axiosInstance);
      const request = {
        id: values.id,
        name: values.name,
        description: values.description,
      } as VoyagePatchRequest;

      try {
        const result = await voyageClient.patch(request);
        if (result) {
          NotificationHelper.showSuccess("Success", "Voyage updated successfully");
        } else {
          NotificationHelper.showError("Error", "An error occurred in updating voyage");
        }
      } catch {
        NotificationHelper.showError("Error", "An error occurred in updating voyage");
      }
      props.setVoyage(request);
    }
    props.setIsOpen(false);
  };

  return (
    <Modal size="lg" opened={props.isOpen} onClose={() => props.setIsOpen(false)} title={t("editvoyage")}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack spacing={"lg"}>
          <TextInput required label={t("editvoyage_voyagename")} {...form.getInputProps("name")} />
          <Textarea label={t("editvoyage_description")} minRows={3} maxRows={5} {...form.getInputProps("description")} />
          <Group>
            <Button type="submit">{t("editvoyage_save")}</Button>
            <Button onClick={() => props.setIsOpen(false)} variant="subtle">
              {t("editvoyage_cancel")}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default EditVoyageModal;
