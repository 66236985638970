import { MantineThemeOverride, MantineProvider, AppShell } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { SpotlightAction, SpotlightProvider } from "@mantine/spotlight";

import SideNav from "../SideNav/SideNav";
import TopNav from "../TopNav/TopNav";
import { useContext, useState } from "react";
import { GlobalContext } from "./GlobalContextProvider";

type Props = {
  children?: React.ReactNode;
};

const AppMantineProvider = (props: Props) => {
  const { systemGlobal } = useContext(GlobalContext);

  // Theme override options
  const myTheme: MantineThemeOverride = {
    fontFamily: "Roboto",
    fontFamilyMonospace: "Roboto, Courier, monospace",
    headings: { fontFamily: "Roboto" },
    primaryColor: "blue",
    // Respect user choice to disable animation in windows
    respectReducedMotion: false,
  };

  // Spotlight actions
  const [spotLightActions, setSpotLightActions] = useState<SpotlightAction[]>([]);

  return (
    <MantineProvider theme={{ ...myTheme, colorScheme: systemGlobal.colorTheme || "light" }} withGlobalStyles withNormalizeCSS>
      <ModalsProvider>
        <NotificationsProvider>
          <SpotlightProvider actions={spotLightActions} limit={5} searchPlaceholder="Search..." nothingFoundMessage="Nothing found...">
            <AppShell header={<TopNav setSpotLightActions={setSpotLightActions} />} navbar={<SideNav />}>
              {props.children ? props.children : <></>}
            </AppShell>
          </SpotlightProvider>
        </NotificationsProvider>
      </ModalsProvider>
    </MantineProvider>
  );
};
export default AppMantineProvider;
