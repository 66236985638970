import { ActionIcon, Button, Card, Group, Select, Stack, Title, Transition } from "@mantine/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  ManualReportLibraryClient,
  ManualReportLibraryResponse,
  ManualReportMappingType,
  ManualReportRequest,
  ManualReportSignalIdMapping,
  ManualReportSignalMapping,
  ManualReportType,
  ReportClient,
  SignalMappingResponse,
  VesselsClient,
} from "../services/WebApiService";
import { axiosInstance } from "../services/AxiosService";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { GlobalContext } from "../providers/GlobalContextProvider";
import ManualReportSignalMappingInput from "./components/ManualReportSignalMappingInput";
import { useForm } from "@mantine/form";
import { ManualReportLibraryForm } from "../models/manual-report-library/ManualReportLibraryForm";
import ManualReportSignalIdMappingInput from "./components/ManualReportSignalIdMappingInput";
import EditIcon from "@mui/icons-material/Edit";
import { Intervals } from "../services/FormService";
import NotificationHelper from "../helpers/NotiHelper";
import { isDev } from "../helpers/DevDetect";
import { useTranslation } from "react-i18next";

type DateForm = {
  reportDateRange?: DateRangePickerValue;
};

const ViewManualReportLibrary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [firstLoad, setFirstLoad] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [report, setReport] = useState<ManualReportLibraryResponse>();
  const [signals, setSignals] = useState<SignalMappingResponse[]>([]);
  const { reportId } = useParams();
  const { systemGlobal } = useContext(GlobalContext);

  const { t } = useTranslation();

  const form = useForm<ManualReportLibraryForm>({
    initialValues: {
      id: 1,
      dataInterval: "5",
      color: "",
      manualReportMappingType: ManualReportMappingType.Mapping,
      manualReportType: ManualReportType.System,
      name: "",
      signalIdMappings: [],
      signalMappings: [],
    },
  });

  const dateForm = useForm<DateForm>({
    initialValues: {},
    validate: {
      reportDateRange: (value?: DateRangePickerValue) => (!value ? "Please set a date range" : null),
    },
  });

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }

    return () => {};
  }, [mounted]);

  useEffect(() => {
    const loadReport = async () => {
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      const manualReportClient = new ManualReportLibraryClient(undefined, axiosInstance);
      const result = await manualReportClient.get(Number(reportId));
      const signals = await vesselClient.signalMappingsGET(systemGlobal.dashboardVesselId);
      setSignals(signals);
      setReport(result);
      setFirstLoad(true);
    };

    loadReport();
    return () => {};
  }, [location, reportId, systemGlobal.dashboardVesselId]);

  useEffect(() => {
    const loadMappings = () => {
      if (report && firstLoad) {
        setFirstLoad(false);
        if (report.manualReportMappingType === ManualReportMappingType.Signal) {
          const mappings = JSON.parse(report.mappingJson) as ManualReportSignalIdMapping[];
          form.setFieldValue("signalIdMappings", mappings);
        } else if (report.manualReportMappingType === ManualReportMappingType.Mapping) {
          const mappings = JSON.parse(report.mappingJson) as ManualReportSignalMapping[];
          form.setFieldValue("signalMappings", mappings);
        }
        form.setFieldValue("id", report.id);
        form.setFieldValue("dataInterval", report.dataInterval.toString());
        form.setFieldValue("color", report.color);
        form.setFieldValue("manualReportMappingType", report.manualReportMappingType);
        form.setFieldValue("manualReportType", report.manualReportType);
        form.setFieldValue("name", report.name);
        form.setFieldValue("userId", report.userId);
      }
    };

    loadMappings();
    return () => {};
  }, [firstLoad, form, report]);

  const navigateToEdit = () => {
    navigate("edit");
  };

  const handleGenerateReport = async () => {
    const values = form.values;
    const dateValues = dateForm.values;
    for (let i = 0; i < values.signalMappings.length; i++) {
      const obj = values.signalMappings[i];
      obj.order = i + 1;
    }
    for (let i = 0; i < values.signalIdMappings.length; i++) {
      const obj = values.signalIdMappings[i];
      obj.order = i + 1;
    }
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // no duplicates sets
    const uniqueCombinations = new Set();
    let duplicatesExist = false;
    if (values.manualReportMappingType === ManualReportMappingType.Mapping) {
      duplicatesExist = values.signalMappings.some((e) => {
        const combination = `${e.classification}-${e.property}`;
        if (uniqueCombinations.has(combination)) {
          return true;
        }
        uniqueCombinations.add(combination);
        return false;
      });
    } else {
      duplicatesExist = values.signalIdMappings.some((e) => {
        const combination = `${e.signalId}`;
        if (uniqueCombinations.has(combination)) {
          return true;
        }
        uniqueCombinations.add(combination);
        return false;
      });
    }

    if (duplicatesExist) {
      NotificationHelper.showError("Error", "Duplicate signals are not allowed in one report");
      return;
    }

    if (dateValues.reportDateRange) {
      const obj = {
        comment: "",
        dataInterval: Number(values.dataInterval),
        reportName: values.name,
        signalIdMappings: values.signalIdMappings,
        signalMappings: values.signalMappings,
        manualReportMappingType: values.manualReportMappingType,
        startDateTime: dateValues.reportDateRange[0],
        endDateTime: dateValues.reportDateRange[1],
        timeZone: timeZone,
      } as ManualReportRequest;
      const reportClient = new ReportClient(undefined, axiosInstance);
      try {
        var fileName = await reportClient.manualReport(obj);
        if (fileName === "") {
          NotificationHelper.showError("Error", "An error occurred in generating report");
        } else {
          const link = document.createElement("a");
          let url = `/api/`;
          if (isDev()) {
            url = `https://localhost:7254/`;
          }
          link.href = `${url}${fileName}`;
          link.setAttribute("download", `${fileName}`);
          document.body.appendChild(link);
          link.click();
          if (link.parentNode) {
            link.parentNode.removeChild(link);
          }
        }
      } catch {
        NotificationHelper.showError("Error", "An error occurred in generating report");
      }
    }
  };

  const loadSignalMappingInput = (
    <>
      <Card.Section withBorder p="lg">
        <Title order={3}>{t("manualreport_mapsignal")}</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <ManualReportSignalMappingInput classifications={systemGlobal.classifications} mappingDefs={systemGlobal.mappingDefs} form={form} />
        </Stack>
      </Card.Section>
    </>
  );

  const loadSignalIdMappingInput = (
    <>
      <Card.Section withBorder p="lg">
        <Title order={3}>{t("manualreport_mapsignalid")}</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <ManualReportSignalIdMappingInput signals={signals} form={form} />
        </Stack>
      </Card.Section>
    </>
  );

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <div style={styles}>
          <Stack>
            <Card shadow="sm" p="lg" radius="sm" withBorder>
              <Card.Section withBorder p="lg">
                <Group>
                  <Title order={3}>
                    {report?.name} - {report?.manualReportType}
                  </Title>
                  <ActionIcon color="blue" onClick={() => navigateToEdit()}>
                    <EditIcon></EditIcon>
                  </ActionIcon>
                </Group>
              </Card.Section>
              <Card.Section withBorder p="lg">
                <Group grow>
                  <DateRangePicker required label={t("manualreport_daterange")} withinPortal {...dateForm.getInputProps("reportDateRange")} />
                  <Select label={t("manualreport_datainterval")} withinPortal data={Intervals} {...form.getInputProps("dataInterval")} />
                </Group>
              </Card.Section>
            </Card>
            <Card shadow="sm" p="lg" radius="sm" withBorder>
              {report?.manualReportMappingType === ManualReportMappingType.Mapping ? loadSignalMappingInput : loadSignalIdMappingInput}
              <Card.Section withBorder p="lg">
                <Button onClick={() => handleGenerateReport()}>{t("manualreport_generatereport")}</Button>
              </Card.Section>
            </Card>
          </Stack>
        </div>
      )}
    </Transition>
  );
};

export default ViewManualReportLibrary;
