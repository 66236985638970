import { useState } from "react";
import { createContext } from "react";
import CreateDashboardConfig from "../dashboard/CreateDashboardConfig";
import EditDashboardConfig from "../dashboard/EditDashboardConfig";

type DashboardConfigContextType = {
  isOpen: boolean;
  configType: "Create" | "Edit";
  dashboardIdToEdit: number;
  // To reload data from server when config is updated
  reload: boolean;
  dashboardType: "Vessel" | "Fleet";
  setIsOpen(value: boolean): void;
  setConfigType(value: "Create" | "Edit"): void;
  setDashboardIdToEdit(value: number): void;
  setReload(value: boolean): void;
  setDashboardType(value: "Vessel" | "Fleet"): void;
};

export const DashboardConfigContext = createContext<DashboardConfigContextType>({
  isOpen: false,
  configType: "Create",
  dashboardIdToEdit: 0,
  reload: false,
  dashboardType: "Vessel",
  setIsOpen: () => console.error("No function provided"),
  setConfigType: () => console.error("No function provided"),
  setDashboardIdToEdit: () => console.error("No function provided"),
  setReload: () => console.error("No function provided"),
  setDashboardType: () => console.error("No function provided"),
});

type Props = {
  children: React.ReactNode;
};

export const DashboardContextProvider = ({ children }: Props) => {
  const [reload, setReload] = useState(false);
  const [dashboardIdToEdit, setDashboardIdToEdit] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [configType, setConfigType] = useState<"Create" | "Edit">("Create");
  const [dashboardType, setDashboardType] = useState<"Vessel" | "Fleet">("Vessel");

  const loadDashboardConfig = () => {
    if (configType === "Create") {
      return <CreateDashboardConfig />;
    } else if (configType === "Edit") {
      return <EditDashboardConfig />;
    }
  };

  return (
    <DashboardConfigContext.Provider
      value={{
        isOpen,
        configType,
        dashboardIdToEdit,
        reload,
        dashboardType,
        setIsOpen,
        setConfigType,
        setDashboardIdToEdit,
        setReload,
        setDashboardType,
      }}
    >
      <>
        {loadDashboardConfig()}
        {children}
      </>
    </DashboardConfigContext.Provider>
  );
};
