import jwtDecode from "jwt-decode";
import { Token } from "../common/Models";

const clearToken = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  const user = localStorage.getItem("user");
  if (user !== null) {
    return JSON.parse(user) as Token;
  }
  return null;
};

const setCurrentUser = (token: string) => {
  const decodedToken = jwtDecode<Token>(token);
  localStorage.setItem("user", JSON.stringify(decodedToken));
};

const isInRole = (role: string) => {
  const user = getCurrentUser();
  if (user !== null) {
    if (user.role.toLowerCase() === role.toLowerCase()) {
      return true;
    }
  }
  return false;
};

const isInRoleArray = (roleArray: string[]) => {
  const user = getCurrentUser();

  if (user !== null) {
    for (let i = 0; i < roleArray.length; i++) {
      if (user.role.toLowerCase() === roleArray[i].toLowerCase()) {
        return true;
      }
    }
  }
  return false;
};

const isAuthenticated = (): boolean => {
  const user = getCurrentUser();
  return user !== null;
};

const AuthService = {
  getCurrentUser,
  setCurrentUser,
  isInRole,
  isInRoleArray,
  isAuthenticated,
  clearToken,
};

export default AuthService;
