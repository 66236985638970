import { Accordion, Center, createStyles, Divider, Navbar, NavLink, Stack, Title } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CategoriesJson from "../documentation/categories.json";
import { GlobalContext } from "../providers/GlobalContextProvider";
const useStyles = createStyles((theme) => ({
  sideNavHeader: {
    "& svg": {
      color: "white",
      backgroundColor: theme.colors[theme.primaryColor][7],
      padding: "5px",
      fontSize: "38px",
      borderRadius: theme.radius.sm,
    },
  },
  sideNavLink: {
    "& svg": {
      color: "white",
      padding: "4px",
      fontSize: "28px",
      borderRadius: theme.radius.sm,
    },
  },
}));

type CategoriesType = {
  [key: string]: any; // Index signature allowing any other string keys
};

const DocumentationSideNav = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const [categories, setCategories] = useState<CategoriesType>({});
  const [categoriesHeader, setCategoriesHeader] = useState<string[]>([]);
  const { isSystemModeVessel } = useContext(GlobalContext);
  const { t } = useTranslation();

  useEffect(() => {
    const loadReportLibrary = async () => {
      if (isSystemModeVessel()) {
        setCategories(CategoriesJson["onboard"]);
        setCategoriesHeader(Object.keys(CategoriesJson["onboard"]));
      } else {
      }
    };

    loadReportLibrary();
    return () => {};
  }, [location, isSystemModeVessel]);

  return (
    <Navbar withBorder p="sm" width={{ base: 280 }} style={{ zIndex: 9 }}>
      <Stack>
        <Center>
          <Title order={4}>{t("documentationnav_documentation")}</Title>
        </Center>
        <Divider size={"sm"}></Divider>
        <Accordion variant="filled">
          {categoriesHeader.map((x) => {
            let childKey = Object.keys(categories[x]);
            return (
              <Accordion.Item key={x} value={x}>
                <Accordion.Control>{x}</Accordion.Control>
                <Accordion.Panel>
                  {childKey.map((c) => (
                    <NavLink
                      component={Link}
                      to={`/documentation/onboard/${x}/${c}`}
                      active={location.pathname.indexOf(`/documentation/onboard/${x}/${c}`) !== -1}
                      className={classes.sideNavLink}
                      label={categories[x][c].name}
                    />
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>

        {/* {reports.map((x) => (
          <NavLink
            component={Link}
            to={`/documentation/manual-report-library/${x.id}/`}
            active={location.pathname.indexOf(`manual-report-library/${x.id}`) !== -1}
            className={classes.sideNavLink}
            label={x.name}
            icon={<SummarizeIcon style={{ backgroundColor: `${x.color}` }} />}
          />
        ))} */}
      </Stack>
    </Navbar>
  );
};

export default DocumentationSideNav;
