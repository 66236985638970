import { useEffect, useState } from "react";
import { Container, Stack, Transition } from "@mantine/core";
import { useParams } from "react-router-dom";
import { CommonEditReportSetting } from "../../models/report-setting/CommonEditReportSetting";
import { useForm } from "@mantine/form";
import CommonEditReportSettingInput from "./components/common/CommonEditReportSettingInput";
import { ReportSettingReponse, VesselsClient } from "../../services/WebApiService";
import { axiosInstance } from "../../services/AxiosService";

const EditReportSetting = () => {
  const { vesselId } = useParams();
  const [mounted, setMounted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [, setReportSettings] = useState<ReportSettingReponse[]>([]);

  const commonForm = useForm<CommonEditReportSetting>({
    initialValues: {
      ShipOwnerName: "",
      ShipOwnerAddress: "",
      ShipOwnerCity: "",
      ShipOwnerState: "",
      ShipOwnerPostCode: "",
      ShipOwnerCountry: "",
      ContactPersonName: "",
      ContactPersonAddress: "",
      ContactPersonCity: "",
      ContactPersonState: "",
      ContactPersonPostCode: "",
      ContactPersonCountry: "",
      ContactPersonPhone: "",
      ContactPersonEmail: "",
      VerifierName: "",
      VerifierAddressLineOne: "",
      VerifierAddressLineTwo: "",
      VerifierCity: "",
      VerifierState: "",
      VerifierPostCode: "",
      VerifierCountry: "",
      VerifierAccreditationNumber: "",
      VerifierStatement: "",
      EmissionSource: [
        {
          Name: "",
          SerialNum: "",
          YearInstalled: "",
          RatedPower: "",
          Sfoc: "",
          ChosenMethod: "",
        },
      ],
      MonitoringMethod: [
        {
          Name: "",
          ApproachUsed: "",
          Value: "",
        },
      ],
    },
    validate: {
      ContactPersonEmail: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  useEffect(() => {
    setMounted(true);
    const loadReportSetting = async () => {
      if (firstLoad && vesselId) {
        setFirstLoad(false);
        const vesselsClient = new VesselsClient(undefined, axiosInstance);
        const data = await vesselsClient.reportSettingsAll(parseInt(vesselId));
        setReportSettings(data);

        data.forEach((obj) => {
          const key = obj.name;
          const value = obj.value;

          if (key === "EmissionSource" || key === "MonitoringMethod") {
            commonForm.setFieldValue(key, JSON.parse(value));
          } else {
            commonForm.setFieldValue(key, value);
          }
        });
      }
    };
    loadReportSetting();
    return () => {};
  }, [firstLoad, commonForm, vesselId]);

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Container size="xl">
              <Stack>
                <CommonEditReportSettingInput form={commonForm} />
              </Stack>
            </Container>
          </div>
        )}
      </Transition>
    </>
  );
};

export default EditReportSetting;
