import { Badge, createStyles, Group, Paper, Stack, Text, Avatar } from "@mantine/core";
import { Login, Logout, Person, Share } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NotificationHelper from "../helpers/NotiHelper";
import AuthService from "../services/AuthService";
import { getBaseUrl } from "../services/BaseUrlService";
import { AuthClient } from "../services/WebApiService";
import PersonImage from "../images/person.png";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../providers/GlobalContextProvider";

const useStyles = createStyles((theme) => ({
  settingSubMenu: {
    position: "absolute",
    width: "350px",
    top: "50px",
    right: "0px",
    zIndex: 99,
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : "white",
  },
  settingSubMenuItem: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : "#f4f5f7",
    },
    "& svg": {
      color: "white",
      backgroundColor: theme.colors[theme.primaryColor][7],
      padding: "4px",
      fontSize: "32px",
      borderRadius: theme.radius.sm,
    },
  },
  titleColor: { color: theme.colorScheme === "light" ? "black" : "white" },
  badgeBackground: { backgroundColor: theme.colorScheme === "light" ? "white" : "#25262b" },
  descColor: { color: theme.colorScheme === "light" ? "grey" : "#b9babc" },
}));

type SettingGroup = {
  key: string;
  category: string;
  links: SettingLink[];
};

type SettingLink = {
  title: string;
  url: string;
  icon: JSX.Element;
  description: string;
};

type Props = {
  isOpen: boolean;
};

const TopNavAccountSubMenu = (props: Props) => {
  const { systemGlobal, isSystemModeVessel } = useContext(GlobalContext);
  const [settingGroups, setSettingGroups] = useState<SettingGroup[]>([]);
  const location = useLocation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleLogout = async () => {
    try {
      const baseUrl = getBaseUrl();
      const authClient = new AuthClient(baseUrl);
      await authClient.logout();
    } catch {
      NotificationHelper.showError("Connection error", "Error connecting to server");
    }
    AuthService.clearToken();
    NotificationHelper.showSuccess("Logout Success", "");
    navigate("/account/login");
  };

  const loadLogoutBtn = () => {
    const user = AuthService.getCurrentUser();
    if (user) {
      return (
        <Stack className={classes.settingSubMenuItem}>
          <Group p="xs" onClick={handleLogout}>
            <Logout />
            <Stack spacing={0}>
              <Text className={classes.titleColor}>{t("usermenu_logout")}</Text>
              <Text size="xs" lineClamp={2} className={classes.descColor}>
                {t("usermenu_logoutdesc")}
              </Text>
            </Stack>
          </Group>
        </Stack>
      );
    }
    return <></>;
  };

  useEffect(() => {
    const loadSettings = () => {
      const user = AuthService.getCurrentUser();
      const result: SettingGroup[] = [];
      if (user && location) {
        result.push({
          key: "usermenu",
          category: t("usermenu_account"),
          links: [
            {
              title: t("usermenu_manageaccount"),
              description: t("usermenu_manageaccountdesc"),
              icon: <Person />,
              url: `/users/edit/${user.userid}`,
            },
          ],
        });
      } else {
        result.push({
          key: "login",
          category: t("usermenu_login"),
          links: [
            {
              title: t("usermenu_login"),
              description: "",
              icon: <Login />,
              url: "/account/login",
            },
          ],
        });
      }

      if (!isSystemModeVessel()) {
        const user = AuthService.getCurrentUser();
        result
          .find((x) => x.key === "usermenu")
          ?.links.push({
            title: t("usermenu_thirdpartyapikey"),
            description: t("usermenu_thirdpartyapikeydesc"),
            icon: <Share />,
            url: `/users/apikey/${user?.userid}`,
          });
      }

      setSettingGroups(result);
    };
    loadSettings();
    return () => {};
  }, [location, t]);

  const displayAccountSettings = settingGroups.map((x) => {
    return (
      <React.Fragment key={x.category}>
        <Group pl="xs" pt="xs">
          <Badge size="lg" color="dark" className={classes.badgeBackground} style={{ padding: "1px" }}>
            {x.category}
          </Badge>
        </Group>
        {x.links.map((y) => {
          return (
            <Stack key={y.title}>
              <Link to={y.url} className={classes.settingSubMenuItem}>
                <Group p="xs">
                  {y.icon}
                  <Stack spacing={0}>
                    <Text className={classes.titleColor}>{y.title}</Text>
                    <Text size="xs" lineClamp={2} className={classes.descColor}>
                      {y.description}
                    </Text>
                  </Stack>
                </Group>
              </Link>
            </Stack>
          );
        })}
        {loadLogoutBtn()}
      </React.Fragment>
    );
  });

  const getDisplayValue = () => {
    if (props.isOpen) {
      return "block";
    }
    return "none";
  };

  return (
    <Paper className={classes.settingSubMenu} withBorder shadow="md" p="md" style={{ display: getDisplayValue() }}>
      <Stack align="stretch" spacing={0}>
        <Group pl="xs">
          <Avatar src={PersonImage} radius={40} />
          <div>
            <Text size={"sm"} weight={500}>
              {AuthService.getCurrentUser()!.nameid}
            </Text>
            <Text size={"sm"} color="dimmed">
              {AuthService.getCurrentUser()!.role}
            </Text>
          </div>
        </Group>
        {displayAccountSettings}
      </Stack>
    </Paper>
  );
};

export default TopNavAccountSubMenu;
