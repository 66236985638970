import {
  Button,
  Group,
  Modal,
  Select,
  SelectItem,
  Stack,
  Switch,
  TextInput,
  Text,
  Divider,
  Title,
  Grid,
  ActionIcon,
  Overlay,
  FileButton,
  Checkbox,
} from "@mantine/core";
import { useForm, UseFormReturnType } from "@mantine/form";
import { Business, CancelOutlined, DirectionsBoat, Person } from "@mui/icons-material";
import { useContext, useEffect, useRef, useState } from "react";
import { Token } from "../../../common/Models";
import NotificationHelper from "../../../helpers/NotiHelper";
import { DashboardConfigForm } from "../../../models/dashboard/DashboardConfigForm";
import AuthService from "../../../services/AuthService";
import { axiosInstance } from "../../../services/AxiosService";
import { EnvService } from "../../../services/EnvService";
import {
  AccountsClient,
  DashboardPermissionRequest,
  DashboardPermissionType,
  DashboardResponse,
  DashboardsClient,
} from "../../../services/WebApiService";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../providers/GlobalContextProvider";

type Props = {
  dashboardIdToEdit?: number;
  title: string;
  buttonLabel: string;
  isOpen: boolean;
  showCloneOption?: boolean;
  dashboardType: string;
  setIsOpen(value: boolean): void;
  setReload(value: boolean): void;
  handleSubmit(
    e: React.FormEvent<HTMLFormElement>,
    form: UseFormReturnType<DashboardConfigForm>,
    file: File | null,
    dashboard: DashboardResponse,
    dashboardPermissions: DashboardPermissionRequest[]
  ): void;
};
const DashboardConfig = (props: Props) => {
  // Set Editable value to true for create dashboard config
  const [dashboard, setDashboard] = useState<DashboardResponse>({
    isEditable: true,
  } as DashboardResponse);
  const [dashboardPermissions, setDashboardPermissions] = useState<DashboardPermissionRequest[]>([]);
  const [viewerTypes, setViewerTypes] = useState<string[]>([]);
  const [cloneDashboards, setCloneDashboards] = useState<SelectItem[]>([]);
  const [, setCompanies] = useState<SelectItem[]>([]);
  const [fleets, setFleets] = useState<SelectItem[]>([]);
  const [vessels, setVessels] = useState<SelectItem[]>([]);
  const [, setUsers] = useState<SelectItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const resetRef = useRef<() => void>(null);
  const form = useForm<DashboardConfigForm>({
    initialValues: {
      vesselId: "",
      fleetId: "",
      companyId: "",
      name: "",
      category: "",
      viewerType: "Private",
      isSystemDashboard: false,
      isFleetDashboard: false,
      viewEnabled: true,
      toClone: false,
    },
  });

  const { systemGlobal } = useContext(GlobalContext);

  const { t } = useTranslation();

  const clearFile = () => {
    setFile(null);
    resetRef.current?.();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (dashboard) {
      setLoading(true);
      e.preventDefault();
      props.handleSubmit(e, form, file, dashboard, dashboardPermissions);
      setFile(null);
      props.setIsOpen(false);
      setLoading(false);
    }
  };

  const loadViewerComponents = () => {
    if (form.values.viewerType === "Private") {
      return (
        <Text c="dimmed" fz="sm" pl="sm">
          Only you
        </Text>
      );
    } else if (form.values.viewerType === "Public") {
      return (
        <Text c="dimmed" fz="sm" pl="sm">
          Everyone
        </Text>
      );
    } else if (form.values.viewerType === "Vessel") {
      return <Select data={vessels} {...form.getInputProps("vesselId")} />;
    } else if (form.values.viewerType === "Fleet") {
      return <Select data={fleets} {...form.getInputProps("fleetId")} />;
    }
  };

  const handleRemoveBackgroundImage = async () => {
    if (props.dashboardIdToEdit) {
      const dashboardClient = new DashboardsClient(undefined, axiosInstance);
      const response = await dashboardClient.backgroundImageDELETE(props.dashboardIdToEdit);
      if (response && response.status === 200) {
        props.setReload(true);
        NotificationHelper.showSuccess("Success", "Background image removed successfully");
      }
    }
  };

  const handleAddDashboardPermission = () => {
    const viewerType = form.values.viewerType;
    if (viewerType === "Private") {
      // Clear the array
      setDashboardPermissions([]);
    } else if (viewerType === "Vessel") {
      const obj = {
        dashboardPermissionType: DashboardPermissionType.Vessel,
        mappedId: Number(form.values.vesselId),
      } as DashboardPermissionRequest;
      if (
        !dashboardPermissions.find((x) => x.dashboardPermissionType === DashboardPermissionType.Vessel && x.mappedId === Number(form.values.vesselId))
      ) {
        setDashboardPermissions((current) => [...current, obj]);
      }
    }
  };

  const loadDashboardPermissions = () => {
    if (dashboardPermissions.length === 0) {
      return (
        <Group grow position="apart">
          <Group>
            <Person></Person>
            <Text>Private</Text>
          </Group>
          <Group position="right"></Group>
        </Group>
      );
    }
    let result: JSX.Element[] = [];
    for (let i = 0; i < dashboardPermissions.length; i++) {
      const obj = dashboardPermissions[i];
      let icon = <DirectionsBoat></DirectionsBoat>;
      if (obj.dashboardPermissionType === DashboardPermissionType.Fleet) {
        // Change icon for fleet
      } else if (obj.dashboardPermissionType === DashboardPermissionType.Company) {
        icon = <Business></Business>;
      }
      result.push(
        <Group grow position="apart">
          <Group>
            {icon}
            <Text>{vessels.find((x) => x.value === obj.mappedId.toString())!.label}</Text>
          </Group>
          <Group position="right">
            <ActionIcon
              color="red"
              onClick={() =>
                setDashboardPermissions((current) =>
                  current.filter((x) => x.dashboardPermissionType !== DashboardPermissionType.Vessel && x.mappedId !== obj.mappedId)
                )
              }
            >
              <CancelOutlined></CancelOutlined>
            </ActionIcon>
          </Group>
        </Group>
      );
    }
    return result;
  };

  useEffect(() => {
    if (props.dashboardType) {
      form.setFieldValue("isFleetDashboard", props.dashboardType === "Fleet");
    }

    const loadUsers = async (accountClient: AccountsClient, user: Token) => {
      if (AuthService.isInRole("Admin")) {
        const dbUsers = await accountClient.managedUsers();
        setUsers(
          dbUsers.map((x) => ({
            value: x.id,
            label: x.username,
          }))
        );

        form.setFieldValue("userId", dbUsers[0].id);
      } else {
        setUsers((current) => [...current, { value: user.userid, label: user.nameid }]);
        form.setFieldValue("userId", user.userid);
      }
    };

    const loadVessels = async (accountClient: AccountsClient, user: Token) => {
      const data = await accountClient.vessels(user.userid, undefined, undefined, undefined);
      setVessels(
        data.map(
          (x) =>
            ({
              value: x.id.toString(),
              label: x.name,
            } as SelectItem)
        )
      );
      form.setFieldValue("vesselId", data[0].id.toString());
    };

    const populateViewerTypes = (systemMode: string) => {
      const result: string[] = [];
      result.push("Private");
      if (systemMode === "Vessel") {
        result.push(DashboardPermissionType.Vessel);
      } else {
        if (AuthService.isInRoleArray(["Admin", "CompanyAdmin"])) {
          Object.keys(DashboardPermissionType).forEach((x) => {
            result.push(x);
          });
        }
      }
      setViewerTypes(result);
    };

    const loadFleets = async (accountClient: AccountsClient, user: Token) => {
      const fleetVessels = await accountClient.fleetVessels(user.userid);
      if (fleetVessels.length > 0) {
        form.setFieldValue("fleetId", fleetVessels[0].fleetId.toString());
      }
      setFleets(
        fleetVessels.map((x) => ({
          value: x.fleetId.toString(),
          label: x.fleetName,
        }))
      );
    };

    const loadCompanies = async (accountClient: AccountsClient, user: Token) => {
      const companies = await accountClient.companies(user.userid);
      if (companies.length > 0) {
        form.setFieldValue("companyId", companies[0].id.toString());
      }
      setCompanies(
        companies.map((x) => ({
          value: x.id.toString(),
          label: x.name,
        }))
      );
    };

    const loadViewableDashboards = async () => {
      try {
        const dashboardClient = new DashboardsClient(undefined, axiosInstance);
        const data = await dashboardClient.getAll("vessel", true);
        if (data.length > 0) {
          form.setFieldValue("cloneDashboardId", data[0].id.toString());
        }
        setCloneDashboards(
          data.map((x) => ({
            value: x.id.toString(),
            label: x.name,
          }))
        );
      } catch {
        NotificationHelper.showError("Connection error", "An error occurred in getting dashboards");
      }
    };

    if (props.isOpen) {
      const accountClient = new AccountsClient(undefined, axiosInstance);
      const user = AuthService.getCurrentUser();
      const systemMode = EnvService.systemMode;
      if (user && systemMode) {
        loadUsers(accountClient, user);
        loadVessels(accountClient, user);
        loadFleets(accountClient, user);
        loadCompanies(accountClient, user);
        loadViewableDashboards();
        populateViewerTypes(systemMode);
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  useEffect(() => {
    const loadDashboard = async () => {
      if (props.dashboardIdToEdit) {
        const dashboardClient = new DashboardsClient(undefined, axiosInstance);
        const localDashboard = await dashboardClient.get(props.dashboardIdToEdit);
        setDashboard(localDashboard);
        setDashboardPermissions(localDashboard.dashboardPermissions);
        form.setFieldValue("name", localDashboard.name);
        form.setFieldValue("category", localDashboard.category);
        form.setFieldValue("isSystemDashboard", localDashboard.isSystemDashboard);
        form.setFieldValue("viewEnabled", localDashboard.viewEnabled);
        form.setFieldValue("isFleetDashboard", localDashboard.isFleetDashboard);
      }
    };
    loadDashboard();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardIdToEdit]);

  return (
    <Modal
      size="lg"
      className={`${systemGlobal.colorTheme === "dark" ? "md-black" : ""}`}
      opened={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      title={props.title}
    >
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack spacing="lg">
          <Group grow>
            <TextInput
              label={t("cunewscreen_screenname")}
              required
              withAsterisk
              disabled={!dashboard?.isEditable ?? true}
              {...form.getInputProps("name")}
            />
            <TextInput
              label={t("cunewscreen_category")}
              disabled={!dashboard?.isEditable ?? true}
              required
              onInput={(e) => {
                e.currentTarget.value = e.currentTarget.value.toUpperCase();
              }}
              {...form.getInputProps("category")}
            />
          </Group>
          <Group grow>
            <Switch
              label={t("cunewscreen_systemdashboard")}
              description={t("cunewscreen_systemdashboarddesc")}
              disabled={!AuthService.isInRoleArray(["Admin", "Supervisor"])}
              {...form.getInputProps("isSystemDashboard", { type: "checkbox" })}
            />
            <Switch
              label={t("cunewscreen_viewenabled")}
              description={t("cunewscreen_viewenableddesc")}
              {...form.getInputProps("viewEnabled", { type: "checkbox" })}
            />
            <Switch
              label={t("cunewscreen_fleetdashboard")}
              description={t("cunewscreen_fleetdashboarddesc")}
              disabled
              {...form.getInputProps("isFleetDashboard", { type: "checkbox" })}
            />
          </Group>
          <Group align="end" grow>
            {props.showCloneOption ? (
              <Checkbox label={t("cunewscreen_clonewidgets")} {...form.getInputProps("toClone", { type: "checkbox" })} />
            ) : (
              <></>
            )}
            <Select
              disabled={!form.values.toClone}
              label={t("cunewscreen_dashboardclonefrm")}
              data={cloneDashboards}
              {...form.getInputProps("cloneDashboardId")}
            />
          </Group>

          <Title order={5}>{t("cunewscreen_backgroundimg")}</Title>
          <Group>
            <FileButton resetRef={resetRef} onChange={setFile} accept="image/png,image/jpeg">
              {(props) => (
                <Button disabled={!dashboard?.isEditable ?? true} variant="light" {...props}>
                  {t("cunewscreen_attachimg")}
                </Button>
              )}
            </FileButton>
            <Button disabled={!file} color="red" onClick={clearFile}>
              {t("cunewscreen_resetattachment")}
            </Button>
            {props.dashboardIdToEdit ? (
              <Button disabled={!dashboard?.isEditable ?? true} variant="light" onClick={handleRemoveBackgroundImage}>
                {t("cunewscreen_removecurimg")}
              </Button>
            ) : (
              <></>
            )}
          </Group>
          {file && (
            <Group>
              <Text size="sm" align="center">
                {t("cunewscreen_pickedfile")}: {file.name}
              </Text>
            </Group>
          )}
          <Stack sx={{ position: "relative" }}>
            {!dashboard?.isEditable && <Overlay opacity={0.8} />}
            {form.values.isSystemDashboard && <Overlay opacity={0.8} />}
            <Title order={5}>{t("cunewscreen_viewers")}</Title>
            <Grid grow gutter="xs" align="flex-end" justify="flex-end">
              <Grid.Col span={2}>
                <Select data={viewerTypes} {...form.getInputProps("viewerType")} />
              </Grid.Col>
              <Grid.Col span={5}>{loadViewerComponents()}</Grid.Col>
              <Grid.Col span={1}>
                <Group position="right">
                  <Button variant="light" onClick={handleAddDashboardPermission}>
                    {t("cunewscreen_add")}
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
            <Divider size="sm"></Divider>
            <Stack>{loadDashboardPermissions()}</Stack>
          </Stack>
          <Group position="right">
            <Button variant="outline" onClick={() => props.setIsOpen(false)}>
              {t("cunewscreen_cancel")}
            </Button>
            <Button type="submit" loading={loading}>
              {props.buttonLabel}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};
export default DashboardConfig;
