import { Group, Select } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AccountsClient, AppUserResponse, DataTransferApiKeyResponse } from "../../../services/WebApiService";
import { axiosInstance } from "../../../services/AxiosService";
import { useTranslation } from "react-i18next";
import { DataTransferApiKey } from "../../../models/datatransfer-apikey/DataTranferApiKey";

type Props = {
  form: UseFormReturnType<DataTransferApiKey>;
  isEditMode: boolean;
  user: AppUserResponse[];
  setUser: Dispatch<SetStateAction<AppUserResponse[]>>;
  dataTransferId: number;
  dataTransferApiKeys: DataTransferApiKeyResponse[];
  dataTransfer?: DataTransferApiKeyResponse;
};

const DataTransferFormInputs = (props: Props) => {
  const [firstLoad, setFirstLoad] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    const loadUser = async () => {
      const accountsClient = new AccountsClient(undefined, axiosInstance);

      let data = await accountsClient.managedUsers();

      //filtered already assigned users
      data = data.filter((item) => {
        return !props.dataTransferApiKeys.some((removeItem) => removeItem.appUserId === item.id);
      });

      props.setUser(data);
    };

    if (firstLoad) {
      loadUser();
      setFirstLoad(false);
    }
  }, [props, firstLoad]);

  return (
    <>
      <Group grow spacing="lg" pb="lg" align="end">
        <Select
          withinPortal
          searchable
          label={t("cudatatransferapikey_user")}
          data={props.user.map((x) => ({ value: x.id, label: `${x.username} (${x.email === "" ? "no email" : x.email})` }))}
          {...props.form.getInputProps(`AppUserId`)}
        />
      </Group>
    </>
  );
};

export default DataTransferFormInputs;
