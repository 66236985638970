import { useState, useContext } from "react";
import Responsive from "react-grid-layout";
import WidgetBar from "./components/Common/WidgetBar";
import WidgetConfig from "./components/Common/WidgetConfig";
import { MappingDefResponse, CurveResponse, DashboardWidgetResponse, WidgetType } from "../services/WebApiService";
import { DashboardConfigContext } from "../providers/DashboardConfigProvider";

type Props = {
  isConfigHidden: boolean;
  isWidgetBarHidden: boolean;
  activeDashboardWidget: DashboardWidgetResponse | undefined;
  widgets: DashboardWidgetResponse[];
  curves: CurveResponse[];
  classifications: MappingDefResponse[];
  mappings: MappingDefResponse[];
  layouts: Responsive.Layout[];
  dashboardType: "Fleet" | "Vessel";
  updateWidgets(widget: DashboardWidgetResponse): void;
  addWidget(widget: DashboardWidgetResponse): void;
  handleOpenWidgetConfig(): void;
  handleCloseWidgetConfig(): void;
  handleCloseWidgetBar(): void;
};

const EditDashboard = (props: Props) => {
  const [isAddWidget, setIsAddWidget] = useState(false);
  const [widgetTypeForAdd, setWidgetTypeForAdd] = useState<WidgetType>();
  return (
    <>
      <WidgetConfig
        layouts={props.layouts}
        isAddWidget={isAddWidget}
        widgetTypeForAdd={widgetTypeForAdd}
        setIsAddWidget={setIsAddWidget}
        setWidgetTypeForAdd={setWidgetTypeForAdd}
        classifications={props.classifications}
        mappings={props.mappings}
        isHidden={props.isConfigHidden}
        handleCloseWidgetConfig={() => props.handleCloseWidgetConfig()}
        activeDashboardWidget={props.activeDashboardWidget}
        updateWidgets={props.updateWidgets}
        addWidget={props.addWidget}
        curves={props.curves}
      />
      <WidgetBar
        setIsAddWidget={setIsAddWidget}
        setWidgetTypeForAdd={setWidgetTypeForAdd}
        handleCloseWidgetBar={props.handleCloseWidgetBar}
        handleOpenWidgetConfig={props.handleOpenWidgetConfig}
        isWidgetBarHidden={props.isWidgetBarHidden}
        dashboardType={props.dashboardType}
      />
    </>
  );
};

export default EditDashboard;
