import { Divider, Group, Title, Transition } from "@mantine/core";
import { useEffect, useState } from "react";
import EditReportSetting from "./Edit";
import { useTranslation } from "react-i18next";

const IndexReportSetting = () => {
  const [mounted, setMounted] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Group>
              <Title order={2}>{t("vesselreportsetting")}</Title>
            </Group>
            <Divider my="sm" size="md"></Divider>
          </div>
        )}
      </Transition>
      <EditReportSetting />
    </>
  );
};

export default IndexReportSetting;
