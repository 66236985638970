import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import React, { Dispatch, SetStateAction } from "react";
import { EmissionReductionClient, EmissionReductionResponse } from "../../services/WebApiService";
import { axiosInstance } from "../../services/AxiosService";
import NotificationHelper from "../../helpers/NotiHelper";

type ModalProps = {
  isOpen: boolean;
  id: number;
  emissionReduction?: EmissionReductionResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  modalProps: ModalType;
  hideModal: (modalName: string) => void;
  emissionReduction: EmissionReductionResponse[];
  setEmissionReduction: Dispatch<SetStateAction<EmissionReductionResponse[]>>;
};
const DeleteEmissionReductionForm = (props: Props) => {
  const handleDeleteEmissionReduction = async () => {
    if (props.modalProps["deleteEmissionReduction"].id !== 0) {
      try {
        const emissionReductionClient = new EmissionReductionClient(undefined, axiosInstance);
        const response = await emissionReductionClient.delete(props.modalProps["deleteEmissionReduction"].id);
        if (response) {
          props.setEmissionReduction((x) => x.filter((y) => y.id !== props.modalProps["deleteEmissionReduction"].id));
          props.hideModal("deleteEmissionReduction");
          NotificationHelper.showSuccess("Success", "Emission Reduction has been successfully deleted");
        } else {
          NotificationHelper.showError("Error", "An error has occurred in deleting emission reduction");
        }
      } catch {
        NotificationHelper.showError("Error", "An error has occurred in deleting emission reduction");
      }
    }
  };

  return (
    <Modal
      opened={props.modalProps["deleteEmissionReduction"].isOpen}
      onClose={() => props.hideModal("deleteEmissionReduction")}
      title="Confirmation"
    >
      <Stack>
        <Text>Are you sure you want to delete this emission reduction? This action is irreversible!</Text>
        <Text>
          <b>Everything link to this emission reduction will be deleted!</b>
        </Text>
        <Group spacing="lg" align="center" grow>
          <Button variant="outline" color="red" onClick={handleDeleteEmissionReduction}>
            Yes
          </Button>
          <Button onClick={() => props.hideModal("deleteEmissionReduction")}>No</Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default DeleteEmissionReductionForm;
