import { ActionIcon, Button, Card, Container, Divider, Group, Stack, Title, Transition } from "@mantine/core";
import { Add, ArrowBack, RemoveCircleOutline, Save } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotificationHelper from "../../helpers/NotiHelper";
import { SettingsTrimOpAndPercentageRequest, TrimOpAngleClient, TrimOpAnglePercentageClient } from "../../services/WebApiService";
import { axiosInstance } from "../../services/AxiosService";
import { GlobalContext } from "../../providers/GlobalContextProvider";
import { useTranslation } from "react-i18next";
import { CellChange, Column, DefaultCellTypes, NumberCell, ReactGrid, Row } from "@silevis/reactgrid";
import { v4 as uuidv4 } from "uuid";
import "@silevis/reactgrid/styles.css";

type Props = {};

interface PowerSpeedDraft {
  speed: number;
  draftMiddle: number;
  power: number[];
}

interface TrimOpData {
  trimAngle: number[];
  powerSpeedDraft: PowerSpeedDraft[];
}

interface PowerSpeedDraftPercentage {
  speed: number;
  draftMiddle: number;
  percentage: number[];
}

interface TrimOpDataPercentage {
  trimAngle: number[];
  powerSpeedDraftPercentage: PowerSpeedDraftPercentage[];
}

const TrimSetting = (props: Props) => {
  const [mounted, setMounted] = useState(false);
  const { systemGlobal } = useContext(GlobalContext);

  const [trimOp, setTrimOp] = useState<TrimOpData>({
    trimAngle: [],
    powerSpeedDraft: [],
  });

  const [trimOpPercentage, setTrimOpPercentage] = useState<TrimOpDataPercentage>({
    trimAngle: [],
    powerSpeedDraftPercentage: [],
  });

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { vesselId } = useParams();

  const handleBack = () => {
    navigate(`/vessel/${vesselId}/kpi`);
  };

  const [cols, setCols] = useState<Column[]>([{ columnId: "delete_button" }, { columnId: "speed" }, { columnId: "draftmiddle" }]);
  const [rows, setRows] = useState<Row[]>([
    {
      rowId: "delete_button",
      cells: [
        {
          type: "text",
          text: "",
          style: {
            background: "#ffffff",
            color: "black",
            border: { left: { color: "white" }, right: { color: "white" }, bottom: { color: "white" }, top: { color: "white" } },
          },
          nonEditable: true,
        },
        {
          type: "text",
          text: "",
          style: {
            background: "#ffffff",
            color: "black",
            border: { left: { color: "white" }, right: { color: "white" }, bottom: { color: "white" }, top: { color: "white" } },
          },
          nonEditable: true,
        },
      ],
    },
    {
      rowId: "heading",
      cells: [
        {
          type: "text",
          text: "",
          style: {
            background: "#ffffff",
            color: "black",
            border: { left: { color: "white" }, right: { color: "white" }, bottom: { color: "white" }, top: { color: "white" } },
          },
          nonEditable: true,
        },
        { type: "text", text: "Speed", style: { background: "#cdcdcd", color: "black" }, nonEditable: true },
        { type: "text", text: "Draft Middle", style: { background: "#cdcdcd", color: "black" }, nonEditable: true },
      ],
    },
  ]);

  const [colsPercentage, setColsPercentage] = useState<Column[]>([{ columnId: "speed" }, { columnId: "draftmiddle" }]);
  const [rowsPercentage, setRowsPercentage] = useState<Row[]>([
    {
      rowId: "heading",
      cells: [
        { type: "text", text: "Speed", style: { background: "#cdcdcd", color: "black" }, nonEditable: true },
        { type: "text", text: "Draft Middle", style: { background: "#cdcdcd", color: "black" }, nonEditable: true },
      ],
    },
  ]);

  useEffect(() => {
    const loadTrim = async () => {
      const trimOpAngleClient = new TrimOpAngleClient(undefined, axiosInstance);
      const data = await trimOpAngleClient.getAllByPowerDraft();

      setTrimOp(() => {
        let tempData: TrimOpData = { trimAngle: [], powerSpeedDraft: [] };

        tempData.trimAngle = data.trimAngle;

        data.powerSpeedDraft.forEach((el) => {
          tempData.powerSpeedDraft.push({
            speed: el.speed,
            draftMiddle: el.draftMiddle,
            power: el.power.split(",").map((item) => parseFloat(item.trim())),
          });
        });

        return tempData;
      });
    };

    const loadTrimPercentage = async () => {
      const trimOpAnglePercentageClient = new TrimOpAnglePercentageClient(undefined, axiosInstance);
      const data = await trimOpAnglePercentageClient.getAllByPowerDraftPercentage();

      setTrimOpPercentage(() => {
        let tempData: TrimOpDataPercentage = { trimAngle: [], powerSpeedDraftPercentage: [] };

        tempData.trimAngle = data.trimAngle;

        data.powerSpeedDraftPercentage.forEach((el) => {
          tempData.powerSpeedDraftPercentage.push({
            speed: el.speed,
            draftMiddle: el.draftMiddle,
            percentage: el.percentage.split(",").map((item) => parseFloat(item.trim())),
          });
        });

        return tempData;
      });
    };

    loadTrim();
    loadTrimPercentage();
    return () => {};
  }, []);

  useEffect(() => {
    setMounted(true);

    let temp_col: any[] = [];

    setCols([{ columnId: "delete_button", width: 100 }, { columnId: "speed" }, { columnId: "draftmiddle" }]);
    setRows([
      {
        rowId: "delete_button",
        cells: [
          {
            type: "text",
            text: "",
            style: {
              background: "#ffffff",
              color: "black",
              border: { left: { color: "white" }, right: { color: "white" }, bottom: { color: "white" }, top: { color: "white" } },
            },
            nonEditable: true,
          },
          {
            type: "text",
            text: "",
            style: {
              background: "#ffffff",
              color: "black",
              border: { left: { color: "white" }, right: { color: "white" }, bottom: { color: "white" }, top: { color: "white" } },
            },
            nonEditable: true,
          },
          {
            type: "text",
            text: "",
            style: {
              background: "#ffffff",
              color: "black",
              border: { left: { color: "white" }, right: { color: "white" }, bottom: { color: "white" }, top: { color: "white" } },
            },
            nonEditable: true,
          },
        ],
      },
      {
        rowId: "heading",
        cells: [
          {
            type: "text",
            text: "",
            style: {
              background: "#ffffff",
              color: "black",
              border: { left: { color: "white" }, right: { color: "white" }, bottom: { color: "white" }, top: { color: "white" } },
            },
            nonEditable: true,
          },
          { type: "text", text: "Speed", style: { background: "#cdcdcd", color: "black" }, nonEditable: true },
          { type: "text", text: "Draft Middle", style: { background: "#cdcdcd", color: "black" }, nonEditable: true },
        ],
      },
    ]);

    setColsPercentage([{ columnId: "speed" }, { columnId: "draftmiddle" }]);
    setRowsPercentage([
      {
        rowId: "heading",
        cells: [
          { type: "text", text: "Speed", style: { background: "#cdcdcd", color: "black" }, nonEditable: true },
          { type: "text", text: "Draft Middle", style: { background: "#cdcdcd", color: "black" }, nonEditable: true },
        ],
      },
    ]);

    setCols((prev) => {
      let newArray = [...prev];

      trimOp.trimAngle.forEach(() => {
        newArray.push({ columnId: uuidv4() });
      });

      temp_col = newArray;

      return newArray;
    });

    setColsPercentage((prev) => {
      let newArray = [...prev];

      trimOp.trimAngle.forEach(() => {
        newArray.push({ columnId: uuidv4() });
      });

      return newArray;
    });

    setRows((prev) => {
      let newArray = [...prev];

      let delete_row = newArray.filter((x) => x.rowId === "delete_button");

      trimOp.trimAngle.forEach((el, i) => {
        delete_row[0].cells.push({
          type: "text",
          text: "",
          style: {
            background: "#ffffff",
            color: "black",
            border: { left: { color: "white" }, right: { color: "white" }, bottom: { color: "white" }, top: { color: "white" } },
          },
          renderer: (cell) => {
            return (
              <Button
                style={{ width: "100%", background: "white", color: "red" }}
                onClick={() => {
                  delCol(temp_col[i + 3].columnId as string);
                }}
              >
                <RemoveCircleOutline />
              </Button>
            );
          },
          nonEditable: true,
        });
      });

      let header = newArray.filter((x) => x.rowId === "heading");

      trimOp.trimAngle.forEach((element) => {
        header[0].cells.push({ type: "number", value: element, style: { background: "#e5e4e4", color: "black" } });
      });

      trimOp.powerSpeedDraft.forEach((element) => {
        let rowId = uuidv4();

        newArray.push({
          rowId: rowId,
          cells: [
            {
              type: "text",
              text: "",
              style: {
                background: "#ffffff",
                color: "black",
                border: { left: { color: "white" }, right: { color: "white" }, bottom: { color: "white" }, top: { color: "white" } },
              },
              renderer: () => {
                return (
                  <Button
                    style={{ width: "100%", background: "white", color: "red" }}
                    onClick={() => {
                      delRow(rowId);
                    }}
                  >
                    <RemoveCircleOutline />
                  </Button>
                );
              },
              nonEditable: true,
            },
            { type: "number", value: element.speed, style: { background: "#e5e4e4", color: "black" } },
            { type: "number", value: element.draftMiddle, style: { background: "#e5e4e4", color: "black" } },
            ...element.power.map<DefaultCellTypes>((el) => {
              return { type: "number", value: el };
            }),
          ],
        });
      });

      return newArray;
    });

    setRowsPercentage((prev) => {
      let newArray = [...prev];

      let header = newArray.filter((x) => x.rowId === "heading");

      trimOp.trimAngle.forEach((element) => {
        header[0].cells.push({ type: "number", value: element, style: { background: "#e5e4e4", color: "black" }, nonEditable: true });
      });

      trimOpPercentage.powerSpeedDraftPercentage.forEach((element) => {
        let rowId = uuidv4();

        newArray.push({
          rowId: rowId,
          cells: [
            { type: "number", value: element.speed, style: { background: "#e5e4e4", color: "black" }, nonEditable: true },
            { type: "number", value: element.draftMiddle, style: { background: "#e5e4e4", color: "black" }, nonEditable: true },
            ...element.percentage.map<DefaultCellTypes>((el) => {
              return { type: "number", value: el };
            }),
          ],
        });
      });

      return newArray;
    });

    return () => {};
  }, [trimOp, trimOpPercentage]);

  const pushCol = () => {
    setTrimOp((prev) => {
      let newArray = { ...prev };

      newArray.trimAngle.push(0);
      newArray.powerSpeedDraft.forEach((element) => {
        element.power.push(0);
      });

      return newArray;
    });

    setTrimOpPercentage((prev) => {
      let newArray = { ...prev };

      newArray.trimAngle.push(0);
      newArray.powerSpeedDraftPercentage.forEach((element) => {
        element.percentage.push(0);
      });

      return newArray;
    });
  };

  const pushRow = () => {
    setTrimOp((prev) => {
      let newArray = { ...prev };

      newArray.powerSpeedDraft.push({
        speed: 0,
        draftMiddle: 0,
        power: [
          ...newArray.trimAngle.map((el) => {
            return 0;
          }),
        ],
      });

      return newArray;
    });

    setTrimOpPercentage((prev) => {
      let newArray = { ...prev };

      newArray.powerSpeedDraftPercentage.push({
        speed: 0,
        draftMiddle: 0,
        percentage: [
          ...newArray.trimAngle.map((el) => {
            return 0;
          }),
        ],
      });

      return newArray;
    });
  };

  const delRow = (rowId: string) => {
    setRows((prev) => {
      let no_rows = prev.findIndex((x) => x.rowId === rowId);
      setTrimOp((prev) => {
        let newArray = { ...prev };

        newArray.powerSpeedDraft.splice(no_rows - 2, 1);

        return newArray;
      });
      return [...prev];
    });
  };

  const delCol = (colId: string) => {
    setCols((prev) => {
      let no_cols = prev.findIndex((x) => x.columnId === colId);

      setTrimOp((prev) => {
        let newArray = { ...prev };

        newArray.trimAngle.splice(no_cols - 3, 1);

        newArray.powerSpeedDraft.forEach((x) => {
          return x.power.splice(no_cols - 3, 1);
        });

        return newArray;
      });

      setTrimOpPercentage((prev) => {
        let newArray = { ...prev };

        newArray.trimAngle.splice(no_cols - 3, 1);

        newArray.powerSpeedDraftPercentage.forEach((x) => {
          return x.percentage.splice(no_cols - 3, 1);
        });

        return newArray;
      });
      return [...prev];
    });
  };

  const handleGridChanges = (changes: CellChange[]) => {
    if (changes.length === 1) {
      let curr_selection = changes[0];

      if (curr_selection.columnId === "speed") {
        let no_rows = rows.findIndex((x) => x.rowId === curr_selection.rowId);
        setTrimOp((prev) => {
          let newArray = { ...prev };

          let newCell: NumberCell = curr_selection.newCell as NumberCell;

          newArray.powerSpeedDraft[no_rows - 2].speed = newCell.value;

          return newArray;
        });

        setTrimOpPercentage((prev) => {
          let newArray = { ...prev };

          let newCell: NumberCell = curr_selection.newCell as NumberCell;

          newArray.powerSpeedDraftPercentage[no_rows - 2].speed = newCell.value;

          return newArray;
        });
      } else if (curr_selection.columnId === "draftmiddle") {
        let no_rows = rows.findIndex((x) => x.rowId === curr_selection.rowId);
        setTrimOp((prev) => {
          let newArray = { ...prev };

          let newCell: NumberCell = curr_selection.newCell as NumberCell;

          newArray.powerSpeedDraft[no_rows - 2].draftMiddle = newCell.value;

          return newArray;
        });

        setTrimOpPercentage((prev) => {
          let newArray = { ...prev };

          let newCell: NumberCell = curr_selection.newCell as NumberCell;

          newArray.powerSpeedDraftPercentage[no_rows - 2].draftMiddle = newCell.value;

          return newArray;
        });
      } else if (curr_selection.rowId === "heading" && curr_selection.columnId !== "draftmiddle" && curr_selection.columnId !== "speed") {
        let no_col = cols.findIndex((x) => x.columnId === curr_selection.columnId);
        setTrimOp((prev) => {
          let newArray = { ...prev };

          let newCell: NumberCell = curr_selection.newCell as NumberCell;

          newArray.trimAngle[no_col - 3] = newCell.value;

          return newArray;
        });

        setTrimOpPercentage((prev) => {
          let newArray = { ...prev };

          let newCell: NumberCell = curr_selection.newCell as NumberCell;

          newArray.trimAngle[no_col - 3] = newCell.value;

          return newArray;
        });
      } else {
        let no_rows = rows.findIndex((x) => x.rowId === curr_selection.rowId);
        let no_col = cols.findIndex((x) => x.columnId === curr_selection.columnId);

        setTrimOp((prev) => {
          let newArray = { ...prev };

          let newCell: NumberCell = curr_selection.newCell as NumberCell;

          newArray.powerSpeedDraft[no_rows - 2].power[no_col - 3] = newCell.value;

          return newArray;
        });
      }
    }
  };

  const handlePercentageGridChanges = (changes: CellChange[]) => {
    if (changes.length === 1) {
      let curr_selection = changes[0];

      let no_rows = rowsPercentage.findIndex((x) => x.rowId === curr_selection.rowId);
      let no_col = colsPercentage.findIndex((x) => x.columnId === curr_selection.columnId);

      setTrimOpPercentage((prev) => {
        let newArray = { ...prev };

        let newCell: NumberCell = curr_selection.newCell as NumberCell;
        newArray.powerSpeedDraftPercentage[no_rows - 1].percentage[no_col - 2] = newCell.value;

        return newArray;
      });
    }
  };

  const handleSubmit = () => {
    try {
      const trimOpAngleClient = new TrimOpAngleClient(undefined, axiosInstance);
      const entity = {
        trimOpSettingRequest: {
          trimAngle: trimOp.trimAngle.toString(),
          powerSpeedDraft: trimOp.powerSpeedDraft.map((el) => {
            return {
              speed: el.speed,
              draftMiddle: el.draftMiddle,
              power: el.power.toString(),
            };
          }),
        },
        percentageSettingRequest: {
          trimAngle: trimOp.trimAngle.toString(),
          powerSpeedDraftPercentage: trimOpPercentage.powerSpeedDraftPercentage.map((el) => {
            return {
              speed: el.speed,
              draftMiddle: el.draftMiddle,
              percentage: el.percentage.toString(),
            };
          }),
        },
      };

      const trimOpPercentageToSave = new SettingsTrimOpAndPercentageRequest();
      trimOpPercentageToSave.init(entity);
      trimOpAngleClient.update(trimOpPercentageToSave);

      NotificationHelper.showSuccess("Success", "Trim Op is update successfully");
    } catch {
      NotificationHelper.showError("Error in saving", "An error occurred in update the trim op");
    }
  };

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Group>
              <ActionIcon variant="subtle" color="blue" onClick={handleBack}>
                <ArrowBack />
              </ActionIcon>

              <Title order={2}>{t("trimsetting")}</Title>
            </Group>
            <Divider my="sm" size="md"></Divider>
            <Container size="xl">
              <Stack>
                <Card shadow="sm" p="lg" radius="md" withBorder>
                  <Group spacing="sm">
                    <Button onClick={pushCol} size="xs">
                      <Add /> Col
                    </Button>
                    <Button onClick={pushRow} size="xs">
                      <Add /> Row
                    </Button>
                    <Button onClick={handleSubmit} size="xs" color="green">
                      <Save /> Save
                    </Button>
                  </Group>
                </Card>
              </Stack>
              <br />
              <h2>Power Draft</h2>
              <Stack style={{ overflowX: "auto" }}>
                <ReactGrid
                  rows={rows}
                  columns={cols}
                  onCellsChanged={handleGridChanges}
                  enableFullWidthHeader
                  enableRowSelection
                  enableColumnSelection
                  enableRangeSelection
                />
              </Stack>
              <br />
              <br />
              <h2>Power Draft Percentage (%)</h2>
              <Stack style={{ overflowX: "auto" }}>
                <ReactGrid
                  rows={rowsPercentage}
                  columns={colsPercentage}
                  onCellsChanged={handlePercentageGridChanges}
                  enableFullWidthHeader
                  enableRowSelection
                  enableColumnSelection
                  enableRangeSelection
                />
              </Stack>
            </Container>
          </div>
        )}
      </Transition>
    </>
  );
};

export default TrimSetting;
