import { Divider, Title, Transition, Text, Button, Group } from "@mantine/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import { EventLogResponse, VesselsClient } from "../../services/WebApiService";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../providers/GlobalContextProvider";

type Props = {};
const EventLog = (props: Props) => {
  const { systemGlobal } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });
  const { vesselId } = useParams();

  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [eventLogs, setEventLogs] = useState<EventLogResponse[]>([]);
  const [pageSize, setPageSize] = useState(10);

  const { t } = useTranslation();

  const [dateRangeValue, setDateRangeValue] = useState<DateRangePickerValue>([
    dayjs().startOf("date").subtract(14, "day").toDate(),
    dayjs().startOf("date").toDate(),
  ]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  const getEventLogs = useCallback(async () => {
    try {
      setLoading(true);
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      const data = await vesselClient.eventLogs(Number(vesselId), dateRangeValue[0], dateRangeValue[1]);
      setEventLogs(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      NotificationHelper.showError("Error", "An error occurred in getting event logs");
    }
  }, [vesselId, dateRangeValue]);

  useEffect(() => {
    const loadData = async () => {
      if (vesselId && firstLoad) {
        getEventLogs();
        setFirstLoad(false);
      }
    };
    loadData();
    return () => {};
  }, [vesselId, firstLoad, getEventLogs]);

  const handleSearch = async (e: any) => {
    e.preventDefault();
    await getEventLogs();
  };

  const columnDef: GridColDef[] = [
    {
      field: "username",
      headerName: t("vesseleventlog_username"),
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row.applicationUser.username,
    },
    {
      field: "eventType",
      headerName: t("vesseleventlog_eventtype"),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "description",
      headerName: t("vesseleventlog_desc"),
      minWidth: 500,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: t("vesseleventlog_createdatetime"),
      minWidth: 250,
      flex: 1,
      renderCell: (cell) => {
        return <Text>{new Date(cell.value).toLocaleString()}</Text>;
      },
    },
  ];

  const loadDataGrid = () => {
    return (
      <ThemeProvider theme={theme}>
        <Group align="flex-end" mb={15}>
          <DateRangePicker
            withinPortal
            required
            label={t("vesseleventlog_daterange")}
            value={dateRangeValue}
            onChange={setDateRangeValue}
            icon={<IconCalendar size={16} />}
            miw={350}
          />
          <Button type="button" disabled={loading} loading={loading} onClick={handleSearch}>
            {t("vesseleventlog_search")}
          </Button>
        </Group>

        <DataGrid
          loading={loading}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          autoHeight
          pagination
          onPageSizeChange={setPageSize}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={pageSize}
          rows={eventLogs}
          columns={columnDef}
        />
      </ThemeProvider>
    );
  };

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <div style={styles}>
          <Title order={2}>{t("vesseleventlog")}</Title>
          <Divider my="sm" size="md"></Divider>
          {loadDataGrid()}
        </div>
      )}
    </Transition>
  );
};
export default EventLog;
