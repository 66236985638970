import Responsive from "react-grid-layout";

const getNewUniqueId = (layouts: Responsive.Layout[]): number => {
  const newId = generateRandomInteger(-100000, -1);
  for (let i = 0; i < layouts.length; i++) {
    if (newId === parseInt(layouts[i].i)) {
      return getNewUniqueId(layouts);
    }
  }
  return newId;
}

const generateRandomInteger = (min: number, max: number): number => {
  return Math.floor(min + Math.random() * (max - min + 1))
}

const GridHelper = {
  getNewUniqueId,
};

export default GridHelper;
