import { MapOptions } from "../../../models/dashboard/DashboardModels";
import { DashboardVesselResponse, DashboardWidgetResponse, HubSignalResponse } from "../../../services/WebApiService";
import GoogleMapHelper from "./GoogleMap/GoogleMapHelper";

type Props = {
  id: string;
  optionJson: string;
  dashboardVessels: DashboardVesselResponse[];
  dashboardWidget: DashboardWidgetResponse;
  hubSignals: HubSignalResponse[];
  dashboardType: "Fleet" | "Vessel";
};

const MapHelper = (props: Props) => {
  const loadMap = () => {
    const options = JSON.parse(props.optionJson) as MapOptions;
    if (options.mapType === "google") {
      return (
        <GoogleMapHelper
          id={props.dashboardWidget.id.toString()}
          dashboardWidget={props.dashboardWidget}
          optionJson={props.dashboardWidget.optionJson}
          dashboardVessels={props.dashboardVessels}
          hubSignals={props.hubSignals}
          dashboardType={props.dashboardType}
        />
      );
    }
  };
  return <>{loadMap()}</>;
};

export default MapHelper;
