import { ActionIcon, Button, Group, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { CommonEditReportSetting } from "../../../../../models/report-setting/CommonEditReportSetting";
import { RemoveCircle } from "@mui/icons-material";
import NotificationHelper from "../../../../../helpers/NotiHelper";
import { useTranslation } from "react-i18next";

type Props = {
  form: UseFormReturnType<CommonEditReportSetting>;
};

type EmissionSource = {
  Name: string;
  SerialNum: string;
  YearInstalled: string;
  RatedPower: string;
  Sfoc: string;
  ChosenMethod: string;
};

const EmissionSourceSectionInput = (props: Props) => {
  const { t } = useTranslation();

  const addEmissionSource = () => {
    const entity = {
      Name: "",
      SerialNum: "",
      YearInstalled: "",
      RatedPower: "",
      Sfoc: "",
      ChosenMethod: "",
    } as EmissionSource;
    props.form.insertListItem("EmissionSource", entity);
  };

  const removeEmissionSource = (index: number) => {
    if (props.form.values.EmissionSource.length > 1) {
      props.form.removeListItem("EmissionSource", index);
    } else {
      NotificationHelper.showError("Error in removing", "Setting needs at least one emission source");
    }
  };

  return (
    <>
      {props.form.values.EmissionSource.map((obj, index) => {
        return (
          <Group grow align="end" key={`EmissionSource${index}`}>
            <Group grow>
              <TextInput label={t("vesselreportsetting_esname")} {...props.form.getInputProps(`EmissionSource.${index}.Name`)} />
              <TextInput label={t("vesselreportsetting_esserialno")} {...props.form.getInputProps(`EmissionSource.${index}.SerialNum`)} />
              <TextInput label={t("vesselreportsetting_seyearinstalled")} {...props.form.getInputProps(`EmissionSource.${index}.YearInstalled`)} />
              <TextInput label={t("vesselreportsetting_ratedpower")} {...props.form.getInputProps(`EmissionSource.${index}.RatedPower`)} />
              <TextInput label={t("vesselreportsetting_sfoc")} {...props.form.getInputProps(`EmissionSource.${index}.Sfoc`)} />
              <TextInput label={t("vesselreportsetting_chosenmethod")} {...props.form.getInputProps(`EmissionSource.${index}.ChosenMethod`)} />
              <ActionIcon color="red" onClick={() => removeEmissionSource(index)}>
                <RemoveCircle />
              </ActionIcon>
            </Group>
          </Group>
        );
      })}
      <Group>
        <Button variant="outline" onClick={addEmissionSource}>
          {t("vesselreportsetting_addemission")}
        </Button>
      </Group>
    </>
  );
};

export default EmissionSourceSectionInput;
