import { Modal, Title } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import Responsive from "react-grid-layout";
import { MappingDefResponse, CurveResponse, DashboardWidgetResponse, WidgetType } from "../../../services/WebApiService";
import InstantValueConfig from "../InstantValue/InstantValueConfig";
import MapConfig from "../Map/MapConfig";
import RadialGaugeConfig from "../RadialGauge/RadialGaugeConfig";
import ScatterChartConfig from "../ScatterChart/ScatterChartConfig";
import TextEditorConfig from "../TextEditor/TextEditorConfig";
import TimeChartConfig from "../TimeChart/TimeChartConfig";
import "./WidgetConfig.scss";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../providers/GlobalContextProvider";
import AttitudeIndicatorConfig from "../AttitudeIndicator/AttitudeIndicatorConfig";

type Props = {
  isAddWidget: boolean;
  widgetTypeForAdd?: WidgetType;
  layouts: Responsive.Layout[];
  isHidden: boolean;
  activeDashboardWidget?: DashboardWidgetResponse;
  curves: CurveResponse[];
  classifications: MappingDefResponse[];
  mappings: MappingDefResponse[];
  setIsAddWidget(value: boolean): void;
  setWidgetTypeForAdd(value: WidgetType | undefined): void;
  handleCloseWidgetConfig(): void;
  updateWidgets(widget: DashboardWidgetResponse): void;
  addWidget(widget: DashboardWidgetResponse): void;
};

const WidgetConfig = (props: Props) => {
  const [title, setTitle] = useState("");

  const { systemGlobal } = useContext(GlobalContext);

  const { t } = useTranslation();

  const handleCloseWidgetConfig = () => {
    props.handleCloseWidgetConfig();
    props.setIsAddWidget(false);
    props.setWidgetTypeForAdd(undefined);
  };

  const loadConfigBasedOnProps = (): JSX.Element => {
    if (props.isAddWidget && props.widgetTypeForAdd) {
      return loadConfig(props.widgetTypeForAdd, undefined);
    } else if (props.activeDashboardWidget) {
      return loadConfig(props.activeDashboardWidget.widgetType, props.activeDashboardWidget);
    }
    return <></>;
  };

  const loadConfig = (widgetType: WidgetType, dashboardWidget?: DashboardWidgetResponse) => {
    if (widgetType === WidgetType.TimeChart) {
      return (
        <TimeChartConfig
          addWidget={props.addWidget}
          isAddWidget={props.isAddWidget}
          layouts={props.layouts}
          classifications={props.classifications}
          mappings={props.mappings}
          activeDashboardWidget={dashboardWidget}
          handleCloseWidgetConfig={handleCloseWidgetConfig}
          updateWidgets={props.updateWidgets}
        />
      );
    } else if (widgetType === WidgetType.ScatterChart) {
      return (
        <ScatterChartConfig
          addWidget={props.addWidget}
          isAddWidget={props.isAddWidget}
          layouts={props.layouts}
          classifications={props.classifications}
          mappings={props.mappings}
          activeDashboardWidget={dashboardWidget}
          handleCloseWidgetConfig={handleCloseWidgetConfig}
          updateWidgets={props.updateWidgets}
          curves={props.curves}
        />
      );
    } else if (widgetType === WidgetType.InstantValue) {
      return (
        <InstantValueConfig
          addWidget={props.addWidget}
          isAddWidget={props.isAddWidget}
          layouts={props.layouts}
          classifications={props.classifications}
          mappings={props.mappings}
          activeDashboardWidget={dashboardWidget}
          handleCloseWidgetConfig={handleCloseWidgetConfig}
          updateWidgets={props.updateWidgets}
        />
      );
    } else if (widgetType === WidgetType.RadialGauge) {
      return (
        <RadialGaugeConfig
          isAddWidget={props.isAddWidget}
          layouts={props.layouts}
          classifications={props.classifications}
          mappings={props.mappings}
          activeDashboardWidget={dashboardWidget}
          handleCloseWidgetConfig={handleCloseWidgetConfig}
          updateWidgets={props.updateWidgets}
          addWidget={props.addWidget}
        />
      );
    } else if (widgetType === WidgetType.TextEditor) {
      return (
        <TextEditorConfig
          addWidget={props.addWidget}
          isAddWidget={props.isAddWidget}
          layouts={props.layouts}
          classifications={props.classifications}
          mappings={props.mappings}
          activeDashboardWidget={dashboardWidget}
          handleCloseWidgetConfig={handleCloseWidgetConfig}
          updateWidgets={props.updateWidgets}
        />
      );
    } else if (widgetType === WidgetType.Map) {
      return (
        <MapConfig
          addWidget={props.addWidget}
          isAddWidget={props.isAddWidget}
          layouts={props.layouts}
          classifications={props.classifications}
          mappings={props.mappings}
          activeDashboardWidget={dashboardWidget}
          handleCloseWidgetConfig={handleCloseWidgetConfig}
          updateWidgets={props.updateWidgets}
        />
      );
    } else if (widgetType === WidgetType.AttitudeIndicator) {
      return (
        <AttitudeIndicatorConfig
          addWidget={props.addWidget}
          isAddWidget={props.isAddWidget}
          layouts={props.layouts}
          classifications={props.classifications}
          mappings={props.mappings}
          activeDashboardWidget={dashboardWidget}
          handleCloseWidgetConfig={handleCloseWidgetConfig}
          updateWidgets={props.updateWidgets}
        />
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (!props.isHidden) {
      let widgetType: WidgetType | null = null;
      if (props.isAddWidget && props.widgetTypeForAdd) {
        widgetType = props.widgetTypeForAdd;
      } else if (props.activeDashboardWidget) {
        widgetType = props.activeDashboardWidget.widgetType;
      }
      if (widgetType) {
        if (widgetType === WidgetType.TimeChart) {
          setTitle(t("dashboardwidget_widgetconfig_yt"));
        } else if (widgetType === WidgetType.ScatterChart) {
          setTitle(t("dashboardwidget_widgetconfig_yx"));
        } else if (widgetType === WidgetType.InstantValue) {
          setTitle(t("dashboardwidget_widgetconfig_instant"));
        } else if (widgetType === WidgetType.RadialGauge) {
          setTitle(t("dashboardwidget_widgetconfig_radial"));
        } else if (widgetType === WidgetType.TextEditor) {
          setTitle(t("dashboardwidget_widgetconfig_text"));
        } else if (widgetType === WidgetType.Map) {
          setTitle(t("dashboardwidget_widgetconfig_map"));
        } else if (widgetType === WidgetType.AttitudeIndicator) {
          setTitle(t("dashboardwidget_widgetconfig_attitudeindicator"));
        }
      }
    }
    return () => {};
  }, [props.activeDashboardWidget, props.isAddWidget, props.isHidden, props.widgetTypeForAdd, t]);

  return (
    <Modal
      className={`widget-config-container`}
      zIndex={200}
      transition="pop"
      transitionDuration={250}
      transitionTimingFunction="ease"
      overflow="inside"
      size="xl"
      title={<Title order={3}>{title}</Title>}
      opened={!props.isHidden}
      onClose={() => handleCloseWidgetConfig()}
      withCloseButton={true}
      closeOnClickOutside={false}
    >
      {loadConfigBasedOnProps()}
    </Modal>
  );
};

export default WidgetConfig;
