import { Button, Group, Modal, Stack, TransferListData } from "@mantine/core";
import { CompanyClient, CompanyPatchRequest, CompanyResponse } from "../../services/WebApiService";
import { useEffect, useState } from "react";
import CompanyFormInputs from "./common/CompanyFormInputs";
import { useForm } from "@mantine/form";
import { Company } from "../../models/company/Company";
import AuthService from "../../services/AuthService";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import { useTranslation } from "react-i18next";

type ModalProps = {
  isOpen: boolean;
  id: number;
  company?: CompanyResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  hideModal: (type: string) => void;
  loadCompany?: () => void;
  companyId: number;
  modalProps: ModalType;
  company: CompanyResponse;
};

const EditCompanyForm = (props: Props) => {
  const [user, setUser] = useState<TransferListData>([[], []]);
  const [fleet, setFleet] = useState<TransferListData>([[], []]);

  const { t } = useTranslation();

  const commonForm = useForm<Company>({
    initialValues: {
      Name: "",
    },
  });

  const handleSubmit = async () => {
    if (AuthService.isInRole("Admin")) {
      try {
        const values = commonForm.values;
        const companyClient = new CompanyClient(undefined, axiosInstance);
        const entity = {
          name: values.Name,
          appUserCompany: user[1].map((x) => ({
            appUserId: x.value,
            companyId: props.companyId,
          })),
          companyFleet: fleet[1].map((x) => ({
            fleetId: Number(x.value),
            companyId: props.companyId,
          })),
        };

        const companyToUpdate = new CompanyPatchRequest();
        companyToUpdate.init(entity);
        await companyClient.patch(companyToUpdate, props.companyId);
        NotificationHelper.showSuccess("Success", "Company is updated successfully");
        props.hideModal("editCompany");
        if (props.loadCompany) props.loadCompany();
      } catch {
        NotificationHelper.showError("Error", "An error occurred in updating company");
      }
    }
  };

  useEffect(() => {
    commonForm.setFieldValue("Name", props.company.name);
    return () => {};
  }, [props.companyId, props.company.name, props.modalProps["editCompany"].isOpen]);

  return (
    <Modal size="xl" opened={props.modalProps["editCompany"].isOpen} onClose={() => props.hideModal("editCompany")} title={t("editcompany")}>
      <Stack spacing="lg">
        <Group spacing="lg" align="center" grow>
          <form>
            <CompanyFormInputs
              company={props.company}
              companyId={props.companyId}
              form={commonForm}
              isEditMode={true}
              user={user}
              setUser={setUser}
              fleet={fleet}
              setFleet={setFleet}
            />
          </form>
        </Group>
        <Group position="right">
          <Button variant="outline" onClick={() => props.hideModal("editCompany")}>
            {t("editcompany_cancel")}
          </Button>
          <Button disabled={!AuthService.isInRole("Admin")} type="submit" onClick={handleSubmit}>
            {t("editcompany_save")}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default EditCompanyForm;
