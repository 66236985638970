import { Group, Button, Stack } from "@mantine/core";
import Responsive from "react-grid-layout";
import React, { useEffect, useState } from "react";
import { TextEditorOptions } from "../../../models/dashboard/DashboardModels";
import { MappingDefResponse, DashboardWidgetResponse, WidgetType } from "../../../services/WebApiService";
import CommonWidgetConfig from "../Common/CommonWidgetConfig";
import GridHelper from "../../../helpers/GridHelper";
import { Link, RichTextEditor } from "@mantine/tiptap";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Highlight from "@tiptap/extension-highlight";
import { useEditor } from "@tiptap/react";
import { useTranslation } from "react-i18next";

type Props = {
  isAddWidget: boolean;
  layouts: Responsive.Layout[];
  handleCloseWidgetConfig: () => void;
  addWidget(widget: DashboardWidgetResponse): void;
  updateWidgets(widget: DashboardWidgetResponse): void;
  activeDashboardWidget?: DashboardWidgetResponse;
  classifications: MappingDefResponse[];
  mappings: MappingDefResponse[];
};

const TextEditorConfig = (props: Props) => {
  const [header, setHeader] = useState(props.activeDashboardWidget?.header ?? "");
  const [color, setColor] = useState(props.activeDashboardWidget?.color ?? "#FFFFFF");
  const [firstLoad, setFirstLoad] = useState(true);
  const { t } = useTranslation();

  const editor = useEditor({
    extensions: [StarterKit, Underline, Link, Superscript, SubScript, Highlight, TextAlign.configure({ types: ["heading", "paragraph"] })],
  });

  const handleSubmit = () => {
    if (editor) {
      const options: TextEditorOptions = {
        data: editor.getJSON(),
      };
      const optionsJson = JSON.stringify(options);
      if (props.isAddWidget) {
        const newId = GridHelper.getNewUniqueId(props.layouts);
        const newObj = {
          header: header,
          color: color,
          optionJson: optionsJson,
          id: newId,
          height: 8,
          width: 10,
          x: 0,
          y: 0,
          widgetType: WidgetType.TextEditor,
        };
        const entity = new DashboardWidgetResponse();
        entity.init(newObj);
        props.addWidget(entity);
      } else {
        const obj = props.activeDashboardWidget;
        const newObj = {
          ...obj,
          header: header,
          color: color,
          optionJson: optionsJson,
        };
        const entity = new DashboardWidgetResponse();
        entity.init(newObj);
        props.updateWidgets(entity);
      }
      props.handleCloseWidgetConfig();
    }
  };

  useEffect(() => {
    const loadFormValues = () => {
      if (firstLoad && props.activeDashboardWidget && editor) {
        const obj = props.activeDashboardWidget;
        const options: TextEditorOptions = JSON.parse(obj.optionJson);
        editor.commands.setContent(options.data);
        setFirstLoad(false);
      }
    };
    loadFormValues();
    return () => {};
  }, [editor, firstLoad, props.activeDashboardWidget]);

  return (
    <Stack>
      <CommonWidgetConfig headerValue={header} setHeader={setHeader} color={color} setColor={setColor} />

      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar sticky stickyOffset={0}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>

      <Group mt={20}>
        <Button onClick={handleSubmit}>{t("dashboardwidget_texteditconfig_submit")}</Button>
        <Button onClick={props.handleCloseWidgetConfig} variant="subtle">
          {t("dashboardwidget_texteditconfig_cancel")}
        </Button>
      </Group>
    </Stack>
  );
};

export default TextEditorConfig;
