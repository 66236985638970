import { ActionIcon, Button, Flex, Grid, Group, MultiSelect, Select, SelectItem, Stack } from "@mantine/core";
import { DateRangePickerValue } from "@mantine/dates";
import { UseFormReturnType } from "@mantine/form";
import { RemoveCircleOutline } from "@mui/icons-material";
import { Dispatch, SetStateAction } from "react";
import { VesselsClient } from "../services/WebApiService";
import { axiosInstance } from "../services/AxiosService";
import AuthService from "../services/AuthService";
import { useTranslation } from "react-i18next";

type YtChartForm = {
  vesselId: string;
  dateRange: DateRangePickerValue;
  interval: string;
  signalIds: string[];
  chartType: string;
  showAlarms: string;
  vesselSignals: vesselSignals[];
};

type vesselSignals = {
  vesselId: string;
  signalIds: string[];
};
type Props = {
  vessels: SelectItem[];
  form: UseFormReturnType<YtChartForm>;
  multipleSignalSelectItems: SelectItem[][];
  setMultipleSignalSelectItems: Dispatch<SetStateAction<SelectItem[][]>>;
};

const numberOfSignalsLimit = 20;

const YtMultipleVesselSignals = (props: Props) => {
  const { t } = useTranslation();

  const addItem = () => {
    const item: vesselSignals = { vesselId: "", signalIds: [] };
    props.form.insertListItem("vesselSignals", item);
    props.setMultipleSignalSelectItems((x) => [...x, []]);
  };

  const removeItem = (index: number) => {
    if (props.form.values.vesselSignals.length > 1) {
      props.form.removeListItem("vesselSignals", index);
    }
  };

  const getSignalMappings = async (index: number, vesselId: string | null) => {
    const vesselClient = new VesselsClient(undefined, axiosInstance);
    const user = AuthService.getCurrentUser();
    const signals = await vesselClient.signalMappingsGET(Number(vesselId), null, user?.userid);

    props.setMultipleSignalSelectItems((prev) => {
      const updatedArray = [...prev];
      updatedArray[index] = signals.map((x) => ({
        value: x.id.toString(),
        label: x.displayName,
      }));
      return updatedArray;
    });
  };

  const loadVesselSignals = props.form.values.vesselSignals.map((item, index) => {
    return (
      <Grid.Col span={6} xs={12} sm={12} md={6} key={index}>
        <Group>
          <Select
            miw={220}
            label={t("ytchartht_vessel")}
            data={props.vessels}
            {...props.form.getInputProps(`vesselSignals.${index}.vesselId`)}
            onChange={(e) => {
              props.form.setFieldValue(`vesselSignals.${index}.vesselId`, e);
              getSignalMappings(index, e);
            }}
          />
          <MultiSelect
            miw={270}
            label={t("ytchartht_signals")}
            data={props.multipleSignalSelectItems[index]}
            searchable
            nothingFound={t("ytchartht_nothingfound")}
            placeholder={t("ytchartht_selectsignalmulti")}
            {...props.form.getInputProps(`vesselSignals.${index}.signalIds`)}
            onChange={(e) => {
              const totalLength = props.form.values.vesselSignals.reduce((sum, subarray) => sum + subarray.signalIds.length, 0);

              if (totalLength !== numberOfSignalsLimit) {
                props.form.setFieldValue(`vesselSignals.${index}.signalIds`, e);
              }

              if (totalLength === numberOfSignalsLimit && props.form.values.vesselSignals[index].signalIds.length > e.length) {
                props.form.setFieldValue(`vesselSignals.${index}.signalIds`, e);
              }
            }}
          />

          {index !== 0 && (
            <ActionIcon color="red" onClick={() => removeItem(index)} mt="20px">
              <RemoveCircleOutline></RemoveCircleOutline>
            </ActionIcon>
          )}
        </Group>
      </Grid.Col>
    );
  });

  return (
    <>
      <Stack
        style={{
          maxHeight: "300px",
          overflow: "auto",
          height: "300px",
          zIndex: "50",
          boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          padding: "20px",
        }}
      >
        <Flex align="end" gap="md" mr="md">
          <h3>{t("ytchartht_signals")}</h3>{" "}
          <Button size="xs" onClick={addItem}>
            {t("ytchartht_add")}
          </Button>
        </Flex>
        <Grid>{loadVesselSignals}</Grid>
      </Stack>
    </>
  );
};

export default YtMultipleVesselSignals;
