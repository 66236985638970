import { Button, Card, Stack, Title } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { AutomaticReportResponse, TriggerType } from "../../services/WebApiService";
import { useTranslation } from "react-i18next";

type Props = {
  handleSubmit(settingJson: string): void;
  automaticReport?: AutomaticReportResponse;
};
type DailySettingJson = {
  startTime: Date;
};
const DailyAutomaticReportInput = (props: Props) => {
  const defaultValue = new Date(1, 1, 1, 0, 0);
  const [firstLoad, setFirstLoad] = useState(true);

  const { t } = useTranslation();

  const form = useForm<DailySettingJson>({
    initialValues: {
      startTime: defaultValue,
    },
  });

  const handleSubmit = () => {
    const settingJson = JSON.stringify(form.values);
    props.handleSubmit(settingJson);
  };

  useEffect(() => {
    const loadForm = () => {
      if (firstLoad && props.automaticReport) {
        setFirstLoad(false);
        if (props.automaticReport.triggerType === TriggerType.Daily) {
          const optionJson = JSON.parse(props.automaticReport.settingJson) as DailySettingJson;
          optionJson.startTime = new Date(optionJson.startTime);
          form.setFieldValue("startTime", optionJson.startTime);
        }
      }
    };
    loadForm();
    return () => {};
  }, [firstLoad, form, props.automaticReport]);

  return (
    <Card shadow="sm" p="lg" radius="sm" withBorder>
      <Card.Section withBorder p="lg">
        <Title order={3}>{t("createautoreport_dailyreport")}</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <TimeInput
            description={t("createautoreport_24hoursformat")}
            label={t("createautoreport_dailyreport")}
            {...form.getInputProps("startTime")}
          />
        </Stack>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Button onClick={handleSubmit}>{t("createautoreport_save")}</Button>
      </Card.Section>
    </Card>
  );
};
export default DailyAutomaticReportInput;
