import { Button, Card, Group, Stack, Title, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

type Props = {
  handleSubmit(optionJson: string): void;
};
const EeoiKpiInput = (props: Props) => {
  const { t } = useTranslation();

  const handleSubmit = async () => {
    props.handleSubmit("");
  };

  return (
    <Card shadow="sm" p="lg" radius="sm" withBorder>
      <Card.Section withBorder p="lg">
        <Title order={3}>{t("eeoikpi")}</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <Group grow>
            <Stack>
              <Text>{t("eeoikpi_requiresdesc1")}</Text>
              <Text>{t("eeoikpi_requiresdesc2")}</Text>
              <Text>{t("eeoikpi_requiresdesc3")}</Text>
            </Stack>
          </Group>
        </Stack>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Button mt="lg" onClick={handleSubmit}>
          {t("eeoikpi_save")}
        </Button>
      </Card.Section>
    </Card>
  );
};

export default EeoiKpiInput;
