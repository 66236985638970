import { isDev } from "../helpers/DevDetect";
import { EnvService } from "./EnvService";

export const getBaseUrl = () => {
  let baseUrl = window.location.origin + "/api";
  if (!EnvService.isVesselSystemMode) {
    // Fleet mode / for azure
    baseUrl = "/api";
  }
  const envUrl = EnvService.webApiUrl;
  if (envUrl) {
    baseUrl = envUrl;
  }

  if (isDev()) {
    baseUrl = "https://localhost:7254";
  }
  return baseUrl;
}