import { Button, Group, Modal, Stack, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import React from "react";
import { VoyageClient, VoyagePostRequest } from "../../../services/WebApiService";
import { axiosInstance } from "../../../services/AxiosService";
import NotificationHelper from "../../../helpers/NotiHelper";
import { useTranslation } from "react-i18next";

type Props = {
  vesselId: number;
  isOpen: boolean;
  setIsOpen(value: boolean): void;
  setReloadExistingVoyages(value: boolean): void;
};

type VoyageForm = {
  name: string;
  description: string;
  vesselId: number;
};

const CreateVoyageModal = (props: Props) => {
  const { t } = useTranslation();

  const form = useForm<VoyageForm>({
    initialValues: {
      name: "",
      description: "",
      vesselId: 0,
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const voyageClient = new VoyageClient(undefined, axiosInstance);
    const values = form.values;
    const request = {
      name: values.name,
      description: values.description,
      vesselId: props.vesselId,
    } as VoyagePostRequest;

    try {
      await voyageClient.post(request);
      NotificationHelper.showSuccess("Success", "New Voyage Created Successfully");
    } catch {
      NotificationHelper.showError("Error", "An error occurred in creating voyage");
    }
    props.setReloadExistingVoyages(true);
    props.setIsOpen(false);
  };

  return (
    <Modal size="lg" opened={props.isOpen} onClose={() => props.setIsOpen(false)} title={t("addvoyage")}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Stack spacing={"lg"}>
          <TextInput required label={t("addvoyage_voyagename")} {...form.getInputProps("name")} />
          <Textarea label={t("addvoyage_descriptions")} minRows={3} maxRows={5} {...form.getInputProps("description")} />
          <Group>
            <Button type="submit">{t("addvoyage_create")}</Button>
            <Button onClick={() => props.setIsOpen(false)} variant="subtle">
              {t("addvoyage_cancel")}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default CreateVoyageModal;
