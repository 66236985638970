import { Route, Routes } from "react-router-dom";
import AccessDenied from "../account/AccessDenied";
import Login from "../account/Login";
import ProtectedRoute from "../auth/ProtectedRoute";
import Home from "../dashboard/Home";
import IndexDashboard from "../dashboard/Index";
import ViewDashboard from "../dashboard/View";
import IndexLicense from "../license/Index";
import CO2Report from "../report/CO2";
import FuelConsReport from "../report/FuelCons";
import SystemSetting from "../system-setting/Index";
import CreateUser from "../users/Create";
import EditUser from "../users/Edit";
import IndexUsers from "../users/Index";
import CreateAutomaticReportSetting from "../automatic-report/Create";
import IndexAutomaticReport from "../automatic-report/Index";
import IndexVessels from "../vessel/Index";
import CreateKpi from "../vessel/kpi/Create";
import EditKpi from "../vessel/kpi/Edit";
import IndexKpi from "../vessel/kpi/Index";
import IndexManualInput from "../vessel/manual-input/Index";
import VesselSetting from "../vessel/Setting";
import SignalMapping from "../vessel/SignalMapping";
import IndexTripSetting from "../vessel/trip-setting/Index";
import EditAutomaticReportSetting from "../automatic-report/Edit";
import EventLog from "../vessel/event-log/Index";
import HistoryYX from "../history/YX";
import NotFound from "../vessel/NotFound";
import AutomaticReportList from "../automatic-report/List";
import HistoryYt from "../history/Yt";
import ViewManualReportLibrary from "../report/ViewManualReportLibrary";
import CreateOrEditManualReportLibrary from "../report/CreateOrEditManualReportLibrary";
import ManageVoyage from "../vessel/voyage/Manage";
import VoyageTrip from "../vessel/voyage/Trip";
import IndexReportSetting from "../vessel/report-setting/Index";
import IndexCompanyManagement from "../company/Index";
import IndexFleetManagement from "../fleet/Index";
import FleetOverviewReport from "../report/FleetOverviewReport";
import OnlyForOnBoard from "../error-page/OnlyForOnBoard";
import OnlyForOnShore from "../error-page/OnlyForOnShore";
import IndexMapSignals from "../vessel/mapsignals/Index";
import DocumentationOnBoard from "../documentation/DocumentationOnBoard";
import DocumentationOnShore from "../documentation/DocumentationOnShore";
import IndexEmissionReduction from "../vessel/emission-reduction/Index";
import IndexCO2OperationMode from "../vessel/co2-operation-mode/Index";
import CIIChart from "../history/CIIChart";
import IndexDataTransferManagement from "../DataTransfer/Index";
import FullLogDataTransfer from "../DataTransfer/FullLog";
import UserApiKey from "../users/ApiKey";
import TrimSetting from "../vessel/kpi/TrimSetting";
import TrimOpChart from "../history/TrimOpChart";

type Props = {};
const RoutesProvider = (props: Props) => {
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute type="Normal">
            <Home />
          </ProtectedRoute>
        }
      />
      <Route path="error">
        <Route path="only-for-onboard" element={<OnlyForOnBoard />}></Route>
        <Route path="only-for-onshore" element={<OnlyForOnShore />}></Route>
      </Route>
      <Route path="account">
        <Route path="login" element={<Login />}></Route>
        <Route path="access-denied" element={<AccessDenied />}></Route>
      </Route>
      <Route path="dashboard" element={<ProtectedRoute type="Normal" />}>
        <Route index element={<IndexDashboard />} />
        <Route path="fleet" element={<IndexDashboard />} />
        <Route path="view/:dashboardId" element={<ViewDashboard isEdit={false} />} />
        <Route path="edit/:dashboardId" element={<ViewDashboard isEdit={true} />} />
        <Route path="home" element={<Home />} />
      </Route>
      <Route path="history" element={<ProtectedRoute type="Normal" />}>
        <Route path="y-x" element={<HistoryYX />} />
        <Route path="y-t/fleet" element={<HistoryYt />} />
        <Route path="y-t/vessel" element={<HistoryYt />} />
        <Route path="cii-chart/fleet" element={<CIIChart />} />
        <Route path="cii-chart/vessel" element={<CIIChart />} />
        <Route path="trimop-chart/vessel" element={<TrimOpChart />} />
      </Route>
      <Route path="users" element={<ProtectedRoute type="Normal" />}>
        <Route
          index
          element={
            <ProtectedRoute type="Admin">
              <IndexUsers />
            </ProtectedRoute>
          }
        />
        <Route path="edit/:userId" element={<EditUser />} />
        <Route path="apikey/:userId" element={<UserApiKey />} />
        <Route
          path="create"
          element={
            <ProtectedRoute type="Admin">
              <CreateUser />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="system-setting"
        element={
          <ProtectedRoute type="Engineer">
            <SystemSetting />
          </ProtectedRoute>
        }
      />
      <Route
        path="license"
        element={
          <ProtectedRoute type="Engineer">
            <IndexLicense />
          </ProtectedRoute>
        }
      />
      <Route path="vessel">
        <Route
          index
          element={
            <ProtectedRoute type="Normal">
              <IndexVessels />
            </ProtectedRoute>
          }
        />
        <Route path="not-found" element={<NotFound />} />
        <Route path=":vesselId">
          <Route
            path="signal-mapping"
            element={
              <ProtectedRoute type="Admin">
                <SignalMapping />
              </ProtectedRoute>
            }
          />
          <Route
            path="setting"
            element={
              <ProtectedRoute type="Admin">
                <VesselSetting />
              </ProtectedRoute>
            }
          />
          <Route path="report-setting" element={<ProtectedRoute type="Engineer" />}>
            <Route index element={<IndexReportSetting />} />
          </Route>
          <Route path="kpi" element={<ProtectedRoute type="Admin" />}>
            <Route index element={<IndexKpi />} />
            <Route path="edit/:kpiId" element={<EditKpi />} />
            <Route path="create" element={<CreateKpi />} />
            <Route path="trim-setting" element={<TrimSetting />} />
          </Route>
          <Route path="trip-setting" element={<ProtectedRoute type="Engineer" />}>
            <Route index element={<IndexTripSetting />} />
          </Route>
          <Route path="voyage" element={<ProtectedRoute type="Engineer" />}>
            <Route path="manage" element={<ManageVoyage />} />
            <Route path="trip" element={<VoyageTrip />} />
          </Route>
          <Route path="manual-input" element={<ProtectedRoute type="Engineer" />}>
            <Route index element={<IndexManualInput />} />
          </Route>
          <Route path="event-log" element={<ProtectedRoute type="Admin" />}>
            <Route index element={<EventLog />} />
          </Route>
          <Route path="map-signal" element={<ProtectedRoute type="Engineer" />}>
            <Route index element={<IndexMapSignals />} />
          </Route>
          <Route path="emission-reduction" element={<ProtectedRoute type="Admin" />}>
            <Route index element={<IndexEmissionReduction />} />
          </Route>
          <Route path="co2-operation-mode" element={<ProtectedRoute type="Admin" />}>
            <Route index element={<IndexCO2OperationMode />} />
          </Route>
        </Route>
      </Route>
      <Route path="automatic-report" element={<ProtectedRoute type="Normal" />}>
        <Route index element={<IndexAutomaticReport />} />
        <Route path="edit/:automaticReportId" element={<EditAutomaticReportSetting />} />
        <Route path="create" element={<CreateAutomaticReportSetting />} />
        <Route path="list" element={<AutomaticReportList />} />
      </Route>
      <Route path="report" element={<ProtectedRoute type="Normal" />}>
        <Route path="fuel-cons" element={<FuelConsReport />} />
        <Route path="co2" element={<CO2Report />} />
        <Route path="fleet-overview" element={<FleetOverviewReport />} />
        <Route path="manual-report-library">
          <Route path=":reportId" element={<ViewManualReportLibrary />} />
          <Route path=":reportId/edit" element={<CreateOrEditManualReportLibrary isCreate={false} />} />
          <Route path="create" element={<CreateOrEditManualReportLibrary isCreate={true} />} />
        </Route>
      </Route>

      <Route path="documentation" element={<ProtectedRoute type="Normal" />}>
        <Route path="onboard/:category/:childname" element={<DocumentationOnBoard />} />
        <Route path="onshore/:category/:childname" element={<DocumentationOnShore />} />
      </Route>

      <Route path="company" element={<ProtectedRoute type="Normal" />}>
        <Route index element={<IndexCompanyManagement />} />
      </Route>
      <Route path="fleet" element={<ProtectedRoute type="Normal" />}>
        <Route index element={<IndexFleetManagement />} />
      </Route>
      <Route path="datatransfer" element={<ProtectedRoute type="Admin" />}>
        <Route path="manage" element={<IndexDataTransferManagement />} />
        <Route path="fulllog" element={<FullLogDataTransfer />} />
      </Route>
    </Routes>
  );
};
export default RoutesProvider;
