import { ActionIcon, Button, Divider, Group, Title, Transition, useMantineTheme } from "@mantine/core";
import { Delete, Edit } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosService";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../providers/GlobalContextProvider";
import { EmissionReductionClient, EmissionReductionResponse } from "../../services/WebApiService";
import CreateEmissionReductionForm from "./CreatForm";
import EditEmissionReductionForm from "./EditForm";
import DeleteEmissionReductionForm from "./DeleteForm";

type Props = {};

type ModalProps = {
  isOpen: boolean;
  id: number;
  emissionReduction?: EmissionReductionResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

const IndexEmissionReduction = (props: Props) => {
  const { systemGlobal } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });

  const { t } = useTranslation();

  const mantineTheme = useMantineTheme();
  const [mounted, setMounted] = useState(false);
  const [modalProps, setmodalProps] = useState<ModalType>({
    createEmissionReduction: {
      isOpen: false,
      id: 0,
    },
    deleteEmissionReduction: {
      isOpen: false,
      id: 0,
    },
    editEmissionReduction: {
      isOpen: false,
      id: 0,
    },
  });
  const [emissionReduction, setEmissionReduction] = useState<EmissionReductionResponse[]>([]);
  const { vesselId } = useParams();

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  const hideModal = (type: string) => {
    setmodalProps((prevModalProps) => ({
      ...prevModalProps,
      [type]: {
        ...prevModalProps[type],
        isOpen: false,
      },
    }));
  };

  const showModal = (id: number, type: string, emissionReduction?: EmissionReductionResponse) => {
    setmodalProps((prevModalProps) => ({
      ...prevModalProps,
      [type]: {
        ...prevModalProps[type],
        isOpen: true,
        id: id,
        emissionReduction,
      },
    }));
  };

  const loadEmissionReduction = async () => {
    const emissionClient = new EmissionReductionClient(undefined, axiosInstance);
    const data = await emissionClient.getAll();
    setEmissionReduction(data);
  };

  useEffect(() => {
    loadEmissionReduction();
    return () => {};
  }, [vesselId]);

  const loadDataGrid = () => {
    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          autoHeight
          rows={emissionReduction}
          columns={[
            {
              field: "year",
              headerName: t("manageemissionreduction_year"),
              flex: 1,
            },
            {
              field: "percentage",
              headerName: t("manageemissionreduction_percentage"),
              flex: 1,
            },
            {
              field: "edit",
              headerName: t("manageemissionreduction_edit"),
              renderCell: (cell) => (
                <ActionIcon
                  variant="subtle"
                  color={mantineTheme.primaryColor}
                  onClick={() => showModal(cell.row.id, "editEmissionReduction", cell.row)}
                >
                  <Edit />
                </ActionIcon>
              ),
            },
            {
              field: "delete",
              headerName: t("manageemissionreduction_delete"),
              renderCell: (cell) => (
                <ActionIcon onClick={() => showModal(cell.row.id, "deleteEmissionReduction")} variant="subtle" color="red">
                  <Delete />
                </ActionIcon>
              ),
            },
          ]}
        />
      </ThemeProvider>
    );
  };

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Group>
              <Title order={2}>{t("manageemission")}</Title>
              <Button onClick={() => showModal(0, "createEmissionReduction")}>{t("manageemissionreduction_createemissionreduction")}</Button>
            </Group>
            <Divider my="sm" size="md"></Divider>
            {loadDataGrid()}
          </div>
        )}
      </Transition>
      <CreateEmissionReductionForm
        modalProps={modalProps}
        loadEmissionReduction={loadEmissionReduction}
        hideModal={() => hideModal("createEmissionReduction")}
      />

      <EditEmissionReductionForm
        modalProps={modalProps}
        loadEmissionReduction={loadEmissionReduction}
        hideModal={() => hideModal("editEmissionReduction")}
        emissionReductionId={modalProps["editEmissionReduction"].id}
        emissionReduction={
          modalProps["editEmissionReduction"].emissionReduction
            ? modalProps["editEmissionReduction"].emissionReduction
            : ({} as EmissionReductionResponse)
        }
      />

      <DeleteEmissionReductionForm
        modalProps={modalProps}
        hideModal={() => hideModal("deleteEmissionReduction")}
        emissionReduction={emissionReduction}
        setEmissionReduction={setEmissionReduction}
      />
    </>
  );
};

export default IndexEmissionReduction;
