import { Mapping } from "../models/common/Mapping";
import { Classification, MappingDefResponse, DashboardSignalResponse, DashboardVesselResponse, FuelType, Property } from "./../services/WebApiService";

const getSignalsByMappingsByVessels = (dashboardVessels: DashboardVesselResponse[], mappings: Mapping[], addVesselName: string) => {
  const result: DashboardSignalResponse[] = [];
  for (let i = 0; i < dashboardVessels.length; i++) {
    const vessel = dashboardVessels[i];
    const signals = dashboardVessels[i].signals;
    for (let j = 0; j < signals.length; j++) {
      const signal = signals[j];
      for (let k = 0; k < mappings.length; k++) {
        const mapping = mappings[k];
        if (signal.classification === mapping.classification && signal.property === mapping.property && signal.classificationIndex === parseInt(mapping.index)) {
          if (mapping.fuelType !== FuelType.All) {
            if (signal.fuelType === mapping.fuelType) {
              const obj = { ...signal } as DashboardSignalResponse;
              if (addVesselName === "true") {
                obj.displayName = `${vessel.vesselName} - ${signal.displayName}`;
              }
              const resultObj = result.find(x => x.id === obj.id);
              if (!resultObj) {
                result.push(obj);
              }

            }
          } else {
            const obj = { ...signal } as DashboardSignalResponse;
            if (addVesselName === "true") {
              obj.displayName = `${vessel.vesselName} - ${signal.displayName}`;
            }
            const resultObj = result.find(x => x.id === obj.id);
            if (!resultObj) {
              result.push(obj);
            }
          }
        }
      }
    }
  }

  return result;
}

const getFirstSignalFromMapping = (dashboardVessels: DashboardVesselResponse[], mapping: Mapping) => {
  for (let i = 0; i < dashboardVessels.length; i++) {
    const signals = dashboardVessels[i].signals;
    for (let j = 0; j < signals.length; j++) {
      const signal = signals[j];
      if (signal.classification === mapping.classification && signal.property === mapping.property && signal.classificationIndex === parseInt(mapping.index)) {
        if (mapping.fuelType !== FuelType.All) {
          if (signal.fuelType === mapping.fuelType) {
            const obj = { ...signal } as DashboardSignalResponse;
            return obj;
          }
        } else {
          const obj = { ...signal } as DashboardSignalResponse;
          return obj;
        }
      }
    }
  }
  return null;
}

const getLatitude = (signals: DashboardSignalResponse[]) => {
  return signals.find(x => x.property === Property.Latitude);
}

const getLongitude = (signals: DashboardSignalResponse[]) => {
  return signals.find(x => x.property === Property.Longitude);
}

const getSog = (signals: DashboardSignalResponse[]) => {
  return signals.find(x => x.property === Property.SpeedOverGround);
}

const getCog = (signals: DashboardSignalResponse[]) => {
  return signals.find(x => x.property === Property.CourseOverGround);
}

const getPlcLiveData = (signals: DashboardSignalResponse[]) => {
  return signals.filter(x => x.isPlcLiveData);
}

const getSignalsByMappings = (signals: DashboardSignalResponse[], mappings: Mapping[]) => {
  const result: DashboardSignalResponse[] = [];
  for (let i = 0; i < mappings.length; i++) {
    const mapping = mappings[i];
    let signal = signals.filter(x => x.classification === mapping.classification && x.property === mapping.property && x.classificationIndex === parseInt(mapping.index));
    if (mapping.fuelType !== FuelType.All) {
      signal = signal.filter(x => x.fuelType === mapping.fuelType);
    }
    result.push(...signal);
  }
  return result;
}


const getOnlyClassifications = (mappings: MappingDefResponse[]): MappingDefResponse[] => {
  const result = new Set<MappingDefResponse>();
  const classificationSet = new Set<Classification>();
  for (let i = 0; i < mappings.length; i++) {
    const obj = mappings[i];
    if (!classificationSet.has(obj.classification)) {
      classificationSet.add(obj.classification);
      result.add(obj);
    }
  }
  return Array.from(result);
}


const SignalHelper = {
  getSignalsByMappingsByVessels,
  getSignalsByMappings,
  getPlcLiveData,
  getFirstSignalFromMapping,
  getOnlyClassifications,
  getLatitude,
  getLongitude,
  getSog,
  getCog
}

export default SignalHelper;