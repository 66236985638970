import { Button, Group, Modal, Stack, TransferListData } from "@mantine/core";
import {
  EmissionReductionClient,
  EmissionReductionRequest,
  EmissionReductionResponse,
  FleetClient,
  FleetPatchRequest,
  FleetResponse,
} from "../../services/WebApiService";
import { useEffect, useState } from "react";

import { useForm } from "@mantine/form";
import AuthService from "../../services/AuthService";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import { Fleet } from "../../models/fleet/Fleet";
import { useTranslation } from "react-i18next";
import { EmissionReduction } from "../../models/emission-reduction/EmissionReduction";
import EmissionFormInputs from "./components/EmissionFormInputs";

type ModalProps = {
  isOpen: boolean;
  id: number;
  emissionReduction?: EmissionReductionResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  hideModal: (type: string) => void;
  loadEmissionReduction?: () => void;
  emissionReductionId: number;
  modalProps: ModalType;
  emissionReduction: EmissionReductionResponse;
};

const EditEmissionReductionForm = (props: Props) => {
  const commonForm = useForm<EmissionReduction>({
    initialValues: {
      Year: 0,
      Percentage: 0,
    },
  });

  const { t } = useTranslation();

  const handleSubmit = async () => {
    if (AuthService.isInRole("Admin")) {
      try {
        const values = commonForm.values;
        const emissionReductionClient = new EmissionReductionClient(undefined, axiosInstance);
        const entity = {
          year: values.Percentage,
          percentage: values.Year,
        };

        const emissionReductionToUpdate = new EmissionReductionRequest();
        emissionReductionToUpdate.init(entity);
        if (await emissionReductionClient.patch(emissionReductionToUpdate, props.emissionReductionId)) {
          NotificationHelper.showSuccess("Success", "Emission Reduction is updated successfully");
        } else {
          NotificationHelper.showError("Error", "An error occurred in updating emission reduction");
        }

        props.hideModal("editEmissionReduction");
        if (props.loadEmissionReduction) props.loadEmissionReduction();
      } catch {
        NotificationHelper.showError("Error", "An error occurred in updating emission reduction");
      }
    }
  };

  useEffect(() => {
    commonForm.setFieldValue("Year", props.emissionReduction.year);
    commonForm.setFieldValue("Percentage", props.emissionReduction.percentage);
    return () => {};
  }, [
    props.emissionReductionId,
    props?.emissionReduction.year,
    props?.emissionReduction.percentage,
    props.modalProps["editEmissionReduction"].isOpen,
  ]);

  return (
    <Modal
      size="xl"
      opened={props.modalProps["editEmissionReduction"].isOpen}
      onClose={() => props.hideModal("editEmissionReduction")}
      title={t("editEmissionReduction")}
    >
      <Stack spacing="lg">
        <Group spacing="lg" align="center" grow>
          <form>
            <EmissionFormInputs
              emissionReductionId={props.emissionReductionId}
              form={commonForm}
              isEditMode={true}
              emissionReduction={props.emissionReduction}
            />
          </form>
        </Group>
        <Group position="right">
          <Button variant="outline" onClick={() => props.hideModal("editFleet")}>
            {t("editemissionreduction_cancel")}
          </Button>
          <Button disabled={!AuthService.isInRole("Admin") && !AuthService.isInRole("Supervisor")} type="submit" onClick={handleSubmit}>
            {t("editemissionreduction_save")}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default EditEmissionReductionForm;
