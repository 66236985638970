import { Group, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { CommonEditReportSetting } from "../../../../../models/report-setting/CommonEditReportSetting";
import ContrySelectInput from "../../../../../components/CountrySelectInput";
import { useTranslation } from "react-i18next";

type Props = {
  form: UseFormReturnType<CommonEditReportSetting>;
};

const VerifierSectionInput = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Group grow spacing="lg" pb="sm" align="end">
        <TextInput
          label={t("vesselreportsetting_verifiername")}
          description={t("vesselreportsetting_verifiernamedesc")}
          {...props.form.getInputProps("VerifierName")}
        />
      </Group>
      <Group grow spacing="lg" align="end">
        <TextInput
          label={t("vesselreportsetting_verifieraddr")}
          description={t("vesselreportsetting_verifieraddrdesc")}
          {...props.form.getInputProps("VerifierAddressLineOne")}
        />
      </Group>
      <Group grow spacing="lg" pb="sm" align="end">
        <TextInput {...props.form.getInputProps("VerifierAddressLineTwo")} />
      </Group>
      <Group grow spacing="lg" pb="md" align="end">
        <TextInput
          label={t("vesselreportsetting_verifiercity")}
          description={t("vesselreportsetting_verifiercitydesc")}
          {...props.form.getInputProps("VerifierCity")}
        />

        <TextInput
          label={t("vesselreportsetting_verifierstate")}
          description={t("vesselreportsetting_verifierstatedesc")}
          {...props.form.getInputProps("VerifierState")}
        />

        <TextInput
          label={t("vesselreportsetting_verifierpostcode")}
          description={t("vesselreportsetting_verifierpostcodedesc")}
          {...props.form.getInputProps("VerifierPostCode")}
        />
      </Group>
      <Group grow spacing="lg" pb="md" align="end">
        <ContrySelectInput
          inputPropsName="VerifierCountry"
          form={props.form}
          label={t("vesselreportsetting_verifiercountry")}
          description={t("vesselreportsetting_verifiercountrydesc")}
        />
      </Group>

      <Group grow spacing="lg" pb="md" align="end">
        <TextInput
          label={t("vesselreportsetting_verifieraccno")}
          description={t("vesselreportsetting_verifieraccnodesc")}
          {...props.form.getInputProps("VerifierAccreditationNumber")}
        />

        <TextInput
          label={t("vesselreportsetting_verifierstatement")}
          description={t("vesselreportsetting_verifierstatementdesc")}
          {...props.form.getInputProps("VerifierStatement")}
        />
      </Group>
    </>
  );
};

export default VerifierSectionInput;
