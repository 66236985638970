import { Button, Card, Container, Divider, Group, Stack, Title, Transition } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MapWidgetSignalForm } from "../../common/Models";
import { useForm } from "@mantine/form";
import MapWidgetMappings from "../components/MapWidgetMappings";
import { SignalMappingResponse, VesselsClient, MapWidgetSignalClient, MapWidgetSignalRequest } from "../../services/WebApiService";
import { axiosInstance } from "../../services/AxiosService";
import AuthService from "../../services/AuthService";
import { useParams } from "react-router-dom";
import NotificationHelper from "../../helpers/NotiHelper";
import { MapWidgetSignal } from "../../models/common/MapWidgetSignal";

const IndexMapSignals = () => {
  const [mounted, setMounted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const { vesselId } = useParams();
  const [signals, setSignals] = useState<SignalMappingResponse[]>([]);

  const { t } = useTranslation();

  const mappingForm = useForm<MapWidgetSignalForm>({
    initialValues: {
      signals: [],
    },
  });

  const hasSignalDuplicates = (array: MapWidgetSignal[]) => {
    const seen = new Set();
    for (const item of array) {
      // Combine the properties you want to use for comparison into a single string
      const key = item.signalid;
      if (seen.has(key)) {
        return true; // Found a duplicate
      }
      seen.add(key);
    }
    return false; // No duplicates found
  };

  const handleSubmit = async () => {
    const values = mappingForm.values;
    const result: MapWidgetSignalRequest[] = [];
    const mapWidgetClient = new MapWidgetSignalClient(undefined, axiosInstance);

    if (hasSignalDuplicates(values.signals)) {
      NotificationHelper.showError("Error", "There is duplicates selections of signals!");
    } else {
      for (const v of values.signals) {
        result.push({ signalId: v.signalid, order: parseInt(v.index), vesselId: parseInt(vesselId ? vesselId : "0") } as MapWidgetSignalRequest);
      }

      if (result.length > 0) {
        try {
          const response = await mapWidgetClient.add(result);

          if (response.length > 0) {
            NotificationHelper.showSuccess("Success", "Mappings updated!");
          }
        } catch {
          NotificationHelper.showError("Error", "An error occurred in updating mappings");
        }
      }
    }
  };

  useEffect(() => {
    const loadSignals = async () => {
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      const user = AuthService.getCurrentUser();
      const vesselSignalPromise = await vesselClient.signalMappingsGET(parseInt(vesselId ? vesselId : "0"), null, user?.userid);
      setSignals(vesselSignalPromise);
    };

    const loadMapWidgetSignals = async () => {
      const mapWidgetSignalClient = new MapWidgetSignalClient(undefined, axiosInstance);
      const mapSignalPromise = await mapWidgetSignalClient.getByVesselId(parseInt(vesselId ? vesselId : "0"));
      for (const m of mapSignalPromise) {
        mappingForm.insertListItem("signals", { signalid: m.signalId, index: m.order.toString() } as MapWidgetSignal);
      }
    };

    if (firstLoad) {
      setFirstLoad(false);
      loadSignals();
      loadMapWidgetSignals();
    }

    return () => {};
  }, [firstLoad, vesselId]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }

    return () => {};
  }, [mounted]);

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Group>
              <Title order={2}>{t("mapsignals")}</Title>
            </Group>
            <Divider my="sm" size="md"></Divider>
            <Container size="xl">
              <Stack>
                <Card shadow="sm" p="lg" withBorder>
                  <Card.Section p="md" withBorder>
                    <Title order={3}>{t("mapsignals_configure")}</Title>
                  </Card.Section>
                  <Card.Section p="lg">
                    <br />
                    <MapWidgetMappings label={""} form={mappingForm} signal={signals} disabled={false} />
                    <br />
                  </Card.Section>
                  <Card.Section p="md" withBorder>
                    <Button onClick={handleSubmit}>{t("mapsignals_submit")}</Button>
                  </Card.Section>
                </Card>
              </Stack>
            </Container>
          </div>
        )}
      </Transition>
    </>
  );
};

export default IndexMapSignals;
