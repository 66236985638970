import { Button, Divider, Group, Modal, Stack, Title, Transition, Text, ActionIcon, useMantineTheme } from "@mantine/core";
import { Delete, Edit } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotificationHelper from "../helpers/NotiHelper";
import { axiosInstance } from "../services/AxiosService";
import { AutomaticReportsClient, AutomaticReportTableResponse } from "../services/WebApiService";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../providers/GlobalContextProvider";

type Props = {};

type ModalProps = {
  isOpen: boolean;
  id: number;
};
const IndexAutomaticReport = (props: Props) => {
  const { systemGlobal } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });

  const navigate = useNavigate();
  const mantineTheme = useMantineTheme();
  const [mounted, setMounted] = useState(false);
  const [modalProps, setmodalProps] = useState<ModalProps>({ id: 0, isOpen: false });
  const [automaticReports, setAutomaticReports] = useState<AutomaticReportTableResponse[]>([]);

  const { t } = useTranslation();

  const handleDeleteAutomaticReport = async () => {
    if (modalProps.id !== 0) {
      try {
        const reportClient = new AutomaticReportsClient(undefined, axiosInstance);
        const response = await reportClient.delete(modalProps.id);
        if (response && response.status === 200) {
          setAutomaticReports((x) => x.filter((y) => y.id !== modalProps.id));
          hideModal();
          NotificationHelper.showSuccess("Success", "Report has been successfully deleted");
        } else {
          NotificationHelper.showError("Error", "An error has occurred in deleting report");
        }
      } catch {
        NotificationHelper.showError("Error", "An error has occurred in deleting kpi");
      }
    }
  };

  const navigateCreateAutomaticReport = () => {
    navigate(`/automatic-report/create`);
  };

  const navigateDownloadReport = () => {
    navigate(`/automatic-report/list`);
  };

  const hideModal = () => {
    setmodalProps((x) => {
      return { ...x, isOpen: false } as ModalProps;
    });
  };

  const showModal = (id: number) => {
    setmodalProps((x) => {
      return { ...x, id: id, isOpen: true };
    });
  };

  const loadDataGrid = () => {
    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          autoHeight
          rows={automaticReports}
          columns={[
            // {
            //   field: "id",
            //   headerName: "Id",
            //   flex: 1
            // },
            {
              field: "name",
              headerName: t("manageautoreport_name"),
              flex: 1,
            },
            {
              field: "reportLevel",
              headerName: t("manageautoreport_reportlevel"),
              flex: 1,
            },
            {
              field: "triggerType",
              headerName: t("manageautoreport_triggertype"),
              flex: 1,
            },
            {
              field: "dataInterval",
              headerName: t("manageautoreport_dataintervalmins"),
              flex: 1,
            },
            {
              field: "edit",
              headerName: t("manageautoreport_edit"),
              renderCell: (cell) => (
                <ActionIcon variant="subtle" color={mantineTheme.primaryColor} component={Link} to={`/automatic-report/edit/${cell.row.id}`}>
                  <Edit />
                </ActionIcon>
              ),
            },
            {
              field: "delete",
              headerName: t("manageautoreport_delete"),
              renderCell: (cell) => (
                <ActionIcon onClick={() => showModal(cell.row.id)} variant="subtle" color="red">
                  <Delete />
                </ActionIcon>
              ),
            },
          ]}
        />
      </ThemeProvider>
    );
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    return () => {};
  }, [mounted]);

  useEffect(() => {
    const loadAutomaticReport = async () => {
      const reportClient = new AutomaticReportsClient(undefined, axiosInstance);
      const data = await reportClient.getAll();
      setAutomaticReports(data);
    };
    loadAutomaticReport();
    return () => {};
  }, []);

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Group>
              <Title order={2}>{t("manageautoreport_automaticreport")}</Title>
              <Button onClick={navigateCreateAutomaticReport}>{t("manageautoreport_createnewreport")}</Button>
              <Button onClick={navigateDownloadReport}>{t("manageautoreport_downloadreport")}</Button>
            </Group>
            <Divider my="sm" size="md"></Divider>
            {loadDataGrid()}
          </div>
        )}
      </Transition>
      <Modal opened={modalProps.isOpen} onClose={hideModal} title="Confirmation">
        <Stack>
          <Text>Are you sure you want to delete this report? This action is irreversible!</Text>
          <Group spacing="lg" align="center" grow>
            <Button variant="outline" color="red" onClick={handleDeleteAutomaticReport}>
              Yes
            </Button>
            <Button onClick={hideModal}>No</Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};
export default IndexAutomaticReport;
