import { Button, Group, Modal, Stack, TransferListData } from "@mantine/core";
import { useForm } from "@mantine/form";
import AuthService from "../../services/AuthService";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import { AppUserResponse, DataTransferApiKeyClient, DataTransferApiKeyPostRequest, DataTransferApiKeyResponse } from "../../services/WebApiService";
import { useEffect, useState } from "react";
import DataTransferFormInputs from "./common/DataTransferFormInputs";
import { useTranslation } from "react-i18next";
import { DataTransferApiKey } from "../../models/datatransfer-apikey/DataTranferApiKey";

type ModalProps = {
  isOpen: boolean;
  id: number;
  dataTransfer?: DataTransferApiKeyPostRequest;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  hideModal: (type: string) => void;
  loadDataTransfer?: () => void;
  modalProps: ModalType;
  dataTransferApiKeys: DataTransferApiKeyResponse[];
};

const CreateDataTransferForm = (props: Props) => {
  const [user, setUser] = useState<AppUserResponse[]>([]);
  const { t } = useTranslation();

  const commonForm = useForm<DataTransferApiKey>({
    initialValues: {
      AppUserId: "",
    },
  });

  const handleSubmit = async () => {
    if (AuthService.isInRole("Admin")) {
      try {
        const values = commonForm.values;
        const dataTransferApiKeyClient = new DataTransferApiKeyClient(undefined, axiosInstance);
        const entity = {
          appUserId: values.AppUserId,
        };

        const dataTransferApiKeyToCreate = new DataTransferApiKeyPostRequest();
        dataTransferApiKeyToCreate.init(entity);
        await dataTransferApiKeyClient.post(dataTransferApiKeyToCreate);
        NotificationHelper.showSuccess("Success", "Data Transfer is created successfully");
        props.hideModal("createDataTransfer");
        if (props.loadDataTransfer) props.loadDataTransfer();
      } catch {
        NotificationHelper.showError("Error", "An error occurred in creating data transfer");
      }
    }
  };

  useEffect(() => {
    commonForm.setFieldValue("AppUserId", "");
    return () => {};
  }, [props.modalProps["createDataTransfer"].isOpen]);

  return (
    <>
      <Modal
        size="xl"
        opened={props.modalProps["createDataTransfer"].isOpen}
        onClose={() => props.hideModal("createDataTransfer")}
        title={t("createdatatransferapikey")}
      >
        <Stack spacing="lg">
          <Group spacing="lg" align="center" grow>
            <form>
              <DataTransferFormInputs
                dataTransferId={0}
                form={commonForm}
                isEditMode={false}
                user={user}
                setUser={setUser}
                dataTransferApiKeys={props.dataTransferApiKeys}
              />
            </form>
          </Group>
          <Group position="right">
            <Button variant="outline" onClick={() => props.hideModal("createDataTransfer")}>
              {t("createdatatransferapikey_cancel")}
            </Button>
            <Button disabled={!AuthService.isInRole("Admin")} type="submit" onClick={handleSubmit}>
              {t("createdatatransferapikey_create")}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default CreateDataTransferForm;
