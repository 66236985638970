const systemMode = process.env.REACT_APP_SYSTEM_MODE;
const webApiUrl = process.env.REACT_APP_WEB_API_URL;
const demoMode = process.env.REACT_APP_DEMO_MODE;

//check if onshore or onboard
const isVesselSystemMode = systemMode === "Vessel";

export const EnvService = {
  systemMode: systemMode,
  isVesselSystemMode: isVesselSystemMode,
  webApiUrl: webApiUrl,
  demoMode: demoMode,
};
