import { ActionIcon, Button, Divider, Group, Modal, Stack, Title, Text, Transition, useMantineTheme } from "@mantine/core";
import { Delete, Edit } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import { KpiClient, KpiResponse, VesselsClient } from "../../services/WebApiService";
import AuthService from "../../services/AuthService";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../providers/GlobalContextProvider";

type Props = {};

type ModalProps = {
  isOpen: boolean;
  id: number;
};

const IndexKpi = (props: Props) => {
  const { systemGlobal } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });

  const { t } = useTranslation();

  const navigate = useNavigate();
  const mantineTheme = useMantineTheme();
  const [mounted, setMounted] = useState(false);
  const [modalProps, setmodalProps] = useState<ModalProps>({ id: 0, isOpen: false });
  const [kpiTableData, setKpiTableData] = useState<KpiResponse[]>([]);
  const { vesselId } = useParams();

  const handleDeleteKpi = async () => {
    if (modalProps.id !== 0) {
      try {
        const kpiClient = new KpiClient(undefined, axiosInstance);
        const response = await kpiClient.delete(modalProps.id);
        if (response && response.status === 200) {
          setKpiTableData((x) => x.filter((y) => y.id !== modalProps.id));
          hideModal();
          NotificationHelper.showSuccess("Success", "Kpi has been successfully deleted");
        } else {
          NotificationHelper.showError("Error", "An error has occurred in deleting kpi");
        }
      } catch {
        NotificationHelper.showError("Error", "An error has occurred in deleting kpi");
      }
    }
  };

  const navigateCreateKpi = () => {
    navigate(`/vessel/${vesselId}/kpi/create`);
  };

  const navigateTrimSetting = () => {
    navigate(`/vessel/${vesselId}/kpi/trim-setting`);
  };

  const hideModal = () => {
    setmodalProps((x) => {
      return { ...x, isOpen: false } as ModalProps;
    });
  };

  const showModal = (id: number) => {
    setmodalProps((x) => {
      return { ...x, id: id, isOpen: true };
    });
  };

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    const loadKpi = async () => {
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      const user = AuthService.getCurrentUser();
      const data = await vesselClient.kpis(Number(vesselId), user?.userid);
      setKpiTableData(data);
    };
    loadKpi();
    return () => {};
  }, [vesselId]);

  const loadDataGrid = () => {
    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          autoHeight
          rows={kpiTableData}
          columns={[
            // {
            //   field: "id",
            //   headerName: "Id",
            //   flex: 1
            // },
            {
              field: "name",
              headerName: t("managekpi_name"),
              flex: 1,
            },
            {
              field: "kpiType",
              headerName: t("managekpi_kpitype"),
              flex: 1,
            },
            {
              field: "order",
              headerName: t("managekpi_order"),
              flex: 1,
            },
            {
              field: "edit",
              headerName: t("managekpi_edit"),
              renderCell: (cell) => (
                <ActionIcon variant="subtle" color={mantineTheme.primaryColor} component={Link} to={`/vessel/${vesselId}/kpi/edit/${cell.row.id}`}>
                  <Edit />
                </ActionIcon>
              ),
            },
            {
              field: "delete",
              headerName: t("managekpi_delete"),
              renderCell: (cell) => (
                <ActionIcon onClick={() => showModal(cell.row.id)} variant="subtle" color="red">
                  <Delete />
                </ActionIcon>
              ),
            },
          ]}
        />
      </ThemeProvider>
    );
  };

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Group>
              <Title order={2}>{t("managekpi")}</Title>
              <Button onClick={navigateCreateKpi}>{t("managekpi_createkpi")}</Button>
              <Button onClick={navigateTrimSetting}>{t("managekpi_trimsetting")}</Button>
            </Group>
            <Divider my="sm" size="md"></Divider>
            {loadDataGrid()}
          </div>
        )}
      </Transition>
      <Modal opened={modalProps.isOpen} onClose={hideModal} title="Confirmation">
        <Stack>
          <Text>Are you sure you want to delete this kpi? This action is irreversible!</Text>
          <Group spacing="lg" align="center" grow>
            <Button variant="outline" color="red" onClick={handleDeleteKpi}>
              Yes
            </Button>
            <Button onClick={hideModal}>No</Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default IndexKpi;
