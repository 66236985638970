import React, { useEffect, useState } from 'react'

type Props = {
  coordinates: google.maps.LatLng[]
  map: google.maps.Map | undefined
}

const GoogleMapPolyline = (props: Props) => {
  const [polyline, setPolyline] = useState<google.maps.Polyline>();
  useEffect(() => {
    if (!polyline) {
      setPolyline(new google.maps.Polyline());
    }

    return () => {
      if (polyline) {
        polyline.setMap(null);
      }
    }
  }, [polyline])

  useEffect(() => {
    if (polyline && props.map) {
      polyline.setOptions({
        map: props.map,
        path: props.coordinates,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      })
    }

    return () => {
    }
  }, [polyline, props.coordinates, props.map])


  return (
    <></>
  )
}

export default GoogleMapPolyline