import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthService from "../services/AuthService";

type Props = {
  children?: React.ReactNode;
  type: "Admin" | "Engineer" | "Normal";
};
const ProtectedRoute = (props: Props) => {
  const rolesToCheck: string[] = [];
  const location = useLocation();
  const checkAuth = () => {
    const user = AuthService.getCurrentUser();
    if (user) {
      let isAllowed = false;
      if (props.type === "Admin") {
        rolesToCheck.push("Admin");
        rolesToCheck.push("Supervisor");
      } else if (props.type === "Engineer") {
        rolesToCheck.push("Admin");
        rolesToCheck.push("Supervisor");
        rolesToCheck.push("Engineer");
      }

      if (rolesToCheck.length === 0) {
        // No roles to check, user is authorized
        isAllowed = true;
      } else {
        isAllowed = AuthService.isInRoleArray(rolesToCheck);
      }

      if (isAllowed) {
        if (props.children) {
          return props.children;
        } else {
          return <Outlet />;
        }
      } else {
        return <Navigate to={"/account/access-denied"} replace />;
      }
    } else {
      return <Navigate to={`/account/login?redirect=${location.pathname}`} replace />;
    }
  };
  return <>{checkAuth()}</>;
};
export default ProtectedRoute;
