import { Badge, createStyles, Group, Paper, Stack, Text } from "@mantine/core";
import { Select } from "@mantine/core";

import { Apartment, Dashboard, Description, DesktopMacOutlined, DirectionsBoat, Key, PeopleAlt, Sailing, MoveUp } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LanguagesList from "../json/languages.json";

import { GlobalContext } from "../providers/GlobalContextProvider";
import AuthService from "../services/AuthService";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  settingSubMenu: {
    position: "absolute",
    width: "500px",
    top: "50px",
    right: "35px",
    zIndex: 99,
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : "white",
  },
  settingSubMenuItem: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : "#f4f5f7",
    },
    "& svg": {
      color: "white",
      backgroundColor: theme.colors[theme.primaryColor][7],
      padding: "4px",
      fontSize: "32px",
      borderRadius: theme.radius.sm,
    },
  },
  titleColor: { color: theme.colorScheme === "light" ? "black" : "white" },
  badgeBackground: { backgroundColor: theme.colorScheme === "light" ? "white" : "#25262b" },
  descColor: { color: theme.colorScheme === "light" ? "grey" : "#b9babc" },
}));

type SettingGroup = {
  category: string;
  links: SettingLink[];
  key: string;
};

type SettingLink = {
  title: string;
  url: string;
  icon: JSX.Element;
  description: string;
};

type Props = {
  isOpen: boolean;
  handleNavMenu: (id: number, settingIsOpen: boolean, accountIsOpen: boolean) => void;
};

const TopNavSettingSubMenu = (props: Props) => {
  const { systemGlobal, isSystemModeVessel } = useContext(GlobalContext);
  const [settingGroups, setSettingGroups] = useState<SettingGroup[]>([]);
  const { classes } = useStyles();

  const { i18n, t } = useTranslation();

  useEffect(() => {
    const loadSettings = () => {
      const result: SettingGroup[] = [];
      let vesselUrl = "/vessel";
      if (isSystemModeVessel()) {
        let firstUrl = "";
        if (AuthService.isInRoleArray(["Admin", "Supervisor", "CompanyAdmin"])) {
          firstUrl = "signal-mapping";
        } else if (AuthService.isInRole("Engineer")) {
          firstUrl = "manual-input";
        } else {
          firstUrl = "automatic-report";
        }
        if (systemGlobal.dashboardVesselId) {
          vesselUrl = `/vessel/${systemGlobal.dashboardVesselId}/${firstUrl}`;
        } else {
          vesselUrl = "/vessel/not-found";
        }
      }
      result.push(
        {
          key: "Admin",
          category: t("settingsmenu_admin"),
          links: [
            {
              title: t("settingsmenu_usermanagement"),
              description: t("settingsemnu_usermanagementdesc"),
              icon: <PeopleAlt />,
              url: "/users",
            },
            {
              title: t("settingsmenu_licenseimport"),
              description: t("settingsmenu_licenseimportdesc"),
              icon: <Key />,
              url: "/license",
            },
            {
              title: t("settingsmenu_vesselmanagement"),
              description: t("settingsmenu_vesselmanagementdesc"),
              icon: <DirectionsBoat />,
              url: vesselUrl,
            },
          ],
        },
        {
          key: "Personal",
          category: t("settingsmenu_personal"),
          links: [
            {
              title: t("settingsmenu_screensettings"),
              description: t("settingsmenu_screensettingsdesc"),
              icon: <Dashboard />,
              url: "/dashboard",
            },
          ],
        }
      );

      if (!isSystemModeVessel()) {
        if (AuthService.isInRole("Admin")) {
          result
            .find((x) => x.key === "Admin")
            ?.links.push({
              title: t("settingsmenu_datatransfermanage"),
              description: t("settingsmenu_datatransfermanagedesc"),
              icon: <MoveUp />,
              url: "/datatransfer/manage",
            });
        }

        result
          .find((x) => x.key === "Admin")
          ?.links.push(
            {
              title: t("settingsmenu_companymanagement"),
              description: t("settingsmenu_companymanagementdesc"),
              icon: <Apartment />,
              url: "/company",
            },
            {
              title: t("settingsmenu_fleetmanagement"),
              description: t("settingsmenu_fleetmanagementdesc"),
              icon: <Sailing />,
              url: "/fleet",
            }
          );

        result
          .find((x) => x.key === "Personal")
          ?.links.push({
            title: t("settingsmenu_tutorialonboard"),
            description: t("settingsmenu_tutorialonboarddesc"),
            icon: <Description />,
            url: "/documentation/tutorial-onshore",
          });
      }

      if (isSystemModeVessel()) {
        result
          .find((x) => x.key === "Admin")
          ?.links.push({
            title: t("settingsmenu_systemsetting"),
            description: t("settingsmenu_systemsettingdesc"),
            icon: <DesktopMacOutlined />,
            url: "/system-setting",
          });

        result
          .find((x) => x.key === "Personal")
          ?.links.push({
            title: t("settingsmenu_tutorialonboard"),
            description: t("settingsmenu_tutorialonboarddesc"),
            icon: <Description />,
            url: "/documentation/tutorial-onboard",
          });
      }

      setSettingGroups(result);
    };
    loadSettings();
    return () => {};
  }, [isSystemModeVessel, systemGlobal.dashboardVesselId, systemGlobal.systemMode, t]);

  const displaySettings = settingGroups.map((x) => {
    return (
      <React.Fragment key={x.category}>
        <Group pl="xs" pt="xs">
          <Badge size="lg" color="dark" className={classes.badgeBackground}>
            {x.category}
          </Badge>
        </Group>
        {x.links.map((y) => {
          return (
            <Stack key={y.title}>
              <Link to={y.url} className={classes.settingSubMenuItem}>
                <Group p="xs">
                  {y.icon}
                  <Stack spacing={0}>
                    <Text className={classes.titleColor}>{y.title}</Text>
                    <Text size="xs" lineClamp={2} className={classes.descColor}>
                      {y.description}
                    </Text>
                  </Stack>
                </Group>
              </Link>
            </Stack>
          );
        })}
      </React.Fragment>
    );
  });

  const getDisplayValue = () => {
    if (props.isOpen) {
      return "block";
    }
    return "none";
  };

  return (
    <Paper className={classes.settingSubMenu} withBorder shadow="md" p="md" style={{ display: getDisplayValue() }}>
      <Stack align="stretch" spacing={0}>
        <Group pl="xs" pt="xs" key="language">
          <Stack key="language-stack">
            <Select
              label={`${t("settingsmenu_language")}:`}
              placeholder="Pick A Language"
              data={LanguagesList}
              defaultValue="en"
              onChange={(e) => {
                if (e) i18n.changeLanguage(e);
                props.handleNavMenu(-1, !props.isOpen, false);
              }}
            />
          </Stack>
        </Group>
        {displaySettings}
      </Stack>
    </Paper>
  );
};

export default TopNavSettingSubMenu;
