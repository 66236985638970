import { Button, Group, Modal, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import AuthService from "../../services/AuthService";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import { Classification, CustomSignalRequest, FuelType, Property, SignalResponse, SignalsClient } from "../../services/WebApiService";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CustomSignal } from "../../models/signals/CustomSignal";
import { GlobalContext } from "../../providers/GlobalContextProvider";

type ModalProps = {
  isOpen: boolean;
  id: number;
  signal?: SignalResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  hideModal: (type: string) => void;
  loadSignals?: () => void;
  modalProps: ModalType;
  signalId: number;
  signal: SignalResponse;
};

const EditCustomSignal = (props: Props) => {
  const { t } = useTranslation();

  const { systemGlobal } = useContext(GlobalContext);

  const commonForm = useForm<CustomSignal>({
    initialValues: {
      DisplayName: "",
    },
  });

  const handleSubmit = async () => {
    if (AuthService.isInRole("Admin")) {
      try {
        const values = commonForm.values;
        const signalsClient = new SignalsClient(undefined, axiosInstance);
        const entity = {
          displayName: values.DisplayName,
          vesselId: systemGlobal.dashboardVesselId,
          classification: Classification.NotApplicable,
          property: Property.NotApplicable,
          fuelType: FuelType.NotApplicable,
          index: 1,
        };

        const customSignalToCreate = new CustomSignalRequest();
        customSignalToCreate.init(entity);
        await signalsClient.updateCustomSignal(customSignalToCreate, props.signal.id);
        props.hideModal("editCustomSignal");
        if (props.loadSignals) props.loadSignals();

        NotificationHelper.showSuccess("Success", "Signal is udpated successfully");
      } catch {
        NotificationHelper.showError("Error", "An error occurred in editing custom signals");
      }
    }
  };

  useEffect(() => {
    commonForm.setFieldValue("DisplayName", props.signal.displayName);
    return () => {};
  }, [props.signalId, props?.signal, props.modalProps["editCustomSignal"].isOpen]);

  return (
    <>
      <Modal
        size="xl"
        opened={props.modalProps["editCustomSignal"].isOpen}
        onClose={() => props.hideModal("editCustomSignal")}
        title={t("editcustomsignal")}
      >
        <Stack spacing="lg">
          <Group spacing="lg" align="center" grow>
            <form>
              <TextInput required label={t("editcustomsignal_displayname")} {...commonForm.getInputProps("DisplayName")} />
            </form>
          </Group>
          <Group position="right">
            <Button variant="outline" onClick={() => props.hideModal("editCustomSignal")}>
              {t("editcustomsignal_cancel")}
            </Button>
            <Button type="submit" onClick={handleSubmit}>
              {t("editcustomsignal_edit")}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default EditCustomSignal;
