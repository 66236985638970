import { ActionIcon, Button, Card, Group, Select, Stack, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { RemoveCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import NotificationHelper from "../../../helpers/NotiHelper";
import { SubtractionOption } from "../../../models/kpi/KpiOptionJson";
import { KpiResponse, KpiType, SignalMappingResponse } from "../../../services/WebApiService";
import { useTranslation } from "react-i18next";

type Props = {
  signalMapping: SignalMappingResponse[];
  kpi?: KpiResponse;
  handleSubmit(optionJson: string): void;
};

const SubtractionKpiInput = (props: Props) => {
  const { t } = useTranslation();

  const [firstLoad, setFirstLoad] = useState(true);
  const form = useForm<SubtractionOption>({
    initialValues: {
      SignalIds: [],
    },
  });

  const addSignalMapping = () => {
    if (props.signalMapping.length > 0) {
      form.insertListItem("SignalIds", props.signalMapping[0].id.toString());
    }
  };

  const removeSignalMapping = (index: number) => {
    if (form.values.SignalIds.length > 1) {
      form.removeListItem("SignalIds", index);
    } else {
      NotificationHelper.showError("Error in removing", "Setting needs at least one signal item");
    }
  };

  const handleSubmit = async () => {
    if (form.values.SignalIds.length > 0) {
      const optionJson = JSON.stringify(form.values);
      props.handleSubmit(optionJson);
    } else {
      NotificationHelper.showError("Error in saving", "Setting needs at least one signal item");
    }
  };

  useEffect(() => {
    const loadForm = () => {
      if (firstLoad && props.kpi) {
        setFirstLoad(false);
        if (props.kpi.kpiType === KpiType.Subtraction) {
          const optionJson = JSON.parse(props.kpi.optionJson) as SubtractionOption;
          form.setFieldValue("SignalIds", optionJson.SignalIds);
        }
      }
    };
    loadForm();
    return () => {};
  }, [firstLoad, form, props.kpi]);

  return (
    <Card shadow="sm" p="lg" radius="sm" withBorder>
      <Card.Section withBorder p="lg">
        <Title order={3}>{t("subtractionkpisignal")}</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          {form.values.SignalIds.map((x, index) => {
            return (
              <Group grow align="end" key={`SignalId${x}${index}`}>
                <Select
                  withinPortal
                  searchable
                  nothingFound={t("subtractionkpisignal_nothingfound")}
                  label={t("subtractionkpisignal_signal")}
                  description={t("subtractionkpisignal_signaldesc")}
                  data={props.signalMapping.map((x) => ({ label: x.displayName, value: x.id.toString() }))}
                  {...form.getInputProps(`SignalIds.${index}`)}
                />
                <Group mb="xs">
                  <ActionIcon color="red" onClick={() => removeSignalMapping(index)}>
                    <RemoveCircle />
                  </ActionIcon>
                </Group>
              </Group>
            );
          })}
        </Stack>
        <Button mt="lg" variant="outline" onClick={addSignalMapping}>
          {t("subtractionkpisignal_addsignal")}
        </Button>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Button mt="lg" onClick={handleSubmit}>
          {t("subtractionkpisignal_save")}
        </Button>
      </Card.Section>
    </Card>
  );
};

export default SubtractionKpiInput;
