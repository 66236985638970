import * as echarts from "echarts/core";
import waldenTheme from "../chart-theme/custom-walden.json";
import {
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  MarkAreaComponent,
  MarkAreaComponentOption,
  DataZoomComponent,
  DataZoomComponentOption,
} from "echarts/components";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Divider, Flex, Grid, Group, Text, NumberInput, Select, SelectItem, Stack, Title } from "@mantine/core";
import AuthService from "../services/AuthService";
import { useForm } from "@mantine/form";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../services/AxiosService";
import { AccountsClient, TrimOpAngleClient, TrimOpCalculatedResponse, TrimOpKpiRequest, VesselsClient } from "../services/WebApiService";
import { Token } from "../common/Models";
import { BarChart, BarSeriesOption, LineChart, LineSeriesOption } from "echarts/charts";
import { MarkAreaOption, YAXisOption } from "echarts/types/dist/shared";

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  MarkAreaComponent,
  DataZoomComponent,
]);

type EChartsOption = echarts.ComposeOption<
  | ToolboxComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | BarSeriesOption
  | LineSeriesOption
  | MarkAreaComponentOption
  | DataZoomComponentOption
>;

type CartesianAxisPosition = "top" | "bottom" | "left" | "right";

type UnitAxisItem = {
  name: string;
  min?: number | undefined;
  max?: number | undefined;
};

type TrimOpChartForm = {
  speed: number;
  draftAft: number;
  draftFore: number;
  actPowerSignal: string;
  actTrimSignal: string;
  vesselId: string;
};

type DataItem = {
  name: string;
  isWarn: boolean;
  isAlarm: boolean;
  unit: string;
  value: [number, number];
};

const TrimOpChart = () => {
  const [chart, setChart] = useState<echarts.ECharts>();
  const [firstLoad, setFirstLoad] = useState(true);
  const [signalSelectItems, setSignalSelectItems] = useState<SelectItem[]>([]);
  const [trimOp, setTrimOp] = useState<TrimOpCalculatedResponse>();
  const [vessels, setVessels] = useState<SelectItem[]>([]);
  const { isSystemModeVessel, systemGlobal } = useContext(GlobalContext);
  const [trimOpType, setTrimOpType] = useState("Vessel");

  const colorThemeRef = useRef(systemGlobal.colorTheme);
  useEffect(() => {
    colorThemeRef.current = systemGlobal.colorTheme;
  }, [systemGlobal.colorTheme]);

  const { t } = useTranslation();

  const form = useForm<TrimOpChartForm>({
    initialValues: {
      speed: 0,
      draftAft: 0,
      draftFore: 0,
      actPowerSignal: "0",
      actTrimSignal: "0",
      vesselId: "0",
    },
  });

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    const loadVessels = async (user: Token) => {
      const accountClient = new AccountsClient(undefined, axiosInstance);
      const entities = await accountClient.vessels(user.userid);
      setVessels(
        entities.map((x) => ({
          value: x.id.toString(),
          label: x.name,
        }))
      );

      if (!isSystemModeVessel() && systemGlobal.dashboardVesselId) {
        form.setFieldValue("vesselId", String(systemGlobal.dashboardVesselId));
      } else {
        form.setFieldValue("vesselId", entities[0].id.toString());
      }
    };

    if (user && firstLoad) {
      loadVessels(user);
      setFirstLoad(false);
    }
    return () => {};
  }, [firstLoad, form, isSystemModeVessel, systemGlobal.dashboardVesselId]);

  useEffect(() => {
    const loadSignals = async () => {
      const vesselClient = new VesselsClient(undefined, axiosInstance);

      if (isSystemModeVessel()) {
        const signals = await vesselClient.signals(Number(form.values.vesselId));
        setSignalSelectItems(
          signals.map((x) => ({
            value: x.id.toString(),
            label: x.displayName,
          }))
        );
      } else {
        const user = AuthService.getCurrentUser();
        const signals = await vesselClient.signalMappingsGET(Number(form.values.vesselId), null, user?.userid);
        setSignalSelectItems(
          signals.map((x) => ({
            value: x.id.toString(),
            label: x.displayName,
          }))
        );
      }
    };

    if (form.values.vesselId) {
      form.setFieldValue("signalIds", []);
      loadSignals();
    }
    return () => {};
  }, [form.values.vesselId, isSystemModeVessel]);

  useEffect(() => {
    form.setFieldValue("speed", 0);
    form.setFieldValue("draftAft", 0);
    form.setFieldValue("draftFore", 0);
    form.setFieldValue("actPowerSignal", "0");
    form.setFieldValue("actTrimSignal", "0");
    return () => {};
  }, [isSystemModeVessel, systemGlobal.dashboardVesselId]);

  const handleGenerate = async () => {
    const trimOpAngleClient = new TrimOpAngleClient(undefined, axiosInstance);
    const entity = {
      speed: form.values.speed,
      draftAft: form.values.draftAft,
      draftFore: form.values.draftAft,
      actPowerSignal: form.values.actPowerSignal,
      actTrimSignal: form.values.actTrimSignal,
    };

    const trimOpToSave = new TrimOpKpiRequest();
    trimOpToSave.init(entity);

    var trimResult = await trimOpAngleClient.calculateTrimOp(trimOpToSave);
    setTrimOp(trimResult);

    createChart(trimResult);
  };

  const createChart = (chartData: TrimOpCalculatedResponse) => {
    const chartDom = document.getElementById(`chart`);
    if (chart) {
      chart.dispose();
    }

    if (!chartDom) {
      return;
    }
    echarts.registerTheme("walden", waldenTheme);
    const timeChart = echarts.init(chartDom, "walden");

    let arrPower = chartData.powerSpeedDraft.power.split(",");
    let arrPowerNum = arrPower.map((str) => parseFloat(str));
    arrPowerNum.push(chartData.actPowerValue);

    let arrAngle = chartData.trimAngle;
    arrAngle.push(chartData.actTrimValue);

    const minVal = Math.min(...arrPowerNum) ?? 0;
    const maxVal = Math.max(...arrPowerNum) + 10 ?? 1000;

    const minXVal = Math.min(...arrAngle) ?? -10;
    const maxXVal = Math.max(...arrAngle) + 2 ?? 10;

    const units = [{ name: "kW", min: minVal < 0 ? minVal - 10 : 0, max: maxVal }] as UnitAxisItem[];
    const seriesArray = createSeriesArray(chartData, units);
    //const legends = [`Trim [kW]`, `SPM`];

    const yAxisArray = createYAxisArray(units);

    const eChartsOption: EChartsOption = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
        formatter: (params: any, ticket: string) => {
          let formatter = "";
          formatter = `<p style="font-size: 16px; margin-bottom: 8px">${params[0].value[0]}, ${params[0].value[1]}(kW)<p>`;
          let labels = `<div style="display:flex; flex-direction:column; padding-right: 12px">`;
          let values = `<div style="display:flex; flex-direction:column; padding-right: 3px">`;
          let units = `<div style="display:flex; flex-direction:column">`;

          for (let i = 0; i < params.length; i++) {
            const obj = params[i];
            const isAlarm = obj.data.isAlarm;
            const isWarn = obj.data.isWarn;
            labels += `
            <div>
              ${obj.marker} ${obj.seriesName}
            </div>
            `;

            units += `
            <div style="display:flex">
              <p>${obj.data.unit}</p>
            </div>
            `;
          }

          values += `</div>`;
          labels += `</div>`;
          units += `</div>`;
          formatter += `
          <div style="display:flex">
            ${labels}
            ${values}
            ${units}
          </div>
          `;
          return formatter;
        },
      },
      dataZoom: [
        {
          type: "inside",
          throttle: 50,
        },
        {
          show: true,
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
        {
          show: true,
          type: "slider",
          yAxisIndex: 0,
          filterMode: "none",
        },
      ],

      toolbox: {
        feature: {
          dataView: { show: true, readOnly: false },
          // restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        textStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
      },
      xAxis: {
        type: "value",
        min: minXVal < 0 ? minXVal - 2 : 0,
        max: maxXVal,
        nameTextStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
        axisLabel: {
          color: systemGlobal.colorTheme === "dark" ? "white" : "black",
        },
      },
      yAxis: yAxisArray,
      series: seriesArray,
    };

    if (yAxisArray.length !== 0 || seriesArray.length !== 0) {
      timeChart.setOption(eChartsOption);
    } else {
      timeChart.setOption({});
    }
    setChart(timeChart);
  };

  const createYAxisArray = useCallback((units: UnitAxisItem[]) => {
    const result = Array<YAXisOption>();
    let position: CartesianAxisPosition = "left";
    let offset = 0;
    let oddOffset = 0;
    let evenOffset = 0;
    const axisBarOffset = 58;

    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      if (i % 2 === 0) {
        position = "left";
        if (i !== 0) {
          evenOffset += axisBarOffset;
          offset = evenOffset;
        }
      } else {
        position = "right";
        if (i !== 1) {
          oddOffset += axisBarOffset;
          offset = oddOffset;
        }
      }
      result.push({
        type: "value",
        nameLocation: "middle",
        nameGap: 42,
        name: unit.name,
        nameTextStyle: { color: colorThemeRef.current === "dark" ? "white" : "black" },
        offset: offset,
        position: position,
        alignTicks: true,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: `{value}`,
          color: colorThemeRef.current === "dark" ? "white" : "black",
        },
        max: unit.max,
        min: unit.min,
      });
    }

    return result;
  }, []);

  const createSeriesArray = (trimOp: TrimOpCalculatedResponse, units: UnitAxisItem[]) => {
    return createLineSeriesArray(trimOp, units);
  };

  const createLineSeriesArray = (trimOp: TrimOpCalculatedResponse, units: UnitAxisItem[]) => {
    const result = Array<LineSeriesOption>();

    const markAreaData: MarkAreaOption["data"] = [];

    trimOp.trimOpPercentageColors.map((e, i) => {
      if (trimOp.trimOpPercentageColors.length - 1 !== i)
        markAreaData.push([
          {
            xAxis: e.trimAngle.toString(),
            itemStyle: {
              color: e.color,
            },
          },
          {
            xAxis: trimOp.trimOpPercentageColors[i + 1]?.trimAngle.toString(),
          },
        ]);

      return { ...e };
    });

    const arrPower = trimOp.powerSpeedDraft.power.split(",");
    const arrAngle = trimOp.trimAngle;
    const data = arrPower.map(
      (x, key) =>
        ({
          value: [arrAngle[key], parseFloat(x)],
          name: arrAngle[key].toString(),
          isAlarm: false,
          isWarn: false,
          unit: "kW",
        } as DataItem)
    );

    result.push({
      type: "line",
      name: `Trim [kW]`,
      yAxisIndex: 0,
      data: data,
      smooth: true,
    });

    result.push({
      type: "line",
      name: `SPM RPM`,
      yAxisIndex: 0,
      data: [
        {
          value: [trimOp.actTrimValue, trimOp.actPowerValue],
          name: trimOp.actTrimValue.toString(),
          isAlarm: false,
          isWarn: false,
          unit: "kW",
        } as DataItem,
      ],
      markArea: {
        itemStyle: {
          color: "rgba(255, 173, 177, 0.4)",
        },
        data: markAreaData,
      },
      smooth: true,
      symbolSize: 12,
      symbol: "circle",
      itemStyle: {
        color: "red",
      },
    });

    return result;
  };

  return (
    <>
      <Stack>
        <Title order={4}>{t("trimopchart")}</Title>
        <Divider size="sm" mb="sm"></Divider>
      </Stack>
      <Flex align="end" gap="md" mb="lg">
        {trimOpType === "Vessel" && (
          <>
            <Select miw={220} label={t("trimopchart_vessel")} data={vessels} {...form.getInputProps("vesselId")} />
            <Select
              miw={280}
              label={t("trimopchart_actpowersignals")}
              data={signalSelectItems}
              searchable
              nothingFound={t("trimopchart_nothingfound")}
              placeholder={t("trimopchart_selectsignal")}
              {...form.getInputProps("actPowerSignal")}
            />
            <Select
              miw={280}
              label={t("trimopchart_acttrimsignals")}
              data={signalSelectItems}
              searchable
              nothingFound={t("trimopchart_nothingfound")}
              placeholder={t("trimopchart_selectsignal")}
              {...form.getInputProps("actTrimSignal")}
            />
          </>
        )}
        <NumberInput label={t("trimopchart_speed")} description={t("trimopchart_speeddesc")} {...form.getInputProps("speed")} />
        <NumberInput label={t("trimopchart_draftAft")} description={t("trimopchart_draftAftdesc")} {...form.getInputProps("draftAft")} />
        <NumberInput label={t("trimopchart_draftFore")} description={t("trimopchart_draftForedesc")} {...form.getInputProps("draftFore")} />
        <Button onClick={handleGenerate}>{t("trimopchart_generate")}</Button>
      </Flex>
      <Grid columns={12} p={2}>
        <Grid.Col span={8}>
          <div>
            <div id="chart" style={{ width: "100%", height: "700px" }}></div>
          </div>
        </Grid.Col>
        <Grid.Col span={4}>
          <Card shadow="sm" p="lg" radius="md" withBorder>
            <Group position="apart" mt="md" mb="xs">
              <Text size="xl" weight={500}>
                Results
              </Text>
            </Group>

            <Text size="md" color="dimmed">
              Actual SPM Power: <b>{trimOp?.actPowerValue}</b>
            </Text>
            <Text size="md" color="dimmed">
              Actual Trim Angle: <b>{trimOp?.actTrimValue}</b>
            </Text>
            <br />
            <hr />
            <br />
            <Text size="md" color="dimmed">
              Draft Middle: <b>{trimOp?.powerSpeedDraft.draftMiddle}</b>
            </Text>
            <Text size="md" color="dimmed">
              Speed: <b>{trimOp?.powerSpeedDraft.speed}</b>
            </Text>
            <Text size="md" color="dimmed">
              Power: <b>{trimOp?.powerSpeedDraft.power}</b>
            </Text>
            <br />
            <hr />
            <br />
            <Text size="md" color="dimmed">
              Trim Angle: <b>{trimOp?.trimAngle.toString()}</b>
            </Text>
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
};
export default TrimOpChart;
