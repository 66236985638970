import { ActionIcon, Container, Divider, Title, Transition, useMantineTheme, Text, Group, Button, Stack } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { Business, Delete, DirectionsBoat, Person, Public, CheckCircle, Sailing } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NotificationHelper from "../helpers/NotiHelper";
import { DashboardConfigContext } from "../providers/DashboardConfigProvider";
import AuthService from "../services/AuthService";
import { axiosInstance } from "../services/AxiosService";
import { DashboardPermissionType, DashboardResponse, DashboardsClient } from "../services/WebApiService";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { useTranslation } from "react-i18next";

type Props = {};

const IndexDashboard = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const mantineTheme = useMantineTheme();

  const { setIsOpen, setConfigType, setDashboardType, dashboardType, setDashboardIdToEdit, setReload, reload } = useContext(DashboardConfigContext);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [dashboards, setDashboards] = useState<DashboardResponse[]>([]);
  const [pageSize, setPageSize] = useState(10);

  const { t } = useTranslation();

  const { systemGlobal, isSystemModeVessel } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });

  let activeId = 0;

  const openDeleteModal = (id: number) => {
    activeId = id;
    openConfirmModal({
      title: "Please confirm your action",
      children: <Text size="sm">Are you sure you want to delete this screen? This action is irreversible!</Text>,
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: deleteDashboard,
      closeOnConfirm: true,
    });
  };

  const deleteDashboard = async () => {
    if (activeId) {
      try {
        const dashboardClient = new DashboardsClient(undefined, axiosInstance);
        await dashboardClient.delete(activeId);
        setDashboards((prev) => prev.filter((x) => x.id !== activeId));
        NotificationHelper.showSuccess("Success", "Screen deleted successfully");
      } catch {
        NotificationHelper.showError("Error", "An error occurred in deleting screen");
      }
      // Needed
      console.log("Screen delete");
    }
  };

  const handleEditDashboardConfig = (id: number) => {
    setDashboardIdToEdit(id);
    setConfigType("Edit");
    setIsOpen(true);
  };

  const handleCreateDashboardConfig = () => {
    setConfigType("Create");
    setIsOpen(true);
  };

  const columnDef: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "Id",
    //   minWidth: 40,
    // },
    {
      field: "name",
      headerName: t("managedashboard_name"),
      minWidth: 300,
      flex: 1,
      renderCell: (cell) => (
        <Button variant="subtle" onClick={() => navigate(`/dashboard/view/${cell.row.id}`)}>
          {cell.row.name}
        </Button>
      ),
    },
    {
      field: "category",
      headerName: t("managedashboard_category"),
      minWidth: 200,
      flex: 1,
    },
    {
      field: "dashboardPermissions",
      headerName: t("managedashboard_viewers"),
      flex: 1,
      minWidth: 150,
      // align: "center",
      // headerAlign: "center",
      renderCell: (cell) => {
        const result: JSX.Element[] = [];
        if (cell.value.length === 0) {
          if (cell.row.isSystemDashboard) {
            result.push(
              <Group>
                <Public></Public>
                <Text>Public</Text>
              </Group>
            );
          } else {
            result.push(
              <Group>
                <Person></Person>
                <Text>Private</Text>
              </Group>
            );
          }
        }
        for (let i = 0; i < cell.value.length; i++) {
          const obj = cell.value[i];
          const permissionType = obj.dashboardPermissionType as DashboardPermissionType;
          const name = obj.name;
          let icon = <DirectionsBoat></DirectionsBoat>;
          if (permissionType === DashboardPermissionType.Company) {
            icon = <Business></Business>;
          } else if (permissionType === DashboardPermissionType.Fleet) {
            icon = <Sailing></Sailing>;
          }
          result.push(
            <Group>
              {icon}
              <Text>{name}</Text>
            </Group>
          );
        }
        return <Stack>{result}</Stack>;
      },
    },
    {
      field: "viewEnabled",
      headerName: t("managedashboard_enabled"),
      align: "center",
      renderCell: (cell) => <>{cell.row.viewEnabled ? <CheckCircle color="success" /> : <CancelIcon color="error" />}</>,
    },
    {
      field: "config",
      headerName: t("managedashboard_settings"),
      align: "center",
      renderCell: (cell) => (
        <ActionIcon variant="subtle" color={mantineTheme.primaryColor} onClick={() => handleEditDashboardConfig(cell.row.id)}>
          <EditIcon />
        </ActionIcon>
      ),
    },
    {
      field: "editWidgets",
      headerName: t("managedashboard_editwidget"),
      align: "center",
      renderCell: (cell) => (
        <>
          {cell.row.isEditable ? (
            <ActionIcon
              variant="subtle"
              color={mantineTheme.primaryColor}
              component={Link}
              to={`/dashboard/edit/${cell.row.id}?back=${dashboardType === "Vessel" ? "dashboard" : "dashboard/fleet"}`}
            >
              <WidgetsIcon />
            </ActionIcon>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      field: "delete",
      headerName: t("managedashboard_delete"),
      align: "center",
      renderCell: (cell) => (
        <>
          {cell.row.isEditable ? (
            <ActionIcon onClick={() => openDeleteModal(cell.row.id)} variant="subtle" color="red">
              <Delete />
            </ActionIcon>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const loadDataGrid = () => {
    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
          }}
          getRowHeight={() => "auto"}
          loading={loading}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          autoHeight
          pagination
          onPageSizeChange={setPageSize}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={pageSize}
          rows={dashboards}
          columns={columnDef}
        />
      </ThemeProvider>
    );
  };

  const loadDashboards = async () => {
    setLoading(true);
    const result: DashboardResponse[] = [];
    try {
      const user = AuthService.getCurrentUser();
      if (user) {
        const dashboardClient = new DashboardsClient(undefined, axiosInstance);
        if (dashboardType === "Vessel") {
          if (isSystemModeVessel()) {
            result.push(...(await dashboardClient.getAll("vessel", false)));
          } else {
            result.push(...(await dashboardClient.getAll("fleet", false, systemGlobal.dashboardVesselId)));
          }
        } else if (dashboardType === "Fleet") {
          result.push(...(await dashboardClient.getAll("fleetonly", false, null, systemGlobal.dashboardFleetId)));
        }

        setDashboards(result);
      }
    } catch {
      NotificationHelper.showError("Error", "An error occurred in getting dashboards");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }

    return () => {};
  }, [mounted]);

  useEffect(() => {
    if (!reload) setReload(true);
    return () => {};
  }, [systemGlobal.dashboardVesselId]);

  useEffect(() => {
    if (location.pathname.includes("fleet") && dashboardType !== "Fleet") {
      setReload(true);
      setDashboardType("Fleet");
    }

    if (!location.pathname.includes("fleet") && dashboardType !== "Vessel") {
      setReload(true);
      setDashboardType("Vessel");
    }

    if (reload) {
      setReload(false);
      loadDashboards();
    }
    return () => {};
  }, [reload, setReload, location.pathname]);

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <Container size="xl" style={styles}>
          <Group>
            <Title order={2}>
              {t("managedashboard_screensof")} <span style={{ color: "#228be6" }}>{systemGlobal?.vesselName}</span>
            </Title>
            <Button onClick={handleCreateDashboardConfig}>{t("managedashboard_createnewscreen")}</Button>
          </Group>
          <Divider my="sm" size="md"></Divider>
          {loadDataGrid()}
        </Container>
      )}
    </Transition>
  );
};
export default IndexDashboard;
