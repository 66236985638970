import { Button, Group, Modal, Stack, TransferListData } from "@mantine/core";
import CompanyFormInputs from "./common/CompanyFormInputs";
import { useForm } from "@mantine/form";

import AuthService from "../../services/AuthService";
import { Company } from "../../models/company/Company";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import { CompanyClient, CompanyPostRequest, CompanyResponse } from "../../services/WebApiService";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../providers/GlobalContextProvider";

type ModalProps = {
  isOpen: boolean;
  id: number;
  company?: CompanyResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  hideModal: (type: string) => void;
  loadCompany?: () => void;
  modalProps: ModalType;
};

const CreateCompanyForm = (props: Props) => {
  const [user, setUser] = useState<TransferListData>([[], []]);
  const [fleet, setFleet] = useState<TransferListData>([[], []]);

  const { t } = useTranslation();

  const { systemGlobal } = useContext(GlobalContext);

  const commonForm = useForm<Company>({
    initialValues: {
      Name: "",
    },
  });

  const handleSubmit = async () => {
    if (AuthService.isInRole("Admin")) {
      try {
        const values = commonForm.values;
        const companyClient = new CompanyClient(undefined, axiosInstance);
        const entity = {
          name: values.Name,
          appUserCompany: user[1].map((x) => ({
            appUserId: x.value,
            companyId: 0,
          })),
          companyFleet: fleet[1].map((x) => ({
            fleetId: Number(x.value),
            companyId: 0,
          })),
        };

        const companyToCreate = new CompanyPostRequest();
        companyToCreate.init(entity);
        await companyClient.post(companyToCreate);
        NotificationHelper.showSuccess("Success", "Company is created successfully");
        props.hideModal("createCompany");
        if (props.loadCompany) props.loadCompany();
      } catch {
        NotificationHelper.showError("Error", "An error occurred in creating company");
      }
    }
  };

  useEffect(() => {
    commonForm.setFieldValue("Name", "");
    return () => {};
  }, [props.modalProps["createCompany"].isOpen]);

  return (
    <>
      <Modal size="xl" opened={props.modalProps["createCompany"].isOpen} onClose={() => props.hideModal("createCompany")} title={t("createcompany")}>
        <Stack spacing="lg">
          <Group spacing="lg" align="center" grow>
            <form>
              <CompanyFormInputs companyId={0} form={commonForm} isEditMode={false} user={user} setUser={setUser} fleet={fleet} setFleet={setFleet} />
            </form>
          </Group>
          <Group position="right">
            <Button variant="outline" onClick={() => props.hideModal("createCompany")}>
              {t("createcompany_cancel")}
            </Button>
            <Button disabled={!AuthService.isInRole("Admin")} type="submit" onClick={handleSubmit}>
              {t("createcompany_create")}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default CreateCompanyForm;
