import { Button, Card, Group, Select, Stack, Title } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { AutomaticReportResponse, TriggerType } from "../../services/WebApiService";
import { useTranslation } from "react-i18next";

type Props = {
  handleSubmit(settingJson: string): void;
  automaticReport?: AutomaticReportResponse;
};
type WeeklySettingJson = {
  startDay: string;
  startTime: Date;
};

const WeeklyAutomaticReportInput = (props: Props) => {
  const defaultValue = new Date(2000, 1, 1, 0, 0);
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [firstLoad, setFirstLoad] = useState(true);
  const form = useForm<WeeklySettingJson>({
    initialValues: {
      startDay: "0",
      startTime: defaultValue,
    },
  });

  const { t } = useTranslation();

  const handleSubmit = () => {
    const settingJson = JSON.stringify(form.values);
    props.handleSubmit(settingJson);
  };

  useEffect(() => {
    const loadForm = () => {
      if (firstLoad && props.automaticReport) {
        setFirstLoad(false);
        if (props.automaticReport.triggerType === TriggerType.Weekly) {
          const optionJson = JSON.parse(props.automaticReport.settingJson) as WeeklySettingJson;
          optionJson.startTime = new Date(optionJson.startTime);
          form.setFieldValue("startTime", optionJson.startTime);
          form.setFieldValue("startDay", optionJson.startDay);
        }
      }
    };
    loadForm();
    return () => {};
  }, [firstLoad, form, props.automaticReport]);

  return (
    <Card shadow="sm" p="lg" radius="sm" withBorder>
      <Card.Section withBorder p="lg">
        <Title order={3}>{t("createautoreport_weeklyreport")}</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <Group grow>
            <Select
              withinPortal
              label={t("createautoreport_startday")}
              description={t("createautoreport_startdaydesc")}
              data={daysOfWeek.map((x, index) => ({
                value: index.toString(),
                label: x,
              }))}
              {...form.getInputProps("startDay")}
            />
            <TimeInput
              description={t("createautoreport_24hoursformat")}
              label={t("createautoreport_starttime")}
              {...form.getInputProps("startTime")}
            />
          </Group>
        </Stack>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Button onClick={handleSubmit}>{t("createautoreport_save")}</Button>
      </Card.Section>
    </Card>
  );
};
export default WeeklyAutomaticReportInput;
