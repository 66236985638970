import { ActionIcon, Button, Card, Group, Select, Stack, TextInput, Title } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { RemoveCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import NotificationHelper from "../../helpers/NotiHelper";
import { CommonAutomaticReportSetting } from "../../models/automatic-report/CommonAutomaticReportModel";
import { Mapping } from "../../models/common/Mapping";
import AuthService from "../../services/AuthService";
import { axiosInstance } from "../../services/AxiosService";
import { AccountsClient, Classification, FuelType, MappingDefResponse, Property, ReportLevel, TriggerType } from "../../services/WebApiService";
import { Token } from "../../common/Models";
import { useTranslation } from "react-i18next";

type Props = {
  form: UseFormReturnType<CommonAutomaticReportSetting>;
  mappingDefs: MappingDefResponse[];
  classifications: MappingDefResponse[];
};

type SelectItem = {
  value: string;
  label: string;
};

const CommonAutomaticReportInput = (props: Props) => {
  const indexArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [firstLoad, setFirstLoad] = useState(true);

  const [reportLevels, setReportLevels] = useState<ReportLevel[]>([]);
  const [users, setUsers] = useState<SelectItem[]>([]);
  const [vessels, setVessels] = useState<SelectItem[]>([]);
  const [dataInterval, setDataInterval] = useState<SelectItem[]>([]);

  const { t } = useTranslation();

  const reloadProperty = (index: number) => {
    if (props.mappingDefs.length > 0) {
      const obj = props.form.values.mappingJson[index];
      const filtered = props.mappingDefs.filter((x) => x.classification === obj.classification);
      const entity = filtered.find((x) => x.property === obj.property);
      if (!entity) {
        props.form.setFieldValue(`mappingJson.${index}.property`, filtered[0].property);
      }
    }
  };

  const removeSignalMapping = (index: number) => {
    if (props.form.values.mappingJson.length > 1) {
      props.form.removeListItem("mappingJson", index);
    } else {
      NotificationHelper.showError("Error in removing", "Setting needs at least one signal mapping");
    }
  };

  const addSignalMapping = () => {
    const entity = {
      classification: Classification.NotApplicable,
      property: Property.NotApplicable,
      fuelType: FuelType.NotApplicable,
      index: "1",
    } as Mapping;
    props.form.insertListItem("mappingJson", entity);
  };

  useEffect(() => {
    const loadManagedUsers = async (user: Token) => {
      if (!users.length) {
        if (AuthService.isInRoleArray(["Admin", "CompanyAdmin", "Supervisor"])) {
          const accountClient = new AccountsClient(undefined, axiosInstance);
          const data = await accountClient.managedUsers();
          setUsers(
            data.map((x) => {
              return { label: x.username, value: x.id } as SelectItem;
            })
          );
          // Set default value for ddl otherwise it will not have default value
          if (!props.form.values.appUserId && data.length > 0) {
            props.form.values.appUserId = data[0].id;
          }
        } else {
          setUsers([
            {
              label: user.nameid,
              value: user.userid,
            },
          ]);
          if (!props.form.values.appUserId) {
            props.form.values.appUserId = user.userid;
          }
        }
      }
    };

    const loadManagedVessels = async (user: Token) => {
      if (!vessels.length) {
        const accountClient = new AccountsClient(undefined, axiosInstance);
        const data = await accountClient.managedVessels(user.userid);
        setVessels(
          data.map((x) => ({
            label: x.name,
            value: x.id.toString(),
          }))
        );
        if (!props.form.values.vesselId && data.length > 0) {
          props.form.values.vesselId = data[0].id.toString();
        }
      }
    };

    const loadReportLevels = () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        if (AuthService.isInRoleArray(["Admin", "Supervisor"])) {
          setReportLevels([ReportLevel.System, ReportLevel.User]);
        } else {
          setReportLevels([ReportLevel.User]);
        }
      }
    };

    const loadDataIntervalArray = () => {
      if (dataInterval.length === 0) {
        setDataInterval([
          {
            label: "5 Mins",
            value: "5",
          },
          {
            label: "10 Mins",
            value: "10",
          },
          {
            label: "15 Mins",
            value: "15",
          },
          {
            label: "30 Mins",
            value: "30",
          },
          {
            label: "Hourly",
            value: "60",
          },
          {
            label: "Daily",
            value: "1440",
          },
        ]);
      }
    };

    const user = AuthService.getCurrentUser();
    if (user && firstLoad) {
      setFirstLoad(false);
      loadManagedUsers(user);
      loadManagedVessels(user);
      loadReportLevels();
      loadDataIntervalArray();
    }
    return () => {};
  }, [dataInterval, firstLoad, props.form.values, users, vessels]);

  return (
    <>
      <Card shadow="sm" p="lg" radius="sm" withBorder>
        <Card.Section withBorder p="lg">
          <Title order={3}>{t("createautoreport_commonsettings")}</Title>
        </Card.Section>
        <Card.Section withBorder p="lg">
          <Stack>
            <Group grow>
              <TextInput label={t("createautoreport_reportname")} {...props.form.getInputProps("name")} />
              <Select withinPortal data={reportLevels} label={t("createautoreport_reportlevel")} {...props.form.getInputProps("reportLevel")} />
              {/* <Select
                withinPortal
                data={[]}
                label="Report Type"
                {...props.form.getInputProps("reportType")}
              /> */}
              <Select
                withinPortal
                data={Object.keys(TriggerType)}
                label={t("createautoreport_triggertype")}
                {...props.form.getInputProps("triggerType")}
              />
            </Group>
            <Group grow>
              <Select withinPortal label={t("createautoreport_datainterval")} data={dataInterval} {...props.form.getInputProps("dataInterval")} />
              <Select
                disabled={props.form.values.reportLevel === ReportLevel.System}
                withinPortal
                label={t("createautoreport_user")}
                data={users}
                {...props.form.getInputProps("appUserId")}
              />
              <Select
                disabled={props.form.values.reportLevel !== ReportLevel.System}
                withinPortal
                label={t("createautoreport_vessel")}
                data={vessels}
                {...props.form.getInputProps("vesselId")}
              />
              <Select disabled withinPortal label={t("createautoreport_fleet")} data={[]} {...props.form.getInputProps("fleetId")} />
            </Group>
          </Stack>
        </Card.Section>
      </Card>
      <Card shadow="sm" p="lg" radius="sm" withBorder>
        <Card.Section withBorder p="lg">
          <Title order={3}>{t("createautoreport_mappingsgenerate")}</Title>
        </Card.Section>
        <Card.Section withBorder p="lg">
          <Stack>
            {props.form.values.mappingJson.map((obj, index) => {
              return (
                <Group grow align="end" key={`Mapping${index}`}>
                  <Select
                    withinPortal
                    label={t("createautoreport_classification")}
                    searchable
                    nothingFound={t("createautoreport_notfound")}
                    data={props.classifications.map((x) => ({ value: x.classification, label: x.classificationDisplayName }))}
                    {...props.form.getInputProps(`mappingJson.${index}.classification`)}
                  />
                  <Select
                    withinPortal
                    label={t("createautoreport_property")}
                    searchable
                    onChange={reloadProperty(index)}
                    nothingFound={t("createautoreport_notfound")}
                    data={props.mappingDefs
                      .filter((x) => x.classification === obj.classification)
                      .map((x) => ({ value: x.property, label: x.propertyDisplayName }))}
                    {...props.form.getInputProps(`mappingJson.${index}.property`)}
                  />
                  <Select
                    withinPortal
                    label={t("createautoreport_fueltype")}
                    data={Object.keys(FuelType).map((x) => ({ value: x, label: x }))}
                    {...props.form.getInputProps(`mappingJson.${index}.fuelType`)}
                  />
                  <Select
                    withinPortal
                    label={t("createautoreport_index")}
                    data={indexArray.map((x) => ({ value: x.toString(), label: x.toString() }))}
                    {...props.form.getInputProps(`mappingJson.${index}.index`)}
                  />
                  <Group pl="md">
                    <ActionIcon color="red" onClick={() => removeSignalMapping(index)}>
                      <RemoveCircle />
                    </ActionIcon>
                  </Group>
                </Group>
              );
            })}
          </Stack>
          <Button mt="lg" variant="outline" onClick={addSignalMapping}>
            {t("createautoreport_addmapping")}
          </Button>
        </Card.Section>
      </Card>
    </>
  );
};
export default CommonAutomaticReportInput;
