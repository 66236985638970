import dayjs from "dayjs"

const toEndOfDay = (value: Date) => {
  return dayjs(value).endOf("date").toDate();
}

const toStartOfDay = (value :Date) =>{
  return dayjs(value).startOf("date").toDate();
}

export const DateService = {  
  toEndOfDay,
  toStartOfDay
}