import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Responsive, { Layout } from "react-grid-layout";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import {
  CurveResponse,
  DashboardResponse,
  DashboardsClient,
  DashboardVesselResponse,
  DashboardWidgetPatchRequest,
  DashboardWidgetResponse,
  HubSignalResponse,
  SignalsClient,
  SystemSettingsClient,
  VesselsClient,
} from "../services/WebApiService";
import "./View.scss";
import NotificationHelper from "../helpers/NotiHelper";
import { Button, SimpleGrid, BackgroundImage, Card, Group, Title } from "@mantine/core";
import EditDashboard from "./Edit";
import WidgetItem from "./components/Common/WidgetItem";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { isDev } from "../helpers/DevDetect";
import ReactGridLayout from "react-grid-layout";
import { axiosInstance } from "../services/AxiosService";
import EditIcon from "@mui/icons-material/Edit";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { GlobalContext } from "../providers/GlobalContextProvider";
import { DashboardCustomize } from "@mui/icons-material";
import { DashboardConfigContext } from "../providers/DashboardConfigProvider";
import { useTranslation } from "react-i18next";
import AuthService from "../services/AuthService";

type Props = {
  isEdit: boolean;
};
// Put this line outside of View class, otherwise the grid will keep initializing every render
// const ReactGridLayout = WidthProvider(RGL);
// const ResponsiveGridLayout = WidthProvider(Responsive);

const ViewDashboard = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [firstLoad, setFirstLoad] = useState(true);

  const { setIsOpen, setConfigType, setDashboardIdToEdit, setReload, reload } = useContext(DashboardConfigContext);
  const { systemGlobal, isSystemModeVessel } = useContext(GlobalContext);
  // Edit
  const [layouts, setLayouts] = useState<Responsive.Layout[]>([]);
  const [isConfigHidden, setIsConfigHidden] = useState(true);
  const [isWidgetBarHidden, setIsWidgetBarHidden] = useState(true);
  const [activeDashboardWidget, setActiveDashboardWidget] = useState<DashboardWidgetResponse>();

  const [searchParams] = useSearchParams();
  const { dashboardId: queryStringDashboardId } = useParams();
  const [curves, setCurves] = useState<CurveResponse[]>([]);
  const [hubSignals, setHubSignals] = useState<HubSignalResponse[]>([]);
  const [dashboard, setDashboard] = useState<DashboardResponse>();
  const [widgets, setWidgets] = useState<DashboardWidgetResponse[]>([]);
  const [dashboardVessels, setDashboardVessels] = useState<DashboardVesselResponse[]>([]);
  const [background, setBackground] = useState("");

  const [testDevelopment, setTestDevelopment] = useState<string>("False");

  const [dashboardType, setDashboardType] = useState<"Fleet" | "Vessel">("Vessel");

  const { t } = useTranslation();

  const handleConfigureWidget = useCallback(
    (widgetId: number) => {
      setIsConfigHidden(false);
      const activeWidget = widgets.find((x) => x.id === widgetId);
      if (activeWidget) {
        setActiveDashboardWidget(activeWidget);
      }
    },
    [widgets]
  );

  const handleOpenWidgetConfig = () => {
    setIsConfigHidden(false);
  };

  const handleCloseWidgetConfig = () => {
    setIsConfigHidden(true);
  };

  const handleOpenWidgetBar = () => {
    setIsWidgetBarHidden(false);
  };

  const handleCloseWidgetBar = () => {
    setIsWidgetBarHidden(true);
  };

  const handleDeleteWidget = useCallback((widgetId: number) => {
    setWidgets((current) => current.filter((x) => x.id !== widgetId));
  }, []);

  const updateWidgets = (widget: DashboardWidgetResponse) => {
    setWidgets(
      widgets.map((x) => {
        if (x.id === widget.id) {
          return widget as DashboardWidgetResponse;
        } else {
          return x;
        }
      })
    );
  };

  const addWidget = (widget: DashboardWidgetResponse) => {
    setWidgets((prev) => [...prev, widget]);
  };

  const updateBackground = (imageName: string) => {
    let url = `/api/backgrounds/${imageName}`;
    if (isDev()) {
      url = `https://localhost:7254/backgrounds/${imageName}`;
    }
    setBackground(url);
  };

  const loadWidgetItems = useMemo(() => {
    return widgets.map((item) => (
      <Card
        withBorder
        shadow="xs"
        p="md"
        style={{ borderTop: "4px solid " + item.color }}
        className="widget-container"
        key={item.id}
        data-grid={{ x: item.x, y: item.y, w: item.width, h: item.height, i: item.id }}
      >
        <WidgetItem
          isEdit={props.isEdit}
          dashboardWidget={item}
          dashboardVessels={dashboardVessels}
          key={item.id}
          hubSignals={hubSignals}
          curves={curves}
          handleConfigureWidget={handleConfigureWidget}
          handleDeleteWidget={handleDeleteWidget}
          dashboardType={dashboardType}
        />
      </Card>
    ));
  }, [widgets, props.isEdit, dashboardVessels, hubSignals, curves, handleConfigureWidget, handleDeleteWidget, dashboardType]);

  // Get dashboard
  const getDashboard = useCallback(async () => {
    const vesselClient = new VesselsClient(undefined, axiosInstance);
    const dashboardClient = new DashboardsClient(undefined, axiosInstance);
    if (queryStringDashboardId) {
      const numberDashboardId = parseInt(queryStringDashboardId);
      // Clear out widgets to reset the grid
      setWidgets([]);
      setBackground("");
      const vesselId = systemGlobal.dashboardVesselId;
      const fleetId = systemGlobal.dashboardFleetId;
      try {
        if (dashboardType === "Vessel") {
          const vessels = await dashboardClient.vessels(numberDashboardId, vesselId, fleetId);
          if (vessels.length === 1) {
            const vesselCurves = await vesselClient.curves(vessels[0].vesselId);
            setCurves(vesselCurves);
          }
          setDashboardVessels(vessels);
        } else {
          const vessels = await dashboardClient.vessels(numberDashboardId, vesselId, fleetId);
          if (vessels.length === 1) {
            const vesselCurves = await vesselClient.curves(vessels[0].vesselId);
            setCurves(vesselCurves);
          }
          setDashboardVessels(vessels);
        }
      } catch (e: any) {
        NotificationHelper.showError(`Http Error ${e.status}`, "Cannot get signals for vessel");
      }

      const localDashboard = await dashboardClient.get(numberDashboardId);

      if (localDashboard) {
        setDashboardType(localDashboard.isFleetDashboard ? "Fleet" : "Vessel");
        setDashboard(localDashboard);
        if (localDashboard.backgroundImage) {
          updateBackground(localDashboard.backgroundImage);
        }
        setWidgets(localDashboard.dashboardWidgets);
      }
    }
  }, [queryStringDashboardId, systemGlobal.dashboardFleetId, systemGlobal.dashboardVesselId]);

  const getLastSignalValue = async (vesselId: number) => {
    const signalClient = new SignalsClient(undefined, axiosInstance);
    const signalData = await signalClient.lastValuesWithVessel(vesselId);
    setHubSignals(signalData);
  };

  useEffect(() => {
    let interval: NodeJS.Timer;

    const startHubConnection = (hubConnection: HubConnection) => {
      if (hubConnection && queryStringDashboardId) {
        const vesselId = systemGlobal.dashboardVesselId;
        const fleetId = systemGlobal.dashboardFleetId;
        const dashboardId = parseInt(queryStringDashboardId);
        hubConnection
          .start()
          .then(async (result) => {
            hubConnection.invoke("SubscribeLiveData", dashboardId, vesselId, fleetId).catch((err) => console.error(err.toString()));
            hubConnection.on("ReceiveLiveData", (serverHubSignals) => {
              setHubSignals(serverHubSignals);
            });

            if (AuthService.isInRole("Admin") && isSystemModeVessel()) {
              const systemSettingClient = new SystemSettingsClient(undefined, axiosInstance);
              const data = await systemSettingClient.get();

              if ((isDev() && data.testDevelopment === "True") || data.testDevelopment === "True") {
                interval = setInterval(() => testHub(hubConnection), 1000 * 3);
              }
            }
          })
          .catch((e) => console.log("Connection failed: ", e));
      }
    };
    let hubConnection: HubConnection;
    if (systemGlobal.dashboardVesselId) {
      getDashboard();
      // Fire resize window event because horizontal scrollbar keeps appearing on the grid
      // Firing resize solves this issue, only happens for responsive gridlayout
      // https://github.com/react-grid-layout/react-grid-layout/issues/879
      // setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 300);
      // Using normal Gridlayout instead of responsive gridlayout
      // because when resize triggers here, the widget contents will have scrollbars
      // Only create signal r connection when it is view dashboard
      if (!props.isEdit) {
        let url = "/api/dashboardHub";
        if (isDev()) {
          url = "https://localhost:7254/dashboardHub";
        }
        hubConnection = new HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();
        startHubConnection(hubConnection);
      }
    }
    return () => {
      if (hubConnection) {
        hubConnection.stop();
        clearInterval(interval);
      }
    };
  }, [getDashboard, location, props.isEdit, queryStringDashboardId, systemGlobal.dashboardFleetId, systemGlobal.dashboardVesselId, testDevelopment]);

  useEffect(() => {
    if (reload) {
      setReload(false);
      getDashboard();
    }

    return () => {};
  }, [getDashboard, reload, setReload]);

  useEffect(() => {
    if (dashboard && props.isEdit) {
      if (!dashboard.isEditable) {
        //navigate("/account/access-denied");
      }
    }
    return () => {};
  }, [dashboard, navigate, props.isEdit]);

  useEffect(() => {
    if (firstLoad && systemGlobal.dashboardVesselId !== 0) {
      setFirstLoad(false);
      getLastSignalValue(systemGlobal.dashboardVesselId);
    }

    return () => {};
  }, [firstLoad, systemGlobal.dashboardVesselId, systemGlobal.dashboardFleetId]);

  const testHub = (hubConnection: HubConnection) => {
    hubConnection.invoke("TestLiveData").catch((err) => console.error(err.toString()));
  };

  const handleSubmit = async () => {
    if (dashboard) {
      dashboard.dashboardWidgets = widgets;
      const dashboardClient = new DashboardsClient(undefined, axiosInstance);
      try {
        const request = {
          id: dashboard.id,
          dashboardWidgets: widgets,
        } as DashboardWidgetPatchRequest;
        const response = await dashboardClient.widgets(request);
        if (response && response.status === 200) {
          NotificationHelper.showSuccess("Success", "Dashboard saved!");
        } else {
          NotificationHelper.showError("Error", "An error occurred in saving dashboard");
        }
      } catch {
        NotificationHelper.showError("Error", "An error occurred in saving dashboard");
      }
    }
  };

  const handleBackClick = () => {
    const backQueryString = searchParams.get("back");
    if (backQueryString) {
      navigate(`/${backQueryString}`);
    } else {
      navigate(`/dashboard/view/${queryStringDashboardId}`);
    }
  };

  const handleNavigateEditClick = () => {
    navigate(`/dashboard/edit/${queryStringDashboardId}`);
  };

  const handleEditDashboardConfig = () => {
    if (dashboard) {
      setDashboardIdToEdit(dashboard.id);
      setIsOpen(true);
      setConfigType("Edit");
    }
  };

  const handleCreateDashboardConfig = () => {
    setConfigType("Create");
    setIsOpen(true);
  };

  const loadEditOrSaveButton = () => {
    if (props.isEdit) {
      return (
        <SimpleGrid cols={3}>
          <Button onClick={() => handleSubmit()}>{t("viewedit_save")}</Button>
          <Button onClick={() => handleOpenWidgetBar()}>{t("viewedit_widgets")}</Button>
          <Button onClick={() => handleBackClick()}>{t("viewedit_back")}</Button>
        </SimpleGrid>
      );
    } else {
      if (dashboard) {
        return (
          <Group>
            <Button leftIcon={<DashboardCustomize sx={{ fontSize: 16 }} />} onClick={() => handleCreateDashboardConfig()}>
              {t("view_create")}
            </Button>
            {dashboard.isEditable ? (
              <Button leftIcon={<WidgetsIcon sx={{ fontSize: 16 }} />} onClick={() => handleNavigateEditClick()}>
                {t("view_editwidgets")}
              </Button>
            ) : (
              <></>
            )}
            {dashboard.isEditable ? (
              <Button leftIcon={<EditIcon sx={{ fontSize: 16 }} />} onClick={() => handleEditDashboardConfig()}>
                {t("view_settings")}
              </Button>
            ) : (
              <></>
            )}
          </Group>
        );
      }
    }
  };

  useEffect(() => {
    if (props.isEdit) {
      for (let i = 0; i < layouts.length; i++) {
        const layout = layouts[i];
        const obj = widgets.find((x) => x.id === parseInt(layout.i));
        if (obj) {
          if (obj.height !== layout.h || obj.width !== layout.w || obj.x !== layout.x || obj.y !== layout.y) {
            const newObj = {
              ...obj,
              height: layout.h,
              width: layout.w,
              x: layout.x,
              y: layout.y,
            };
            const entity = new DashboardWidgetResponse();
            entity.init(newObj);
            setWidgets(
              widgets.map((x) => {
                if (x.id === entity.id) {
                  return entity;
                } else {
                  return x;
                }
              })
            );
          }
        }
      }
    }
    return () => {};
  }, [layouts, props.isEdit, widgets]);

  const handleResizeStop = (layout: Layout[], oldItem: Layout, newItem: Layout, placeholder: Layout, event: MouseEvent, element: HTMLElement) => {
    if (props.isEdit) {
      const obj = widgets.find((x) => x.id === parseInt(newItem.i));
      if (obj !== null) {
        const newObj = {
          ...obj,
          height: newItem.h,
          width: newItem.w,
          x: newItem.x,
          y: newItem.y,
        };
        const entity = new DashboardWidgetResponse();
        entity.init(newObj);
        setWidgets(
          widgets.map((x) => {
            if (x.id === entity.id) {
              return entity;
            } else {
              return x;
            }
          })
        );
      }
    }
  };

  const loadEditDashboard = () => {
    if (props.isEdit) {
      return (
        <EditDashboard
          layouts={layouts}
          classifications={systemGlobal.classifications}
          mappings={systemGlobal.mappingDefs}
          activeDashboardWidget={activeDashboardWidget}
          handleOpenWidgetConfig={handleOpenWidgetConfig}
          handleCloseWidgetBar={handleCloseWidgetBar}
          handleCloseWidgetConfig={handleCloseWidgetConfig}
          updateWidgets={updateWidgets}
          addWidget={addWidget}
          isConfigHidden={isConfigHidden}
          isWidgetBarHidden={isWidgetBarHidden}
          widgets={widgets}
          curves={curves}
          dashboardType={dashboardType}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <BackgroundImage src={background}>
        <div className="dashboard-container">
          <Group grow p="xs">
            <Title order={2}>{dashboard?.name}</Title>
            <Group position="right">{loadEditOrSaveButton()}</Group>
          </Group>

          <ReactGridLayout
            onLayoutChange={setLayouts}
            className="dashboard-grid"
            cols={24}
            rowHeight={40}
            isBounded={true}
            width={window.innerWidth - 25}
            // onWidthChange={(a, b, c, d) => test(a, b, c, d)}
            useCSSTransforms={true}
            isDraggable={props.isEdit}
            isResizable={props.isEdit}
            onResizeStop={(a, b, c, d, e, f) => handleResizeStop(a, b, c, d, e, f)}
            draggableHandle=".gridItemDragHandler"
          >
            {loadWidgetItems}
          </ReactGridLayout>
        </div>
      </BackgroundImage>
      {loadEditDashboard()}
    </>
  );
};

export default ViewDashboard;
