import { Container, Divider, Text, Title } from "@mantine/core";

const OnlyForOnBoard = () => {
  return (
    <Container size="lg">
      <Title order={3}>OnBoard Only</Title>
      <Divider my="sm" size="md"></Divider>
      <Text>This features is only be access at OnBoard platform</Text>
    </Container>
  );
};
export default OnlyForOnBoard;
