import {
  ActionIcon,
  Button,
  Card,
  ColorInput,
  Group,
  Modal,
  Select,
  Text,
  Stack,
  TextInput,
  Title,
  Transition,
  useMantineTheme,
} from "@mantine/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  Classification,
  FuelType,
  ManualReportLibraryClient,
  ManualReportLibraryPostRequest,
  ManualReportLibraryPutRequest,
  ManualReportLibraryResponse,
  ManualReportMappingDataType,
  ManualReportMappingType,
  ManualReportSignalIdMapping,
  ManualReportSignalMapping,
  ManualReportType,
  Property,
  SignalMappingResponse,
  VesselsClient,
} from "../services/WebApiService";
import { axiosInstance } from "../services/AxiosService";
import { GlobalContext } from "../providers/GlobalContextProvider";
import ManualReportSignalMappingInput from "./components/ManualReportSignalMappingInput";
import { useForm } from "@mantine/form";
import { ManualReportLibraryForm } from "../models/manual-report-library/ManualReportLibraryForm";
import ManualReportSignalIdMappingInput from "./components/ManualReportSignalIdMappingInput";
import { ArrowBack } from "@mui/icons-material";
import AuthService from "../services/AuthService";
import NotificationHelper from "../helpers/NotiHelper";
import { Intervals } from "../services/FormService";
import { useTranslation } from "react-i18next";

type Props = {
  isCreate: Boolean;
};

const CreateOrEditManualReportLibrary = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [firstLoad, setFirstLoad] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [report, setReport] = useState<ManualReportLibraryResponse>();
  const [signals, setSignals] = useState<SignalMappingResponse[]>([]);
  const [modelIsOpen, setModelIsOpen] = useState(false);
  const { reportId } = useParams();
  const { systemGlobal } = useContext(GlobalContext);
  const theme = useMantineTheme();
  const colorArray = Object.keys(theme.colors).map((loopColor) =>
    theme.colorScheme === "dark" ? theme.colors[loopColor][7] : theme.colors[loopColor][5]
  );

  const { t } = useTranslation();

  const form = useForm<ManualReportLibraryForm>({
    initialValues: {
      id: 1,
      color: "",
      dataInterval: "5",
      manualReportMappingType: ManualReportMappingType.Mapping,
      manualReportType: ManualReportType.User,
      name: "",
      signalIdMappings: [
        {
          dataType: ManualReportMappingDataType.Instant,
          signalId: "1",
        } as ManualReportSignalIdMapping,
      ],
      signalMappings: [
        {
          classification: Classification.NotApplicable,
          property: Property.NotApplicable,
          fuelType: FuelType.NotApplicable,
          index: "1",
          dataType: ManualReportMappingDataType.Instant,
        } as ManualReportSignalMapping,
      ],
    },
  });

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }

    return () => {};
  }, [mounted]);

  useEffect(() => {
    const loadReport = async () => {
      const vesselClient = new VesselsClient(undefined, axiosInstance);
      const signals = await vesselClient.signalMappingsGET(systemGlobal.dashboardVesselId);
      setSignals(signals);
      if (!props.isCreate) {
        const manualReportClient = new ManualReportLibraryClient(undefined, axiosInstance);
        const result = await manualReportClient.get(Number(reportId));
        setReport(result);
      }

      setFirstLoad(true);
    };

    loadReport();
    return () => {};
  }, [location, props.isCreate, reportId, systemGlobal.dashboardVesselId]);

  useEffect(() => {
    const loadMappings = () => {
      if (report && firstLoad) {
        setFirstLoad(false);
        if (report.manualReportMappingType === ManualReportMappingType.Signal) {
          const mappings = JSON.parse(report.mappingJson) as ManualReportSignalIdMapping[];
          form.setFieldValue("signalIdMappings", mappings);
        } else if (report.manualReportMappingType === ManualReportMappingType.Mapping) {
          const mappings = JSON.parse(report.mappingJson) as ManualReportSignalMapping[];
          form.setFieldValue("signalMappings", mappings);
        }
        form.setFieldValue("id", report.id);
        form.setFieldValue("color", report.color);
        form.setFieldValue("manualReportMappingType", report.manualReportMappingType);
        form.setFieldValue("manualReportType", report.manualReportType);
        form.setFieldValue("name", report.name);
        form.setFieldValue("userId", report.userId);
        form.setFieldValue("dataInterval", report.dataInterval.toString());
      }
    };

    loadMappings();
    return () => {};
  }, [firstLoad, form, report]);

  const loadSignalMappingInput = (
    <>
      <Card.Section withBorder p="lg">
        <Title order={3}>{t("manualreport_mapsignal")}</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <ManualReportSignalMappingInput classifications={systemGlobal.classifications} mappingDefs={systemGlobal.mappingDefs} form={form} />
        </Stack>
      </Card.Section>
    </>
  );

  const loadSignalIdMappingInput = (
    <>
      <Card.Section withBorder p="lg">
        <Title order={3}>{t("manualreport_mapsignalid")}</Title>
      </Card.Section>
      <Card.Section withBorder p="lg">
        <Stack>
          <ManualReportSignalIdMappingInput signals={signals} form={form} />
        </Stack>
      </Card.Section>
    </>
  );

  const navigateToBack = () => {
    navigate(`/report/manual-report-library/${form.values.id}`);
  };

  const hideModal = () => {
    setModelIsOpen(false);
  };

  const handleSaveReport = async () => {
    const manualReportClient = new ManualReportLibraryClient(undefined, axiosInstance);
    const values = form.values;
    const user = AuthService.getCurrentUser();
    if (values.name === "" || values.color === "") {
      NotificationHelper.showError("Error", "Please fill in required fields");
      return;
    }

    // no duplicates sets
    const uniqueCombinations = new Set();
    let duplicatesExist = false;
    if (values.manualReportMappingType === ManualReportMappingType.Mapping) {
      duplicatesExist = values.signalMappings.some((e) => {
        const combination = `${e.classification}-${e.property}`;
        if (uniqueCombinations.has(combination)) {
          return true;
        }
        uniqueCombinations.add(combination);
        return false;
      });
    } else {
      duplicatesExist = values.signalIdMappings.some((e) => {
        const combination = `${e.signalId}`;
        if (uniqueCombinations.has(combination)) {
          return true;
        }
        uniqueCombinations.add(combination);
        return false;
      });
    }

    if (duplicatesExist) {
      NotificationHelper.showError("Error", "Duplicate signals are not allowed in one report");
      return;
    }

    if (user) {
      let mappingJson = "";
      if (values.manualReportMappingType === ManualReportMappingType.Mapping) {
        for (let i = 0; i < values.signalMappings.length; i++) {
          const obj = values.signalMappings[i];
          obj.order = i + 1;
        }
        mappingJson = JSON.stringify(values.signalMappings);
      } else {
        for (let i = 0; i < values.signalIdMappings.length; i++) {
          const obj = values.signalIdMappings[i];
          obj.order = i + 1;
        }
        mappingJson = JSON.stringify(values.signalIdMappings);
      }
      if (props.isCreate) {
        const obj = {
          color: values.color,
          dataInterval: Number(values.dataInterval),
          manualReportMappingType: values.manualReportMappingType,
          manualReportType: values.manualReportType,
          name: values.name,
          userId: user.userid,
          mappingJson: mappingJson,
        } as ManualReportLibraryPostRequest;
        try {
          const result = await manualReportClient.post(obj);
          NotificationHelper.showSuccess("Update success", "Create new report successfully");
          navigate(`/report/manual-report-library/${result.id}`);
        } catch {
          NotificationHelper.showError("Update error", "Cannot create new report");
        }
      } else {
        const obj = {
          color: values.color,
          dataInterval: Number(values.dataInterval),
          id: values.id,
          manualReportMappingType: values.manualReportMappingType,
          manualReportType: values.manualReportType,
          name: values.name,
          userId: user.userid,
          mappingJson: mappingJson,
        } as ManualReportLibraryPutRequest;
        try {
          await manualReportClient.put(obj);
          NotificationHelper.showSuccess("Update success", "Update report details successfully");
        } catch {
          NotificationHelper.showError("Update error", "Cannot save report details");
        }
      }
    }
  };

  const handleDeleteManualReport = async () => {
    const manualReportClient = new ManualReportLibraryClient(undefined, axiosInstance);
    var result = await manualReportClient.delete(form.values.id);
    if (result) {
      NotificationHelper.showSuccess("Delete success", "Manual report has been deleted");
      navigate("/report/manual-report-library");
    } else {
      NotificationHelper.showError("Delete failed", "Cannot delete manual report");
    }
  };

  return (
    <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
      {(styles) => (
        <div style={styles}>
          <Stack>
            <Card shadow="sm" p="lg" radius="sm" withBorder>
              <Card.Section withBorder p="lg">
                <Group>
                  {props.isCreate ? (
                    <></>
                  ) : (
                    <ActionIcon onClick={() => navigateToBack()}>
                      <ArrowBack></ArrowBack>
                    </ActionIcon>
                  )}
                  <Title order={3}>{props.isCreate ? t("manualreport_createmanualreport") : t("manualreport_editmanualreport")}</Title>
                </Group>
              </Card.Section>
              <Card.Section withBorder p="lg">
                <Group grow>
                  <TextInput required label={t("manualreport_reportname")} {...form.getInputProps("name")} />
                  <ColorInput
                    withinPortal
                    dropdownZIndex={999}
                    swatchesPerRow={8}
                    placeholder={t("manualreport_pickcolor")}
                    label={t("manualreport_widgetcolor")}
                    required
                    withPicker={true}
                    swatches={[...colorArray]}
                    {...form.getInputProps("color")}
                  />
                  <Select
                    withinPortal
                    label={t("manualreport_mappingtype")}
                    data={Object.keys(ManualReportMappingType).map((x) => ({ label: x, value: x }))}
                    {...form.getInputProps("manualReportMappingType")}
                  />
                  <Select withinPortal label={t("manualreport_datainterval")} data={Intervals} {...form.getInputProps("dataInterval")} />
                </Group>
              </Card.Section>
            </Card>
            <Card shadow="sm" p="lg" radius="sm" withBorder>
              {form.values.manualReportMappingType === ManualReportMappingType.Mapping ? loadSignalMappingInput : loadSignalIdMappingInput}
              <Card.Section withBorder p="lg">
                <Group>
                  <Button onClick={() => handleSaveReport()}>{t("manualreport_savereport")}</Button>
                  {props.isCreate ? (
                    <></>
                  ) : (
                    <Button color="red" onClick={() => setModelIsOpen(true)}>
                      {t("manualreport_deletereport")}
                    </Button>
                  )}
                </Group>
              </Card.Section>
            </Card>
          </Stack>
          <Modal opened={modelIsOpen} onClose={hideModal} title="Confirmation">
            <Stack>
              <Text>Are you sure you want to delete this report? This action is irreversible!</Text>
              <Group spacing="lg" align="center" grow>
                <Button variant="outline" color="red" onClick={handleDeleteManualReport}>
                  Yes
                </Button>
                <Button onClick={hideModal}>No</Button>
              </Group>
            </Stack>
          </Modal>
        </div>
      )}
    </Transition>
  );
};

export default CreateOrEditManualReportLibrary;
