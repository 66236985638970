import { Button, Card, Container, Divider, Group, Select, Stack, Title, Transition } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import { CO2OperationClient, CO2OperationResponse, CO2OperationTriggerType, CO2OperationType, TripClient } from "../../services/WebApiService";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../providers/GlobalContextProvider";
import { ThemeProvider, createTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Close, Done, PauseCircleFilled, PlayCircleFilledWhite } from "@mui/icons-material";

type Props = {};

type CO2OperationModeForm = {
  operationMode: string;
  triggered: string;
};

const IndexCO2OperationMode = (props: Props) => {
  const { systemGlobal } = useContext(GlobalContext);
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [co2OperationMode, setCo2OperationMode] = useState<CO2OperationResponse[]>([]);
  const { vesselId } = useParams();

  const { isSystemModeVessel } = useContext(GlobalContext);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const form = useForm<CO2OperationModeForm>({
    initialValues: {
      operationMode: "",
      triggered: "",
    },
  });

  const loadLastOperationMode = async () => {
    if (vesselId) {
      try {
        const co2OperationClient = new CO2OperationClient(undefined, axiosInstance);
        const data = await co2OperationClient.getByLastCO2OperationMode(Number(vesselId));

        if (data) {
          form.setFieldValue("operationMode", data.operationMode);
          form.setFieldValue("triggered", data.triggered);
        }
      } catch {}
    }
  };

  const loadOperationMode = async () => {
    if (vesselId) {
      const co2OperationClient = new CO2OperationClient(undefined, axiosInstance);
      const data = await co2OperationClient.getAllByVesselId(Number(vesselId));

      if (data) {
        setCo2OperationMode(data);
      }
    }
  };

  const handleSwitchToStart = async () => {
    try {
      const co2OperationClient = new CO2OperationClient(undefined, axiosInstance);
      const values = form.values;
      const obj = {
        operationMode: values.operationMode,
        triggered: CO2OperationTriggerType.Start,
        vesselId: Number(vesselId),
      } as CO2OperationResponse;

      const entity = new CO2OperationResponse();
      entity.init(obj);
      await co2OperationClient.switchOperationMode(entity);

      loadLastOperationMode();
      loadOperationMode();

      NotificationHelper.showSuccess("Success", "Opeartion mode switched to start successfully");
    } catch (e) {
      NotificationHelper.showError("Error", "An error occurred in switching operation mode. Please refresh mauybe there is some changes.");
    }
  };

  const handleSwitchToEnd = async () => {
    try {
      const co2OperationClient = new CO2OperationClient(undefined, axiosInstance);
      const values = form.values;
      const obj = {
        operationMode: values.operationMode,
        triggered: CO2OperationTriggerType.End,
        vesselId: Number(vesselId),
      } as CO2OperationResponse;

      const entity = new CO2OperationResponse();
      entity.init(obj);
      await co2OperationClient.switchOperationMode(entity);

      loadLastOperationMode();
      loadOperationMode();

      NotificationHelper.showSuccess("Success", "Opeartion mode switched to end successfully");
    } catch (e) {
      NotificationHelper.showError("Error", "An error occurred in switching operation mode. Please refresh mauybe there is some changes.");
    }
  };

  const loadOperationModeControls = () => {
    if (form.values.triggered === CO2OperationTriggerType.Start) {
      return (
        <Button onClick={handleSwitchToEnd} variant="outline">
          {t("co2operationmode_switchtostopoperation")}
        </Button>
      );
    } else {
      return (
        <Button onClick={handleSwitchToStart} variant="outline">
          {t("co2operationmode_switchtostartoperation")}
        </Button>
      );
    }
  };

  useEffect(() => {
    if (!isSystemModeVessel()) {
      navigate("/error/only-for-onboard");
    }

    if (!mounted) {
      setMounted(true);
    }

    return () => {};
  }, [mounted]);

  const loadDataGrid = () => {
    return (
      <>
        <ThemeProvider theme={theme}>
          <DataGrid
            autoHeight
            rows={co2OperationMode}
            columns={[
              {
                field: "createdAt",
                headerName: t("co2operationmode_datetime"),
                flex: 1,
                renderCell: (cell) => (
                  <>
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    }).format(cell.row.createdAt)}
                  </>
                ),
              },
              {
                field: "operationMode",
                headerName: t("co2operationmode_operationmode"),
                flex: 1,
              },
              {
                field: "triggered",
                headerName: t("co2operationmode_startstop"),
                flex: 1,
                renderCell: (cell) => (
                  <>
                    {cell.row.triggered === "Start" && <PlayCircleFilledWhite color="success" />}
                    {cell.row.triggered === "End" && <PauseCircleFilled color="error" />}
                  </>
                ),
              },
            ]}
          />
        </ThemeProvider>
      </>
    );
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      loadLastOperationMode();
      loadOperationMode();
    }

    return () => {};
  }, [firstLoad, form, vesselId]);

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Group>
              <Title order={2}>{t("co2operationmode")}</Title>
            </Group>
            <Divider my="sm" size="md"></Divider>
            <Container size="xl">
              <Stack>
                <Card shadow="sm" p="lg" radius="sm" withBorder>
                  <Card.Section withBorder p="md">
                    <Title order={3}>{t("co2operationmode_operationgcontrol")}</Title>
                  </Card.Section>
                  <Card.Section withBorder p="lg">
                    <Stack>
                      <Group grow align="end">
                        <Select
                          label={t("co2operationmode_currentmode")}
                          description={t("co2operationmode_currentmodedesc")}
                          disabled={form.values.triggered === CO2OperationTriggerType.Start}
                          value={form.values.operationMode}
                          defaultValue={"Voyage"}
                          data={["Voyage", "STS", "Electrical", "Boiler", "Other"]}
                          {...form.getInputProps("operationMode")}
                        />
                      </Group>
                      <Group>{loadOperationModeControls()}</Group>
                      <hr />
                    </Stack>
                    <Stack mt="lg">{loadDataGrid()}</Stack>
                  </Card.Section>
                </Card>
              </Stack>
            </Container>
          </div>
        )}
      </Transition>
    </>
  );
};

export default IndexCO2OperationMode;
