import { ActionIcon, Button, Container, Divider, Group, Modal, Stack, Text, Title, Transition, useMantineTheme } from "@mantine/core";
import { Delete, Edit } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NotificationHelper from "../helpers/NotiHelper";
import { axiosInstance } from "../services/AxiosService";
import { AccountsClient, AppUserResponse } from "../services/WebApiService";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../providers/GlobalContextProvider";

type ModalProps = {
  isOpen: boolean;
  id: string;
};

const IndexUsers = () => {
  const { systemGlobal } = useContext(GlobalContext);
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [users, setUsers] = useState<AppUserResponse[]>([]);
  const [modalProps, setmodalProps] = useState<ModalProps>({ id: "", isOpen: false });
  const theme = createTheme({
    palette: {
      mode: systemGlobal.colorTheme === "dark" ? "dark" : "light",
    },
  });
  const mantineTheme = useMantineTheme();

  const { t } = useTranslation();

  const hideModal = () => {
    setmodalProps((x) => {
      return { ...x, isOpen: false } as ModalProps;
    });
  };

  const showModal = (id: string) => {
    setmodalProps((x) => {
      return { ...x, id: id, isOpen: true };
    });
  };

  const handleDeleteUser = async () => {
    if (modalProps.id) {
      try {
        const accountClient = new AccountsClient(undefined, axiosInstance);
        const response = await accountClient.delete(modalProps.id);
        if (response.succeeded) {
          setUsers((x) => x.filter((y) => y.id !== modalProps.id));
          NotificationHelper.showSuccess("Success", "User deleted successfully");
        } else {
          NotificationHelper.showError("Error", response.errors![0].description!);
        }
      } catch {
        NotificationHelper.showError("Error", "An error occurred in deleting user");
      }
      hideModal();
    }
  };

  const loadDataGrid = () => {
    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          loading={loading}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          autoHeight
          pagination
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20]}
          rows={users}
          columns={[
            // {
            //   field: "id",
            //   headerName: "Id",
            //   flex: 1,
            // },
            {
              field: "username",
              headerName: t("manageuser_name"),
              flex: 1,
            },
            {
              field: "role",
              headerName: t("manageuser_userrole"),
              flex: 1,
            },
            {
              field: "edit",
              headerName: t("manageuser_edit"),
              renderCell: (cell) => {
                return (
                  <ActionIcon variant="subtle" color={mantineTheme.primaryColor} component={Link} to={`/users/edit/${cell.row.id}`}>
                    <Edit />
                  </ActionIcon>
                );
              },
            },
            {
              field: "delete",
              headerName: t("manageuser_delete"),
              renderCell: (cell) => (
                <ActionIcon onClick={() => showModal(cell.row.id)} variant="subtle" color="red">
                  <Delete />
                </ActionIcon>
              ),
            },
          ]}
        />
      </ThemeProvider>
    );
  };

  useEffect(() => {
    const loadManagedUsers = async () => {
      if (users.length === 0) {
        setLoading(true);
        const accountClient = new AccountsClient(undefined, axiosInstance);
        const data = await accountClient.managedUsers();
        setUsers(data);
        setLoading(false);
      }
    };
    loadManagedUsers();
    return () => {};
  }, [users.length]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }

    return () => {};
  }, [mounted]);

  return (
    <>
      <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => (
          <Container size="xl" style={styles}>
            <Group>
              <Title order={3}>{t("manageuser_users")}</Title>
              <Button component={Link} to={`/users/create`}>
                {t("manageuser_createuser")}
              </Button>
            </Group>
            <Divider my="sm" size="md"></Divider>
            {loadDataGrid()}
          </Container>
        )}
      </Transition>
      <Modal opened={modalProps.isOpen} onClose={hideModal} title="Confirmation">
        <Stack>
          <Text>Are you sure you want to delete this user? This action is irreversible!</Text>
          <Group spacing="lg" align="center" grow>
            <Button variant="outline" color="red" onClick={handleDeleteUser}>
              Yes
            </Button>
            <Button onClick={hideModal}>No</Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};
export default IndexUsers;
