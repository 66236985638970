import { showNotification } from "@mantine/notifications";
import { CheckCircle, Error } from "@mui/icons-material";

const showNormal = (title: string, message: string) => {
  showNotification({
    title: title,
    message: message,
  })
}

const showSuccess = (title: string , message: string) =>{
  showNotification({
    title: title,
    message: message,
    icon: <CheckCircle/>,
    color: "green",
  })
}

const showError = (title: string, message: string) => {
  showNotification({
    title: title,
    message: message,
    icon: <Error />,
    color: "red",
  })
}

const showErrorNoAutoClose = (title: string, message: string) =>{
  showNotification({
    title: title,
    message: message,
    icon: <Error />,
    color: "red",
    autoClose: false,
  })
}

const NotificationHelper = {
  showNormal,
  showError,
  showSuccess,
  showErrorNoAutoClose
};

export default NotificationHelper;