import { Button, Group, Modal, Stack, TransferListData } from "@mantine/core";
import { useForm } from "@mantine/form";
import AuthService from "../../services/AuthService";
import NotificationHelper from "../../helpers/NotiHelper";
import { axiosInstance } from "../../services/AxiosService";
import { FleetClient, FleetPostRequest, FleetResponse } from "../../services/WebApiService";
import { useEffect, useState } from "react";
import FleetFormInputs from "./common/FleetFormInputs";
import { Fleet } from "../../models/fleet/Fleet";
import { useTranslation } from "react-i18next";

type ModalProps = {
  isOpen: boolean;
  id: number;
  fleet?: FleetResponse;
};

type ModalType = {
  [key: string]: ModalProps;
};

type Props = {
  hideModal: (type: string) => void;
  loadFleet?: () => void;
  modalProps: ModalType;
};

const CreateFleetForm = (props: Props) => {
  const [user, setUser] = useState<TransferListData>([[], []]);
  const [vessel, setVessel] = useState<TransferListData>([[], []]);

  const { t } = useTranslation();

  const commonForm = useForm<Fleet>({
    initialValues: {
      Name: "",
      IsActive: false,
    },
  });

  const handleSubmit = async () => {
    if (AuthService.isInRole("Admin")) {
      try {
        const values = commonForm.values;
        const fleetClient = new FleetClient(undefined, axiosInstance);
        const entity = {
          name: values.Name,
          isActive: values.IsActive,
          appUserFleet: user[1].map((x) => ({
            appUserId: x.value,
            fleetId: 0,
          })),
          fleetVessel: vessel[1].map((x) => ({
            vesselId: Number(x.value),
            fleetId: 0,
          })),
        };

        const fleetToCreate = new FleetPostRequest();
        fleetToCreate.init(entity);
        await fleetClient.post(fleetToCreate);
        NotificationHelper.showSuccess("Success", "Fleet is created successfully");
        props.hideModal("createFleet");
        if (props.loadFleet) props.loadFleet();
      } catch {
        NotificationHelper.showError("Error", "An error occurred in creating fleet");
      }
    }
  };

  useEffect(() => {
    commonForm.setFieldValue("Name", "");
    commonForm.setFieldValue("IsActive", false);
    return () => {};
  }, [props.modalProps["createFleet"].isOpen]);

  return (
    <>
      <Modal size="xl" opened={props.modalProps["createFleet"].isOpen} onClose={() => props.hideModal("createFleet")} title={t("createfleet")}>
        <Stack spacing="lg">
          <Group spacing="lg" align="center" grow>
            <form>
              <FleetFormInputs fleetId={0} form={commonForm} isEditMode={false} user={user} setUser={setUser} vessel={vessel} setVessel={setVessel} />
            </form>
          </Group>
          <Group position="right">
            <Button variant="outline" onClick={() => props.hideModal("createFleet")}>
              {t("createfleet_cancel")}
            </Button>
            <Button disabled={!AuthService.isInRole("Admin")} type="submit" onClick={handleSubmit}>
              {t("createfleet_create")}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default CreateFleetForm;
