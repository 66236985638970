import React from "react";
import { Accordion, Badge, Grid, Text } from "@mantine/core";
import SignalHelper from "../../../helpers/SignalHelper";
import { InstantValueOptions } from "../../../models/dashboard/DashboardModels";
import { DashboardSignalResponse, DashboardVesselResponse, DashboardWidgetResponse, HubSignalResponse } from "../../../services/WebApiService";
import "./InstantValueHelper.scss";

type InstantValueMapping = {
  key: string;
  signals: DashboardSignalResponse[];
};

type Props = {
  id: string;
  optionJson: string;
  dashboardVessels: DashboardVesselResponse[];
  dashboardWidget: DashboardWidgetResponse;
  hubSignals: HubSignalResponse[];
  dashboardType: "Fleet" | "Vessel";
};

const InstantValueHelper = (props: Props) => {
  const createInstantValue = () => {
    const options = JSON.parse(props.optionJson) as InstantValueOptions;
    const signalMappings: InstantValueMapping[] = [];
    for (let i = 0; i < options.mappingGroups.length; i++) {
      const mappings = options.mappingGroups[i].mappings;
      const signals = SignalHelper.getSignalsByMappingsByVessels(props.dashboardVessels, mappings, "false");
      signalMappings.push({
        key: options.mappingGroups[i].name,
        signals: signals,
      });
    }

    return options.mappingGroups.map((x, index) => {
      return (
        <Accordion.Item value={x.name} key={`InstantValue-${index}`}>
          <Accordion.Control>{x.name}</Accordion.Control>
          <Accordion.Panel>
            {props.dashboardType === "Vessel" &&
              signalMappings
                .find((y) => y.key === x.name)
                ?.signals.map((signal) => (
                  <Grid columns={10} p={2} className="instant-value-data" key={`InstantValueGrid-${signal.id}`}>
                    <Grid.Col span={5}>
                      <Text>{signal.displayName}</Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Text style={{ textAlign: "end" }}>{props.hubSignals.find((x) => x.id === signal.id)?.value ?? 0}</Text>
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <Text style={{ textAlign: "end" }}>{signal.unitDescription}</Text>
                    </Grid.Col>
                  </Grid>
                ))}

            {props.dashboardType === "Fleet" &&
              props.dashboardVessels.map((v) => {
                return (
                  <React.Fragment>
                    <Grid columns={10} p={2} key={`VesselGroup-${v.vesselId}`}>
                      <Grid.Col span={5}>
                        <Badge>{v.vesselName}</Badge>
                      </Grid.Col>
                    </Grid>
                    {signalMappings
                      .find((y) => y.key === x.name)
                      ?.signals.filter((s) => s.vesselId === v.vesselId)
                      ?.map((signal) => (
                        <Grid columns={10} p={2} className="instant-value-data" key={`InstantValueGrid-${signal.id}`}>
                          <Grid.Col span={5}>
                            <Text>{signal.displayName}</Text>
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Text style={{ textAlign: "end" }}>{props.hubSignals.find((x) => x.id === signal.id)?.value ?? 0}</Text>
                          </Grid.Col>
                          <Grid.Col span={2}>
                            <Text style={{ textAlign: "end" }}>{signal.unitDescription}</Text>
                          </Grid.Col>
                        </Grid>
                      ))}
                  </React.Fragment>
                );
              })}
          </Accordion.Panel>
        </Accordion.Item>
      );
    });
  };

  const getFirstGroupName = () => {
    const options = JSON.parse(props.optionJson) as InstantValueOptions;
    if (options.mappingGroups.length > 0) {
      return options.mappingGroups[0].name;
    }
    return "";
  };

  return (
    <Accordion defaultValue={getFirstGroupName()} variant="separated">
      {createInstantValue()}
    </Accordion>
  );
};

export default InstantValueHelper;
