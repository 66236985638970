import * as echarts from "echarts/core";
import waldenTheme from "../../../chart-theme/custom-walden.json";
import { GridComponent, GridComponentOption } from "echarts/components";
import { LineSeriesOption, ScatterChart, ScatterSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import {
  CurveResponse,
  CurvesClient,
  DashboardSignalResponse,
  DashboardVesselResponse,
  DashboardWidgetResponse,
  HubSignalResponse,
  SignalDataClient,
} from "../../../services/WebApiService";
import { useCallback, useContext, useEffect, useState } from "react";
import { ScatterChartOptions } from "../../../models/dashboard/DashboardModels";
import SignalHelper from "../../../helpers/SignalHelper";
import { axiosInstance } from "../../../services/AxiosService";
import { GlobalContext } from "../../../providers/GlobalContextProvider";

echarts.use([GridComponent, ScatterChart, CanvasRenderer, UniversalTransition]);

type EChartsOption = echarts.ComposeOption<GridComponentOption | ScatterSeriesOption | LineSeriesOption>;

type ChartState = {
  xAxisSignalId: number;
  yAxisSignalId: number;
  name: string;
  data: DataItem[];
};

type ChartSeries = {
  data: DataItem[];
};

type DataItem = {
  name: string;
  value: [number, number];
  itemStyle: ItemStyle;
};

type ItemStyle = {
  color: string;
};

type Props = {
  id: string;
  optionJson: string;
  dashboardVessels: DashboardVesselResponse[];
  dashboardWidget: DashboardWidgetResponse;
  hubSignals: HubSignalResponse[];
  selectedInterval: string;
  isEdit: boolean;
  curves: CurveResponse[];
};

const ScatterChartHelper = (props: Props) => {
  const [chart, setChart] = useState<echarts.ECharts>();
  const [chartData, setChartData] = useState<ChartState>();
  const [xAxisSignal, setXAxisSignal] = useState<DashboardSignalResponse>();
  const [yAxisSignal, setYAxisSignal] = useState<DashboardSignalResponse>();
  const [previousInterval, setPreviousInterval] = useState("");
  const { systemGlobal } = useContext(GlobalContext);

  const loadCurve = useCallback(async () => {
    let resultSeries: ChartSeries[] = [];
    const options = JSON.parse(props.optionJson) as ScatterChartOptions;
    if (options.curveId) {
      const curveIds: number[] = [parseInt(options.curveId)];
      const curvesClient = new CurvesClient(undefined, axiosInstance);
      const curves = await curvesClient.curvePoints(curveIds);
      const data: DataItem[] = [];
      if (curves.length > 0) {
        const curve = curves[0];
        for (let i = 0; i < curve.points.length; i++) {
          const point = curve.points[i];
          data.push({
            name: curve.name,
            value: [point.x, point.y],
            itemStyle: {
              color: "",
            },
          });
        }
        const obj = {
          name: curve.name,
          data: data,
        } as ChartSeries;
        resultSeries.push(obj);
        if (chart) {
          chart.setOption({
            series: resultSeries,
          });
        }
      }
    }
  }, [chart, props.optionJson]);

  // Create Chart
  useEffect(() => {
    const createChart = () => {
      const chartDom = document.getElementById(`widget-${props.id}`);
      const options = JSON.parse(props.optionJson) as ScatterChartOptions;
      const localXAxisSignal = SignalHelper.getFirstSignalFromMapping(props.dashboardVessels, options.xAxisMapping);
      const localYAxisSignal = SignalHelper.getFirstSignalFromMapping(props.dashboardVessels, options.yAxisMapping);

      if (chartDom && !chart && localYAxisSignal && localXAxisSignal) {
        setPreviousInterval(options.interval);
        echarts.registerTheme("walden", waldenTheme);
        const scatterChart = echarts.init(chartDom, "walden");
        let curve: CurveResponse | undefined;
        if (options.curveId) {
          curve = props.curves.find((x) => x.id === Number(options.curveId));
        }

        const eChartsOption: EChartsOption = {
          tooltip: {
            trigger: "item",
            axisPointer: {
              type: "cross",
            },
            // formatter: "{a} - {c} "
            // valueFormatter: (value : number) => '$' + value.toFixed(2)
          },
          // Need to empty legend to show legends
          legend: {
            textStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
          },
          yAxis: {
            type: "value",
            name: localYAxisSignal.displayName,
            nameTextStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
            axisLine: {
              show: true,
              onZero: false,
            },
            axisLabel: {
              formatter: `{value}`,
              color: systemGlobal.colorTheme === "dark" ? "white" : "black",
            },
          },
          xAxis: {
            type: "value",
            name: localXAxisSignal.displayName,
            nameTextStyle: { color: systemGlobal.colorTheme === "dark" ? "white" : "black" },
            axisLine: {
              show: true,
            },
            axisLabel: {
              formatter: `{value}`,
              color: systemGlobal.colorTheme === "dark" ? "white" : "black",
            },
          },
          series: [
            {
              name: `${localXAxisSignal.displayName} x ${localYAxisSignal.displayName}`,
              symbolSize: 8,
              type: "scatter",
              data: [],
            },
            {
              name: `${curve?.name ?? ""}`,
              type: "line",
              data: [],
              symbolSize: 0,
            },
          ],
        };
        setChartData({
          data: [],
          yAxisSignalId: localYAxisSignal.id,
          xAxisSignalId: localXAxisSignal.id,
          name: "",
        });
        setXAxisSignal(localXAxisSignal);
        setYAxisSignal(localYAxisSignal);
        scatterChart.setOption(eChartsOption);
        setChart(scatterChart);
      }
    };
    createChart();

    return () => {
      if (chart) {
        chart.dispose();
        setChart(undefined);
      }
    };
  }, [chart, props.curves, props.dashboardVessels, props.id, props.optionJson, systemGlobal.colorTheme]);

  // Chart data update
  useEffect(() => {
    let resultSeries: ChartSeries[] = [];
    if (chartData && xAxisSignal && yAxisSignal) {
      resultSeries.push({ data: chartData.data });
      if (chart) {
        chart.setOption({
          series: resultSeries,
        });
      }
    }
    return () => {};
  }, [chart, chartData, xAxisSignal, yAxisSignal]);

  // Live - update chart
  useEffect(() => {
    const updateChartData = () => {
      const options = JSON.parse(props.optionJson) as ScatterChartOptions;
      if (yAxisSignal && xAxisSignal) {
        const xSignal = props.hubSignals.find((x) => x.id === xAxisSignal.id);
        const ySignal = props.hubSignals.find((x) => x.id === yAxisSignal.id);
        if (xSignal && ySignal) {
          if (previousInterval !== "0") {
            setPreviousInterval("0");
            setChartData((current) => {
              if (current) {
                current.data = [];
              }
              return current;
            });
          }
          setChartData((current) => {
            if (current) {
              if (current.data.length > 0) {
                current.data[current.data.length - 1].itemStyle.color = "#5470c6"; // Reset to hardcoded value
              }
              if (current.data.length > options.maxPoints) {
                current.data.shift();
              }
              return {
                ...current,
                data: [
                  ...current.data,
                  {
                    name: xSignal.value.toString(),
                    value: [xSignal.value, ySignal.value],
                    itemStyle: {
                      color: "#ff0000",
                    },
                  },
                ],
              };
            }
            return current;
          });
        }
      }
    };

    if (props.selectedInterval === "0") {
      updateChartData();
    }
    return () => {};
  }, [previousInterval, props.hubSignals, props.optionJson, props.selectedInterval, xAxisSignal, yAxisSignal]);

  // Interval Change
  useEffect(() => {
    const updateChartQuery = async () => {
      setPreviousInterval(props.selectedInterval);
      if (xAxisSignal && yAxisSignal) {
        const options = JSON.parse(props.optionJson) as ScatterChartOptions;
        const signalIds = [xAxisSignal.id, yAxisSignal.id];
        const signalDataClient = new SignalDataClient(undefined, axiosInstance);
        const response = await signalDataClient.get(signalIds, parseInt(props.selectedInterval), options.maxPoints);
        const xSignal = response.find((x) => x.signalId === xAxisSignal.id);
        const ySignal = response.find((x) => x.signalId === yAxisSignal.id);
        if (xSignal && ySignal) {
          const data: DataItem[] = [];
          for (let i = 0; i < xSignal.signalData.length; i++) {
            data.push({
              name: xSignal.signalData[i].value.toString(),
              value: [xSignal.signalData[i].value, ySignal.signalData[i].value],
              itemStyle: {
                color: "#65C1E9", // hard coded value
              },
            });
          }
          setChartData((prevState) => ({ ...prevState, data: data } as ChartState));
        }
      }
    };
    if (chart) {
      chart.showLoading();
      loadCurve();
      chart.hideLoading();
      if (props.selectedInterval !== "0" && chart && !props.isEdit) {
        chart.showLoading();
        updateChartQuery();
        chart.hideLoading();
      }
    }

    return () => {};
  }, [chart, loadCurve, props.isEdit, props.optionJson, props.selectedInterval, xAxisSignal, yAxisSignal]);

  // Resize
  useEffect(() => {
    if (chart) {
      chart.resize();
    }
    return () => {};
  }, [chart, props.dashboardWidget]);

  return <></>;
};

export default ScatterChartHelper;
