import { useEffect, useState } from "react";
import { VesselResponse, MappingDefResponse, ClassificationPropertysClient } from "../services/WebApiService";

import { createContext } from "react";
import { axiosInstance } from "../services/AxiosService";
import SignalHelper from "../helpers/SignalHelper";
import { EnvService } from "../services/EnvService";
import { ColorScheme } from "@mantine/core";

type SystemGlobal = {
  systemMode: string; // System mode "Vessel" or "Fleet"
  dashboardVesselId: number; // Current vessel id for dropdown list in top nav
  dashboardFleetId: number; // Current fleet id for dropdown list in top nav
  vesselName: string; // Current vessel name for dropdown list in top nav
  displayVessels: VesselResponse[]; // User display vessels
  adminVessels: VesselResponse[]; // Vessels that user have admin access for (Company Admins only)
  classifications: MappingDefResponse[];
  mappingDefs: MappingDefResponse[];
  colorTheme: ColorScheme;
};

type SystemGlobalContextType = {
  systemGlobal: SystemGlobal;
  setDashboardVesselId(vesselId: number): void;
  setDashboardFleetId(fleetId: number): void;
  setDashboardVesselName(name: string): void;
  setDisplayVessels(vessels: VesselResponse[]): void;
  setAdminVessels(vessels: VesselResponse[]): void;
  setClassifications(value: MappingDefResponse[]): void;
  setMappingDefs(value: MappingDefResponse[]): void;
  setColortheme(theme: ColorScheme): void;
  isSystemModeVessel(): boolean;
};

export const GlobalContext = createContext<SystemGlobalContextType>({
  systemGlobal: {
    adminVessels: [],
    displayVessels: [],
    dashboardFleetId: 0,
    dashboardVesselId: 0,
    vesselName: "",
    systemMode: "Vessel",
    classifications: [],
    mappingDefs: [],
    colorTheme: "light",
  },
  setDashboardFleetId: () => console.error("No function provided"),
  setDashboardVesselId: () => console.error("No function provided"),
  setDashboardVesselName: () => console.error("No function provided"),
  setAdminVessels: () => console.error("No function provided"),
  setDisplayVessels: () => console.error("No function provided"),
  setClassifications: () => console.error("No function provided"),
  setMappingDefs: () => console.error("No function provided"),
  setColortheme: () => console.error("No function provided"),
  isSystemModeVessel: () => {
    return false;
  },
});

type Props = {
  children: React.ReactNode;
};

export const GlobalContextProvider = ({ children }: Props) => {
  const [systemGlobal, setSystemGlobal] = useState<SystemGlobal>({
    adminVessels: [],
    displayVessels: [],
    dashboardFleetId: 0,
    dashboardVesselId: 0,
    vesselName: "",
    systemMode: EnvService.systemMode ?? "",
    classifications: [],
    mappingDefs: [],
    colorTheme: "light",
  });

  const isSystemModeVessel = () => {
    return systemGlobal.systemMode === "Vessel";
  };

  const setDisplayVessels = (vessels: VesselResponse[]) => {
    setSystemGlobal((prevState) => ({
      ...prevState,
      displayVessels: vessels,
    }));
  };

  const setAdminVessels = (vessels: VesselResponse[]) => {
    setSystemGlobal((prevState) => ({
      ...prevState,
      adminVessels: vessels,
    }));
  };

  const setDashboardFleetId = (fleetId: number) => {
    setSystemGlobal((prevState) => ({
      ...prevState,
      dashboardFleetId: fleetId,
    }));
  };

  const setDashboardVesselId = (vesselId: number) => {
    setSystemGlobal((prevState) => ({
      ...prevState,
      dashboardVesselId: vesselId,
    }));
  };

  const setDashboardVesselName = (name: string) => {
    setSystemGlobal((prevState) => ({
      ...prevState,
      vesselName: name,
    }));
  };

  const setClassifications = (classifications: MappingDefResponse[]) => {
    setSystemGlobal((prevState) => ({
      ...prevState,
      classifications: classifications,
    }));
  };

  const setMappingDefs = (mappingDefs: MappingDefResponse[]) => {
    setSystemGlobal((prevState) => ({
      ...prevState,
      mappingDefs: mappingDefs,
    }));
  };

  const setColortheme = (theme: ColorScheme) => {
    setSystemGlobal((prevState) => ({
      ...prevState,
      colorTheme: theme,
    }));
  };

  useEffect(() => {
    const loadMappings = async () => {
      const mappingClient = new ClassificationPropertysClient(undefined, axiosInstance);
      const mappings = await mappingClient.get();
      const classifications = SignalHelper.getOnlyClassifications(mappings);
      setSystemGlobal((prevState) => ({
        ...prevState,
        classifications: classifications,
        mappingDefs: mappings,
      }));
    };
    loadMappings();
    return () => {};
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        systemGlobal,
        setDashboardVesselId,
        setDashboardFleetId,
        setDashboardVesselName,
        setAdminVessels,
        setDisplayVessels,
        setClassifications,
        setMappingDefs,
        setColortheme,
        isSystemModeVessel,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
